import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Btn } from "../../../../../AbstractElements";
import NurseryAPi from "../../../../../services/NurseryApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../../../Utils";
import { toast } from "react-toastify";

const StaffLeaveForm = (props: any) => {
  const initialState: any = {
    id: "",
    title: "",
    nurseryId: props.nurseryId,
    startDate: new Date(),
    endDate: new Date(),
    leave: {
      annualLeave: "",
      sickLeave: "",
      maternityLeave: "",
      paternityLeave: "",
      bereavementLeave: "",
      compassionateLeave: "",
      unPaidLeave: "",
      publicHolidayLeave: "",
      educationalLeave: "",
      sabbaticalLeave: "",
      culturalLeave: "",
      militaryLeave: "",
      medicalLeave: "",
      adoptionLeave: "",
      juryDutyLeave: "",
      toilLeave: "",
    },
  };
  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const saveDataForm = (name: string, value: any, type?: any) => {
    if (type === "leave") {
      setForm({
        ...form,
        leave: {
          ...form.leave,
          [name]: value,
        },
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      // Format the data before sending it to the API
      let formattedData: any = {
        ...form.leave,
        id: form.id,
        nurseryId: form.nurseryId,
        title: form.title,
        startDate: form.startDate.getTime(),
        endDate: form.endDate.getTime(),
      };
      const response = await NurseryAPi.addUpdateLeave(formattedData);
      Swal.fire({
        title: "Success!",
        text: `${response.message}`,
        icon: "success",
      }).then(async () => {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData("Settings", `${response.message}`)
        );
        props.setAction({ id: "", action: "List" });
      });
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    saveDataForm("nurseryId", props.nurseryId);
  }, [props.nurseryId]);

  const getLeave = async () => {
    try {
      const response = await NurseryAPi.getLeave({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        const filteredData = response?.data.filter(
          (row: any) => row.id === props.id
        );
        if (filteredData.length > 0) {
          const formData = {
            id: filteredData?.[0]?.id,
            title: filteredData?.[0]?.title,
            nurseryId: props.nurseryId,
            startDate: new Date(filteredData?.[0]?.startDate),
            endDate: new Date(filteredData?.[0]?.endDate),
            leave: {
              annualLeave: filteredData?.[0]?.annualLeave,
              sickLeave: filteredData?.[0]?.sickLeave,
              maternityLeave: filteredData?.[0]?.maternityLeave,
              paternityLeave: filteredData?.[0]?.paternityLeave,
              bereavementLeave: filteredData?.[0]?.bereavementLeave,
              compassionateLeave: filteredData?.[0]?.compassionateLeave,
              unPaidLeave: filteredData?.[0]?.unPaidLeave,
              publicHolidayLeave: filteredData?.[0]?.publicHolidayLeave,
              educationalLeave: filteredData?.[0]?.educationalLeave,
              sabbaticalLeave: filteredData?.[0]?.sabbaticalLeave,
              culturalLeave: filteredData?.[0]?.culturalLeave,
              militaryLeave: filteredData?.[0]?.militaryLeave,
              medicalLeave: filteredData?.[0]?.medicalLeave,
              adoptionLeave: filteredData?.[0]?.adoptionLeave,
              juryDutyLeave: filteredData?.[0]?.juryDutyLeave,
              toilLeave: filteredData?.[0]?.toilLeave,
            },
          };

          reset(formData);
          setForm(formData);
        }
      } else {
        reset(initialState);
        setForm(initialState);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    props.id != "" && getLeave();
  }, [props.id]);

  return (
    <Card className="card-absolute">
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ borderBottom: "5px solid #eee" }}
      >
        <CommonCardHeader
          title={`${props.action} Staff Leave`}
          headClass="bg-primary"
          titleClass="text-light"
        />
        <Button
          color="dark"
          className="btn-md m-4 d-flex align-items-center mb-3 mt-3"
          onClick={() => props.setAction({ id: "", action: "List" })}
        >
          <i
            className="iconly-Arrow-Left icli"
            style={{ marginRight: "0.5rem" }}
          ></i>{" "}
          Back
        </Button>
      </div>
      <CardBody>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="mt-3">
            {/* Title */}
            <Col md={12}>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  type="text"
                  placeholder="Enter Title"
                  value={form?.title || ""}
                  onChange={(e) => saveDataForm("title", e.target.value)}
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <Label>
                {"Start Date"} <span className="text-danger">*</span>
              </Label>
              <InputGroup className="flatpicker-calender">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        className={`form-control flatpickr-input ${
                          errors.startDate ? "is-invalid" : ""
                        }`}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          saveDataForm("startDate", date);
                        }}
                        placeholderText="Select a date"
                        dateFormat="MMMM dd,yyyy"
                      />
                    );
                  }}
                />

                {errors.startDate && (
                  <FormFeedback
                    style={{ display: "block", color: "orangered" }}
                  >
                    <>{"Please select start date."}</>
                  </FormFeedback>
                )}
              </InputGroup>
            </Col>

            <Col md={6} className="position-relative">
              <Label>
                {"End Date"} <span className="text-danger">*</span>
              </Label>
              <InputGroup className="flatpicker-calender">
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        className={`form-control flatpickr-input ${
                          errors.endDate ? "is-invalid" : ""
                        }`}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          saveDataForm("endDate", date);
                        }}
                        placeholderText="Select a date"
                        dateFormat="MMMM dd,yyyy"
                      />
                    );
                  }}
                />

                {errors.endDate && (
                  <FormFeedback
                    style={{ display: "block", color: "orangered" }}
                  >
                    <>{"Please select end date."}</>
                  </FormFeedback>
                )}
              </InputGroup>
            </Col>

            {/* Annual Leave */}
            <Col md={6} className="position-relative mt-3">
              <FormGroup>
                <Label>Annual Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Annual Leave Days"
                  value={form?.leave?.annualLeave || ""}
                  onChange={(e) =>
                    saveDataForm("annualLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            {/* Sick Leave */}
            <Col md={6} className="position-relative mt-3">
              <FormGroup>
                <Label>Sick Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Sick Leave Days"
                  value={form?.leave?.sickLeave || ""}
                  onChange={(e) =>
                    saveDataForm("sickLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            {/* Maternity Leave */}
            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Maternity Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Maternity Leave Days"
                  value={form?.leave?.maternityLeave || ""}
                  onChange={(e) =>
                    saveDataForm("maternityLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>
            {/* Paternity Leave */}
            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Paternity Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Paternity Leave Days"
                  value={form?.leave?.paternityLeave || ""}
                  onChange={(e) =>
                    saveDataForm("paternityLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            {/* Bereavement Leave */}
            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Bereavement Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Bereavement Leave Days"
                  value={form?.leave?.bereavementLeave || ""}
                  onChange={(e) =>
                    saveDataForm("bereavementLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Compassionate Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Compassionate Leave Days"
                  value={form?.leave?.compassionateLeave || ""}
                  onChange={(e) =>
                    saveDataForm("compassionateLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Unpaid Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Unpaid Leave Days"
                  value={form?.leave?.unPaidLeave || ""}
                  onChange={(e) =>
                    saveDataForm("unPaidLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Public Holiday Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Public Holiday Leave Days"
                  value={form?.leave?.publicHolidayLeave || ""}
                  onChange={(e) =>
                    saveDataForm("publicHolidayLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label> Study or Educational Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Study Leave Days"
                  value={form?.leave?.educationalLeave || ""}
                  onChange={(e) =>
                    saveDataForm("educationalLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Sabbatical Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Sabbatical Leave Days"
                  value={form?.leave?.sabbaticalLeave || ""}
                  onChange={(e) =>
                    saveDataForm("sabbaticalLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Religious or Cultural Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Religious Leave Days"
                  value={form?.leave?.culturalLeave || ""}
                  onChange={(e) =>
                    saveDataForm("culturalLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Military Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Military Leave Days"
                  value={form?.leave?.militaryLeave || ""}
                  onChange={(e) =>
                    saveDataForm("militaryLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Family and Medical Leave (FMLA in the U.S.)</Label>
                <Input
                  type="number"
                  placeholder="Enter Family and Medical Leave Days"
                  value={form?.leave?.medicalLeave || ""}
                  onChange={(e) =>
                    saveDataForm("medicalLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Adoption Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Adoption Leave Days"
                  value={form?.leave?.adoptionLeave || ""}
                  onChange={(e) =>
                    saveDataForm("adoptionLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Jury Duty Leave</Label>
                <Input
                  type="number"
                  placeholder="Enter Jury Duty Leave Days"
                  value={form?.leave?.juryDutyLeave || ""}
                  onChange={(e) =>
                    saveDataForm("juryDutyLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>

            <Col md={6} className="position-relative">
              <FormGroup>
                <Label>Time Off in Lieu (TOIL)</Label>
                <Input
                  type="number"
                  placeholder="Enter TOIL Days"
                  value={form?.leave?.toilLeave || ""}
                  onChange={(e) =>
                    saveDataForm("toilLeave", e.target.value, "leave")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex mt-4 justify-content-end">
            <Btn
              color="primary"
              style={{ marginRight: "0.5rem" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="d-flex gap-2">
                  <Spinner color="white" size="sm" /> Loading...
                </span>
              ) : (
                <>
                  {props.action === "Add" ? "Save & Update" : "Update & Save"}
                </>
              )}
            </Btn>
            <Button
              color="dark"
              className="btn-md m-0 d-flex align-items-center"
              onClick={() => props.setAction({ id: "", action: "List" })}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default StaffLeaveForm;
