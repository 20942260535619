import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader'
import DataTable, { TableColumn } from 'react-data-table-component'
import FilterComponent from 'src/Components/Tables/DataTables/Common/FilterComponent'
import { Btn, LI, UL } from 'src/AbstractElements'
import PrescribedMedicationForm from './form'
import MedicationAPi from 'src/services/MedicationApi'


const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);


function Medication(props: any) {
    const [medication, setMedication] = useState<any>([]);
    const [selectedId, setSelectedId] = useState("");
    const [filterText, setFilterText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const filteredItems: any = medication?.filter((item: any) => {
        item = {
            name: `${item.children.firstName} ${item.children.lastName}`,
            kind: `${item.kind}`,
            location: `${item.location}`,
        };
        return Object.values(item).some(
            (value) =>
                value &&
                value
                    .toString()
                    .toLowerCase()
                    .includes(filterText.toLowerCase())
        );
    });

    const handleEditAccident = (row: any) => {
        setSelectedId(row.id);
        setIsModalOpen(!isModalOpen);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Child Name" />,
            selector: (row) => `${row.children.firstName} ${row.children.lastName}`,
            sortable: true,
            style: {
                minWidth: "42%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "42%", // Set consistent width
            maxWidth: "42%",
        },
        {
            name: <CustomHeader title="Medication" />,
            selector: (row) => row.medication,
            sortable: true,
            style: {
                minWidth: "20%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "20%", // Set consistent width
            maxWidth: "20%",
        },
        {
            name: <CustomHeader title="Dose Amount" />,
            selector: (row) => row.doseAmount,
            sortable: true,
            style: {
                minWidth: "20%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "20%", // Set consistent width
            maxWidth: "20%",
        },
        {
            name: <CustomHeader title="Action" />,
            style: {
                minWidth: "120px", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "120px", // Set consistent width
            maxWidth: "120px",
            center: true, // Center-aligns content in both header and cells
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            href="javascript:void(0);"
                            onClick={() => handleEditAccident(row)}
                            className="icon"
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil me-1 text-white"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setSelectedId("");
    };

    const fetchMedication = async () => {
        try {
            const response = await MedicationAPi.getMedications(props.nurseryId, {});
            if (response.success && Array.isArray(response.data)) {
                setMedication(response.data);
            } else setMedication([]);
        } catch (error: any) {
            setMedication([]);
        }
    };

    useEffect(() => {
        fetchMedication();
    }, [props.nurseryId]);

    return (
        <>
            <Card className="card-absolute">
                <div
                    className="d-flex justify-content-end align-items-center"
                    style={{ borderBottom: "5px solid #eee" }}
                >
                    <CommonCardHeader
                        title="Medication"
                        headClass="bg-primary"
                        titleClass="text-light"
                    />
                    <Button
                        color="primary"
                        className="btn-md m-4 mb-3 mt-3"
                        onClick={toggleModal}
                    >
                        <i className="fa fa-plus me-2" /> Add
                    </Button>
                </div>
                <CardBody>
                    <Row className="justify-content-end">
                        <Col md={6}>
                            <FilterComponent
                                onFilter={(e) => setFilterText(e.target.value)}
                                filterText={filterText}
                            />
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <DataTable
                            data={filteredItems}
                            columns={columns}
                            pagination
                            className="display"
                            conditionalRowStyles={[
                                {
                                    when: (row) => true,
                                    style: {
                                        "&:hover": {
                                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                                            cursor: "pointer",
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>

            <PrescribedMedicationForm id={selectedId} medication={medication} isOpen={isModalOpen} toggle={toggleModal} nurseryId={props.nurseryId} fetchMedication={fetchMedication} />
        </>
    )
}

export default Medication