import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { countryCode } from "../../../../../Utils/CountryCode";
import { Btn } from "src/AbstractElements";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Health(props: any) {
  const navigate = useNavigate();

  const initialState = {
    id: "",
    allergy: "",
    isPenicillinTolerant: "",
    dietaryPreferences: "",
    vaccines: "",
    heathSpecialNote: "",
    doctorName: "",
    doctorPhoneNo: "",
    doctorAddress: "",
    doctorPostcode: "",
    doctorCity: "",
    dentistName: "",
    dentistPhoneNo: "",
    dentistAddress: "",
    dentistPostcode: "",
    dentistCity: "",
  };

  const [form, setForm] = useState<any>(props.initialState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.createAndUpdateChild({
        ...form,
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Children", response.message)
          );
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const modifiedObj = commonKeysObject(props.initialState, initialState);
    setForm(modifiedObj);
    reset(modifiedObj);
  }, [props.initialState]);

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Heath Information"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Allergy"}</Label>
                  <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Enter your allergy"
                    {...register("allergy")}
                    value={form.allergy || ""}
                    onChange={(e) => saveDataForm("allergy", e.target.value)}
                  ></textarea>

                  {errors.allergy && (
                    <span style={{ color: "red" }}>
                      {"Allergy is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={12} className="position-relative">
                <Label style={{ marginRight: "1.5rem" }}>
                  Tolerates Penicillin
                </Label>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id={`isPenicillinTolerant-YES`}
                    className="form-check-input"
                    {...register(`isPenicillinTolerant`, {
                      required: false,
                    })}
                    value={"Yes"}
                    checked={form.isPenicillinTolerant === "Yes"}
                    onChange={(e) =>
                      saveDataForm("isPenicillinTolerant", e.target.value)
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={`isPenicillinTolerant-YES`}
                  >
                    Yes
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id={`isPenicillinTolerant-NO`}
                    value="No"
                    className="form-check-input"
                    {...register(`isPenicillinTolerant`, {
                      required: false,
                    })}
                    checked={form.isPenicillinTolerant === "No"}
                    onChange={(e) =>
                      saveDataForm("isPenicillinTolerant", e.target.value)
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={`isPenicillinTolerant-NO`}
                  >
                    No
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id={`isPenicillinTolerant-MAYBE`}
                    value="Maybe Not"
                    className="form-check-input"
                    {...register(`isPenicillinTolerant`, {
                      required: false,
                    })}
                    checked={form.isPenicillinTolerant === "Maybe Not"}
                    onChange={(e) =>
                      saveDataForm("isPenicillinTolerant", e.target.value)
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={`isPenicillinTolerant-MAYBE`}
                  >
                    Unknown
                  </Label>
                </div>
                {errors.isPenicillinTolerant && (
                  <span style={{ color: "red" }}>
                    {"Tolerates Penicillin is required"}{" "}
                  </span>
                )}
              </Col>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Special Dietary Considerations"}</Label>
                  <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Enter your dietary"
                    {...register("dietaryPreferences")}
                    value={form.dietaryPreferences || ""}
                    onChange={(e) =>
                      saveDataForm("dietaryPreferences", e.target.value)
                    }
                  ></textarea>

                  {errors.dietaryPreferences && (
                    <span style={{ color: "red" }}>
                      {"Special Dietary Considerations is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Vaccines"}</Label>
                  <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Enter your vaccines"
                    {...register("vaccines")}
                    value={form.vaccines || ""}
                    onChange={(e) => saveDataForm("vaccines", e.target.value)}
                  ></textarea>

                  {errors.vaccines && (
                    <span style={{ color: "red" }}>
                      {"Vaccines is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Special Note"}</Label>
                  <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Enter your notes here"
                    {...register("heathSpecialNote")}
                    value={form.heathSpecialNote || ""}
                    onChange={(e) =>
                      saveDataForm("heathSpecialNote", e.target.value)
                    }
                  ></textarea>

                  {errors.heathSpecialNote && (
                    <span style={{ color: "red" }}>
                      {"Special Note is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} xl={12} className="pt-2">
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Doctor Info"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={6}>
                <FormGroup>
                  <Label>{"Name"}</Label> <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.doctorName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Doctor Name"
                    {...register("doctorName", { required: true })}
                    value={form.doctorName}
                    onChange={(e) => saveDataForm("doctorName", e.target.value)}
                  />
                  {errors.doctorName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Doctor Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={6} className="position-relative">
                <Label>
                  {"Mobile"} <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <input
                    type="tel"
                    className={`form-control ${
                      errors.doctorPhoneNo ? "is-invalid" : ""
                    }`}
                    placeholder="Enter your mobile"
                    {...register(`doctorPhoneNo`, {
                      required: "Doctor Phone no is required",
                      pattern: {
                        value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                        message: "Please enter a valid 11-digit phone number",
                      },
                    })}
                    value={form.doctorPhoneNo}
                    onChange={(e) =>
                      saveDataForm("doctorPhoneNo", e.target.value)
                    }
                  />
                  <InputGroupText className="list-light-primary">
                    <i className="fa-solid fa-phone text-primary" />
                  </InputGroupText>
                </InputGroup>
                {errors.doctorPhoneNo && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {`${errors?.doctorPhoneNo?.message}`}{" "}
                  </FormFeedback>
                )}
              </Col>

              <Col md={7}>
                <FormGroup>
                  <Label>{"Address"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.doctorAddress ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Doctor Address"
                    {...register("doctorAddress", { required: true })}
                    value={form.doctorAddress}
                    onChange={(e) =>
                      saveDataForm("doctorAddress", e.target.value)
                    }
                  />
                  {errors.doctorAddress && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Doctor Address is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>{"Postcode"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.doctorPostcode ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Postcode"
                    {...register("doctorPostcode", { required: true })}
                    value={form.doctorPostcode}
                    onChange={(e) =>
                      saveDataForm("doctorPostcode", e.target.value)
                    }
                  />
                  {errors.doctorPostcode && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Doctor Postcode is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>{"City"}</Label> <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.doctorCity ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Doctor City"
                    {...register("doctorCity", { required: true })}
                    value={form.doctorCity}
                    onChange={(e) => saveDataForm("doctorCity", e.target.value)}
                  />
                  {errors.doctorCity && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Doctor City is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} xl={12} className="pt-2">
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Dentist Info"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={6}>
                <FormGroup>
                  <Label>{"Name"}</Label> <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.dentistName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Dentist Name"
                    {...register("dentistName", { required: true })}
                    value={form.dentistName}
                    onChange={(e) =>
                      saveDataForm("dentistName", e.target.value)
                    }
                  />
                  {errors.dentistName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Dentist Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={6} className="position-relative">
                <Label>
                  {"Mobile"} <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <input
                    type="tel"
                    className={`form-control  ${
                      errors.dentistPhoneNo ? "is-invalid" : ""
                    }`}
                    placeholder="Enter your mobile"
                    {...register(`dentistPhoneNo`, {
                      required: "Dentist Phone no is required",
                      pattern: {
                        value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                        message: "Please enter a valid 11-digit phone number",
                      },
                    })}
                    value={form.dentistPhoneNo}
                    onChange={(e) =>
                      saveDataForm("dentistPhoneNo", e.target.value)
                    }
                  />
                  <InputGroupText className="list-light-primary">
                    <i className="fa-solid fa-phone text-primary" />
                  </InputGroupText>
                  {errors.dentistPhoneNo && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {`${errors?.dentistPhoneNo?.message}`}{" "}
                    </FormFeedback>
                  )}
                </InputGroup>
              </Col>

              <Col md={7}>
                <FormGroup>
                  <Label>{"Address"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.dentistAddress ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Dentist Address"
                    {...register("dentistAddress", { required: true })}
                    value={form.dentistAddress}
                    onChange={(e) =>
                      saveDataForm("dentistAddress", e.target.value)
                    }
                  />
                  {errors.dentistAddress && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Dentist Address is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>{"Postcode"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.dentistPostcode ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Postcode"
                    {...register("dentistPostcode", { required: true })}
                    value={form.dentistPostcode}
                    onChange={(e) =>
                      saveDataForm("dentistPostcode", e.target.value)
                    }
                  />
                  {errors.dentistPostcode && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Dentist Postcode is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>{"City"}</Label> <span className="text-danger">*</span>
                  <input
                    className={`form-control ${
                      errors.dentistCity ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Dentist City"
                    {...register("dentistCity", { required: true })}
                    value={form.dentistCity}
                    onChange={(e) =>
                      saveDataForm("dentistCity", e.target.value)
                    }
                  />
                  {errors.dentistCity && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Dentist City is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save & Update"
                )}
              </Btn>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    </Form>
  );
}

export default Health;
