import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
        nurseryId: state.auth.selectedNursery,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export const connectComponent = (Component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(Component);
};
