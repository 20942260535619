import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Btn, LI, UL } from "../../../../../AbstractElements";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../../Components/Tables/DataTables/Common/FilterComponent";
import NurseryAPi from "../../../../../services/NurseryApi";
import Swal from "sweetalert2";
import moment from "moment";
import Status from "../../../../../Components/Common/Status";
import { statusColors } from "../../../../../Utils/Constants";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const StaffLeaveList = (props: any) => {
  const navigate = useNavigate();
  const [leave, setLeave] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const [dropdownOpenOne, setDropdownOpenOne] = useState<{ [key: string]: boolean }>({});
  const toggleDropdownOne = (id: string) => {
    setDropdownOpenOne((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleEditLeave = (leave: any) => {
    props.setAction({ id: leave.id, action: "Edit" });
  };

  // Fetch Leave from the API
  const fetchLeave = async () => {
    try {
      const response = await NurseryAPi.getLeave({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        setLeave(response.data);
      } else {
        throw new Error("Failed to fetch Leave");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Leave",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchLeave();
  }, [props.nurseryId]);

  const filteredItems = leave.filter((leave) =>
    leave.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleStatusChange = async (row: any, status: string) => {
    const currentStatus = status == "ACTIVE" ? 0 : 1;
    const id = row.id;

    try {
      // Call the API to update the status, passing the boolean value
      await NurseryAPi.addUpdateLeave({
        status: currentStatus,
        id,
      });
      toggleDropdownOne(id);
      // Update the local state to reflect the status change
      await fetchLeave();
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="Start Date" />,
      selector: (row) => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="End Date" />,
      selector: (row) => moment(row.endDate).format("DD-MM-YYYY"),
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
      cell: (row) => {
        return (
          <Status
            position={row?.status === 0 ? "ACTIVE" : "INACTIVE"}
            color={
              row?.status === 0
                ? {
                  text: statusColors.active.text,
                  background: statusColors.active.background,
                }
                : {
                  text: statusColors.inactive.text,
                  background: statusColors.inactive.background,
                }
            }
            id={row.id}
            row={row}
            handleStatusChange={handleStatusChange}
            newUI={true}
            statusList={["ACTIVE", "INACTIVE"]}
            toggleDropdownOne={toggleDropdownOne}
            dropdownOpenOne={dropdownOpenOne}
          />
        );
      },
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="javascript:void(0);"
              onClick={() => handleEditLeave(row)}
              className="icon"
            >
              <Btn color="primary" size="sm">
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
                {"Edit"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <Card className="card-absolute">
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ borderBottom: "5px solid #eee" }}
      >
        <CommonCardHeader
          title="Staff Leave"
          headClass="bg-primary"
          titleClass="text-light"
        />
        <Button
          color="primary"
          className="btn-md m-4 mb-3 mt-3"
          onClick={() => props.setAction({ id: "", action: "Add" })}
        >
          <i className="fa fa-plus me-2" /> Add Leave
        </Button>
      </div>
      <CardBody>
        <Row className="justify-content-end">
          <Col md={6}>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              filterText={filterText}
            />
          </Col>
        </Row>
        <div className="table-responsive">
          <DataTable
            data={filteredItems}
            columns={columns}
            pagination
            className="display"
            conditionalRowStyles={[
              {
                when: (row) => true,
                style: {
                  "&:hover": {
                    backgroundColor: "rgba(48, 142, 135, 0.2)",
                    cursor: "pointer",
                  },
                },
              },
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default StaffLeaveList;
