import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../../Components/Forms/Common/DropItem";
import { Btn, H3, H4 } from "../../../../AbstractElements";
import {
  assignedAsOptions,
  employeeType,
  FirstName,
  LastName,
  LooksGood,
} from "../../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormDataTypes } from "../../../../Types/Forms.type";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { countryCode } from "../../../../Utils/CountryCode";
import Select from "react-select";
import RolesAPi from "../../../../services/RolesApi";
import DropDownAPi from "../../../../services/DropdownApi";
import UploadAPi from "../../../../services/UploadApi";
import UserApi from "../../../../services/UserApi";
import {
  commonKeysObject,
  getUserActivityFormattedData,
  timeZone,
} from "../../../../Utils";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import { baseUrl } from "../../../../Utils/Constants/config";
import JobTitleApi from "../../../../services/JobTitleApi";
import NurseryAPi from "../../../../services/NurseryApi";
import { connectComponent } from "./connector";
import RoomOverviewApi from "src/services/RoomOverviewApi";
import { s } from "node_modules/@fullcalendar/core/internal-common";
import { set } from "node_modules/@types/lodash";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
interface IAddEmployeeForm {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  role?: string;
  joinDate: any;
  dob?: any;
  nationality: string;
  packageId?: string;
  timeZone?: string;
  avatarUrl: string;
  employeeType?: string;
  employeeId?: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  postalCode: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNo: string;
  emergencyContactRelation: string;
  jobTitleId: string;
  monthlySalary: string;
  nurseryId: string;
  empRoomArrObj: {
    roomId: string;
    assignedAs: string;
  }[];
  docFolderId: string;
  fileName: string;
  mediaUrl: string;
  mediaType: string;
  size: string;
  roleTitleId: string;
  files?: any;
  sendLoginDetails: boolean;
  notes: string;
  gender: string; // Gender as a string
  rolePermissionId: string; // Gender as a string
}

interface RoomOverview {
  roomName: string;
  description: string;
  color: string;
  capacity: number;
  staffRatio: number;
}

function EditEmployees(props: any) {
  const { id } = useParams();
  const [dynamicFields, setDynamicFields] = useState<
    {
      roomId: string;
      assignedAs: string;
      id?: string;
    }[]
  >([
    {
      assignedAs: "MAIN_STAFF",
      roomId: "",
    },
  ]);

  const initialState: IAddEmployeeForm = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    role: "EMPLOYEE",
    roleTitleId: "",
    joinDate: new Date(),
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    nationality: "United Kingdom",
    timeZone: timeZone,
    avatarUrl: "",
    primaryAddress: "",
    secondaryAddress: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    employeeType: "FULLTIME",
    jobTitleId: "",
    monthlySalary: "",
    nurseryId: "",
    empRoomArrObj: dynamicFields,
    docFolderId: "",
    fileName: "my-pics",
    mediaUrl: "qwertyuio.com",
    mediaType: "VIDEO",
    size: "5mb",
    sendLoginDetails: true,
    notes: "",
    gender: "",
    rolePermissionId: "",
  };

  const initialStateClassroom = {
    roomName: "",
    description: "",
    color: "",
    capacity: 0,
    staffRatio: 0,
  };

  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [roomNames, setRoomNames] = useState([]);
  const [nursery, setNursery] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJobTitle, setSelectedJobTitle] = useState(""); // For editing Job Title
  const [validate, setValidate] = useState(false);
  const [classroomModal, setClassroomModal] = useState(false);
  const [form, setForm] = useState<IAddEmployeeForm>(initialState);
  const [classroomForm, setClassroomForm] = useState(initialStateClassroom);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingJobTitle, setIsLoadingJobTitle] = useState(false);
  const [isLoadingClassroom, setIsLoadingClassroom] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IAddEmployeeForm>({
    defaultValues: {
      nationality: initialState.nationality, // Set the default value here
      employeeType: initialState.employeeType,
    },
  });
  const onSubmit = async () => {
    setValidate(true);
    setIsLoading(true);
    if (form?.files && form?.files?.length > 0) {
      const { mediaUrl } = await uploadImage(form?.files[0]);
      const createEmpObj = JSON.parse(JSON.stringify(form));
      createEmpObj.avatarUrl = mediaUrl;

      delete createEmpObj.files;
      delete createEmpObj.password;
      delete createEmpObj.docFolderId;
      delete createEmpObj.children;
      delete createEmpObj.package;

      const empRoomArrObj: {
        roomId: string;
        assignedAs: string;
        id?: string;
      }[] = [];
      for (const { assignedAs, roomId } of dynamicFields) {
        if (roomId && assignedAs) {
          empRoomArrObj.push({ assignedAs, roomId });
        }
      }

      try {
        const addedResponse = await UserApi.updateEmployee({
          ...createEmpObj,
          joinDate: form.joinDate.getTime(),
          dob: form.dob.getTime(),
          monthlySalary: Number(createEmpObj.monthlySalary),
          empRoomArrObj,
          timeZone: timeZone,
        });

        if (addedResponse.success) {
          Swal.fire({
            title: "Success!",
            text: "Employee updated successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Employees",
                `Employee updated successfully`
              )
            );
            // Navigate to plan list on success
            navigate(`${process.env.PUBLIC_URL}/dashboard/users/employees/`);
          });
        }
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      const createEmpObj = JSON.parse(JSON.stringify(form));

      delete createEmpObj.files;
      delete createEmpObj.password;
      delete createEmpObj.docFolderId;
      delete createEmpObj.children;
      delete createEmpObj.package;

      const empRoomArrObj: {
        roomId: string;
        assignedAs: string;
        id?: string;
      }[] = [];
      for (const { assignedAs, roomId } of dynamicFields) {
        if (roomId && assignedAs) {
          empRoomArrObj.push({ assignedAs, roomId });
        }
      }

      try {
        const addedResponse = await UserApi.updateEmployee({
          ...createEmpObj,
          joinDate: form.joinDate.getTime(),
          dob: form.dob.getTime(),
          monthlySalary: Number(createEmpObj.monthlySalary),
          empRoomArrObj,
          timeZone: timeZone,
        });

        if (addedResponse.success) {
          Swal.fire({
            title: "Success!",
            text: "Employee updated successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Employees",
                `Employee updated successfully`
              )
            );
            // Navigate to plan list on success
            navigate(`${process.env.PUBLIC_URL}/dashboard/users/employees/`);
          });
        }
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await NurseryAPi.getRolesPermissions({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        const roleOptions = response?.data?.map((item: any) => ({
          label: item.title,
          value: item.id,
        }));
        setRoles(roleOptions);
      }
    } catch (error: any) {
      console.error("error: ", error);
    }
  };

  const fetchJobTitle = async () => {
    try {
      const response = await DropDownAPi.getJobTitle("false");

      if (response.success && response?.data?.length > 0) {
        const jobTitleOptions = response?.data?.map((item: any) => ({
          label: item.jobTitle,
          value: item.id,
        }));
        setJobTitles(jobTitleOptions);
      }
    } catch (error: any) {
      console.error("error: ", error);
    }
  };

  const fetchClassRoomsOptions = async () => {
    try {
      const response = await DropDownAPi.getClassRoomsOptions(
        form.nurseryId || ""
      );

      if (response.success && response?.data?.length > 0) {
        const roomNameOptions = response?.data?.map((item: any) => ({
          label: item.roomName,
          value: item.id,
        }));
        setRoomNames(roomNameOptions);
      } else {
        setRoomNames([]);
      }
    } catch (error: any) {
      console.error("error: ", error);
    }
  };

  const fetchNurseryOptions = async () => {
    try {
      const response = await DropDownAPi.getNurseryOptions("false");

      if (response.success && response?.data?.length > 0) {
        const nurseryOptions = response?.data?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setNursery(nurseryOptions);
      }
    } catch (error: any) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchJobTitle();
    fetchNurseryOptions();
  }, []);

  useEffect(() => {
    fetchClassRoomsOptions();
  }, [form.nurseryId]);

  const countryOptions = countryCode.map(({ country }) => ({
    label: country,
    value: country,
  }));

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const addDynamicField = () => {
    setDynamicFields((prevFields) => [
      ...prevFields,
      {
        assignedAs: "MAIN_STAFF",
        roomId: "",
      },
    ]);
    setForm((prevForm: IAddEmployeeForm) => ({
      ...prevForm,
      empRoomArrObj: [
        ...(prevForm.empRoomArrObj as {
          roomId: string;
          assignedAs: string;
          id?: string;
        }[]),
        {
          assignedAs: "",
          roomId: "",
        },
      ],
    }));
  };

  const removeDynamicField = (index: number) => {
    setDynamicFields((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });

    setForm((prevForm) => {
      const newEmpRoomArrObj = [
        ...(prevForm.empRoomArrObj as {
          roomId: string;
          assignedAs: string;
          id?: string;
        }[]),
      ];
      newEmpRoomArrObj.splice(index, 1);
      return {
        ...prevForm,
        empRoomArrObj: newEmpRoomArrObj,
      };
    });
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await UserApi.getEmployeeById(id);
        if (response.success && Object.keys(response.data).length > 0) {
          const modifiedObj = commonKeysObject(response.data, form);

          const empRoomArrObj: {
            roomId: string;
            id?: string;
            assignedAs: string;
          }[] = [];
          for (const { assignedAs, id, rooms } of response.data.employeeRooms) {
            if (id && assignedAs && rooms) {
              empRoomArrObj.push({ assignedAs, id, roomId: rooms?.[0]?.id });
            }
          }

          setDynamicFields(empRoomArrObj);

          setForm({
            ...modifiedObj,
            joinDate: new Date(modifiedObj.joinDate),
            dob: new Date(modifiedObj.dob),
            password: response.data.backupPassword,
            empRoomArrObj,
          } as IAddEmployeeForm);

          reset({
            ...modifiedObj,
            joinDate: new Date(modifiedObj.joinDate),
            dob: new Date(modifiedObj.dob),
            password: response.data.backupPassword,
            empRoomArrObj,
          });
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
      } finally {
      }
    };
    fetchEmployees();
  }, [id]);

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "avatar");
    try {
      const response = await UploadAPi.uploadFile(formData, "upload-avatar");

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      console.log("err: ", err);
      return { mediaUrl: "", mediaType: "" };
    }
  };

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setSelectedJobTitle("");
    }
  };

  const jobTitleSubmit = async () => {
    setIsLoadingJobTitle(true);
    try {
      if (selectedJobTitle == "") {
        Swal.fire({
          title: "Error!",
          text: "Please enter job title",
          icon: "error",
        });
        setIsLoadingJobTitle(false);
        return;
      }
      await JobTitleApi.createJobTitle({ jobTitle: selectedJobTitle });
      Swal.fire({
        title: "Success!",
        text: "Job Title created successfully",
        icon: "success",
      });
      // Reload the data after successful operation
      fetchJobTitle();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoadingJobTitle(false);
    }
  };

  const handleClassroomModal = () => {
    setClassroomModal(!classroomModal);
    if (!classroomModal) {
      setClassroomForm(initialStateClassroom);
    }
  };

  const {
    register: registerClassroom,
    handleSubmit: handleSubmitClassroom,
    formState: { errors: errorsClassroom },
  } = useForm<RoomOverview>();

  const onSubmitClassroom = async (data: RoomOverview) => {
    setIsLoadingClassroom(true);
    try {
      // Create new Room Overview
      await RoomOverviewApi.createRoomOverview({
        ...data,
        nurseryId: props.nurseryId,
      });
      Swal.fire({
        title: "Success!",
        text: "Room Overview created successfully",
        icon: "success",
      });
      // Reload the data after successful operation
      fetchClassRoomsOptions();
      handleClassroomModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoadingClassroom(false);
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Employees Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/users/employees`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>
            <Form
              className="needs-validation custom-input tooltip-valid validation-forms"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Row className="g-3">
                <Col md={12}>
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Personal Detail</H4>
                    </CardHeader>
                    <CardBody>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {FirstName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            }`}
                            placeholder="Enter first name"
                            {...register("firstName", {
                              required: "Please enter your valid first name",
                              maxLength: {
                                value: 255,
                                message:
                                  "First name cannot be longer than 255 characters",
                              },
                            })}
                            value={form.firstName}
                            onChange={(e) =>
                              saveDataForm("firstName", e.target.value)
                            }
                          />
                          {errors.firstName && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {LastName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            }`}
                            placeholder="Enter last name"
                            {...register("lastName", {
                              required: "Please enter your valid last name",
                              maxLength: {
                                value: 255,
                                message:
                                  "Last name cannot be longer than 255 characters",
                              },
                            })}
                            value={form.lastName}
                            onChange={(e) =>
                              saveDataForm("lastName", e.target.value)
                            }
                          />
                          {errors.lastName && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter your email"
                              {...register("email", {
                                required: "Please enter your valid email",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                                  message: "Please enter a valid email address",
                                },
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Email cannot be longer than 255 characters",
                                },
                              })}
                              value={form.email}
                              onChange={(e) =>
                                saveDataForm("email", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.email && (
                            <FormFeedback
                              style={{ color: "orangeRed", display: "block" }}
                            >
                              {errors.email.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.phoneNo ? "is-invalid" : ""
                              }`}
                              placeholder="Enter your phoneNo"
                              {...register("phoneNo", {
                                required: "Please enter your phone no",
                                pattern: {
                                  value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                                  message:
                                    "Please enter a valid 11-digit phone number",
                                },
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Phone number cannot be longer than 255 characters",
                                },
                              })}
                              max={11}
                              value={form.phoneNo}
                              onChange={(e) =>
                                saveDataForm("phoneNo", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.phoneNo && (
                            <FormFeedback
                              style={{ color: "orangeRed", display: "block" }}
                            >
                              {errors.phoneNo.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Gender"} <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="gender"
                            control={control}
                            rules={{
                              required: "Please select a gender",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="gender"
                                className={`${
                                  errors.gender ? "is-invalid" : ""
                                }`}
                                options={[
                                  { value: "male", label: "Male" },
                                  { value: "female", label: "Female" },
                                  { value: "other", label: "Other" },
                                ]} // Gender options
                                value={
                                  [
                                    { value: "male", label: "Male" },
                                    { value: "female", label: "Female" },
                                    { value: "other", label: "Other" },
                                  ].find(
                                    (option) => option.value === form.gender
                                  ) || null
                                } // Set current value, handle null
                                onChange={(selectedOption) => {
                                  // Check if selectedOption is not null
                                  if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    saveDataForm(
                                      "gender",
                                      selectedOption.value
                                    ); // Save the data
                                  }
                                }} // Handle value change
                              />
                            )}
                          />
                          {errors.gender && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.gender?.message}</>
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Password"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            placeholder="Enter password"
                            {...register("password", {
                              required: "Please enter your valid password",
                              maxLength: {
                                value: 255,
                                message:
                                  "Password cannot be longer than 255 characters",
                              },
                            })}
                            value={form.password}
                            onChange={(e) =>
                              saveDataForm("password", e.target.value)
                            }
                          />
                          {errors.password && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.password.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Join date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="joinDate"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.joinDate ? "is-invalid" : ""
                                    }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("joinDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />
                          </InputGroup>
                          {errors.joinDate && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              {"Please select join date."}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Date of Birth"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="dob"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.dob ? "is-invalid" : ""
                                    }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("dob", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />
                          </InputGroup>
                          {errors.dob && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              {"Please select dob date."}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Nationality"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="nationality"
                            control={control}
                            rules={{
                              required: "Please select any one nationality",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="nationality"
                                className={`${
                                  errors.nationality ? "is-invalid" : ""
                                }`}
                                options={countryOptions}
                                value={countryOptions.find(
                                  (option: any) =>
                                    option.value === form.nationality
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "nationality",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.nationality && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              {errors?.nationality?.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <div className="d-flex align-items-center">
                            <Label className="me-2">
                              {"Role"} <span className="text-danger">*</span>
                            </Label>
                            <Button
                              color="primary"
                              className="btn-sm"
                              style={{
                                fontSize: "12px", // Smaller font size
                                padding: "2px 6px", // Reduced padding
                                height: "auto", // Auto height to avoid unnecessary space
                                lineHeight: "1.5", // Adjust line height for compactness
                              }}
                              onClick={() =>
                                navigate("/dashboard/settings?role=true")
                              } // No action on click
                            >
                              <i className="fa fa-plus" /> Manage Roles
                            </Button>
                          </div>
                          <Controller
                            name="rolePermissionId"
                            control={control}
                            rules={{
                              required:
                                "Please select any one rolePermissionId",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="role"
                                className={`${
                                  errors.rolePermissionId ? "is-invalid" : ""
                                }`}
                                options={roles}
                                value={roles.find(
                                  (option: any) =>
                                    option.value === form.rolePermissionId
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "rolePermissionId",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.rolePermissionId && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.rolePermissionId?.message}</>
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Photo"}</Label>
                          <div style={{ display: "flex" }}>
                            <input
                              className="form-control"
                              type="file"
                              accept="image/*"
                              {...register("avatarUrl")}
                              onChange={(e) => {
                                saveDataForm("files", e.target.files);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Address Line 1"} </Label>
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Enter Address Line 1"
                            {...register("primaryAddress")}
                            value={form.primaryAddress}
                            onChange={(e) =>
                              saveDataForm("primaryAddress", e.target.value)
                            }
                          />
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Address Line 2"} </Label>
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Enter Address Line 2"
                            {...register("secondaryAddress")}
                            value={form.secondaryAddress}
                            onChange={(e) =>
                              saveDataForm("secondaryAddress", e.target.value)
                            }
                          />
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"City"}</Label>
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Enter City"
                            {...register("city")}
                            value={form.city}
                            onChange={(e) =>
                              saveDataForm("city", e.target.value)
                            }
                          />
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Post Code"}</Label>
                          <input
                            type="text"
                            className={`form-control`}
                            placeholder="Enter Post Code"
                            {...register("postalCode")}
                            value={form.postalCode}
                            onChange={(e) =>
                              saveDataForm("postalCode", e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12} className="mb-3">
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Emergency Contact</H4>
                    </CardHeader>
                    <CardBody>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {"First Name"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.emergencyContactFirstName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter First Name"
                            {...register("emergencyContactFirstName", {
                              required: "Please enter your valid name",
                              maxLength: {
                                value: 255,
                                message:
                                  "Name cannot be longer than 255 characters",
                              },
                            })}
                            value={form.emergencyContactFirstName}
                            onChange={(e) =>
                              saveDataForm(
                                "emergencyContactFirstName",
                                e.target.value
                              )
                            }
                          />
                          {errors.emergencyContactFirstName && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.emergencyContactFirstName.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Last Name"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.emergencyContactLastName
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Last Name"
                            {...register("emergencyContactLastName", {
                              required: "Please enter your valid name",
                              maxLength: {
                                value: 255,
                                message:
                                  "Name cannot be longer than 255 characters",
                              },
                            })}
                            value={form.emergencyContactLastName}
                            onChange={(e) =>
                              saveDataForm(
                                "emergencyContactLastName",
                                e.target.value
                              )
                            }
                          />
                          {errors.emergencyContactLastName && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.emergencyContactLastName.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.emergencyContactEmail ? "is-invalid" : ""
                              }`}
                              placeholder="Enter your email"
                              {...register("emergencyContactEmail", {
                                required: "Please enter your valid email",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                                  message: "Please enter a valid email address",
                                },
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Email cannot be longer than 255 characters",
                                },
                              })}
                              value={form.emergencyContactEmail}
                              onChange={(e) =>
                                saveDataForm(
                                  "emergencyContactEmail",
                                  e.target.value
                                )
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.emergencyContactEmail && (
                            <FormFeedback
                              style={{ color: "orangeRed", display: "block" }}
                            >
                              {errors.emergencyContactEmail.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.emergencyContactPhoneNo
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter your mobile"
                              {...register("emergencyContactPhoneNo", {
                                required: "Please enter your valid phone no",
                                pattern: {
                                  value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                                  message:
                                    "Please enter a valid 10-digit phone number",
                                },
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Phone number cannot be longer than 255 characters",
                                },
                              })}
                              max={11}
                              value={form.emergencyContactPhoneNo}
                              onChange={(e) =>
                                saveDataForm(
                                  "emergencyContactPhoneNo",
                                  e.target.value
                                )
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.emergencyContactPhoneNo && (
                            <FormFeedback
                              style={{ color: "orangeRed", display: "block" }}
                            >
                              {errors.emergencyContactPhoneNo.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Relationship"} </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.emergencyContactRelation
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Enter Relationship"
                            {...register("emergencyContactRelation")}
                            value={form.emergencyContactRelation}
                            onChange={(e) =>
                              saveDataForm(
                                "emergencyContactRelation",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12} className="mb-3">
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Employment Details</H4>
                    </CardHeader>
                    <CardBody>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Employment Type"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="employeeType"
                            control={control}
                            rules={{
                              required: "Please select any one employeeType",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="employeeType"
                                className={`${
                                  errors.employeeType ? "is-invalid" : ""
                                }`}
                                options={employeeType}
                                value={employeeType.find(
                                  (option: any) =>
                                    option.value === form.employeeType
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "employeeType",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.employeeType && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.employeeType?.message}</>
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <div className="d-flex align-items-center">
                            <Label className="me-2">
                              {"Job Title"}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Button
                              color="primary"
                              className="btn-sm"
                              style={{
                                fontSize: "12px", // Smaller font size
                                padding: "2px 6px", // Reduced padding
                                height: "auto", // Auto height to avoid unnecessary space
                                lineHeight: "1.5", // Adjust line height for compactness
                              }}
                              onClick={toggleModal} // No action on click
                            >
                              <i className="fa fa-plus" /> Manage Job Title
                            </Button>
                          </div>
                          <Controller
                            name="jobTitleId"
                            control={control}
                            rules={{
                              required: "Please select any one jobTitleId",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="jobTitleId"
                                className={`${
                                  errors.jobTitleId ? "is-invalid" : ""
                                }`}
                                options={jobTitles}
                                value={jobTitles.find(
                                  (option: any) =>
                                    option.value === form.jobTitleId
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "jobTitleId",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.jobTitleId && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.jobTitleId?.message}</>
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Monthly Salary"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.monthlySalary ? "is-invalid" : ""
                              }`}
                              placeholder="Enter your Monthly Salary"
                              {...register("monthlySalary", {
                                required:
                                  "Please enter your valid monthly Salary",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Monthly Salary cannot be longer than 255 characters",
                                },
                              })}
                              value={form.monthlySalary}
                              onChange={(e) =>
                                saveDataForm("monthlySalary", e.target.value)
                              }
                            />
                          </InputGroup>
                          {errors.monthlySalary && (
                            <FormFeedback
                              style={{ color: "orangeRed", display: "block" }}
                            >
                              {errors.monthlySalary.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={12} className="mt-3">
                          {/* <Button
                            variant="outlined"
                            size="large"
                            onClick={addDynamicField}
                          >
                            + Add Classroom Another
                          </Button> */}
                          {dynamicFields?.map(
                            (fieldValue: any, index: number) => (
                              <Row className="mt-3">
                                <Col md={4} className="position-relative">
                                  <div className="d-flex align-items-center">
                                    <Label className="me-2">
                                      {"Classroom"}{" "}
                                    </Label>
                                    <Button
                                      color="primary"
                                      className="btn-sm"
                                      style={{
                                        fontSize: "12px", // Smaller font size
                                        padding: "2px 6px", // Reduced padding
                                        height: "auto", // Auto height to avoid unnecessary space
                                        lineHeight: "1.5", // Adjust line height for compactness
                                      }}
                                      onClick={handleClassroomModal} // No action on click
                                    >
                                      <i className="fa fa-plus" /> Manage
                                      Classroom
                                    </Button>
                                  </div>
                                  <Controller
                                    name={`empRoomArrObj.${index}.roomId`}
                                    control={control}
                                    rules={{}} // Validation rules
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        id={`empRoomArrObj.${index}.roomId`}
                                        className={`${
                                          errors?.empRoomArrObj?.[index]?.roomId
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        isDisabled={form.nurseryId === ""}
                                        options={roomNames}
                                        value={
                                          roomNames.find(
                                            (option: any) =>
                                              option.value ===
                                              dynamicFields[index]?.roomId
                                          ) || null
                                        } // Set current value
                                        onChange={(selectedOption: any) => {
                                          field.onChange(selectedOption?.value);

                                          const newFields = [...dynamicFields];
                                          newFields[index].roomId =
                                            selectedOption?.value;
                                          setDynamicFields(newFields);

                                          saveDataForm(
                                            "empRoomArrObj",
                                            newFields
                                          );
                                        }} // Update the value using Controller's onChange
                                      />
                                    )}
                                  />
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>{"Assigned as"}</Label>
                                  <Controller
                                    name={`empRoomArrObj.${index}.assignedAs`}
                                    control={control}
                                    rules={{}} // Validation rules
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        id={`empRoomArrObj.${index}.assignedAs`}
                                        className={``}
                                        isDisabled={form.nurseryId === ""}
                                        options={assignedAsOptions}
                                        value={assignedAsOptions.find(
                                          (option: any) =>
                                            option.value ===
                                            fieldValue.assignedAs
                                        )} // Set current value
                                        onChange={(selectedOption: any) => {
                                          field.onChange(selectedOption?.value);

                                          const newFields = [...dynamicFields];
                                          newFields[index].assignedAs =
                                            selectedOption?.value;
                                          setDynamicFields(newFields);

                                          saveDataForm(
                                            "empRoomArrObj",
                                            newFields
                                          );
                                        }} // Update the value using Controller's onChange
                                      />
                                    )}
                                  />
                                </Col>
                                <Col md={2}>
                                  {index != 0 && (
                                    <span
                                      className="p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => removeDynamicField(index)}
                                    >
                                      <i className="iconly-Close-Square icli h2"></i>
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-5">
                          <Label>
                            Login Details <span className="text-danger">*</span>
                          </Label>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className={`form-check-input ${
                                errors.sendLoginDetails ? "is-invalid" : ""
                              }`}
                              {...register("sendLoginDetails", {
                                required:
                                  "Please confirm to send login details",
                              })}
                              checked={form.sendLoginDetails}
                              onChange={(e) =>
                                saveDataForm(
                                  "sendLoginDetails",
                                  e.target.checked
                                )
                              }
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="sendLoginDetails"
                            >
                              Send Login Details
                            </Label>
                          </div>
                          {errors.sendLoginDetails && (
                            <FormFeedback style={{ color: "orangeRed" }}>
                              {errors.sendLoginDetails.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={8} className="position-relative mt-4">
                          <Label>
                            Notes <span className="text-muted">(optional)</span>
                          </Label>
                          <textarea
                            rows={5}
                            className={`form-control`}
                            placeholder="Enter your notes here"
                            {...register("notes")}
                            value={form.notes || ""}
                            maxLength={255}
                            onChange={(e) =>
                              saveDataForm("notes", e.target.value)
                            }
                          ></textarea>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  xs={12}
                  style={{ borderTop: "5px solid #eee", padding: 0 }}
                >
                  <Card
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  >
                    <CardBody>
                      <Btn
                        color="dark"
                        className="btn-md"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Update & Save"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/users/employees/`
                          );
                        }}
                        className="btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {/* Modal for Adding Job Title */}
        <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
          <ModalHeader toggle={toggleModal}>{"Add Job Title"}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Label for="jobTitle">
                  Job Title <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control`}
                  id="jobTitle"
                  name="jobTitle"
                  onChange={(e) => setSelectedJobTitle(e.target.value)}
                  value={selectedJobTitle}
                />
              </FormGroup>
              <ModalFooter>
                <Button
                  color="primary"
                  type="button"
                  disabled={isLoadingJobTitle}
                  onClick={jobTitleSubmit}
                >
                  {isLoadingJobTitle ? (
                    <span className="d-flex gap-2">
                      <Spinner color="white" size="sm" /> Loading...
                    </span>
                  ) : (
                    "Add Job Title"
                  )}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>

        {/* Modal for Adding Job Title */}
        <Modal isOpen={classroomModal} toggle={handleClassroomModal} backdrop="static">
          <ModalHeader toggle={handleClassroomModal}>
            {"Add Classroom"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmitClassroom(onSubmitClassroom)}>
              <FormGroup>
                <Label for="name">
                  Room Name <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${
                    errorsClassroom.roomName ? "is-invalid" : ""
                  }`}
                  id="roomName"
                  {...registerClassroom("roomName", {
                    required: "Room name is required",
                    maxLength: {
                      value: 255,
                      message: "Room name cannot be longer than 255 characters",
                    },
                  })}
                  defaultValue={classroomForm.roomName}
                />
                {errorsClassroom.roomName && (
                  <FormFeedback style={{ color: "orangered" }}>
                    {errorsClassroom.roomName.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="description">
                  Description <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${
                    errorsClassroom.description ? "is-invalid" : ""
                  }`}
                  id="description"
                  {...registerClassroom("description", {
                    required: "Description is required",
                    maxLength: {
                      value: 500,
                      message:
                        "Description cannot be longer than 255 characters",
                    },
                  })}
                  defaultValue={classroomForm.description}
                />
                {errorsClassroom.description && (
                  <FormFeedback style={{ color: "orangered" }}>
                    {errorsClassroom.description.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="color">
                  Color <span className="text-danger">*</span>
                </Label>
                <input
                  type="color"
                  className={`form-control ${
                    errorsClassroom.color ? "is-invalid" : ""
                  }`}
                  id="color"
                  {...registerClassroom("color", {
                    required: "Color is required",
                    validate: (value) =>
                      /^#[0-9A-Fa-f]{6}$/i.test(value) ||
                      "Please provide a valid color", // Regex validation
                  })}
                  defaultValue={classroomForm.color || "#ffffff"} // Default value if color is empty or undefined
                />
                {errorsClassroom.color && (
                  <FormFeedback style={{ color: "orangered" }}>
                    {errorsClassroom.color.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="capacity">
                  Capacity <span className="text-danger">*</span>
                </Label>
                <input
                  type="number"
                  className={`form-control ${
                    errorsClassroom.capacity ? "is-invalid" : ""
                  }`}
                  id="capacity"
                  {...registerClassroom("capacity", {
                    required: "Capacity is required",
                    min: { value: 1, message: "Capacity must be at least 1" },
                  })}
                  defaultValue={classroomForm.capacity}
                />
                {errorsClassroom.capacity && (
                  <FormFeedback style={{ color: "orangered" }}>
                    {errorsClassroom.capacity.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="staffRatio">
                  Staff Ratio <span className="text-danger">*</span>
                </Label>
                <input
                  type="number"
                  className={`form-control ${
                    errorsClassroom.staffRatio ? "is-invalid" : ""
                  }`}
                  id="staffRatio"
                  {...registerClassroom("staffRatio", {
                    required: "Staff ratio is required",
                    min: {
                      value: 1,
                      message: "Staff ratio must be at least 1",
                    },
                  })}
                  defaultValue={classroomForm.staffRatio}
                />
                {errorsClassroom.staffRatio && (
                  <FormFeedback style={{ color: "orangered" }}>
                    {errorsClassroom?.staffRatio?.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <ModalFooter>
                <Btn
                  color="primary"
                  type="submit"
                  disabled={isLoadingClassroom}
                >
                  {isLoadingClassroom ? (
                    <span className="d-flex gap-2">
                      <Spinner color="white" size="sm" /> Loading...
                    </span>
                  ) : (
                    "Add Room Overview"
                  )}
                </Btn>
                <Button color="secondary" onClick={handleClassroomModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
}

export default connectComponent(EditEmployees);
