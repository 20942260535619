import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import General from "./General/General";
import AdminProfile from "../Profile/connector";
import ClosureDay from "./General/ClosureDay"; // ClosureDay Component
import RoomOverview from "./Room/RoomOverview"; // Room Overview Component
import RoomCustomStatus from "./Room/RoomCustomStatus"; // Room Custom Status Component
import RoomMoves from "./Room/RoomMoves";
import JobTitle from "./Staff/JobTitle";
import StaffHours from "./Staff/StaffHours";

import {
  FaCalendar,
  FaChevronDown,
  FaChevronRight,
  FaFileAlt,
  FaUsers,
  FaUserCircle,
  FaBook,
  FaSchool,
  FaChild,
  FaRegCalendarAlt,
  FaFile,
} from "react-icons/fa";
import Children from "./Children/Children";
import RolesPermission from "./Staff/RolesPermission";
import OpeningHours from "./General/OpeningHours";
import { useLocation } from "react-router-dom";
import StaffLeave from "./Staff/StaffLeave";
import RotaManagement from "./RotaManagement/RotaManagement";
import CompanyDocuments from "./CompanyDocuments";

const Settings = (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab_no = Boolean(searchParams.get("role")) || false; // Get the value of 'id'

  const [selectedSection, setSelectedSection] = useState<string>(
    tab_no === true ? "Roles & Permissions" : "General"
  );
  const [openSection, setOpenSection] = useState<string>(
    tab_no === true ? "Staff" : "General"
  );
  const [hoveredButton, setHoveredButton] = useState<string | null>(null); // Track hovered button

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? "" : section);
  };

  const handleClick = (section: string) => {
    setSelectedSection(section);
  };

  // Render the corresponding component based on selectedSection
  const renderComponent = () => {
    switch (selectedSection) {
      case "Profile":
        return (
          <>
            <AdminProfile nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Children":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Children Settings"
                titleClass="mb-0"
              />
            </div>
            <Children />
          </>
        );
      case "Rota Management":
        return (
          <>
            <RotaManagement nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Company Documents":
        return (
          <>
            <CompanyDocuments
              nurseryId={props.auth.selectedNursery}
              id={props?.auth?.user?.id}
            />
          </>
        );
      case "General":
        return (
          <>
            <General nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Closure Day":
        return (
          <>
            <ClosureDay nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Opening Hours":
        return (
          <>
            <OpeningHours nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Overview":
        return (
          <>
            <RoomOverview nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Custom Status":
        return (
          <>
            <RoomCustomStatus nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Room Moves":
        return (
          <>
            <RoomMoves />
          </>
        );
      case "Job Title":
        return (
          <>
            <JobTitle />
          </>
        );
      case "Staff Hours":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Staff Hours Settings"
                titleClass="mb-0"
              />
            </div>
            <StaffHours />
          </>
        );
      case "Roles & Permissions":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Roles & Permission Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RolesPermission
              nurseryId={props.auth.selectedNursery}
              openAddForm={tab_no === true}
            />
          </>
        );
      case "Staff Leave":
        return (
          <>
            <StaffLeave nurseryId={props.auth.selectedNursery} />
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          {/* Left Sidebar with Tabs */}
          <Col md={3}>
            <Card className="shadow-sm rounded border-0">
              <CardBody>
                <ul className="main-menu mt-3 setting-menu">
                  {/* Profile Section */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Profile");
                        toggleSection("Profile");
                      }}
                      onMouseEnter={() => setHoveredButton("Profile")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background: selectedSection != "Profile" ? "#fff" : "",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className={`text-dark ${
                        selectedSection === "Profile"
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaUserCircle />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Profile
                        </span>
                      </div>
                    </button>
                  </li>

                  {/* General Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("General")}
                      onMouseEnter={() => setHoveredButton("General")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: `${
                          openSection != "General" || // If General is open
                          !["General", "Closure Day", "Opening Hours"].includes(
                            selectedSection
                          )
                            ? "#fff"
                            : ""
                        }`,
                        width: "100%", // Ensures the button fills the available space
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px", // Apply padding to the entire button
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className={`text-dark ${
                        openSection === "General" || // If General is open
                        selectedSection === "General" || // If General is active
                        selectedSection === "Closure Day"
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaBook />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          General
                        </span>
                      </div>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "General" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>

                    {openSection === "General" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("General")}
                            onMouseEnter={() =>
                              setHoveredButton("General-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              border: "none",                              
                              background:
                                selectedSection != "General" ? "#fff" : "",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className={`${
                              selectedSection === "General"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">General</span>
                          </button>
                        </li>

                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Closure Day")}
                            onMouseEnter={() =>
                              setHoveredButton("Closure Day-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              border: "none",
                              color: "#555",
                              background:
                                selectedSection != "Closure Day" ? "#fff" : "",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className={`${
                              selectedSection === "Closure Day"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Closure Day</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Opening Hours")}
                            onMouseEnter={() =>
                              setHoveredButton("Opening Hours-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              background:
                                selectedSection != "Opening Hours"
                                  ? "#fff"
                                  : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className={`${
                              selectedSection === "Opening Hours"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Opening Hours</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>

                  {/* Rooms Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("Rooms")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        background: `${
                          openSection != "Rooms" || // If General is open
                          !["Overview", "Custom Status", "Room Moves"].includes(
                            selectedSection
                          )
                            ? "#fff"
                            : ""
                        }`,
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      className={`text-dark ${
                        openSection === "Rooms" || // If General is open
                        ["Overview", "Custom Status", "Room Moves"].includes(
                          selectedSection
                        )
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaSchool />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Rooms
                        </span>
                      </div>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "Rooms" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>
                    {openSection === "Rooms" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Overview")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection != "Overview" ? "#fff" : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Overview"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Overview</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Custom Status")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection != "Custom Status"
                                  ? "#fff"
                                  : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Custom Status"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Custom Status</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Room Moves")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection != "Room Moves" ? "#fff" : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Room Moves"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Room Moves</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Chidren Section */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Children");
                        toggleSection("Children");
                      }}
                      onMouseEnter={() => setHoveredButton("Children")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background: selectedSection != "Children" ? "#fff" : "",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className={`text-dark ${
                        selectedSection === "Children"
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaChild />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Children
                        </span>
                      </div>
                    </button>
                  </li>
                  {/* Rota Management */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Rota Management");
                        toggleSection("Rota Management");
                      }}
                      onMouseEnter={() => setHoveredButton("Rota Management")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background:
                          selectedSection !== "Rota Management" // Check if this section is active
                            ? "#fff" // Light active background color
                            : "", // Light hover background color
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className={`text-dark ${
                        selectedSection === "Rota Management"
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div className="d-flex">
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaRegCalendarAlt />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Rota Management
                        </span>
                      </div>
                    </button>
                  </li>

                  {/* Company Documents */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Company Documents");
                        toggleSection("Company Documents");
                      }}
                      onMouseEnter={() => setHoveredButton("Company Documents")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background:
                          selectedSection !== "Company Documents" // Check if this section is active
                            ? "#fff" // Light active background color
                            : "",
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                        whiteSpace: "nowrap", // Prevent text wrapping
                      }}
                      className={`text-dark ${
                        selectedSection === "Company Documents"
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaFile />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Company Documents
                        </span>
                      </div>
                    </button>
                  </li>

                  {/* Staff Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("Staff")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        background:
                          openSection !== "Staff" || // If Rooms is open
                          ![
                            "Job Title",
                            "Staff Houres",
                            "Roles & Permissions",
                            "Staff Leave",
                          ].includes(selectedSection) // If any child under Rooms is active
                            ? "#fff" // Light active background color
                            : "", // No background otherwise
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      className={`text-dark ${
                        openSection === "Staff" || // If General is open
                        [
                          "Job Title",
                          "Staff Houres",
                          "Roles & Permissions",
                          "Staff Leave",
                        ].includes(selectedSection)
                          ? "badge-light-primary"
                          : ""
                      }`}
                    >
                      <div>
                        <span
                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                          style={{
                            borderRadius: "100px",
                            lineHeight: "0px",
                            marginRight: "0.5rem",
                          }}
                        >
                          <FaUsers />
                        </span>
                        <span
                          className=""
                          style={{
                            color: "#495057",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Staff
                        </span>
                      </div>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "Staff" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>
                    {openSection === "Staff" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Job Title")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection !== "Job Title" ? "#fff" : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Job Title"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Job Title</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Staff Hours")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection !== "Staff Hours" ? "#fff" : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Staff Hours"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Staff Hours</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Roles & Permissions")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection !== "Roles & Permissions"
                                  ? "#fff"
                                  : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Roles & Permissions"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Role & Permissions</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Staff Leave")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection !== "Staff Leave" ? "#fff" : "",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "15px 10px",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className={`${
                              selectedSection === "Staff Leave"
                                ? "badge-light-primary"
                                : ""
                            }`}
                          >
                            <span className="general">Staff Leave</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          {/* Right Content for each tab */}
          <Col md={9}>
            {/* Render the selected section component */}
            {renderComponent()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
