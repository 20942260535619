import api from "./api"; // Use the Axios instance with interceptor

export default class SuperAdminApi {
  static async getSuperAdmins() {
    try {
      const response = await api.get("/user/getAllSuperAdmin"); // Changed to /faq/all

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createSuperAdmin(data: any) {
    try {
      const response = await api.post("/user/create-super-admin", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateSuperAdmin(id: string | undefined, data: any) {
    try {
      const response = await api.patch(`/user/info/superAdmin/${id}`, data); // Change to PATCH
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
