import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Btn, LI, UL } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import RoomCustomStatusApi from "../../../../services/RoomCustomStatusApi";
import { useForm } from "react-hook-form";
interface RoomCustomStatus {
  id: string;
  title: string;
  color: string;
  icon: File | null;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const RoomCustomStatus = (props: any) => {
  const navigate = useNavigate();
  const [customStatus, setCustomStatus] = useState<RoomCustomStatus[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEditCustomStatus = (customStatus: RoomCustomStatus) => {
    setSelectedCustomStatus(customStatus);
    toggleModal();
  };

  const [selectedCustomStatus, setSelectedCustomStatus] = useState<
    Partial<RoomCustomStatus>
  >({}); // For editing Room Custom Status

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RoomCustomStatus>();

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedCustomStatus({}); // Clear selected room data
    } else {
      // If opening modal, reset with selected room data for edit, otherwise with empty data for add
      reset(selectedCustomStatus);
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async (data: RoomCustomStatus) => {
    try {
      setIsLoading(true);
      // Check if 'icon' is a string (URL), if so, treat it differently
      const updatedData = {
        ...data,
        // If icon is a string (e.g., URL), store it as `iconUrl` or handle appropriately
        icon: data.icon instanceof File ? data.icon : null, // Keep the File as is, else set it to null
        iconUrl: typeof data.icon === "string" ? data.icon : undefined, // Add a separate field for URL if it's a string
      };

      // Update or create Room Custom Status
      if (selectedCustomStatus.id) {
        // Update Room Custom Status
        await RoomCustomStatusApi.updateRoomCustomStatus(
          selectedCustomStatus.id,
          {
            ...updatedData,
            nurseryId: props.nurseryId,
          }
        );
        Swal.fire({
          title: "Success!",
          text: "Room Custom Status updated successfully",
          icon: "success",
        });
      } else {
        // Create new Room Custom Status
        await RoomCustomStatusApi.createRoomCustomStatus({
          ...updatedData,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Room Custom Status created successfully",
          icon: "success",
        });
      }

      // Reload the data after successful operation
      fetchCustomStatuses();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Room Custom Status from the API
  const fetchCustomStatuses = async () => {
    try {
      const response = await RoomCustomStatusApi.getRoomCustomStatuses({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setCustomStatus(response.data);
      } else {
        throw new Error("Failed to fetch Room Custom Status");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Room Custom Status",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchCustomStatuses();
  }, [props.nurseryId]);

  // Filter the custom status list based on title
  const filteredItems = customStatus.filter((status) =>
    status.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const customStyles = {
    table: {
      style: {
        tableLayout: "fixed", // Fixes the table layout to enforce column widths
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontWeight: "bold",
        width: "150px", // Adjust width for all cells
      },
    },
    cells: {
      style: {
        width: "150px", // Adjust width for all cells
        whiteSpace: "nowrap", // Prevent text wrapping
        overflow: "hidden",
        textOverflow: "ellipsis", // Show ellipsis for overflow
      },
    },
  };

  // Columns for the DataTable
  const columns: TableColumn<RoomCustomStatus>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <CustomHeader title="Color" />,
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.color,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
          }}
        ></div>
      ),
      sortable: false,
      center: true,
    },
    {
      name: <CustomHeader title="Icon" />,
      cell: (row) => (
        <div>
          {row.icon ? (
            <img
              src={URL.createObjectURL(row.icon)}
              alt="Room Icon"
              width={40}
              height={40}
            />
          ) : (
            "No icon"
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
    {
      name: <CustomHeader title="Action" />,
      center: true,
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="javascript:void(0);"
              onClick={() => handleEditCustomStatus(row)}
              className="icon"
            >
              <Btn color="primary" size="sm">
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
                {"Edit"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <>
      <Card className="card-absolute">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ borderBottom: "5px solid #eee" }}
        >
          <CommonCardHeader
            title="Custom Status"
            headClass="bg-primary"
            titleClass="text-light"
          />
          <Button
            color="primary"
            className="btn-md m-4 mt-3 mb-3"
            onClick={toggleModal}
          >
            <i className="fa fa-plus me-2" /> Add New Status
          </Button>
        </div>
        <CardBody>
          <Row className="justify-content-end mb-3">
            <Col md={6}>
              <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                filterText={filterText}
              />
            </Col>
          </Row>
          <div className="table-responsive">
            <DataTable
              data={filteredItems}
              columns={columns}
              pagination
              className="display"
              customStyles={customStyles}
              responsive
              highlightOnHover
              conditionalRowStyles={[
                {
                  when: (row) => true,
                  style: {
                    "&:hover": {
                      backgroundColor: "rgba(48, 142, 135, 0.2)",
                      cursor: "pointer",
                    },
                  },
                },
              ]}
            />
          </div>
        </CardBody>
      </Card>
      {/* Modal for adding new custom status */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          {selectedCustomStatus.id
            ? "Edit Custom Status"
            : "Add New Custom Status"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="title">
                Title <span className="text-danger">*</span>
              </Label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                {...register("title", { required: "Title is required" })}
                defaultValue={selectedCustomStatus.title}
              />
              {errors.title && (
                <FormFeedback>{errors.title.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="color">
                Color <span className="text-danger">*</span>
              </Label>
              <input
                type="color"
                className={`form-control ${errors.color ? "is-invalid" : ""}`}
                id="color"
                {...register("color", {
                  required: "Color is required",
                  validate: (value) =>
                    /^#[0-9A-Fa-f]{6}$/i.test(value) ||
                    "Please provide a valid color", // Regex validation
                })}
                defaultValue={selectedCustomStatus.color || "#ffffff"} // Default value if color is empty or undefined
              />
              {errors.color && (
                <FormFeedback>{errors.color.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="icon">Icon (Optional)</Label>
              <input
                type="file"
                className={`form-control ${errors.icon ? "is-invalid" : ""}`}
                id="icon"
                {...register("icon")}
              />
              {errors.icon && (
                <FormFeedback>{errors.icon.message}</FormFeedback>
              )}
            </FormGroup>

            <ModalFooter>
              <Button color="primary" disabled={isLoading} type="submit">
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  <>
                    {selectedCustomStatus.id
                      ? "Update Custom Status"
                      : "Add Custom Status"}
                  </>
                )}
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RoomCustomStatus;
