import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Profile from "./Component/Profile";
import Details from "./Component/Details";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import { Image, P } from "../../../../AbstractElements";
import { AbsoluteCard } from "../../../../Utils/Constants";
import { dynamicImage } from "../../../../Utils";
import { connectComponent } from "./connector";

const ViewEmployees = (props: any) => {
  const [activeTab, setActiveTab] = useState(1);
  const [employeeDetailUpdated, setEmployeeDetailUpdated] = useState(1);
  const activeToggle = (tab: number) => {
    setActiveTab(tab);
  };
  return (
    <Container fluid className="pt-3">
      <div className="edit-profile email-wrap bookmark-wrap">
        <Row>
          <Profile
            activeToggle={activeToggle}
            activeTab={activeTab}
            employeeDetailUpdated={employeeDetailUpdated}
          />
          <Details
            activeTab={activeTab}
            nurseryId={props.nurseryId}
            setEmployeeDetailUpdated={setEmployeeDetailUpdated}
          />
        </Row>
      </div>
    </Container>
  );
};

export default connectComponent(ViewEmployees);
