import React, { useState } from "react";
import AccidentList from "./list";

const Accident = (props: any) => {
  const [action, setAction] = useState({
    id: "",
    action: "List",
  }); // * action: list, add, edit

  return (
    <>
      <AccidentList setAction={setAction} nurseryId={props.nurseryId} action={action} />
    </>
  );
};

export default Accident;
