import React, { useEffect, useState } from "react";
import PackageApi from "../../../services/PackageApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { LI, UL } from "../../../AbstractElements";
import Swal from "sweetalert2";
import Status from "../../../Components/Common/Status";
import { statusColors } from "../../../Utils/Constants";
import DemoRequestAPi from "../../../services/DemoRequest";
import moment from "moment";

interface DemoRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  designation: string;
  demoDate: string;
  demoTime: string;
  message: string;
  id: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListDemoRequest: React.FC = () => {
  const [demoRequests, setDemoRequests] = useState<DemoRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDemoRequests = async () => {
      try {
        const response = await DemoRequestAPi.getDemoRequest({
          page: 1,
          pageSize: 1000,
        });
        if (response.success && Array.isArray(response.data.demos)) {
          setDemoRequests(response.data.demos);
        } else {
          throw new Error("Expected an array of DemoRequests");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching demoRequests");
      } finally {
        setLoading(false);
      }
    };

    fetchDemoRequests();
  }, []);

  const filteredItems = demoRequests.filter((demoRequest) =>
    demoRequest.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDetailPage = (id: string) => {
    navigate(`/dashboard/bookings/edit/${id}`);
  };
  const handleStatusChange = async (row: DemoRequest) => {
    // const currentStatus = row.isActive ? "active" : "inactive"; // Determine the current status as a string
    // const { value: newStatus } = await Swal.fire({
    //   title: "Update Status",
    //   input: "select",
    //   inputOptions: {
    //     active: "ACTIVE",
    //     inactive: "INACTIVE",
    //   },
    //   inputValue: currentStatus,
    //   showCancelButton: true,
    // });
    // if (newStatus && newStatus !== currentStatus) {
    //   // Convert the selected status ("active" or "inactive") to a boolean value
    //   const updatedStatus = newStatus === "active"; // true for "active", false for "inactive"
    //   try {
    //     // Call the API to update the status, passing the boolean value
    //     // await PackageApi.updateDemoRequest(row.id, { isActive: updatedStatus });
    //     // Update the local state to reflect the status change
    //     setDemoRequests((prevDemoRequests) =>
    //       prevDemoRequests.map((demoRequest) =>
    //         demoRequest.id === row.id
    //           ? { ...demoRequest, isActive: updatedStatus }
    //           : demoRequest
    //       )
    //     );
    //     Swal.fire("Success", "Status updated successfully!", "success");
    //   } catch (error: any) {
    //     Swal.fire("Error", error.message || "Failed to update status", "error");
    //   }
    // }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => row.firstName,
      style: { maxWidth: "50%" },
      sortable: true,
      cell: (row) => (
        <div
          style={{
            maxWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${row.firstName} ${row.lastName}`}
        </div>
      ),
    },
    {
      name: <CustomHeader title="Phone" />,
      selector: (row) => row.phoneNo,
      style: { maxWidth: "50%" },
      sortable: true,
    },
    {
      name: <CustomHeader title="Email" />,
      selector: (row) => row.email,
      style: { maxWidth: "50%" },
      sortable: true,
    },
    {
      name: <CustomHeader title="Designation" />,
      selector: (row) => row.designation,
      style: { maxWidth: "100%" },
      center: true,
      sortable: true,
    },
    {
      name: <CustomHeader title="Date" />,
      selector: (row) => {
        return moment(row?.demoDate).format("DD MMM YYYY");
      },
      style: { maxWidth: "100%" },
      center: true,
      sortable: true,
    },
    {
      name: <CustomHeader title="Time" />,
      selector: (row) => {
        return moment(row?.demoTime).format("HH:MM:SS A");
      },
      style: { maxWidth: "100%" },
      center: true,
      sortable: true,
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "150px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "150px", // Set consistent width
      maxWidth: "150px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All DemoRequests`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                {/* <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`/dashboard/demoRequests/add`);
                  }}
                >
                  Add New DemoRequest
                </Button> */}
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  highlightOnHover
                  striped
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListDemoRequest;
