import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "src/AbstractElements";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";

function Sensitive(props: any) {
  const religions = [
    "Christians",
    "Baha'i",
    "Buddhism",
    "Hinduism",
    "Islam",
    "Jainism",
    "Judaism",
    "Paganism",
    "Rastafarian",
    "Scientology",
    "Sikhism",
    "Zoroastrians"
  ];

  const ethnicities = {
    "Asian or Asian British": [
      "Indian",
      "Pakistani",
      "Bangladeshi",
      "Chinese",
      "Any other Asian background"
    ],
    "Black, Black British, Caribbean or African": [
      "Caribbean",
      "African",
      "Any other Black, Black British, or Caribbean background"
    ],
    "Mixed or multiple ethnic groups": [
      "White and Black Caribbean",
      "White and Black African",
      "White and Asian",
      "Any other Mixed or multiple ethnic background"
    ],
    "White": [
      "English, Welsh, Scottish, Northern Irish or British",
      "Irish",
      "Gypsy or Irish Traveller",
      "Roma",
      "Any other White background"
    ],
    "Other ethnic group": [
      "Arab",
      "Any other ethnic group"
    ]
  };

  const initialState = {
    id: "",
    religion: "",
    ethnicity: "",
  };

  const [form, setForm] = useState<any>(props.initialState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.createAndUpdateChild({
        ...form,
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Children", response.message)
          );
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const modifiedObj = commonKeysObject(props.initialState, initialState);
    setForm(modifiedObj);
    reset(modifiedObj);
  }, [props.initialState]);

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Sensitive Information"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={12}>
                <FormGroup>
                  <Label>{"Religion"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <div className="d-flex flex-wrap gap-2 align-items-center justify-content-start">
                    {religions.map((religion) => (
                      <div key={religion} className="form-check" style={{ minWidth: '150px' }}>
                        <input
                          className="form-check-input"
                          type="radio"
                          id={religion}
                          value={religion}
                          {...register("religion", { required: true })}
                          checked={form.religion === religion}
                          onChange={(e) => saveDataForm("religion", e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={religion}>
                          {religion}
                        </label>
                      </div>
                    ))}
                  </div>
                  {errors.religion && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Religion is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>{"Ethnicity"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <div className="d-flex flex-column gap-3">
                    {Object.entries(ethnicities).map(([group, options]) => (
                      <div key={group} className="w-100">
                        <h6 className="mb-2 p-2 bg-light rounded text-dark" style={{
                          color: '#1b4c77',
                          fontWeight: '600',
                          borderLeft: '4px solid #308e87'
                        }}>{group}</h6>
                        <div className="d-flex flex-wrap gap-2 ms-3 align-items-start justify-content-start flex-column">
                          {options.map((ethnicity) => (
                            <div key={ethnicity} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id={ethnicity}
                                value={ethnicity}
                                {...register("ethnicity", { required: true })}
                                checked={form.ethnicity === ethnicity}
                                onChange={(e) => saveDataForm("ethnicity", e.target.value)}
                              />
                              <label className="form-check-label" htmlFor={ethnicity}>
                                {ethnicity}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  {errors.ethnicity && (
                    <FormFeedback style={{ color: "orangeRed", display: "block" }}>
                      {"Ethnicity is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save & Update"
                )}
              </Btn>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    </Form>
  );
}

export default Sensitive;
