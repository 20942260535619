import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H4 } from "../../../../AbstractElements";
import { LooksGood } from "../../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { connectComponent } from "./connector";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";

interface IAddParentForm {
  id?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  relation: string;
  primaryAddress: string;
  secondaryAddress: string;
  email: string;
  phoneNo: string;
  password: string;
  carerName?: string;
  role?: string;
  joinDate: any;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNo: string;
  emergencyContactAddress: string;
  emergencyContactRelation: string;
  emergencyContactPlaceOfWork: string;
  emergencyContactOccupation: string;
  emergencyContactWorkNo: string;
  emergencyContactHomeNo: string;
  sendLoginDetails: boolean;
  hideParentNo: string;
  nurseryId: string;
  postalCode: string;
  city: string;
}

function EditParents(props: any) {
  const { id } = useParams();
  const initialState = [
    {
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      relation: "",
      primaryAddress: "",
      secondaryAddress: "",
      email: "",
      phoneNo: "",
      password: "",
      carerName: "",
      role: "PARENT",
      joinDate: new Date().getTime(),
      emergencyContactFirstName: "",
      emergencyContactLastName: "",
      emergencyContactEmail: "",
      emergencyContactPhoneNo: "",
      emergencyContactAddress: "",
      emergencyContactRelation: "",
      emergencyContactPlaceOfWork: "",
      emergencyContactOccupation: "",
      emergencyContactWorkNo: "",
      emergencyContactHomeNo: "",
      sendLoginDetails: true,
      hideParentNo: "No",
      nurseryId: props.nurseryId,
      postalCode: "",
      city: "",
    },
  ];

  const navigate = useNavigate();
  const [form, setForm] = useState<IAddParentForm[]>(initialState);
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<IAddParentForm[]>();

  const onSubmit = async () => {
    setValidate(false);
    setIsLoading(true);
    try {
      const response = await UserApi.updateParent(id, form);
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: "Parent updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Parents",
              `Parent updated successfully`
            )
          );
          // Navigate to plan list on success
          navigate(`${process.env.PUBLIC_URL}/dashboard/parents/`);
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAnotherParent = () => {
    setForm((prevForm: IAddParentForm[]) => [...prevForm, initialState[0]]);
  };

  const handleRemoveParent = (index: number) => {
    setForm((prevForm) => {
      const newParentForm = [...prevForm];
      newParentForm.splice(index, 1);
      return [...newParentForm];
    });
  };

  const saveDataForm = (index: number, name: string, value: any) => {
    setForm((prevForm: any) => {
      const updatedForm = [...prevForm]; // Create a copy of the form array
      updatedForm[index] = { ...updatedForm[index], [name]: value }; // Update specific index
      return updatedForm; // Set the updated array
    });
  };

  useEffect(() => {
    setForm(initialState);
    saveDataForm(0, "nurseryId", props.nurseryId);
  }, [props.nurseryId]);

  useEffect(() => {
    const fetchParents = async () => {
      try {
        const response = await UserApi.getParentById(id);
        console.log('response: ', response);
        if (response.success && Array.isArray(response.data)) {
          const formData = response.data.map((row: any) => {
            const modifiedObj = commonKeysObject(row, form[0]);
            return { ...modifiedObj, password: row.backupPassword };
          });

          reset([...formData]);
          setForm(formData);
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
      } finally {
      }
    };
    fetchParents();
  }, [id]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Parents Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/parents`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>
            <Form
              className="needs-validation custom-input tooltip-valid validation-forms"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {form &&
                form.map((formData: IAddParentForm, index: number) => {
                  return (
                    <>
                      <Card style={{ borderRadius: "0.4rem" }}>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <H4>Personal Detail</H4>
                            {index != 0 && (
                              <span
                                className="p-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveParent(index)}
                              >
                                <i className="iconly-Close-Square icli h2 text-danger"></i>
                              </span>
                            )}
                          </div>
                        </CardHeader>
                        <CardBody>
                          <Row className="g-3" key={index}>
                            <Col md={12}>
                              <Row>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"First Name"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.firstName
                                      ? "is-invalid"
                                      : ""
                                      } ${validate && !errors?.[index]?.firstName
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter first name"
                                    {...register(`${index}.firstName`, {
                                      required: false,
                                    })}
                                    value={formData.firstName}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "firstName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.firstName && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.firstName?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.firstName && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"Middle Name"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.middleName
                                      ? "is-invalid"
                                      : ""
                                      } ${validate && !errors?.[index]?.middleName
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter middle name"
                                    {...register(`${index}.middleName`, {
                                      required: false,
                                    })}
                                    value={formData.middleName}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "middleName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.middleName && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.middleName?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.middleName && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"Last Name"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.lastName
                                      ? "is-invalid"
                                      : ""
                                      } ${validate && !errors?.[index]?.lastName
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter last name"
                                    {...register(`${index}.lastName`, {
                                      required: false,
                                    })}
                                    value={formData.lastName}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "lastName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.lastName && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.lastName?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.lastName && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Email"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="email"
                                      className={`form-control ${errors?.[index]?.email
                                        ? "is-invalid"
                                        : ""
                                        } ${validate && !errors?.[index]?.email
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your email"
                                      {...register(`${index}.email`, {
                                        required: false,
                                      })}
                                      value={formData.email}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "email",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-envelope text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]?.email && (
                                      <FormFeedback tooltip>
                                        {errors?.[index]?.email?.message}
                                      </FormFeedback>
                                    )}
                                    {validate && !errors?.[index]?.email && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                  </InputGroup>
                                </Col>
                                {/* Add Mobile field */}
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Mobile"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="tel"
                                      className={`form-control ${errors?.[index]?.phoneNo
                                        ? "is-invalid"
                                        : ""
                                        } ${validate && !errors?.[index]?.phoneNo
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your mobile"
                                      {...register(`${index}.phoneNo`, {
                                        required: false,
                                      })}
                                      value={formData.phoneNo}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "phoneNo",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-phone text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]?.phoneNo && (
                                      <FormFeedback tooltip>
                                        {errors?.[index]?.phoneNo?.message}
                                      </FormFeedback>
                                    )}
                                    {validate && !errors?.[index]?.phoneNo && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                  </InputGroup>
                                </Col>
                                {/* Add Password field */}
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Password"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.password
                                      ? "is-invalid"
                                      : ""
                                      } ${validate && !errors?.[index]?.password
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter password"
                                    {...register(`${index}.password`, {
                                      required: false,
                                    })}
                                    value={formData.password}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "password",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.password && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.password?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.password && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>

                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Relation"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Controller
                                    name={`${index}.relation`}
                                    control={control}
                                    rules={{
                                      required: false,
                                    }} // Validation rules
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        id={`${index}.relation`}
                                        className={`${errors?.[index]?.relation
                                          ? "is-invalid"
                                          : ""
                                          } ${validate && !errors?.[index]?.relation
                                            ? "is-valid"
                                            : ""
                                          }`}
                                        options={[
                                          {
                                            value: "father",
                                            label: "Father",
                                          },
                                          {
                                            value: "mother",
                                            label: "Mother",
                                          },
                                          {
                                            value: "carer",
                                            label: "Carer",
                                          },
                                        ]} // Gender options
                                        value={
                                          [
                                            {
                                              value: "father",
                                              label: "Father",
                                            },
                                            {
                                              value: "mother",
                                              label: "Mother",
                                            },
                                            {
                                              value: "carer",
                                              label: "Carer",
                                            },
                                          ].find(
                                            (option) =>
                                              option.value === formData.relation
                                          ) || null
                                        } // Set current value, handle null
                                        onChange={(selectedOption) => {
                                          // Check if selectedOption is not null
                                          if (selectedOption) {
                                            field.onChange(
                                              selectedOption.value
                                            ); // Update the field value
                                            saveDataForm(
                                              index,
                                              "relation",
                                              selectedOption.value
                                            );
                                          }
                                        }} // Handle value change
                                      />
                                    )}
                                  />
                                  {errors?.[index]?.relation && (
                                    <FormFeedback
                                      tooltip
                                      style={{ display: "block" }}
                                    >
                                      <>{errors?.[index]?.relation?.message}</>
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.relation && (
                                    <FormFeedback
                                      tooltip
                                      valid
                                      style={{ display: "block" }}
                                    >
                                      {LooksGood}
                                    </FormFeedback>
                                  )}
                                </Col>

                                {formData.relation === "carer" && (
                                  <Col
                                    md={4}
                                    className="position-relative mt-3"
                                  >
                                    <Label>{"Carer Name"} </Label>
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      placeholder="Enter carer name"
                                      {...register(`${index}.carerName`)}
                                      value={formData.carerName}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "carerName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ borderRadius: "0.4rem" }}>
                        <CardHeader>
                          <H4>Home Address</H4>
                        </CardHeader>
                        <CardBody>
                          <Row className="g-3" key={index}>
                            <Col md={12} className="mb-3">
                              <Row>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"Address"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.primaryAddress
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]?.primaryAddress
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Address"
                                    {...register(`${index}.primaryAddress`, {
                                      required: false,
                                    })}
                                    value={formData.primaryAddress}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "primaryAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.primaryAddress && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.primaryAddress?.message}
                                    </FormFeedback>
                                  )}
                                  {validate &&
                                    !errors?.[index]?.primaryAddress && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"PostCode"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.postalCode
                                      ? "is-invalid"
                                      : ""
                                      } ${validate && !errors?.[index]?.postalCode
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter PostCode"
                                    {...register(`${index}.postalCode`, {
                                      required: false,
                                    })}
                                    value={formData.postalCode}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "postalCode",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.postalCode && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.postalCode?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.postalCode && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"City"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.city ? "is-invalid" : ""
                                      } ${validate && !errors?.[index]?.city
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter City"
                                    {...register(`${index}.city`, {
                                      required: false,
                                    })}
                                    value={formData.city}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "city",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.city && (
                                    <FormFeedback tooltip>
                                      {errors?.[index]?.city?.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors?.[index]?.city && (
                                    <FormFeedback tooltip valid>
                                      Looks Good
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ borderRadius: "0.4rem" }}>
                        <CardHeader>
                          <H4>Emergency Contact</H4>
                        </CardHeader>
                        <CardBody>
                          <Row className="g-3" key={index}>
                            <Col md={12} className="mb-3">
                              <Row>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"First Name"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.emergencyContactFirstName
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]
                                          ?.emergencyContactFirstName
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Emergency Contact First Name"
                                    {...register(
                                      `${index}.emergencyContactFirstName`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactFirstName}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactFirstName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]
                                    ?.emergencyContactFirstName && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]
                                            ?.emergencyContactFirstName?.message
                                        }
                                      </FormFeedback>
                                    )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactFirstName && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"Last Name"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.emergencyContactLastName
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]?.emergencyContactLastName
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Emergency Contact Last Name"
                                    {...register(
                                      `${index}.emergencyContactLastName`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactLastName}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactLastName",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]
                                    ?.emergencyContactLastName && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]
                                            ?.emergencyContactLastName?.message
                                        }
                                      </FormFeedback>
                                    )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactLastName && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative">
                                  <Label>
                                    {"Email"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="email"
                                      className={`form-control ${errors?.[index]?.emergencyContactEmail
                                        ? "is-invalid"
                                        : ""
                                        } ${validate &&
                                          !errors?.[index]?.emergencyContactEmail
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your email"
                                      {...register(
                                        `${index}.emergencyContactEmail`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      value={formData.emergencyContactEmail}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "emergencyContactEmail",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-envelope text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]?.emergencyContactEmail && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]?.emergencyContactEmail
                                            ?.message
                                        }
                                      </FormFeedback>
                                    )}
                                    {validate &&
                                      !errors?.[index]
                                        ?.emergencyContactEmail && (
                                        <FormFeedback tooltip valid>
                                          Looks Good
                                        </FormFeedback>
                                      )}
                                  </InputGroup>
                                </Col>
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Mobile"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="tel"
                                      className={`form-control ${errors?.[index]?.emergencyContactPhoneNo
                                        ? "is-invalid"
                                        : ""
                                        } ${validate &&
                                          !errors?.[index]
                                            ?.emergencyContactPhoneNo
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your mobile"
                                      {...register(
                                        `${index}.emergencyContactPhoneNo`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      value={formData.emergencyContactPhoneNo}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "emergencyContactPhoneNo",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-phone text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]
                                      ?.emergencyContactPhoneNo && (
                                        <FormFeedback tooltip>
                                          {
                                            errors?.[index]
                                              ?.emergencyContactPhoneNo?.message
                                          }
                                        </FormFeedback>
                                      )}
                                    {validate &&
                                      !errors?.[index]
                                        ?.emergencyContactPhoneNo && (
                                        <FormFeedback tooltip valid>
                                          Looks Good
                                        </FormFeedback>
                                      )}
                                  </InputGroup>
                                </Col>
                                <Col md={8} className="position-relative mt-3">
                                  <Label>
                                    {"Address"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.emergencyContactAddress
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]?.emergencyContactAddress
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Address"
                                    {...register(
                                      `${index}.emergencyContactAddress`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactAddress}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]?.emergencyContactAddress && (
                                    <FormFeedback tooltip>
                                      {
                                        errors?.[index]?.emergencyContactAddress
                                          ?.message
                                      }
                                    </FormFeedback>
                                  )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactAddress && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Relationship"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]?.emergencyContactRelation
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]?.emergencyContactRelation
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Emergency Contact Relationship"
                                    {...register(
                                      `${index}.emergencyContactRelation`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactRelation}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactRelation",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]
                                    ?.emergencyContactRelation && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]
                                            ?.emergencyContactRelation?.message
                                        }
                                      </FormFeedback>
                                    )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactRelation && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Place Of Work"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]
                                      ?.emergencyContactPlaceOfWork
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]
                                          ?.emergencyContactPlaceOfWork
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Emergency Contact Place Of Work"
                                    {...register(
                                      `${index}.emergencyContactPlaceOfWork`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactPlaceOfWork}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactPlaceOfWork",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]
                                    ?.emergencyContactPlaceOfWork && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]
                                            ?.emergencyContactPlaceOfWork?.message
                                        }
                                      </FormFeedback>
                                    )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactPlaceOfWork && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Occupation"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <input
                                    type="text"
                                    className={`form-control ${errors?.[index]
                                      ?.emergencyContactOccupation
                                      ? "is-invalid"
                                      : ""
                                      } ${validate &&
                                        !errors?.[index]
                                          ?.emergencyContactOccupation
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    placeholder="Enter Emergency Contact Occupation"
                                    {...register(
                                      `${index}.emergencyContactOccupation`,
                                      {
                                        required: false,
                                      }
                                    )}
                                    value={formData.emergencyContactOccupation}
                                    onChange={(e) =>
                                      saveDataForm(
                                        index,
                                        "emergencyContactOccupation",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.[index]
                                    ?.emergencyContactOccupation && (
                                      <FormFeedback tooltip>
                                        {
                                          errors?.[index]
                                            ?.emergencyContactOccupation?.message
                                        }
                                      </FormFeedback>
                                    )}
                                  {validate &&
                                    !errors?.[index]
                                      ?.emergencyContactOccupation && (
                                      <FormFeedback tooltip valid>
                                        Looks Good
                                      </FormFeedback>
                                    )}
                                </Col>

                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Work Mobile No"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="tel"
                                      className={`form-control ${errors?.[index]?.emergencyContactWorkNo
                                        ? "is-invalid"
                                        : ""
                                        } ${validate &&
                                          !errors?.[index]?.emergencyContactWorkNo
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your work mobile no"
                                      {...register(
                                        `${index}.emergencyContactWorkNo`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      value={formData.emergencyContactWorkNo}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "emergencyContactWorkNo",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-phone text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]
                                      ?.emergencyContactWorkNo && (
                                        <FormFeedback tooltip>
                                          {
                                            errors?.[index]
                                              ?.emergencyContactWorkNo?.message
                                          }
                                        </FormFeedback>
                                      )}
                                    {validate &&
                                      !errors?.[index]
                                        ?.emergencyContactWorkNo && (
                                        <FormFeedback tooltip valid>
                                          Looks Good
                                        </FormFeedback>
                                      )}
                                  </InputGroup>
                                </Col>

                                <Col md={4} className="position-relative mt-3">
                                  <Label>
                                    {"Home Mobile No"}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <InputGroup>
                                    <input
                                      type="tel"
                                      className={`form-control ${errors?.[index]?.emergencyContactHomeNo
                                        ? "is-invalid"
                                        : ""
                                        } ${validate &&
                                          !errors?.[index]?.emergencyContactHomeNo
                                          ? "is-valid"
                                          : ""
                                        }`}
                                      placeholder="Enter your home mobile no"
                                      {...register(
                                        `${index}.emergencyContactHomeNo`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      value={formData.emergencyContactHomeNo}
                                      onChange={(e) =>
                                        saveDataForm(
                                          index,
                                          "emergencyContactHomeNo",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputGroupText className="list-light-primary">
                                      <i className="fa-solid fa-phone text-primary" />
                                    </InputGroupText>
                                    {errors?.[index]
                                      ?.emergencyContactHomeNo && (
                                        <FormFeedback tooltip>
                                          {
                                            errors?.[index]
                                              ?.emergencyContactHomeNo?.message
                                          }
                                        </FormFeedback>
                                      )}
                                    {validate &&
                                      !errors?.[index]
                                        ?.emergencyContactHomeNo && (
                                        <FormFeedback tooltip valid>
                                          Looks Good
                                        </FormFeedback>
                                      )}
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card style={{ borderRadius: "0.4rem" }}>
                        <CardBody>
                          <Row>
                            <Col md={4} className="position-relative">
                              <Label>
                                Login Details{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className={`form-check-input ${errors?.[0]?.sendLoginDetails
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  {...register(`${index}.sendLoginDetails`, {
                                    required: false,
                                  })}
                                  checked={formData.sendLoginDetails}
                                  onChange={(e) => {
                                    saveDataForm(
                                      index,
                                      "sendLoginDetails",
                                      e.target.checked
                                    );
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="sendLoginDetails"
                                >
                                  Send Login Details
                                </Label>
                              </div>
                              {errors?.[0]?.sendLoginDetails && (
                                <FormFeedback tooltip>
                                  {errors?.[0]?.sendLoginDetails?.message}
                                </FormFeedback>
                              )}
                            </Col>

                            <Col md={4} className="position-relative">
                              <Label>
                                Hide phone number from parent phone list?{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id={`hidePhoneYes-${index}`}
                                  className="form-check-input"
                                  {...register(`${index}.hideParentNo`, {
                                    required: false,
                                  })}
                                  value={"Yes"}
                                  checked={formData.hideParentNo === "Yes"}
                                  onChange={(e) =>
                                    saveDataForm(
                                      index,
                                      "hideParentNo",
                                      e.target.value
                                    )
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor={`hidePhoneYes-${index}`}
                                >
                                  Yes
                                </Label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id={`hidePhoneNo-${index}`}
                                  value="No"
                                  className="form-check-input"
                                  {...register(`${index}.hideParentNo`, {
                                    required: false,
                                  })}
                                  checked={formData.hideParentNo === "No"}
                                  onChange={(e) =>
                                    saveDataForm(
                                      index,
                                      "hideParentNo",
                                      e.target.value
                                    )
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor={`hidePhoneNo-${index}`}
                                >
                                  No
                                </Label>
                              </div>

                              {errors?.[0]?.sendLoginDetails && (
                                <FormFeedback tooltip>
                                  {errors?.[0]?.sendLoginDetails?.message}
                                </FormFeedback>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  );
                })}

              <Row>
                <Col md={12} className="mb-3">
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleAddAnotherParent}
                  >
                    + Add Another Parent
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Card
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderTop: "5px solid #eee",
                    }}
                  >
                    <CardBody>
                      <Btn
                        color="dark"
                        className="btn-md"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Update & Save"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="btn-md"
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/parents`
                          );
                        }}
                      >
                        {`Cancel`}
                      </Btn>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(EditParents);
