import { TableColumn } from "react-data-table-component";
import { Badges, Btn, LI, UL } from "../../../../../AbstractElements";

export const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns: TableColumn<any>[] = [
  {
    name: <CustomHeader title="Title" />,
    selector: (row) => row.title,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Action" />,
    sortable: true,
    center: true,
    style: { maxWidth: "50%" },
    cell: (row) => (
      <UL className="action simple-list flex-row">
        <LI className="edit">
          <a
            className="icon"
            href={`/dashboard/plans/pricing-bands/edit/${row.id}`}
          >
            <Btn color="primary" size="sm">
              <i
                className="fa-solid fa-pencil text-white me-1"
                style={{ fontSize: "12px" }}
              ></i>
              {"Edit"}
            </Btn>
          </a>
        </LI>
      </UL>
    ),
  },
];
