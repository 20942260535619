import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { connectComponent } from "./connector";
import { checkUserRole, flattenObject } from "../../../../Utils";
import UserApi from "../../../../services/UserApi";
import DropDownAPi from "../../../../services/DropdownApi";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  AddProjectsAndUploadTitle,
  empStatusOption,
  Href,
} from "../../../../Utils/Constants";
import { camelCase } from "lodash";
import NurseryAPi from "../../../../services/NurseryApi";

function Employees(props: any) {
  const navigate = useNavigate();
  const userRole = checkUserRole(props.user);

  const [hasLeave, setHasLeave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [employeeList, setEmployeeList] = useState([]);

  const [jobTitleDropDown, setJobTitleDropDown] = useState({
    id: "JobTitle",
    options: [],
    title: "Select Job",
  });

  const [statusDropDown, setStatusDropDown] = useState({
    id: "status",
    options: [
      { label: "All Status", value: "all-status" },
      { label: "Active", value: "ACTIVE" },
      { label: "On Boarding", value: "ONBOARDING" },
      { label: "Probation", value: "PROBATION" },
      { label: "On Leave", value: "ONLEAVE" },
      { label: "Suspended", value: "SUSPENDED" },
      { label: "Inactive", value: "INACTIVE" },
    ],
    title: "Select Status",
  });

  const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});

  const toggleDropdownOne = (id: any) => {
    setDropdownOpenOne({ [id]: !dropdownOpenOne[id] ?? true });
  };

  const [filterObj, setFilterObj] = useState<any>({
    searchText: "",
    status: "all-status",
    nurseryId: props.nurseryId,
    jobTitleId: "all-job",
    pageNo: 1,
    pageSize: 10000,
  });

  const filteredItems: any = employeeList.filter((item: any) => {
    item = {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
    return Object.values(item).some(
      (value) =>
        value &&
        value
          .toString()
          .toLowerCase()
          .includes(filterObj.searchText.toLowerCase())
    );
  });

  const fetchJobTitle = async () => {
    try {
      const response = await DropDownAPi.getJobTitle("false");

      if (response.success && response?.data?.length > 0) {
        let jobTitleOptions: any = [];
        jobTitleOptions.push({ label: "All Job", value: "all-job" });
        response?.data?.forEach((item: any) => {
          jobTitleOptions.push({
            label: item.jobTitle,
            value: item.id,
          });
        });

        setJobTitleDropDown({ ...jobTitleDropDown, options: jobTitleOptions });
      }
    } catch (error: any) {
      toast.error("error: ", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getAllEmployees(filterObj);
      if (response.success && Array.isArray(response.data)) {
        setSaveData(response);
        setEmployeeList(response.data);

        const leaveData = await NurseryAPi.getLeave({
          nurseryId: props.nurseryId,
        });
        if (leaveData.success && Array.isArray(leaveData.data)) {
          const filteredData = leaveData.data.filter(
            (row: any) => row.status === 0
          );
          if (filteredData.length > 0) {
            setHasLeave(true);
          } else {
            setHasLeave(false);
          }
        } else {
          setHasLeave(false);
        }
      } else {
        throw new Error("Expected an array of Employees");
      }
    } catch (error: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchJobTitle();
    setFilterObj({ ...filterObj, nurseryId: props.nurseryId });
  }, [props.nurseryId]);

  useEffect(() => {
    fetchEmployees();
  }, [filterObj, setFilterObj]);

  const handleStatusChange = async (row: any, status: string) => {
    // Convert the selected status ("active" or "inactive") to a boolean value
    const updatedStatus = status.toUpperCase(); // true for "active", false for "inactive"
    const isActive = status.toUpperCase() !== "INACTIVE";

    try {
      // Call the API to update the status, passing the boolean value
      await UserApi.updateStatus({
        id: row.id,
        employeeStatus: updatedStatus.toLocaleUpperCase(),
        isActive,
      });
      toggleDropdownOne(row.id);
      // Update the local state to reflect the status change
      await fetchEmployees();

      // Swal.fire("Success", "Status updated successfully!", "success");
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const handleAddEmployee = () => {
    if (hasLeave) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/users/employees/add`);
    } else {
      Swal.fire(
        "Error",
        "Please active the leave before adding employee.",
        "error"
      );
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Employees Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={handleAddEmployee}
                >
                  Add New Employees
                </Button>
              </div>
              <CardBody>
                <Row className="mb-4 d-flex align-items-center">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterObj({ ...filterObj, jobTitleId: e })
                      }
                      filterText={filterObj.jobTitleId}
                      type="select"
                      dropdown={{ ...jobTitleDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Job Title" // Custom placeholder text
                    />
                  </Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
                        console.log("e: ", e);

                        setFilterObj({ ...filterObj, status: e });
                      }}
                      filterText={filterObj.status}
                      type="select"
                      dropdown={{ ...statusDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Status" // Custom placeholder text
                    />
                  </Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterObj({
                          ...filterObj,
                          searchText: e.target.value,
                        })
                      }
                      filterText={filterObj.searchText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns({
                      handleStatusChange: (row: any, status: string) => handleStatusChange(row, status),
                      toggleDropdownOne: (id: any) => toggleDropdownOne(id),
                      dropdownOpenOne: dropdownOpenOne
                    })}
                    pagination
                    className="display text-nowrap"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(Employees);
