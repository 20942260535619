import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import UserApi from "src/services/UserApi";
import { connectComponent } from "./connector";
import Swal from "sweetalert2";

function Parents(props: any) {
  const navigate = useNavigate();
  const [saveData, setSaveData] = useState({});
  const [parentList, setParentList] = useState([]);
  const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});

  const toggleDropdownOne = (id: any) => {
    setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
  };

  const [filterText, setFilterText] = useState("");

  const fetchParents = async () => {
    try {
      const response = await UserApi.getAllFamilies({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.family)) {
        setSaveData(response);
        setParentList(response.family);
      } else {
        throw new Error("Expected an array of Parents");
      }
    } catch (error: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchParents();
  }, [props.nurseryId]);

  const filteredItems: any = parentList.filter((item: any) => {
    item = {
      ...item,
      name: `${item.firstName} ${item.middleName} ${item.lastName}`,
    };
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const handleStatusChange = async (row: any, status: string) => {
    console.log('status: ', status);
    const currentStatus = status === "ACTIVE" ? 0 : 1;
    const id = row.id;

    try {
      // Call the API to update the status, passing the boolean value
      await UserApi.updateFamilyStatus({
        status: currentStatus,
        id,
      });

      toggleDropdownOne(id);
      // Update the local state to reflect the status change
      await fetchParents();
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Parents Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/parents/add`);
                  }}
                >
                  Add New Parents
                </Button>
              </div>
              <CardBody>
                <Row className="justify-content-end">
                  <Col md={4}></Col>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns({
                      toggleDropdownOne: (row: any) => toggleDropdownOne(row),
                      handleStatusChange: (row: any, status: string) => handleStatusChange(row, status),
                      dropdownOpenOne: dropdownOpenOne,
                    })}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(Parents);
