import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";

import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  commonKeysObject,
  formatString,
  getUserActivityFormattedData,
  isTimeFormatValid,
  shiftsData,
} from "../../../../../Utils";
import moment from "moment";
import { employeeType, shiftFields } from "../../../../../Utils/Constants";
import { H1 } from "../../../../../AbstractElements";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import RotaCalendar from "./RotaCalendar";

const Shift = (props: any) => {
  const { id } = useParams();
  const initialState: any = {
    id: "",
    employeeId: "",
    businessId: "",
    createdAt: "",
    updatedAt: "",
    mondayHour: "",
    tuesdayHour: "",
    wednesdayHour: "",
    thursdayHour: "",
    fridayHour: "",
    saturdayHour: "",
    sundayHour: "",
    sickLeaveHour: "",
    unpaidLeaveHour: "",
    emergencyLeaveHour: "",
    annualLeaveHour: "",
    effectiveFrom: "",
    workingHours: "",
    scheduleType: "",
  };

  const [form, setForm] = useState<any>(initialState);
  const [showHide, setShowHide] = useState<boolean>(false);

  useEffect(() => {
    const fetchEmployeeShiftDetail = async () => {
      try {
        const response = await UserApi.getEmployeeShiftDetail(id);
        if (
          response.success &&
          Object.keys(response.employeeShift).length > 0
        ) {
          const modifiedObj = commonKeysObject(response.employeeShift, form);
          setForm({
            ...modifiedObj,
          });
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
        if (typeof error === "string") {
          toast.error(error);
        } else {
          toast.error(error.message);
        }
      } finally {
      }
    };
    fetchEmployeeShiftDetail();
  }, [id, props.tabId]);

  const handleEdit = () => {
    setShowHide(!showHide);
  };

  const [scheduleType, setScheduleType] = useState("DURATION_BASED");
  const [workingHours, setWorkingHours] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState<Date>(new Date()); // Allow null

  const [dailyHours, setDailyHours] = useState<any>({
    Monday: { enabled: true, time: "8h 00m" },
    Tuesday: { enabled: true, time: "8h 00m" },
    Wednesday: { enabled: true, time: "8h 00m" },
    Thursday: { enabled: true, time: "8h 00m" },
    Friday: { enabled: true, time: "8h 00m" },
    Saturday: { enabled: true, time: "8h 00m" },
    Sunday: { enabled: true, time: "8h 00m" },
  });

  const [leaveBalance, setLeaveBalance] = useState<any>({
    AnnualLeave: { enabled: false, hours: "" },
    SickLeave: { enabled: false, hours: "" },
    EmergencyLeave: { enabled: false, hours: "" },
    UnpaidLeave: { enabled: false, hours: "" },
  });

  useEffect(() => {
    setScheduleType(form.scheduleType);
    setWorkingHours(form.workingHours);
    setEffectiveFrom(new Date(form.effectiveFrom));

    const dailyHoursData = {
      Monday: {
        enabled: form.mondayHour != "" ? true : false,
        time: form.mondayHour,
      },
      Tuesday: {
        enabled: form.tuesdayHour != "" ? true : false,
        time: form.tuesdayHour,
      },
      Wednesday: {
        enabled: form.wednesdayHour != "" ? true : false,
        time: form.wednesdayHour,
      },
      Thursday: {
        enabled: form.tuesdayHour != "" ? true : false,
        time: form.tuesdayHour,
      },
      Friday: {
        enabled: form.fridayHour != "" ? true : false,
        time: form.fridayHour,
      },
      Saturday: {
        enabled: form.saturdayHour != "" ? true : false,
        time: form.saturdayHour,
      },
      Sunday: {
        enabled: form.sundayHour != "" ? true : false,
        time: form.sundayHour,
      },
    };

    const leaveData = {
      AnnualLeave: {
        enabled: form.annualLeaveHour != 0 ? true : false,
        hours: form.annualLeaveHour || "",
      },
      SickLeave: {
        enabled: form.sickLeaveHour != 0 ? true : false,
        hours: form.sickLeaveHour || "",
      },
      EmergencyLeave: {
        enabled: form.emergencyLeaveHour != 0 ? true : false,
        hours: form.emergencyLeaveHour || "",
      },
      UnpaidLeave: {
        enabled: form.unpaidLeaveHour != 0 ? true : false,
        hours: form.unpaidLeaveHour || "",
      },
    };
    setDailyHours(dailyHoursData);
    setLeaveBalance(leaveData);
  }, [form]);

  const toggleDay = (day: any) => {
    setDailyHours({
      ...dailyHours,
      [day]: {
        ...dailyHours[day],
        enabled: !dailyHours[day].enabled,
        time: !dailyHours[day].enabled === true ? "0h 00m" : "",
      },
    });
  };

  const updateDayTime = (day: any, time: any) => {
    setDailyHours({
      ...dailyHours,
      [day]: {
        ...dailyHours[day],
        time,
        enabled: time == "" ? false : dailyHours[day].enabled,
      },
    });
  };

  const toggleLeaveType = (type: any) => {
    setLeaveBalance({
      ...leaveBalance,
      [type]: {
        ...leaveBalance[type],
        enabled: !leaveBalance[type].enabled,
      },
    });
  };

  const updateLeaveHours = (type: any, value: any) => {
    setLeaveBalance({
      ...leaveBalance,
      [type]: { ...leaveBalance[type], hours: value },
    });
  };

  const handleSubmit = async () => {
    const formData: any = {
      id: form.id,
      mondayHour: dailyHours.Monday.enabled ? dailyHours.Monday.time : "",
      tuesdayHour: dailyHours.Tuesday.enabled ? dailyHours.Tuesday.time : "",
      wednesdayHour: dailyHours.Wednesday.enabled
        ? dailyHours.Wednesday.time
        : "",
      thursdayHour: dailyHours.Thursday.enabled ? dailyHours.Thursday.time : "",
      fridayHour: dailyHours.Friday.enabled ? dailyHours.Friday.time : "",
      saturdayHour: dailyHours.Saturday.enabled ? dailyHours.Saturday.time : "",
      sundayHour: dailyHours.Sunday.enabled ? dailyHours.Sunday.time : "",

      sickLeaveHour: leaveBalance.SickLeave.enabled
        ? leaveBalance.SickLeave.hours != ""
          ? Number(leaveBalance.SickLeave.hours)
          : ""
        : 0,
      unpaidLeaveHour: leaveBalance.UnpaidLeave.enabled
        ? leaveBalance.UnpaidLeave.hours != ""
          ? Number(leaveBalance.UnpaidLeave.hours)
          : ""
        : 0,
      emergencyLeaveHour: leaveBalance.EmergencyLeave.enabled
        ? leaveBalance.EmergencyLeave.hours != ""
          ? Number(leaveBalance.EmergencyLeave.hours)
          : ""
        : 0,
      annualLeaveHour: leaveBalance.AnnualLeave.enabled
        ? leaveBalance.AnnualLeave.hours != ""
          ? Number(leaveBalance.AnnualLeave.hours)
          : ""
        : 0,
      effectiveFrom: new Date(effectiveFrom).getTime(),
      workingHours: workingHours,
      scheduleType: scheduleType,
    };

    const invalidDays = shiftFields
      .filter(
        (day) => formData[day.key] && !isTimeFormatValid(formData[day.key])
      )
      .map((day) => day.name);

    const leaveTypeErrors = [
      "annualLeaveHour",
      "sickLeaveHour",
      "emergencyLeaveHour",
      "unpaidLeaveHour",
    ]
      .filter((type) => formData[type] !== 0 && !formData[type])
      .map((type) => type.replace(/([A-Z])/g, " $1").toLowerCase());

    if (invalidDays.length > 0 || leaveTypeErrors.length > 0) {
      const errorMessage = [
        invalidDays.length > 0 &&
          `Invalid time format for ${invalidDays.join(
            ", "
          )}. Please use the format '[hh]h [mm]m'\n`,
        leaveTypeErrors.length > 0 &&
          `Please provide ${leaveTypeErrors.join(", ")}`,
      ]
        .filter(Boolean)
        .join("\n");

      toast.error(errorMessage);
    } else {
      const shiftEditResp = await UserApi.updateEmployeeShiftDetail(formData);
      if (
        shiftEditResp.success &&
        Object.keys(shiftEditResp.updatedEmployeeShift).length > 0
      ) {
        Swal.fire({
          title: "Success!",
          text: "Employee Shift updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee Shift updated successfully`
            )
          );

          const modifiedObj = commonKeysObject(
            shiftEditResp.updatedEmployeeShift,
            form
          );
          setForm({
            ...modifiedObj,
          });

          handleEdit();
        });
      }
      // updateEmployeeShiftDetail({ id: editItem?.id, body: form });
    }
  };

  return (
    // <Col xl={12}>
    //   <Card>
    //     <CommonCardHeader titleClass="card-title" title={"Shifts"} />
    //     <CardBody style={{ paddingTop: "0.5rem" }}>
    //       <Card
    //         style={{
    //           border: "1px solid #eee",
    //           borderRadius: "0.8rem",
    //           padding: "1.5rem",
    //         }}
    //       >
    //         <CommonCardHeader
    //           headClass="custom-header-card d-flex justify-content-between"
    //           titleClass="card-title"
    //           title={`${showHide ? "Edit " : ""}Work Schedule`}
    //           noBorder={true}
    //           showEditBtn={showHide === false}
    //           showBackBtn={showHide === true}
    //           handleBackBtn={() => handleEdit()}
    //         />
    //         <CardBody style={{ padding: "0", paddingTop: "1rem" }}>
    //         </CardBody>
    //       </Card>
    //     </CardBody>
    //   </Card>
    // </Col>
    <Col md={12} xl={12}>
      <Card className="card-absolute">
        <CardBody>
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Shifts"}
          />{" "}
          <Row className="mt-0">
            <RotaCalendar />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Shift;
