import api from "./api"; // Use the Axios instance with interceptor

export default class MealPlanApi {
  static async getMealPlans(nurseryId: string) {
    try {
      const response = await api.get(`/mealPlan/all/${nurseryId}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getMealPlanById(id: string) {
    try {
      const response = await api.get(`/mealPlan/${id}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createMealPlan(data: any) {
    try {
      const response = await api.post("/mealPlan/create", data);
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateMealPlan(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/mealPlan/${id}`, body); // Change to PATCH
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // New method for deleting an mealPlan
  static async deleteMealPlan(id: string) {
    try {
      const response = await api.delete(`/mealPlan/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
