import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
  TabPane,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import UserApi from "../../../../services/UserApi";
import Swal from "sweetalert2";
import CommonCardHeader from "src/Utils/CommonComponents/CommonCardHeader";

function Password(props: any) {
  return (
    <TabPane tabId={`2`} key={1}>
      <PasswordForm user={props.user} />
      <PinForm user={props.user} />
    </TabPane>
  );
}

function PasswordForm(props: any) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const initialState = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmitPasswordForm = async () => {
    setIsLoading(true);
    let formData = JSON.parse(JSON.stringify(form));
    formData.id = props.user != null ? props.user.id : "";
    try {
      const data = await UserApi.updateUserProfile(formData);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating
            reset(initialState);
            setForm(initialState);
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("err: ", error.message);
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const passwordValue = watch(`password`); // Watch the password field for real-time matching
  return (
    <Form onSubmit={handleSubmit(onSubmitPasswordForm)}>
      <Card className="card-absolute mt-5">
        <CommonCardHeader
          headClass="bg-primary"
          titleClass="text-light"
          title={"Change Password"}
        />
        <CardBody>
          <Row className="mt-2">
            <Col md={4}>
              <FormGroup>
                <Label>
                  {"Current Password"} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control ${
                    errors.currentPassword ? "is-invalid" : ""
                  }`}
                  type="password"
                  placeholder="Current Password"
                  {...register("currentPassword", { required: true })}
                  value={form.currentPassword}
                  onChange={(e) =>
                    saveDataForm("currentPassword", e.target.value)
                  }
                />
                {errors.currentPassword && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {"Current Password is required"}{" "}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>
                  {"New Password"} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  type="password"
                  placeholder="New Password"
                  {...register("password", { required: true })}
                  value={form.password}
                  onChange={(e) => saveDataForm("password", e.target.value)}
                />
                {errors.password && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {"Password is required"}{" "}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>
                  {"Confirm New Password"}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                  type="password"
                  placeholder="Confirm New Password"
                  {...register("confirmPassword", {
                    required: true,
                    validate: (value) =>
                      value === passwordValue || "Passwords do not match",
                  })}
                  value={form.confirmPassword}
                  onChange={(e) =>
                    saveDataForm("confirmPassword", e.target.value)
                  }
                />
                {errors.confirmPassword && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {errors.confirmPassword.message
                      ? "Passwords do not match"
                      : "Confirm Password is required"}{" "}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={7}>
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save Password"
                )}
              </Btn>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
}

function PinForm(props: any) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const initialState = {
    pin: "",
    password: "",
    id: "",
  };

  useEffect(() => {
    if (props.user) {
      const formData = {
        pin: props.user.pinNo,
        password: "",
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    }
  }, [props]);

  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldType, setFieldType] = useState<any>("password");

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onSubmitPinForm = async () => {
    setIsLoading(true);
    try {
      const data = await UserApi.checkPassword(form);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            if (props.user) {
              const formData = {
                pin: props.user.pinNo,
                password: "",
                id: props.user.id,
              };

              reset(formData);
              setForm(formData);
              setFieldType("text");
            }
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        Swal.fire({
          title: "Error !!!",
          text: error.message,
          icon: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitPinForm)}>
      <Card className="card-absolute mt-5">
        <CommonCardHeader
          headClass="bg-primary"
          titleClass="text-light"
          title={"Manage Pin"}
        />
        <CardBody>
          <Row className="mt-2">
            <Col md={4}>
              <FormGroup>
                <Label>
                  {"Pin"} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control ${errors.pin ? "is-invalid" : ""}`}
                  type={fieldType}
                  placeholder="Pin"
                  {...register("pin", { required: true })}
                  value={form.pin}
                  disabled={true}
                />
                {errors.company && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {"Pin is required"}{" "}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>
                  {"Password"} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  type="password"
                  placeholder="Password"
                  {...register("password", { required: true })}
                  value={form.password}
                  onChange={(e) => saveDataForm("password", e.target.value)}
                />
                {errors.password && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {"Password is required"}{" "}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={7}>
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Show Pin"
                )}
              </Btn>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
}

export default Password;
