import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    Table,
    Input,
    Collapse,
    Button,
    UncontrolledTooltip,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Label
} from "reactstrap";
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import { connectComponent } from './connector';
import { format, addDays, startOfWeek, eachDayOfInterval, addWeeks, subWeeks } from 'date-fns';
import { FaThermometer, FaChevronDown, FaChevronUp, FaInfoCircle, FaFileExport, FaArrowLeft, FaCheck, FaSun, FaExclamationTriangle } from 'react-icons/fa';
import './ChildAttendance.css';
import FilterComponent from 'src/Components/Tables/DataTables/Common/FilterComponent';

interface DayAttendance {
    date: string;
    expectedDuration: string;
    completedDuration: string;
    isPresent: boolean;
    temperature?: number;
}

interface Child {
    id: number;
    name: string;
    age: string;
    weeklyAttendance: DayAttendance[];
    totalExpectedHours: number;
    totalCompletedHours: number;
}

interface Room {
    id: number;
    name: string;
    totalChildren: number;
    children: Child[];
    totalExpectedHours: number;
    totalCompletedHours: number;
}

function ChildAttendance({ user, nurseryId }: any) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [expandedRooms, setExpandedRooms] = useState<{ [key: string]: boolean }>({});
    const [showLeave, setShowLeave] = useState(false);
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const weekDays = Array.from({ length: 5 }, (_, i) => {
        const day = addDays(startOfWeek(selectedDate, { weekStartsOn: 1 }), i);
        return {
            date: day,
            formattedDate: format(day, 'dd MMMM'),
            dayName: format(day, 'EEEE')
        };
    });

    const rooms: Room[] = [
        {
            id: 1,
            name: 'Discoverers',
            totalChildren: 3,
            totalExpectedHours: 120,
            totalCompletedHours: 110,
            children: [
                {
                    id: 1,
                    name: 'Annie Sophia Laing',
                    age: '1 year',
                    totalExpectedHours: 40,
                    totalCompletedHours: 35,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '09:03 - 23:59',
                            isPresent: true,
                            temperature: 37.2
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 17:30',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '',
                            isPresent: false
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '08:00 - 17:15',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:50 - 17:45',
                            isPresent: true,
                            temperature: 37.0
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'John Smith',
                    age: '1.2 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 38,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 37.0
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.8
                        }
                    ]
                },
                {
                    id: 3,
                    name: 'Emma Wilson',
                    age: '11 months',
                    totalExpectedHours: 40,
                    totalCompletedHours: 37,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '',
                            isPresent: false
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 37.8
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.8
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            name: 'Explorers',
            totalChildren: 3,
            totalExpectedHours: 120,
            totalCompletedHours: 115,
            children: [
                {
                    id: 4,
                    name: 'Oliver Brown',
                    age: '2 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 38,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 37.9
                        }
                    ]
                },
                {
                    id: 5,
                    name: 'Sophia Davis',
                    age: '1.8 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 39,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.8
                        }
                    ]
                },
                {
                    id: 6,
                    name: 'Lucas Miller',
                    age: '2.1 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 38,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '',
                            isPresent: false
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.8
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            name: 'Adventurers',
            totalChildren: 3,
            totalExpectedHours: 120,
            totalCompletedHours: 118,
            children: [
                {
                    id: 7,
                    name: 'Mia Taylor',
                    age: '2.5 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 39,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:45 - 18:15',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.7
                        }
                    ]
                },
                {
                    id: 8,
                    name: 'Ethan Anderson',
                    age: '2.3 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 40,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.8
                        }
                    ]
                },
                {
                    id: 9,
                    name: 'Ava Thompson',
                    age: '2.4 years',
                    totalExpectedHours: 40,
                    totalCompletedHours: 39,
                    weeklyAttendance: [
                        {
                            date: '2025-02-03',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        },
                        {
                            date: '2025-02-04',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-05',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:30 - 18:30',
                            isPresent: true,
                            temperature: 36.7
                        },
                        {
                            date: '2025-02-06',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:35 - 18:25',
                            isPresent: true,
                            temperature: 36.8
                        },
                        {
                            date: '2025-02-07',
                            expectedDuration: '07:30 - 18:30',
                            completedDuration: '07:40 - 18:20',
                            isPresent: true,
                            temperature: 36.9
                        }
                    ]
                }
            ]
        }
    ];

    const toggleAllRooms = () => {
        const newState = !allCollapsed;
        setAllCollapsed(newState);
        const newExpandedRooms = rooms.reduce((acc, room) => ({
            ...acc,
            [room.id]: !newState
        }), {});
        setExpandedRooms(newExpandedRooms);
    };

    const toggleRoom = (roomId: number) => {
        setExpandedRooms(prev => ({
            ...prev,
            [roomId]: !prev[roomId]
        }));
    };

    const getPresentChildrenCount = (room: Room, date: Date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        return room.children.filter(child =>
            child.weeklyAttendance.find(day =>
                day.date === dateStr && day.isPresent
            )
        ).length;
    };

    const getTemperatureCount = (room: Room, date: Date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        return room.children.filter(child => {
            const attendance = child.weeklyAttendance.find(day => day.date === dateStr);
            return attendance?.temperature && attendance.temperature > 37.5;
        }).length;
    };

    const isEndTimeGreaterThanExpected = (expectedDuration: string, completedDuration: string) => {
        if (!completedDuration) return false;
        const expectedEndTime = expectedDuration.split(' - ')[1];
        const actualEndTime = completedDuration.split(' - ')[1];
        const [expectedHour, expectedMinute] = expectedEndTime.split(':').map(Number);
        const [actualHour, actualMinute] = actualEndTime.split(':').map(Number);

        if (actualHour > expectedHour) return true;
        if (actualHour === expectedHour && actualMinute > expectedMinute) return true;
        return false;
    };

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`Child Attendance`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                            </div>
                            <CardBody>
                                {/* Filters Section */}
                                <Row className="mb-3 g-3">
                                    <Col md={2}>
                                        <Label>From</Label>
                                        <FilterComponent
                                            onFilter={(date: any) => {
                                                setSelectedDate(date);
                                            }}
                                            filterText={selectedDate}
                                            type="date"
                                            showDateLabel={false}
                                            placeholder="Select From Date"
                                        />
                                    </Col>
                                </Row>

                                {/* Checkboxes */}
                                <Row className="mb-3">
                                    <Col>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Show leave when not expected
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Table responsive bordered className="attendance-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="room-name font-weight-bold">
                                                    Room
                                                </div>
                                            </th>
                                            {weekDays.map((day, index) => (
                                                <th key={index}>
                                                    <div>{format(day.date, 'd MMMM')}</div>
                                                    <div>{format(day.date, 'EEEE')}</div>
                                                </th>
                                            ))}
                                            <th>Total Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rooms.map(room => (
                                            <React.Fragment key={room.id}>
                                                <tr className="room-row">
                                                    <td>
                                                        <div className="room-info">
                                                            <div className="room-name">
                                                                {room.name}
                                                            </div>
                                                            <div onClick={() => toggleRoom(room.id)} style={{ cursor: 'pointer' }}>
                                                                {expandedRooms[room.id] ? <FaChevronUp /> : <FaChevronDown />}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {weekDays.map((day, index) => {
                                                        const presentCount = getPresentChildrenCount(room, day.date);
                                                        const tempCount = getTemperatureCount(room, day.date);
                                                        return (
                                                            <td key={index}>
                                                                <div className="attendance-counts">
                                                                    <div className="count-item bg-light-dark" style={{ maxWidth: "50px" }}>
                                                                        <FaCheck className="icon" /> {presentCount}
                                                                    </div>
                                                                    <div className="count-item bg-light-success" style={{ maxWidth: "50px" }}>
                                                                        <FaCheck className="icon" /> {presentCount}
                                                                    </div>
                                                                </div>
                                                                <div className="attendance-counts">
                                                                    <div className="count-item bg-light-danger" style={{ maxWidth: "50px" }}>
                                                                        <FaThermometer className="icon" /> {tempCount}
                                                                    </div>
                                                                    <div className="count-item bg-light-warning" style={{ maxWidth: "50px" }}>
                                                                        <FaSun className="icon" /> {tempCount}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                    <td>
                                                        <div className="total-hours">
                                                            <span className="expected">{room.totalExpectedHours}h</span>
                                                            <span className="completed">{room.totalCompletedHours}h</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {expandedRooms[room.id] && room.children.map(child => (
                                                    <tr key={child.id} className="child-row">
                                                        <td>
                                                            <div className="child-info">
                                                                <div className="child-name">{child.name}</div>
                                                                <div className="child-age">{child.age}</div>
                                                            </div>
                                                        </td>
                                                        {weekDays.map((day, index) => {
                                                            const attendance = child.weeklyAttendance.find(
                                                                att => att.date === format(day.date, 'yyyy-MM-dd')
                                                            );
                                                            return (
                                                                <td key={index}>
                                                                    {attendance && (
                                                                        <div className="attendance-counts children">
                                                                            <div className="count-item bg-light-dark" style={{ maxWidth: "150px" }}>
                                                                                <FaCheck className="icon" /> {attendance.expectedDuration}
                                                                            </div>
                                                                            {attendance.isPresent && (
                                                                                <div className={`count-item ${isEndTimeGreaterThanExpected(attendance.expectedDuration, attendance.completedDuration) ? 'bg-light-warning' : 'bg-light-success'}`}
                                                                                    style={{ maxWidth: "150px" }}>
                                                                                    {isEndTimeGreaterThanExpected(attendance.expectedDuration, attendance.completedDuration) ?
                                                                                        <FaExclamationTriangle className="icon" /> :
                                                                                        <FaCheck className="icon" />
                                                                                    }
                                                                                    {attendance.completedDuration}
                                                                                </div>
                                                                            )}
                                                                            {attendance.temperature && attendance.temperature > 37.5 && (
                                                                                <div className="count-item bg-light-danger" style={{ maxWidth: "150px" }}>
                                                                                    <FaThermometer className="icon" /> {attendance.temperature}°C
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            );
                                                        })}
                                                        <td>
                                                            <div className="total-hours">
                                                                <span className="expected">{child.totalExpectedHours}h</span>
                                                                <span className="completed">{child.totalCompletedHours}h</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        {/* Total Row */}
                                        <tr className="total-row">
                                            <td>
                                                <div className="room-info">
                                                    <div className="room-name">
                                                        Total
                                                    </div>
                                                </div>
                                            </td>
                                            {weekDays.map((day, index) => {
                                                const totalPresent = rooms.reduce((sum, room) =>
                                                    sum + getPresentChildrenCount(room, day.date), 0
                                                );
                                                const totalTemp = rooms.reduce((sum, room) =>
                                                    sum + getTemperatureCount(room, day.date), 0
                                                );
                                                return (
                                                    <td key={index}>
                                                        <div className="attendance-counts">
                                                            <div className="count-item bg-light-dark" style={{ maxWidth: "50px" }}>
                                                                <FaCheck className="icon" /> {totalPresent}
                                                            </div>
                                                            <div className="count-item bg-light-success" style={{ maxWidth: "50px" }}>
                                                                <FaCheck className="icon" /> {totalPresent}
                                                            </div>

                                                        </div>

                                                        <div className="attendance-counts">
                                                            <div className="count-item bg-light-danger" style={{ maxWidth: "50px" }}>
                                                                <FaThermometer className="icon" /> {totalTemp}
                                                            </div>
                                                            <div className="count-item bg-light-warning" style={{ maxWidth: "50px" }}>
                                                                <FaSun className="icon" /> {totalTemp}
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                            <td>
                                                <div className="total-hours">
                                                    <span className="expected">{rooms.reduce((sum, room) => sum + room.totalExpectedHours, 0)}h</span>
                                                    <span className="completed">{rooms.reduce((sum, room) => sum + room.totalCompletedHours, 0)}h</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>


    );
}

export default connectComponent(ChildAttendance)