import React, { useEffect, useState } from "react";
import {
  Table,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Form,
  Row,
  FormGroup,
  Label,
  InputGroup,
  FormFeedback,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Btn } from "../../../../AbstractElements";
import UserApi from "../../../../services/UserApi";
import { toast } from "react-toastify";
import {
  calculateTotalHours,
  getUserActivityFormattedData,
} from "../../../../Utils";
import RotaAPi from "../../../../services/RotaApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import { employeeType } from "../../../../Utils/Constants";

const RotaManagementForm = (props: any) => {
  const initialState = {
    id: "",
    nurseryId: props.nurseryId,
    rotaType: "",
    startDate: "",
    endDate: "",
    employeeHours: [
      {
        employee: {},
        startTime: "",
        endTime: "",
        breakTime: "0",
        rotaEmployeeId: "",
      },
    ],
  };

  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const saveDataForm = (
    name: string,
    value: any,
    index?: number,
    nestedField?: string
  ) => {
    if (name === "employeeHours" && index !== undefined && nestedField) {
      // Handle nested field updates inside employeeHours array
      const updatedEmployeeHours = form.employeeHours.map(
        (item: any, idx: any) =>
          idx === index ? { ...item, [nestedField]: value } : item
      );

      setForm({ ...form, employeeHours: updatedEmployeeHours });
    } else {
      // Handle top-level field updates
      setForm({ ...form, [name]: value });
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getAllEmployees({
        searchText: "",
        status: "ACTIVE",
        nurseryId: props.nurseryId,
        jobTitleId: "all-job",
        pageNo: 1,
        pageSize: 10000,
      });

      if (response.success && Array.isArray(response.data)) {
        const formattedData = response.data.map((employee: any) => {
          return {
            employee: {
              name: `${employee.firstName} ${employee.lastName}`,
              id: employee.id,
              contractedHours: JSON.parse(
                employee?.contractedHours || JSON.stringify({})
              ),
            },
            startTime: "",
            endTime: "",
            breakTime: "0",
            rotaEmployeeId: "",
          };
        });
        setForm({ ...form, employeeHours: formattedData });
      } else {
        throw new Error(JSON.stringify("Expected an array of Employees"));
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    props.id == "" && fetchEmployees();
    props.id != "" && fetchRotas();
    saveDataForm("nurseryId", props.nurseryId);
  }, [props.nurseryId]);

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      if (form.employeeHours.length <= 0) {
        throw new Error(
          JSON.stringify(
            `No Employee available. Please add at least one employee...`
          )
        );
      }

      const response = await RotaAPi.addUpdate({
        ...form,
        startDate: form.startDate.getTime(),
        endDate: form.endDate.getTime(),
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Settings", `${response.message}`)
          );
          props.setAction({ id: "", action: "List" });
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateEndDate = () => {
    const rotaType = watch("rotaType"); // Watch rotaType value
    const startDate = watch("startDate"); // Watch startDate value

    let minDate = undefined; // Initialize minDate
    let maxDate = undefined; // Initialize maxDate

    if (rotaType && startDate) {
      if (rotaType === "weekly") {
        // Calculate Monday (start of the week) and Sunday (end of the week)
        const startDay = startDate.getDay();
        const daysToMonday = startDay === 0 ? -6 : 1 - startDay; // Adjust for Monday as start of week
        const monday = new Date(startDate);
        monday.setDate(startDate.getDate() + daysToMonday);

        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6); // Sunday = Monday + 6 days

        minDate = monday;
        maxDate = sunday;
      } else if (rotaType === "monthly") {
        // Calculate the first and last dates of the month
        const firstDayOfMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );

        minDate = firstDayOfMonth;
        maxDate = lastDayOfMonth;
      }
    }

    return { minDate, maxDate };
  };

  const updateFormDataWithRotaEmployees = (
    responseData: any,
    formData: any
  ) => {
    // Loop through the rotaEmployees from the response
    responseData.rotaEmployees.forEach((rotaEmployee: any) => {
      // Find the employee in the formData that matches the employeeId
      const employeeIndex = formData.findIndex(
        (employee: any) => employee.employee.id === rotaEmployee.employeeId
      );

      // If a match is found, update the startTime, endTime, breakTime
      if (employeeIndex !== -1) {
        formData[employeeIndex].startTime = rotaEmployee.startTime;
        formData[employeeIndex].endTime = rotaEmployee.endTime;
        formData[employeeIndex].breakTime = rotaEmployee.breakTime;
        formData[employeeIndex].rotaEmployeeId = rotaEmployee.id;
      }
    });

    return formData;
  };

  // Fetch Rota from the API
  const fetchRotas = async () => {
    try {
      const responseData = await UserApi.getAllEmployees({
        searchText: "",
        status: "ACTIVE",
        nurseryId: props.nurseryId,
        jobTitleId: "all-job",
        pageNo: 1,
        pageSize: 10000,
      });

      let formattedData = form.employeeHours;
      if (
        responseData.success &&
        Array.isArray(responseData.data) &&
        responseData.data.length > 0
      ) {
        formattedData = responseData.data.map((employee: any) => {
          return {
            employee: {
              name: `${employee.firstName} ${employee.lastName}`,
              id: employee.id,
              contractedHours: JSON.parse(
                employee?.contractedHours || JSON.stringify({})
              ),
            },
            startTime: "",
            endTime: "",
            breakTime: "0",
            rotaEmployeeId: "",
          };
        });
      } else {
        formattedData = initialState.employeeHours;
        throw new Error(
          JSON.stringify(`No Employee available for selected nursery...`)
        );
      }

      const response = await RotaAPi.getRotas({
        nurseryId: props.nurseryId,
      });

      if (
        response.success &&
        Array.isArray(response.data) &&
        responseData.data.length > 0
      ) {
        const filteredData = response?.data.filter(
          (row: any) => row.id === props.id
        );
        if (filteredData.length > 0) {
          const formData = {
            id: filteredData?.[0]?.id,
            nurseryId: props.nurseryId,
            rotaType: filteredData?.[0]?.rotaType,
            startDate: new Date(filteredData?.[0]?.startDate),
            endDate: new Date(filteredData?.[0]?.endDate),
            employeeHours: updateFormDataWithRotaEmployees(
              filteredData?.[0],
              formattedData
            ),
          };

          setForm(formData);
          reset(formData);
        } else {
          setForm(form);
          reset(form);
        }
      } else {
        setForm(initialState);
        reset(initialState);
        throw new Error(JSON.stringify("Failed to fetch Rota"));
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
    }
  };

  return (
    <Card className="card-absolute">
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ borderBottom: "5px solid #eee" }}
      >
        <CommonCardHeader
          title={`${props.id ? "Edit Rota" : "Add Rota"}`}
          headClass="bg-primary"
          titleClass="text-light"
        />
        <Button
          color="dark"
          className="btn-md m-4 d-flex align-items-center mt-3 mb-3"
          onClick={() => props.setAction({ id: "", action: "List" })}
        >
          <i
            className="iconly-Arrow-Left icli"
            style={{ marginRight: "0.5rem" }}
          ></i>{" "}
          Back
        </Button>
      </div>
      <CardBody>
        <Form onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="mt-3">
            <Col md={4} className="position-relative">
              <FormGroup>
                <Label>
                  Rota Type <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="rotaType"
                  control={control}
                  rules={{
                    required: true,
                  }} // Validation rules
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { value: "weekly", label: "Weekly" },
                        { value: "monthly", label: "Monthly" },
                        { value: "customize", label: "Customize" },
                      ]}
                      value={
                        [
                          { value: "weekly", label: "Weekly" },
                          { value: "monthly", label: "Monthly" },
                          { value: "customize", label: "Customize" },
                        ].find((option) => option.value === form.rotaType) ||
                        null
                      }
                      onChange={(selectedOption) => {
                        // Check if selectedOption is not null
                        if (selectedOption) {
                          field.onChange(selectedOption.value); // Update the field value
                          saveDataForm("rotaType", selectedOption.value); // Save the data
                        }
                      }} // Handle value change
                    />
                  )}
                />
                {errors.rotaType && (
                  <FormFeedback
                    style={{ display: "block", color: "orangered" }}
                  >
                    {"Please select a rota type"}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col md={4} className="position-relative">
              <Label>
                {"Start Date"} <span className="text-danger">*</span>
              </Label>
              <InputGroup className="flatpicker-calender">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      className={`form-control flatpickr-input ${
                        errors.startDate ? "is-invalid" : ""
                      }`}
                      placeholderText="Select a date"
                      dateFormat="MMMM dd,yyyy"
                      selected={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        saveDataForm("startDate", date); // Save the data
                      }}
                    />
                  )}
                />

                {errors.startDate && (
                  <FormFeedback
                    style={{ display: "block", color: "orangered" }}
                  >
                    {"Please select start date."}
                  </FormFeedback>
                )}
              </InputGroup>
            </Col>

            <Col md={4} className="position-relative">
              <Label>
                {"End Date"} <span className="text-danger">*</span>
              </Label>
              <InputGroup className="flatpicker-calender">
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: true,
                  }} // Validation rules
                  render={({ field }) => {
                    const { minDate, maxDate } = calculateEndDate();
                    return (
                      <DatePicker
                        className={`form-control flatpickr-input ${
                          errors.endDate ? "is-invalid" : ""
                        }`}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          saveDataForm("endDate", date);
                        }}
                        placeholderText="Select End Date"
                        dateFormat="MMMM dd, yyyy"
                        {...(minDate ? { startDate: minDate, minDate } : {})}
                        {...(maxDate ? { endDate: maxDate, maxDate } : {})}
                      />
                    );
                  }}
                />

                {errors.endDate && (
                  <FormFeedback
                    style={{ display: "block", color: "orangered" }}
                  >
                    {"Please select end date."}
                  </FormFeedback>
                )}
              </InputGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>
                      Break Time <br />
                      <small>(in Minutes)</small>
                    </th>
                    <th>
                      Total Hours <br />
                      <small>(without break)</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.employeeHours.map((entry: any, index: number) => (
                    <tr key={index}>
                      <td>
                        {entry.employee.name}
                        <br />
                        <small>
                          (Contract Hours:{" "}
                          {`${
                            entry?.employee?.contractedHours?.hour || ""
                          }, Minutes: ${
                            entry?.employee?.contractedHours?.minutes || ""
                          }` || ""}
                          )
                        </small>
                      </td>
                      <td>
                        <Input
                          type="time"
                          value={entry.startTime}
                          onChange={
                            (e) =>
                              saveDataForm(
                                "employeeHours",
                                e.target.value,
                                index,
                                "startTime"
                              ) // Save the data
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="time"
                          value={entry.endTime}
                          onChange={
                            (e) =>
                              saveDataForm(
                                "employeeHours",
                                e.target.value,
                                index,
                                "endTime"
                              ) // Save the data
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="number"
                          value={entry.breakTime}
                          min={0}
                          max={60}
                          onChange={
                            (e) =>
                              saveDataForm(
                                "employeeHours",
                                e.target.value,
                                index,
                                "breakTime"
                              ) // Save the data
                          }
                        />
                      </td>
                      <td>
                        {
                          calculateTotalHours({
                            startTime: entry.startTime,
                            endTime: entry.endTime,
                          }).totalHours
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="d-flex mt-4 justify-content-end">
                <Btn
                  color="primary"
                  style={{ marginRight: "0.5rem" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="d-flex gap-2">
                      <Spinner color="white" size="sm" /> Loading...
                    </span>
                  ) : (
                    "Save & Update"
                  )}
                </Btn>
                <Button
                  color="dark"
                  className="btn-md m-0 d-flex align-items-center"
                  onClick={() => props.setAction({ id: "", action: "List" })}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default RotaManagementForm;
