import { TableColumn } from "react-data-table-component";
import { Badges, LI, UL } from "../../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import { convertDateTimeToTime, showNames } from "../../../../../Utils";
import JustifyContent from "src/Components/UiKits/Dropdown/JustifyContent";

// export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [model, setModel] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 237, 236)",
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            padding: "4px 0px",
            color: "rgb(224, 49, 55)",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
      <span
        style={{ padding: "4px 4px", fontWeight: "700", fontSize: "16px" }}
        onClick={() => setModel(!model)}
      >
        <i className="iconly-Arrow-Down-2 icli"></i>
      </span>
      {model && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            zIndex: "1",
            width: "150px",
            height: "90px",
            background: "#000",
          }}
        >
          <h2>Hello</h2>
        </div>
      )}
    </>
  );
};

const CustomHeader = ({ title, style }: { title: string; style: any }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px", ...style }}>
    {title}
  </span>
);

export const columns = ({
  handleStatusChange,
}: {
  handleStatusChange: (row: any) => void;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Employee Name" style={{}} />,
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%", justifyContent:"start" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Employee In Time" style={{justifyContent: "flex-end", width:"100%", display:"flex"}} /> , style: {textAlign:"left", justifyContent:" end", paddingRight:"30px" },
      selector: (row) =>
        convertDateTimeToTime(row?.attendance?.[0]?.clockInTime) ||
        "Haven't Clock IN",
      cell: (row: any) => {
        return (
          <>
            {convertDateTimeToTime(row?.attendance?.[0]?.clockInTime) ? (
              <>
                {row.EmployeeLeave.length > 0
                  ? showNames(row?.EmployeeLeave)
                  : convertDateTimeToTime(row?.attendance?.[0]?.clockInTime)}
              </>
            ) : (
              <>
                {row.EmployeeLeave.length > 0
                  ? showNames(row?.EmployeeLeave)
                  : "Haven't Clock IN"}
              </>
            )}
          </>
        );
      }, 
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Employee Out Time" style={{justifyContent: "flex-end", width:"100%", display:"flex"}} />, style: {textAlign:"left", justifyContent:" end", paddingRight:"30px" },
      selector: (row) =>
        convertDateTimeToTime(row?.attendance?.[0]?.clockOutTime) ||
        "Haven't Clock OUT",
      cell: (row: any) => {
        return (
          <>
            {convertDateTimeToTime(row?.attendance?.[0]?.clockOutTime) ? (
              <>
                {row.EmployeeLeave.length > 0
                  ? showNames(row?.EmployeeLeave)
                  : convertDateTimeToTime(row?.attendance?.[0]?.clockOutTime)}
              </>
            ) : (
              <>
                {row.EmployeeLeave.length > 0
                  ? showNames(row?.EmployeeLeave)
                  : "Haven't Clock OUT"}
              </>
            )}
          </>
        );
      }, 
      sortable: true,
      center: false,
    },
  ];
  return columns;
};
