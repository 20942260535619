import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // For styling
import { toast } from "react-toastify";
import AttendanceAPi from "../../../../services/AttendanceApi";
import { connectComponent } from "./connector";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import {
  convertTimeToDateTime,
  formatDateToYYYYMMDD,
  getUserActivityFormattedData,
} from "../../../../Utils";
import { widgetContentData } from "src/Data/Widgets/General";
import DashboardSvgIcon from "src/Utils/CommonComponents/DashboardSvgIcon";
import { H4 } from "src/AbstractElements";
import moment from "moment-timezone";

function EmployeeAttendance(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState(""); // Filter text for search
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]); // Track selected employees by id
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [time, setTime] = useState(""); // Time selection state
  const [action, setAction] = useState(""); // Time selection state
  const [selectedDate, setSelectedDate] = useState(new Date()); // Date picker state
  const [isIndeterminate, setIsIndeterminate] = useState(false); // "Select All" indeterminate state
  const [isAllSelected, setIsAllSelected] = useState(false); // "Select All" selected state
  const [clear, setClear] = useState(false);
  const [saveData, setSaveData] = useState([]); // "Select All" selected state
  const [isLoading, setIsLoading] = useState(false);

  // Filter data based on search text
  const filteredItems: any = saveData.filter((item: any) => {
    const filterObj = { firstName: item.firstName, lastName: item.lastName };
    return Object.values(filterObj).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  // Handle row checkbox selection
  const handleRowSelect = (state: { selectedRows: any[] }) => {
    const selectedIds = state.selectedRows.map((row: any) => {
      return { empId: row.id, attendanceId: row.attendance?.[0]?.id || "" };
    });
    setSelectedEmployees(selectedIds);
    setIsIndeterminate(
      selectedIds.length > 0 && selectedIds.length < filteredItems.length
    );
    setIsAllSelected(selectedIds.length === filteredItems.length);
    setClear(false);
  };

  // Handle Clock In/Out actions
  const handleClockInOut = (action: "clockIn" | "clockOut") => {
    if (selectedEmployees.length === 0) {
      toast.error("Please select employees first.");
      return;
    }
    setModalOpen(true); // Open the modal for time selection
    setAction(action);
  };

  const fetchAllEmployeeAttendance = async () => {
    try {
      const response = await AttendanceAPi.getAllEmployeeAttendance({
        nurseryId: props.nurseryId,
        employeeRole: "EMPLOYEE",
        currentDate: formatDateToYYYYMMDD(selectedDate),
      });
      if (response.success && Array.isArray(response.data)) {
        setSaveData(response.data);
        setClear(false);
      } else {
        setSaveData([]);
        setClear(false);
      }
    } catch (error: any) {
      setSaveData([]);
      setClear(false);
    }
  };

  useEffect(() => {
    fetchAllEmployeeAttendance();
  }, [props.nurseryId, selectedDate]);

  const handleModal = () => {
    setAction("");
    setModalOpen(false);
  };

  const handleAttendance = async () => {
    if (time === "") return toast.error("Please select time");
    setIsLoading(true);
    try {
      const formattedDate = selectedDate.toISOString().split("T")[0];
      const customDateTime = moment
        .tz(`${formattedDate} ${time}`, "Europe/London")
        .format("YYYY-MM-DD HH:mm:00");
      let response: any;
      if (action === "clockIn") {
        response = await AttendanceAPi.addClockIn({
          nurseryId: props.nurseryId,
          employeesId: selectedEmployees,
          clockInTime: customDateTime,
          attendanceDate: formatDateToYYYYMMDD(selectedDate),
        });
      } else if (action === "clockOut") {
        response = await AttendanceAPi.addClockOut({
          nurseryId: props.nurseryId,
          employeesId: selectedEmployees,
          clockOutTime: customDateTime,
          attendanceDate: formatDateToYYYYMMDD(selectedDate),
        });
      }

      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );

          // Clear selected employees
          setSelectedEmployees([]); // Unselect all employees
          setIsIndeterminate(false); // Reset the indeterminate state
          setIsAllSelected(false); // Reset the select-all checkbox state

          // Reset modal and time
          setModalOpen(false);
          setTime("");
          setAction("");
          setClear(true);

          // Fetch updated attendance data
          fetchAllEmployeeAttendance();
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (clear) setClear(false);
    }, 5000);
  }, [clear]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Employee Attendance Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <div className="d-flex" style={{ width: "250px" }}>
                  <Button
                    className="btn-md"
                    color="primary"
                    style={{ marginRight: "1rem" }}
                    onClick={() => handleClockInOut("clockIn")}
                  >
                    {" "}
                    <DashboardSvgIcon
                      className="svg-fill danger icon-size-small"
                      iconId="clock"
                    />
                    &nbsp;&nbsp;Clock In
                  </Button>
                  <Button
                    color="light"
                    onClick={() => handleClockInOut("clockOut")}
                    className="ml-1 text-dark"
                  >
                    <DashboardSvgIcon
                      className="svg-fill danger icon-size-small"
                      iconId="clock"
                    />
                    &nbsp;&nbsp;Clock Out
                  </Button>
                </div>
              </div>
              <CardBody>
                <Row className="justify-content-end">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(date: any) => {
                        setSelectedDate(date);
                      }}
                      filterText={selectedDate}
                      type="date"
                      showDateLabel={true}
                    />
                  </Col>
                  <Col md={4}></Col>

                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>

                <div className="table-responsive mt-3">
                  <DataTable
                    data={filteredItems}
                    columns={columns({
                      handleStatusChange: (row: any) => {},
                    })}
                    pagination
                    onSelectedRowsChange={handleRowSelect}
                    selectableRows
                    clearSelectedRows={clear}
                    selectableRowsHighlight
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal for Time Selection */}
      <Modal isOpen={modalOpen} toggle={handleModal} backdrop="static">
        <ModalHeader toggle={handleModal}>
          Select Time for Clock In/Out
        </ModalHeader>
        <ModalBody>
          <Input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={handleAttendance}
          >
            {isLoading ? (
              <span className="d-flex gap-2">
                <Spinner color="white" size="sm" /> Loading...
              </span>
            ) : (
              "Submit"
            )}
          </Button>
          <Button color="secondary" onClick={handleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default connectComponent(EmployeeAttendance);
