import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../Components/Forms/Common/DropItem";
import { Btn } from "../../../AbstractElements";
import { FirstName, LastName, LooksGood } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormDataTypes } from "../../../Types/Forms.type";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import Select from "react-select";
import AnnouncementApi from "src/services/AnnouncementApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { getUserActivityFormattedData } from "src/Utils";
import { connectComponent } from "./connector";

interface AnnouncementTypes {
  id: string;
  nurseryId: string;
  title: string;
  content: string;
  status: string;
  visibility: string;
}

function AddAnnouncements(props: any) {
  const initialState = {
    id: "",
    nurseryId: "",
    title: "",
    content: "",
    status: "DRAFT",
    visibility: "EVERYONE",
  };
  const navigate = useNavigate();
  const [form, setForm] = useState<AnnouncementTypes>(initialState);
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AnnouncementTypes>({
    defaultValues: {
      status: initialState.status, // Set the default value here
      visibility: initialState.visibility,
    },
  });

  const onSubmit = async () => {
    setValidate(true);
    setIsLoading(true);
    try {
      const response = await AnnouncementApi.createAnnouncement({
        ...form,
        id: undefined,
      });

      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Announcements", response.message)
          );
          // Navigate to plan list on success
          navigate(`${process.env.PUBLIC_URL}/dashboard/announcements`);
        });
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    saveDataForm("nurseryId", props.nurseryId);
  }, [props.nurseryId]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Announcements Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/announcements/`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-3">
                        <Col md={6} className="position-relative">
                          <Label>
                            {`Title`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            }`}
                            placeholder="Enter title name"
                            {...register("title", {
                              required: "Please enter your valid name",
                            })}
                            value={form.title}
                            onChange={(e) =>
                              saveDataForm("title", e.target.value)
                            }
                          />
                          {errors.title && (
                            <FormFeedback
                              style={{ display: "block", color: "orangered" }}
                            >
                              {errors.title.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={3} className="position-relative">
                          <Label>
                            {"Share"} <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="visibility"
                            control={control}
                            rules={{
                              required: "Please select a visibility",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="visibility"
                                className={`${
                                  errors.visibility ? "is-invalid" : ""
                                }`}
                                options={[
                                  { value: "EVERYONE", label: "Everyone" },
                                  { value: "PARENTS", label: "Parent" },
                                  { value: "EMPLOYEES", label: "Employee" },
                                ]} // Visibility options
                                value={
                                  [
                                    { value: "EVERYONE", label: "Everyone" },
                                    { value: "PARENTS", label: "Parent" },
                                    { value: "EMPLOYEES", label: "Employee" },
                                  ].find(
                                    (option) => option.value === form.visibility
                                  ) || null
                                } // Set current value, handle null
                                onChange={(selectedOption) => {
                                  // Check if selectedOption is not null
                                  if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    saveDataForm(
                                      "visibility",
                                      selectedOption.value
                                    ); // Save the data
                                  }
                                }} // Handle value change
                              />
                            )}
                          />
                          {errors.visibility && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.visibility?.message}</>
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={3} className="position-relative">
                          <Label>
                            {"Status"} <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="status"
                            control={control}
                            rules={{
                              required: "Please select a status",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="status"
                                className={`${
                                  errors.status ? "is-invalid" : ""
                                }`}
                                options={[
                                  { value: "DRAFT", label: "Draft" },
                                  { value: "PUBLISHED", label: "Published" },
                                  { value: "INACTIVE", label: "Inactive" },
                                ]} // Status options
                                value={
                                  [
                                    { value: "DRAFT", label: "Draft" },
                                    { value: "PUBLISHED", label: "Published" },
                                    { value: "INACTIVE", label: "Inactive" },
                                  ].find(
                                    (option) => option.value === form.status
                                  ) || null
                                } // Set current value, handle null
                                onChange={(selectedOption) => {
                                  // Check if selectedOption is not null
                                  if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    saveDataForm(
                                      "status",
                                      selectedOption.value
                                    ); // Save the data
                                  }
                                }} // Handle value change
                              />
                            )}
                          />
                          {errors.status && (
                            <FormFeedback
                              style={{ display: "block", color: "orangeRed" }}
                            >
                              <>{errors?.status?.message}</>
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-3 position-relative">
                      <Label>
                        {`Description`} <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Content is required" }} // Validation for the content field
                        render={({ field }) => {
                          return (
                            <ReactQuill
                              theme="snow"
                              value={field.value}
                              className={`${errors.title ? "is-invalid" : ""}`}
                              onChange={(value) => {
                                field.onChange(
                                  value === "<p><br></p>" ? "" : value
                                );
                                saveDataForm(
                                  "content",
                                  value === "<p><br></p>" ? "" : value
                                );
                              }} // Update form value on change
                              onBlur={field.onBlur} // Optional: handle onBlur event
                            />
                          );
                        }}
                      />
                      {errors.content && (
                        <FormFeedback
                          style={{
                            display: "block",
                            color: "orangeRed",
                          }}
                        >
                          {errors.content.message}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Save & List"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/announcements/`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(AddAnnouncements);
