import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H5, Image, P } from "../../../../AbstractElements";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Award,
  Briefcase,
  Calendar,
  Clock,
  File,
  FilePlus,
} from "react-feather/dist";
import Information from "./Components/Information";
import { connectComponent } from "./connector";
import UserApi from "src/services/UserApi";
import {
  bytesToMB,
  commonKeysObject,
  dynamicImage,
  fetchImage,
  getUserActivityFormattedData,
} from "src/Utils";
import Health from "./Components/Health";
import Sensitive from "./Components/Sensitive";
import Password from "./Components/Password";
import ChildDocument from "./Components/ChildDocument";
import ChildrenPermission from "./Components/ChildrenPermission";
import Rooms from "./Components/Rooms";
import { toast } from "react-toastify";
import UploadAPi from "src/services/UploadApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { baseUrl } from "src/Utils/Constants/config";
import { MyProfileTitle, statusColors } from "src/Utils/Constants";
import Bookings from "./Components/Bookings";

const CommonComp = ({ title }: any) => {
  return (
    <Col md={12} xl={12}>
      <Card className="card-absolute">
        <CommonCardHeader
          headClass="bg-primary"
          titleClass="text-light"
          title={title}
        />
        <CardBody>
          <h4>Coming Soon!</h4>
        </CardBody>
      </Card>
    </Col>
  );
};

function EditChildren(props: any) {
  const { id } = useParams();
  const location = useLocation();
  console.log("location: ", location.pathname.slice(0, 24));
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const [parentData, setParentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [isFamily, setIsFamily] = useState(
    location.pathname.slice(0, 24) !== "/dashboard/children/edit"
  );
  const activeToggle = (tab: number) => {
    setActiveTab(tab);
  };

  const [parentInfo, setParentInfo] = useState<any>({});

  const [initialState, setInitialState] = useState({
    id: "",
    firstName: "",
    lastName: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    birthPlace: "",
    liveWith: [],
    parentalResponsibility: [],
    keyPerson: [],
    specialNote: "",
    nurseryId: props.nurseryId,
    familyId: "",
    allergy: "",
    isPenicillinTolerant: "",
    dietaryPreferences: "",
    vaccines: "",
    heathSpecialNote: "",
    doctorName: "",
    doctorPhoneNo: "",
    doctorAddress: "",
    doctorPostcode: "",
    doctorCity: "",
    dentistName: "",
    dentistPhoneNo: "",
    dentistAddress: "",
    dentistPostcode: "",
    dentistCity: "",
    religion: "",
    ethnicity: "",
    password: "",
    backupPassword: "",
    nursery: {},
    avatarUrl: "",
  });

  useEffect(() => {
    setInitialState({ ...initialState, nurseryId: props.nurseryId });
  }, [props.nurseryId]);

  const fetchChildren = async () => {
    try {
      const response = await UserApi.getChildrenById({
        id,
        nurseryId: props.nurseryId,
      });
      if (response.success && Object.keys(response.children).length > 0) {
        const modifiedObj = commonKeysObject(response.children, initialState);

        let avatarUrlData = "";
        if (!!modifiedObj.avatarUrl) {
          // Fetch the image if avatarUrl exists
          avatarUrlData = await fetchImage(baseUrl + modifiedObj.avatarUrl);
        }

        setInitialState({
          ...modifiedObj,
          dob: new Date(modifiedObj.dob),
          keyPerson: modifiedObj.keyPerson.split(","),
          parentalResponsibility: modifiedObj.parentalResponsibility.split(","),
          liveWith: modifiedObj.liveWith.split(","),
          avatarUrl: avatarUrlData,
        } as any);

        await fetchParents(modifiedObj.familyId);
        await fetchEmployees();
      } else {
        setInitialState({ ...initialState });
      }
    } catch (error: any) {
      setInitialState({ ...initialState });
    }
  };

  const fetchParents = async (id: any) => {
    try {
      const response = await UserApi.getParentById(id);
      if (response.success && Array.isArray(response.data)) {
        const formData = response.data.map((row: any, index: any) => {
          const modifiedObj = commonKeysObject(row, {
            id: "",
            firstName: "",
            middleName: "",
            lastName: "",
            relation: "",
            phoneNo: "",
          });

          if (index === 0) {
            setParentInfo({
              parentNames: [`${modifiedObj.firstName} ${modifiedObj.lastName}`],
              parentPhoneNo: [`${modifiedObj.phoneNo}`],
            });
          }

          return { ...modifiedObj, password: row.backupPassword };
        });

        setParentData(formData);
      }
    } catch (error: any) {
    } finally {
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getAllEmployees({
        searchText: "",
        status: "all-status",
        nurseryId: props.nurseryId,
        jobTitleId: "all-job",
        pageNo: 1,
        pageSize: 10000,
      });
      if (response.success && Array.isArray(response.data)) {
        setEmployeeData(response.data);
      } else {
        setEmployeeData([]);
      }
    } catch (error: any) {
      setEmployeeData([]);
    }
  };

  useEffect(() => {
    fetchChildren();
  }, [id]);

  const sections = [
    {
      id: 1,
      title: "Basic Info",
      icon: <FilePlus />,
      component: (
        <Information
          initialState={initialState}
          parentData={parentData}
          employeeData={employeeData}
        />
      ),
    },
    {
      id: 2,
      title: "Health Information", // Corrected spelling
      icon: <Briefcase />,
      component: <Health initialState={initialState} />,
    },
    {
      id: 3,
      title: "Sensitive Information",
      icon: <Award />, // Changed icon for better representation (you can revert to <DollarSign /> if needed)
      component: <Sensitive initialState={initialState} />,
    },
    {
      id: 4,
      title: "Registration and Rooms",
      icon: <Clock />, // Added Leave tab with a relevant icon
      component: <Rooms initialState={initialState} />,
    },
    {
      id: 5,
      title: "Documents",
      icon: <File />,
      component: <ChildDocument id={id} tabId={activeTab} />,
    },
    {
      id: 6,
      title: "Children Permission",
      icon: <Calendar />,
      component: (
        <ChildrenPermission id={id} tabId={activeTab} parentData={parentData} />
      ),
    },
    {
      id: 7,
      title: "Set Collection Password",
      icon: <Calendar />,
      component: <Password initialState={initialState} />,
    },
    {
      id: 8,
      title: "Bookings",
      icon: <Calendar />,
      component: <Bookings nurseryId={initialState.nurseryId} childrenName={`${initialState.firstName} ${initialState.lastName}`} childrenId={initialState.id} />,
    },
  ];


  const getFile = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    childrenId: any
  ) => {
    // setIsUploading(true);
    const files: any = e.target.files;
    try {
      const file = files[0];
      if (bytesToMB(file.size) > 5)
        return toast.error("Can't upload file above 5 MB");
      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("folder", "avatar");
      const response = await UploadAPi.uploadFile(formData, "upload-avatar");
      const documentAddedResp = await UserApi.createAndUpdateChild({
        id: childrenId,
        avatarUrl: response.filePath,
      });
      if (documentAddedResp.success) {
        Swal.fire({
          title: "Success!",
          text: "Profile Picture updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Children",
              `Profile Picture updated successfully`
            )
          );
          await fetchChildren();
          if (inputRef.current) {
            inputRef.current.value = ""; // Reset the file input
          }
        });
      }
    } catch (error: any) {
      if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error(error.message);
      }
    } finally {
      // setIsUploading(false);
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginBottom: 0,
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Children Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/${isFamily ? "parents/" : ""
                      }children${isFamily ? `/${initialState.familyId}` : ""}`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>

            <Container fluid className="pt-3">
              <div className="edit-profile email-wrap bookmark-wrap">
                <Row>
                  <Col xl={3} className="box-col-4e" style={{ paddingLeft: 0 }}>
                    <Card>
                      <CommonCardHeader
                        titleClass="card-title"
                        title={"Profile"}
                      />
                      <CardBody className="p-2">
                        <div
                          className="profile-title"
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <div className="d-flex gap-3">
                            <div className="position-relative">
                              <Image
                                className="img-70 rounded-circle"
                                alt=""
                                src={
                                  !!initialState.avatarUrl
                                    ? initialState.avatarUrl
                                    : dynamicImage(`default-profile.jpg`)
                                }
                              />
                              <div
                                className="icon-wrapper bg-primary"
                                style={{
                                  padding: "5px",
                                  borderRadius: "50px",
                                  position: "absolute",
                                  top: "50px",
                                  right: "5px",
                                }}
                                onClick={getFile}
                              >
                                <i className="iconly-Edit icli text-white"></i>

                                <div
                                  style={{
                                    height: "0px",
                                    width: "0px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <input
                                    id="upfile"
                                    type="file"
                                    ref={inputRef}
                                    onChange={(event) =>
                                      onFileChange(event, initialState.id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <H5 className="mb-1">{`${initialState.firstName} ${initialState.lastName}`}</H5>
                              <P
                                className="mb-0 font-weight-bold"
                                style={{ fontWeight: "bold" }}
                              >
                                {"Children"}
                              </P>

                              <span
                                className="badge-light-primary mt-2"
                                style={{
                                  padding: "0 1.6rem",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  height: "29px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100px",
                                  backgroundColor: `${statusColors["active"]?.background}`,
                                  color: `${statusColors["active"]?.text}`,
                                }}
                              >
                                {"Active"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <ul className="d-flex flex-column gap-1 align-items-start">
                            <li>
                              <strong>
                                {" "}
                                <i className="fa-solid fa-user text-primary" />
                              </strong>{" "}
                              {parentInfo?.parentNames?.join(",")}
                            </li>
                            <li>
                              <strong>
                                {" "}
                                <i className="fa-solid fa-phone text-primary" />
                              </strong>{" "}
                              {parentInfo?.parentPhoneNo?.join(",")}
                            </li>
                          </ul>
                        </div>
                        <div
                          className="md-sidebar mt-0"
                          style={{
                            borderBottom: "1px solid #eee",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Btn
                            color="primary"
                            className="md-sidebar-toggle"
                            onClick={() => { }}
                          >
                            {""}
                          </Btn>
                          <div
                            className={`md-sidebar-aside job-left-aside custom-scrollbar open`}
                          >
                            <div className="email-left-aside">
                              <div
                                className="email-app-sidebar left-bookmark task-sidebar"
                                style={{
                                  height: "100%",
                                }}
                              >
                                <Nav className="main-menu mt-3">
                                  {sections.map((nav) => (
                                    <NavItem key={nav.id}>
                                      <a
                                        style={{ padding: "10px" }}
                                        href="javascript:void(0);"
                                        className={
                                          activeTab === nav.id
                                            ? "active badge-light-primary"
                                            : ""
                                        }
                                        onClick={() => activeToggle(nav.id)}
                                      >
                                        {/* Render the dynamic Font Awesome icon */}

                                        <span
                                          className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                          style={{
                                            borderRadius: "100px",
                                            lineHeight: "0px",
                                          }}
                                        >
                                          {nav.icon}
                                        </span>

                                        <span className="title ms-2">
                                          {nav.title}
                                        </span>
                                      </a>
                                    </NavItem>
                                  ))}
                                </Nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={9} xl={9} md={12} className="box-col-12">
                    <div className="email-right-aside bookmark-tabcontent">
                      <div className="ps-0">
                        <TabContent activeTab={activeTab}>
                          {sections.map((item: any) => (
                            <TabPane key={item.id} tabId={item.id}>
                              {item.component}
                            </TabPane>
                          ))}
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(EditChildren);
