import { Container, Row } from "reactstrap";
import MyProfile from "./Components/MyProfile";
import EditProfile from "./Components/EditProfile";
import { useLocation } from "react-router";

export default function AdminProfile(props: any) {
  const location = useLocation();
  return (
    <>
      <Container
        fluid
        className={`${
          location.pathname !== "/dashboard/settings" ? "pt-3" : "p-0"
        }`}
      >
        <div className="edit-profile">
          <Row>
            {/* <MyProfile user={props.user} /> */}
            <EditProfile user={props.user} setUser={props.setUser} />
          </Row>
        </div>
      </Container>
    </>
  );
}
