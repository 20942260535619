import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { FilterComponentProps } from "../../../../Types/Tables.type";
import { Title } from "../../../../Utils/Constants";
import Select from "react-select";
import { DropItemChangeProp, Option } from "../../../../Types/Forms.type";
import DatePicker from "react-datepicker";

export default function FilterComponent({
  onFilter,
  filterText,
  type = "search",
  dropdown,
  placeholder, // Accept the placeholder prop
  showDateLabel, // New prop to control visibility of Date label
}: FilterComponentProps) {
  const handleChange: DropItemChangeProp = (newValue, actionMeta) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as Option;
      onFilter(newSelectedOption?.value || null);
    }
  };
  return (
    <>
      {type === "search" ? (
        <Row className="align-items-center justify-content-center">
          <Col md="2">
            <Label className="mb-0">{"Search"}:</Label>
          </Col>
          <Col md="10" className="">
            <Input type="text" value={filterText} onChange={onFilter} />
          </Col>
        </Row>
      ) : (
        <>
          {type === "datepicker" ? (
            <Row className="align-items-center justify-content-center">
              <Col md="2">
                <Label className="mb-0" style={{ marginLeft: "14px" }}>
                  {"Date"}:
                </Label>
              </Col>
              <Col md="10" className="">
                <DatePicker
                  className={`form-control flatpickr-input`}
                  selected={filterText ?? new Date()}
                  onChange={onFilter}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Col>
            </Row>
          ) : (
            <>
              {type === "date" ? (
                <Row className="align-items-center justify-content-center">
                  {showDateLabel && (
                    <Col md="2">
                      <Label className="mb-0" style={{ marginLeft: "14px" }}>
                        {"Date"}:
                      </Label>
                    </Col>
                  )}
                  <Col md={showDateLabel ? "10" : "12"} className="">
                    <DatePicker
                      className={`form-control flatpickr-input`}
                      selected={filterText ?? new Date()}
                      onChange={onFilter}
                      dateFormat="MMMM dd, yyyy"
                      placeholderText={placeholder || "Select Date"}
                      endDate={new Date()}
                      maxDate={new Date()}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center">
                  <Label className="mb-0">
                    {" "}
                    {dropdown?.title ? (
                      <Label>{dropdown.title}:</Label> // Add colon only if title exists
                    ) : null}
                  </Label>
                  <Select
                    id={dropdown?.id}
                    options={dropdown?.options ?? []}
                    value={dropdown?.options?.find(
                      (option: any) => option.value === filterText
                    ) ?? ""}
                    onChange={handleChange}
                    placeholder={placeholder || "Select..."}
                    className={`${dropdown?.id}-form`}
                  />
                </Row>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
