import api from "./api"; // Use the Axios instance with interceptor

export default class AnnouncementApi {
  static async getAnnouncement(params: any) {
    try {
      const response = await api.get("/announcement", { params }); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createAnnouncement(data: any) {
    try {
      const response = await api.post("/announcement", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateAnnouncement(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/announcement/${id}`, body); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
