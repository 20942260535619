import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "./Helper/column";
import PricingBrandsAPi from "src/services/PricingBrandsApi";
import { connectComponent } from "./connector";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";

interface PriceBrand {
  id: string;
  title: string;
}

function PricingBands(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [priceBrand, setPriceBrand] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriceBrand, setSelectedPriceBrand] = useState<Partial<PriceBrand>>({});

  const filteredItems: any = priceBrand.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const fetchPricingBrands = async () => {
    try {
      const response = await PricingBrandsAPi.getPriceBand({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setPriceBrand(response.data);
      } else setPriceBrand([]);
    } catch (error: any) {
      setPriceBrand([]);
    }
  };

  useEffect(() => {
    fetchPricingBrands();
  }, [props.nurseryId]);

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<PriceBrand>();

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedPriceBrand({}); // Clear selected Price Brand
    } else {
      // If opening modal, reset with selected Price Brand data for edit, otherwise with empty data for add
      reset(selectedPriceBrand);
    }
  };

  const handleEditPriceBrand = (priceBrand: PriceBrand) => {
    setSelectedPriceBrand(priceBrand);
    toggleModal();
  };

  // Handle form submission for both create and update
    const onSubmit = async (data: PriceBrand) => {
      setIsLoading(true);
      try {
        setTimeout(async () => {
          if (selectedPriceBrand.id) {
            // Update Price Brand
            await PricingBrandsAPi.updatePriceBand({ ...data, id: selectedPriceBrand.id });
            Swal.fire({
              title: "Success!",
              text: "Price Brand updated successfully",
              icon: "success",
            });
          } else {
            // Create new Price Brand
            await PricingBrandsAPi.createPriceBand({ ...data, nurseryId: props.nurseryId });
            Swal.fire({
              title: "Success!",
              text: "Price Brand created successfully",
              icon: "success",
            });

          }
          // Reload the data after successful operation
          fetchPricingBrands();
          toggleModal();
        }, 2000);
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: error.message || "Something went wrong",
          icon: "error",
        });
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2300);
      }
    };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              className="icon"
              href="javascript:void(0);"
              onClick={() => handleEditPriceBrand(row)}
            >
              <Btn color="primary" size="sm">
                <i
                  className="fa-solid fa-pencil text-white me-1"
                  style={{ fontSize: "12px" }}
                ></i>
                {"Edit"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];  

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Pricing Brands`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={toggleModal}
                >
                  Add New Pricing Brands
                </Button>
              </div>
              <CardBody>
                <Row className="justify-content-end">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>

                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Modal for Adding Price Brand */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          {selectedPriceBrand.id ? "Edit Price Brand" : "Add Price Brand"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="title">
                Title <span className="text-danger">*</span>
              </Label>
              <input
                type="text"
                className={`form-control ${
                  errors.title ? "is-invalid" : ""
                }`}
                id="title"
                {...register("title", {
                  required: "Price Brand Title is required",
                })}
                defaultValue={selectedPriceBrand.title}
              />
              {errors.title && (
                <FormFeedback>{errors.title.message}</FormFeedback>
              )}
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  <>
                    {selectedPriceBrand.id ? "Update Price Brand" : "Add Price Brand"}
                  </>
                )}
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default connectComponent(PricingBands);
