import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../Components/Forms/Common/DropItem";
import { Btn } from "../../../AbstractElements";
import {
  FirstName,
  LastName,
  LooksGood,
  visibilityOptions,
} from "../../../Utils/Constants";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormDataTypes } from "../../../Types/Forms.type";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import Select from "react-select";
import EventAPi from "../../../services/EventApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";
import UploadAPi from "../../../services/UploadApi";

function AddEvents() {
  const navigate = useNavigate();
  const initialState = {
    title: "",
    eventDate: new Date(),
    startAt: new Date(),
    endAt: new Date(),
    visibility: "",
    files: [],
    description: "",
  };
  const [form, setForm] = useState({ ...initialState });

  const [validate, setValidate] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setValidate(true);
    try {
      // Format the data before sending it to the API
      let formattedData = JSON.parse(JSON.stringify(form));
      formattedData.endAt = `${new Date(formattedData.endAt).getTime()}`;
      formattedData.startAt = `${new Date(formattedData.startAt).getTime()}`;
      formattedData.eventDate = new Date(formattedData.eventDate).getTime();
      const { mediaUrl, mediaType } = await uploadImage(form.files[0]);
      if (!mediaUrl && !mediaType) {
        Swal.fire({
          title: "Error!",
          text: "Facing issue while uploading image",
          icon: "error",
        });
      } else {
        delete formattedData.files;
        formattedData["mediaUrl"] = mediaUrl;
        formattedData["mediaType"] = mediaType;
        formattedData["creationType"] = "SUPER_ADMIN";

        // Call the API to create the event using EventApi
        await EventAPi.createEvent(formattedData);

        Swal.fire({
          title: "Success!",
          text: "Event created successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Calendar",
              `Event created successfully`
            )
          );
          // Navigate to plan list on success
          navigate(`${process.env.PUBLIC_URL}/dashboard/calendar`);
        });
      }
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
    console.log(form);
    console.log("errors: ", errors);
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "event");
    try {
      const response = await UploadAPi.uploadFile(formData, "upload-event");

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      console.log("err: ", err);
      return { mediaUrl: "", mediaType: "" };
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Events Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/calendar/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {`Title`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            } ${validate && !errors.title ? "is-valid" : ""}`}
                            placeholder="Enter title name"
                            {...register("title", {
                              required: true,
                            })}
                            value={form.title}
                            onChange={(e) =>
                              saveDataForm("title", e.target.value)
                            }
                          />
                          {errors.title && errors?.title?.message && (
                            <FormFeedback tooltip>
                              {"Please enter title"}
                            </FormFeedback>
                          )}
                          {validate && !errors.title && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="eventDate"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.eventDate ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.eventDate
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("eventDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />

                            {errors.eventDate && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{"Please select event date."}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.eventDate && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Start Time"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="startAt"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.startAt ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.startAt
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    selected={form.startAt}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("startAt", date);
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                );
                              }}
                            />

                            {errors.startAt && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{"Please select event start time."}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.startAt && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"End Time"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="endAt"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.endAt ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.endAt
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    selected={form.endAt}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("endAt", date);
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                );
                              }}
                            />

                            {errors.endAt && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{"Please select event end time."}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.endAt && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Visibility"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="visibility"
                            control={control}
                            rules={{
                              required: true,
                            }} // Validation rules
                            render={({ field }: any) => (
                              <Select
                                {...field}
                                id="visibility"
                                className={`${
                                  errors.visibility ? "is-invalid" : ""
                                } ${
                                  validate && !errors.visibility
                                    ? "is-valid"
                                    : ""
                                }`}
                                options={visibilityOptions}
                                value={visibilityOptions.find(
                                  (option: any) =>
                                    option.value === field.visibility
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "visibility",
                                    selectedOption?.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.visibility && (
                            <FormFeedback tooltip style={{ display: "block" }}>
                              <>{"Please select any visibility"}</>
                            </FormFeedback>
                          )}
                          {validate && !errors.visibility && (
                            <FormFeedback
                              tooltip
                              valid
                              style={{ display: "block" }}
                            >
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Photo"}</Label>
                          <div style={{ display: "flex" }}>
                            <input
                              className="form-control"
                              type="file"
                              accept="image/*"
                              {...register("files", { required: true })}
                              onChange={(e) => {
                                saveDataForm("files", e.target.files);
                              }}
                            />
                            {errors.files && (
                              <FormFeedback tooltip>
                                {"Please upload a file"}
                              </FormFeedback>
                            )}
                            {validate && !errors.files && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="position-relative mt-3">
                      <Label>
                        {`Description`} <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(
                                value === "<p><br></p>" ? "" : value
                              );
                              saveDataForm(
                                "description",
                                value === "<p><br></p>" ? "" : value
                              );
                            }} // Update form value on change
                            onBlur={field.onBlur} // Optional: handle onBlur event
                            placeholder="Enter Event Description"
                          />
                        )}
                      />
                      {errors.description && (
                        <FormFeedback
                          className="d-block"
                          tooltip
                          style={{
                            display: "block",
                            top: "-7px",
                            left: "120px",
                            right: "unset",
                          }}
                        >
                          {"Description is required"}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/calendar/`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddEvents;
