import { createSlice } from "@reduxjs/toolkit";

interface IChildHelper {
  id: string;
  firstName: string;
  lastName: string;
  nursery: {
    id: string;
    name: string;
  };
  room: {
    id: string;
    roomName: string;
  };
}

interface IUser extends Record<string, unknown> {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "admin" | "employee" | "parent" | "super_admin";
  avatarUrl: string;
  createdAt: string;
  employeeRooms?: any[];
  jobTitle: any;
  children: IChildHelper[];
}

interface IAuthInitialState {
  user: IUser | null;
  isLoadingUser: boolean;
  token: string | null;
  error: string | null;
  success: string | null;
  userIp: string;
  selectedNursery: string;
  userClockIn: boolean;
  userClockOut: boolean;
  userClockInDate: string;
  attendanceId: string;
  empClockIn: string;
  empClockOut: string;
}

const initialState: IAuthInitialState = {
  user: null,
  isLoadingUser: false,
  token: "",
  error: "",
  success: "",
  userIp: "",
  selectedNursery: "",
  userClockIn: false,
  userClockOut: false,
  userClockInDate: "",
  attendanceId: "",
  empClockIn: "",
  empClockOut: "",
};

const UserSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGOUT: (state: IAuthInitialState) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
    },
    SET_USER_DATA: (state, action) => {
      if (action.payload.success) {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.success = "Login Success...!";
      } else {
        state.user = null;
        state.token = "";
        state.success = "";
        state.error = action.payload.error;
      }
    },
    SET_USER: (state, action) => {
      state.user = action.payload;
    },
    SET_ERROR: (state, action) => {
      state.error = action.payload;
    },
    SET_SUCCESS: (state, action) => {
      state.success = action.payload;
    },
    SET_USER_IP: (state, action) => {
      state.userIp = action.payload;
    },
    SET_NURSERY: (state, action) => {
      state.selectedNursery = action.payload;
    },
    SET_USER_CLOCK_IN: (state, action) => {
      state.userClockIn = action.payload.clockIn;
      state.userClockInDate = action.payload.clockInDate;
      state.attendanceId = action.payload.attendanceId;
      state.userClockOut = action.payload.clockOut;
      state.empClockOut = action.payload.empClockOut;
      state.empClockIn = action.payload.empClockIn;
    },
    SET_EMP_CLOCK_IN: (state, action) => {
      state.empClockIn = action.payload.empClockIn;
      state.userClockIn = action.payload.clockIn;
    },
    SET_EMP_CLOCK_OUT: (state, action) => {
      state.empClockOut = action.payload.empClockOut;
      state.userClockOut = action.payload.userClockOut;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  SET_USER_DATA,
  SET_ERROR,
  SET_SUCCESS,
  SET_USER,
  LOGOUT,
  SET_USER_IP,
  SET_NURSERY,
  SET_USER_CLOCK_IN,
  SET_EMP_CLOCK_IN,
  SET_EMP_CLOCK_OUT,
} = UserSlice.actions;
export default UserSlice.reducer;
