export const prepareHeaders = (headers: any, { getState }: any) => {
  const token = getState().auth.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

//   export const bucketBaseUrl = import.meta.env.VITE_BUCKET_BASEURL;

// export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
// export const baseUrl = "http://localhost:3000";
// export const baseUrl = "https://backend.bloomkidz.net/";
export const baseUrl = "https://stgbackend.bloomkidz.net/";
