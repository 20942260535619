import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../../../AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { countryCode } from "../../../../../Utils/CountryCode";
import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  commonKeysObject,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const General = (props: any) => {
  const { id } = useParams();
  const initialState: any = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    primaryAddress: "",
    country: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    joinDate: new Date(),
    employeeType: "",
    jobTitleId: "",
    monthlySalary: "",
    nurseryId: "",
    roleTitleId: "",
    rolePermissionId: "",
  };

  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const countryOptions = countryCode.map(({ country }) => ({
    label: country,
    value: country,
  }));

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await UserApi.getEmployeeById(id);
        if (response.success && Object.keys(response.data).length > 0) {
          const modifiedObj = commonKeysObject(response.data, form);

          setForm({
            ...modifiedObj,
            dob: new Date(modifiedObj.dob),
            joinDate: new Date(modifiedObj.joinDate),
          });

          reset({
            ...modifiedObj,
            dob: new Date(modifiedObj.dob),
            joinDate: new Date(modifiedObj.joinDate),
          });
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
        toast.error("error: ", error);
      }
    };
    fetchEmployees();
  }, [id, props.tabId]);

  const onEditSubmit = async () => {
    setIsLoading(true);
    const createEmpObj = JSON.parse(JSON.stringify(form));

    try {
      const addedResponse = await UserApi.updateEmployee({
        ...createEmpObj,
        dob: form.dob.getTime(),
        joinDate: form.joinDate.getTime(),
      });

      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Employee updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee updated successfully`
            )
          );

          props.setEmployeeDetailUpdated(Math.random());
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Personal Info"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"First Name"} <span className="text-danger">*</span>
                    </Label>
                    <input
                      className={`form-control ${
                        errors.firstName ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="First Name"
                      {...register("firstName", { required: true })}
                      value={form.firstName}
                      onChange={(e) =>
                        saveDataForm("firstName", e.target.value)
                      }
                    />
                    {errors.firstName && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"First Name is required"}{" "}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"Last Name"} <span className="text-danger">*</span>
                    </Label>
                    <input
                      className={`form-control ${
                        errors.lastName ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                      value={form.lastName}
                      onChange={(e) => saveDataForm("lastName", e.target.value)}
                    />
                    {errors.lastName && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"Last Name is required"}{" "}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>
                    {"Date of Birth"} <span className="text-danger">*</span>
                  </Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="dob"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            className={`form-control flatpickr-input ${
                              errors.dob ? "is-invalid" : ""
                            }`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("dob", date);
                            }}
                            placeholderText="Select a date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />

                    {errors.dob && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"DOB is required"}{" "}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>
                    {"Nationality"} <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="nationality"
                    control={control}
                    rules={{
                      required: "Please select any one nationality",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="nationality"
                        className={`${errors.nationality ? "is-invalid" : ""}`}
                        options={countryOptions}
                        value={countryOptions.find(
                          (option: any) => option.value === form.nationality
                        )} // Set current value
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption?.value);
                          saveDataForm("nationality", selectedOption.value);
                        }} // Update the value using Controller's onChange
                      />
                    )}
                  />
                  {errors.nationality && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Nationality is required"}{" "}
                    </FormFeedback>
                  )}
                </Col>

                <Col md={4} className="position-relative">
                  <Label>
                    {"Email"} <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                          message: "Please enter a valid email address",
                        },
                      })}
                      value={form.email}
                      onChange={(e) => saveDataForm("email", e.target.value)}
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-envelope text-primary" />
                    </InputGroupText>
                    {errors?.email && errors?.email?.message && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {`${errors?.email?.message ?? "Email is required"}`}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>
                <Col md={4} className="position-relative">
                  <Label>
                    {"Mobile"} <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.phoneNo ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your phoneNo"
                      {...register("phoneNo", {
                        required: "Please enter your phone no",
                        pattern: {
                          value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                          message: "Please enter a valid 11-digit phone number",
                        },
                      })}
                      max={11}
                      value={form.phoneNo}
                      onChange={(e) => saveDataForm("phoneNo", e.target.value)}
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-phone text-primary" />
                    </InputGroupText>
                    {errors.phoneNo && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {`${
                          errors?.phoneNo?.message ?? "Phone No is required"
                        }`}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative mt-3">
                  <FormGroup>
                    <Label>
                      {"Gender"} <span className="text-danger">*</span>
                    </Label>
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: "Please select a gender",
                      }} // Validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="gender"
                          options={[
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" },
                            { value: "other", label: "Other" },
                          ]} // Gender options
                          value={
                            [
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "other", label: "Other" },
                            ].find((option) => option.value === form.gender) ||
                            null
                          } // Set current value, handle null
                          onChange={(selectedOption) => {
                            // Check if selectedOption is not null
                            if (selectedOption) {
                              field.onChange(selectedOption.value); // Update the field value
                              saveDataForm("gender", selectedOption.value); // Save the data
                            }
                          }} // Handle value change
                        />
                      )}
                    />

                    {errors.gender && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {`${errors?.gender?.message ?? "Gender is required"}`}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-5">
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Address"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={8}>
                  <FormGroup>
                    <Label>{"Primary Address"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Primary Address"
                      {...register("primaryAddress", { required: false })}
                      value={form.primaryAddress}
                      onChange={(e) =>
                        saveDataForm("primaryAddress", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Country"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Country"
                      {...register("country", { required: false })}
                      value={form.country}
                      onChange={(e) => saveDataForm("country", e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"City"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="City"
                      {...register("city", { required: false })}
                      value={form.city}
                      onChange={(e) => saveDataForm("city", e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Post Code"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Post Code"
                      {...register("postalCode", { required: false })}
                      value={form.postalCode}
                      onChange={(e) =>
                        saveDataForm("postalCode", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-5">
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Emergency Contact"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"First Name"} <span className="text-danger">*</span>
                    </Label>
                    <input
                      className={`form-control ${
                        errors.emergencyContactFirstName ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="First Name"
                      {...register("emergencyContactFirstName", {
                        required: true,
                      })}
                      value={form.emergencyContactFirstName}
                      onChange={(e) =>
                        saveDataForm(
                          "emergencyContactFirstName",
                          e.target.value
                        )
                      }
                    />
                    {errors.emergencyContactFirstName && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"First Name is required"}{" "}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"Last Name"} <span className="text-danger">*</span>
                    </Label>
                    <input
                      className={`form-control ${
                        errors.emergencyContactLastName ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="Last Name"
                      {...register("emergencyContactLastName", {
                        required: true,
                      })}
                      value={form.emergencyContactLastName}
                      onChange={(e) =>
                        saveDataForm("emergencyContactLastName", e.target.value)
                      }
                    />
                    {errors.emergencyContactLastName && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"Last Name is required"}{" "}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>
                    {"Email"} <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.emergencyContactEmail ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your email"
                      {...register("emergencyContactEmail", {
                        required: "Please enter your Emergency Contact Email",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                          message: "Please enter a valid email address",
                        },
                      })}
                      value={form.emergencyContactEmail}
                      onChange={(e) =>
                        saveDataForm("emergencyContactEmail", e.target.value)
                      }
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-envelope text-primary" />
                    </InputGroupText>
                    {errors.emergencyContactEmail && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {`${
                          errors?.emergencyContactEmail?.message ??
                          "Email is required"
                        }`}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>
                <Col md={4} className="position-relative">
                  <Label>
                    {"Mobile"} <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Enter your phoneNo"
                      {...register("emergencyContactPhoneNo", {
                        required: "Please enter your phone no",
                        pattern: {
                          value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                          message: "Please enter a valid 11-digit phone number",
                        },
                      })}
                      max={11}
                      value={form.emergencyContactPhoneNo}
                      onChange={(e) =>
                        saveDataForm("emergencyContactPhoneNo", e.target.value)
                      }
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-phone text-primary" />
                    </InputGroupText>
                    {errors.emergencyContactPhoneNo && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {`${
                          errors?.emergencyContactPhoneNo?.message ??
                          "Phone No is required"
                        }`}
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <FormGroup>
                    <Label>{"Relationship"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Emergency Contact Relation"
                      {...register("emergencyContactRelation", {
                        required: false,
                      })}
                      value={form.emergencyContactRelation}
                      onChange={(e) =>
                        saveDataForm("emergencyContactRelation", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Update"
                )}
              </Btn>
            </CardFooter>
          </Card>
        </Col>
      </Form>
    </>
  );
};

export default General;
