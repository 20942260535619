import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import UserApi from "src/services/UserApi";
import { connectComponent } from "./connector";
import DocumentAPi from "src/services/DocumentApi";
import { capitalize } from "lodash";
import { handleFileDownload } from "src/Utils";
import { toast } from "react-toastify";
import { baseUrl } from "src/Utils/Constants/config";
import DashboardSvgIcon from "src/Utils/CommonComponents/DashboardSvgIcon";

function Documents(props: any) {
  const navigate = useNavigate();
  const [tab, setTab] = useState("EMPLOYEE");
  const [saveData, setSaveData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState<any>({});
  const [showMainMenu, setShowMainMenu] = useState<boolean>(false);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>({});

  const [filterText, setFilterText] = useState("");

  const filteredItems: HtmlColumnsInterface[] = saveData.filter(
    (item: HtmlColumnsInterface) => {
      return Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getAllEmployees({
        searchText: filterText,
        status: "all-status",
        nurseryId: props.nurseryId,
        jobTitleId: "all-job",
        pageNo: 1,
        pageSize: 10000,
      });
      if (response.success && Array.isArray(response.data)) {
        setSaveData(response.data);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      setSaveData([]);
    }
  };

  const fetchChildren = async () => {
    try {
      const response = await UserApi.getAllChildren({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.children)) {
        setSaveData(response.children);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      setSaveData([]);
    }
  };

  const fetchCompanyFolder = async () => {
    try {
      const response = await DocumentAPi.getFoldersByUserId(
        {
          visibility: "ADMINS",
          page: 1,
          pageSize: 10000,
          nurseryId: props.nurseryId,
        },
        props.user.id
      );
      if (
        response.success &&
        Object.keys(response.data).length > 0 &&
        response.data.list.length > 0
      ) {
        setSaveData(response.data.list);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      setSaveData([]);
    }
  };

  useEffect(() => {
    if (tab === "EMPLOYEE") fetchEmployees();
    if (tab === "CHILDREN") fetchChildren();
    if (tab === "COMPANY") fetchCompanyFolder();
  }, [props.nurseryId, tab]);

  useEffect(() => {
    if (saveData.length == 0) {
      setShowMainMenu(false);
      setShowSubMenu(false);
      setSelectedFolder({});
      setSelectedUser({});
    }
  }, [props.nurseryId]);

  const fetchFolders = async () => {
    try {
      let response: any = {};
      if (tab === "CHILDREN") {
        response = await DocumentAPi.getFoldersByChildrenId(
          {
            visibility: "SPECIFIC_CHILDREN",
          },
          selectedUser.id
        );
      } else {
        response = await DocumentAPi.getFoldersByUserId(
          {
            page: 1,
            pageSize: 10000,
            visibility: "SPECIFIC_EMPLOYEE",
          },
          selectedUser.id
        );
      }
      if (
        response.success &&
        Object.keys(response.data).length > 0 &&
        response.data.list.length > 0
      ) {
        setSaveData(response.data.list);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      setSaveData([]);
    }
  };

  useEffect(() => {
    if (selectedUser.id) fetchFolders();
    else {
      if (tab === "EMPLOYEE") fetchEmployees();
      if (tab === "CHILDREN") fetchChildren();
    }
  }, [selectedUser]);

  const handleBack = () => {
    if (tab === "COMPANY") {
      setShowMainMenu(false);
      setShowSubMenu(false);
      setSelectedFolder({});
    } else if (tab === "CHILDREN" || tab === "EMPLOYEE") {
      if (selectedUser.id) {
        if (Object.keys(selectedFolder).length > 0) {
          setShowSubMenu(false);
          setSelectedFolder({});
        } else {
          setShowMainMenu(false);
          setSelectedUser({});
        }
      } else {
        setShowMainMenu(false);
        setShowSubMenu(false);
        setSelectedFolder({});
        setSelectedUser({});
      }
    }
  };

  const handleTab = (value: string) => {
    setTab(value);
    setShowMainMenu(false);
    setShowSubMenu(false);
    setSelectedFolder({});
    setSelectedUser({});
  };

  const handleViewPersonFolder = (data: any) => {
    setSelectedUser(data);
    setShowMainMenu(true);
  };

  const handleViewFolder = (folder: any) => {
    if (tab === "COMPANY") {
      setShowMainMenu(true);
      setShowSubMenu(true);
      setSelectedFolder(folder);
    } else {
      setShowSubMenu(true);
      setSelectedFolder(folder);
    }
  };

  const fetchFiles = async () => {
    try {
      let fileResp: any = {};

      if (tab === "EMPLOYEE" || tab === "COMPANY") {
        fileResp = await DocumentAPi.getFiles({
          docFolderId: selectedFolder?.id as string,
          page: 1,
          pageSize: 10000,
        });
      } else {
        fileResp = await DocumentAPi.getChildrenFiles({
          docFolderId: selectedFolder?.id as string,
          page: 1,
          pageSize: 10000,
        });
      }

      if (
        fileResp.success &&
        Object.keys(fileResp.data).length > 0 &&
        fileResp.data.list.length > 0
      ) {
        setSaveData(fileResp.data.list);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      setSaveData([]);
    }
  };

  useEffect(() => {
    if (selectedFolder?.id) {
      fetchFiles();
    } else {
      if (tab === "COMPANY") fetchCompanyFolder();
      else if (
        (tab === "EMPLOYEE" || tab === "CHILDREN") &&
        Object.keys(selectedUser).length > 0
      )
        fetchFolders();
    }
  }, [selectedFolder]);

  const downloadFile = (fileData: any) => {
    if (fileData.mediaUrl) {
      handleFileDownload(`${baseUrl}${fileData.mediaUrl}`, fileData.fileName);
    } else {
      toast.error("File download failed: Media URL not available");
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Documents Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <Row className="mb-3">
                  <Col md={4}>
                    <div className="d-flex align-item-center">
                      <div className="general-widget w-100">
                        <Card
                          className="widget-1 mb-0"
                          color="primary"
                          onClick={() => handleTab("EMPLOYEE")}
                          outline={tab === "EMPLOYEE"}
                          style={{ borderRadius: "8px", cursor: "pointer" }}
                        >
                          <CardBody className="p-2">
                            <div className="widget-content">
                              <div className={`widget-round primary`}>
                                <div className="bg-round">
                                  <i
                                    className="fas fa-user"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                </div>
                              </div>
                              <div>
                                <h4>Employee</h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-item-center">
                      <div className="general-widget w-100">
                        <Card
                          className="widget-1 mb-0"
                          color="primary"
                          onClick={() => handleTab("CHILDREN")}
                          outline={tab === "CHILDREN"}
                          style={{ borderRadius: "8px", cursor: "pointer" }}
                        >
                          <CardBody className="p-2">
                            <div className="widget-content">
                              <div className={`widget-round primary`}>
                                <div className="bg-round">
                                  <i
                                    className="fas fa-users"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                </div>
                              </div>
                              <div>
                                <h4>Children</h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-item-center">
                      <div className="general-widget w-100">
                        <Card
                          className="widget-1 mb-0"
                          color="primary"
                          onClick={() => handleTab("COMPANY")}
                          outline={tab === "COMPANY"}
                          style={{ borderRadius: "8px", cursor: "pointer" }}
                        >
                          <CardBody className="p-2">
                            <div className="widget-content">
                              <div className={`widget-round primary`}>
                                <div className="bg-round">
                                  <i
                                    className="fas fa-building"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                </div>
                              </div>
                              <div>
                                <h4>Company</h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                {(showMainMenu || showSubMenu) && (
                  <Card className="mt-4 mb-4">
                    <div>
                      <CommonCardHeader
                        headClass="custom-header-card d-flex justify-content-between"
                        titleClass="card-title"
                        title={`${capitalize(tab)} ${
                          selectedUser?.firstName
                            ? "> " + selectedUser.firstName
                            : ""
                        } ${
                          selectedFolder?.folderName
                            ? "> " + selectedFolder.folderName
                            : ""
                        }`}
                        noBorder={true}
                        showBackBtn
                        handleBackBtn={() => handleBack()}
                      />
                    </div>
                  </Card>
                )}

                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns(
                      tab,
                      handleViewFolder,
                      downloadFile,
                      selectedFolder,
                      handleViewPersonFolder,
                      selectedUser?.id
                    )}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(Documents);
