import api from "./api"; // Use the Axios instance with interceptor

export default class HolidayAPi {
    static async createHoliday(data: any) {
        try {
            const response = await api.post(`/holiday/add`, data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async fetchHoliday(data: any) {
        try {
            const response = await api.get(`/holiday`, { params: data }); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async deleteHoliday(id: any) {
        try {
            const response = await api.delete(`/holiday/${id}`); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async updateHoliday(id: any, data: any) {
        try {
            const response = await api.patch(`/holiday/${id}`, data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }
}
