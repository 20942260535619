import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import MealPlanApi from "../../../services/MealPlanApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import UserActivityAPi from "../../../services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "../../../Utils";
import DatePicker from "react-datepicker";
import Select from "react-select";
import UserApi from "src/services/UserApi";
import { connectComponent } from "./connector";
import { toast } from "node_modules/react-toastify/dist";
import DropDownAPi from "src/services/DropdownApi";
import moment from "moment";
import { set } from "node_modules/@types/lodash";
import { Href } from "src/Utils/Constants";

type DayOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

type MealType = "Break Fast" | "Lunch" | "Tea" | "Snack" | "Bottle";

type MealPlan = {
  [day in DayOfWeek]: {
    [meal in MealType]: string | string[] | any;
  };
};

// Interface for form data
interface IForm {
  id: string;
  title: string;
  assignTo: string;
  startDate: any;
  endDate: any;
  children: any;
  rooms: any;
  mealPlan: MealPlan | any;
}

const AddMealPlan = (props: any) => {
  const initialState = {
    id: "",
    title: "",
    assignTo: "children",
    children: [],
    rooms: [],
    startDate: "",
    endDate: "",
    mealPlan: {
      Monday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
      Tuesday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
      Wednesday: {
        "Break Fast": "",
        Lunch: "",
        Tea: "",
        Snack: "",
        Bottle: "",
      },
      Thursday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
      Friday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
      Saturday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
      Sunday: { "Break Fast": "", Lunch: "", Tea: "", Snack: "", Bottle: "" },
    },
  };

  const mealTypes: MealType[] = [
    "Break Fast",
    "Lunch",
    "Tea",
    "Snack",
    "Bottle",
  ];
  const daysOfWeek: DayOfWeek[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const navigate = useNavigate();
  const [form, setForm] = useState<IForm>(initialState);
  const [childrenData, setChildrenData] = useState<any>([]);
  const [roomNames, setRoomNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [basicTab, setBasicTab] = useState("Monday");

  const [open, setOpen] = useState("Monday");
  const toggle = (id: string) => {
    open === id ? setOpen("") : setOpen(id);
  };

  // Using React Hook Form for handling form state and validation
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: initialState,
  });

  const saveDataForm = (
    name: string,
    value: any,
    day?: string,
    meal?: string
  ) => {
    if (!!day && !!meal) {
      setForm((prevForm: any) => ({
        ...prevForm,
        [name]: {
          ...prevForm[name],
          [day]: {
            ...prevForm[name]?.[day],
            [meal]: value,
          },
        },
      }));
    } else if (name === "assignTo") {
      if (value === "rooms") {
        setForm({ ...form, [name]: value, children: [] });
        setValue("children", []);
      } else {
        setForm({ ...form, [name]: value, rooms: [] });
        setValue("rooms", []);
      }
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  useEffect(() => {
    const fetchChildren = async () => {
      try {
        const response = await UserApi.getAllChildren({
          nurseryId: props.nurseryId,
        });
        if (response.success && Array.isArray(response.children)) {
          const formData = response.children.map((row: any, index: any) => {
            const modifiedObj = commonKeysObject(row, {
              id: "",
              firstName: "",
              lastName: "",
            });
            return {
              label: `${modifiedObj.firstName} ${modifiedObj.lastName}`,
              value: modifiedObj.id,
            };
          });
          setChildrenData(formData);
        } else {
          setChildrenData([]);
        }
      } catch (error: any) {
        setChildrenData([]);
      }
    };
    fetchChildren();

    const fetchClassRoomsOptions = async () => {
      try {
        const response = await DropDownAPi.getClassRoomsOptions(
          props.nurseryId || ""
        );

        if (response.success && response?.data?.length > 0) {
          const roomNameOptions = response?.data?.map((item: any) => ({
            label: item.roomName,
            value: item.id,
          }));
          setRoomNames(roomNameOptions);
        } else {
          setRoomNames([]);
        }
      } catch (error: any) {
        setRoomNames([]);
        // toast.error("error: ", error);
      }
    };

    fetchClassRoomsOptions();
  }, [props.nurseryId]);

  console.log('form: ', form);
  // Function to handle form submission
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setTimeout(async () => {
        await MealPlanApi.createMealPlan({
          ...form,
          mealPlan: JSON.stringify(form.mealPlan),
          startDate: moment(form.startDate).format("YYYY-MM-DD"),
          endDate: moment(form.endDate).format("YYYY-MM-DD"),
          children: form.children.join(","),
          rooms: form.rooms.join(","),
          id: undefined,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Meal Plan created successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Meal Plan",
              `Meal Plan created successfully`
            )
          );

          navigate(`${process.env.PUBLIC_URL}/dashboard/meal-plans`);
        });
      }, 2000);
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  // Handler for adding tags
  const handleKeyDown = (e: any, day: any, mealType: any) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const value = e.target.value.trim();
      const tags = form.mealPlan?.[day]?.[mealType] || [];
      if (value && !tags.includes(value)) {
        setForm((prevForm: any) => ({
          ...prevForm,
          mealPlan: {
            ...prevForm.mealPlan,
            [day]: {
              ...prevForm.mealPlan?.[day],
              [mealType]: [...tags, value],
            },
          }, // Add the new tag to the tags array
        }));
      }

      // Clear the input field after adding a tag
      e.target.value = "";
    }
    return "";
  };

  const handleBlur = (e: any, day: any, mealType: any) => {
    const value = e.target.value.trim();
      const tags = form.mealPlan?.[day]?.[mealType] || [];
      if (value && !tags.includes(value)) {
        setForm((prevForm: any) => ({
          ...prevForm,
          mealPlan: {
            ...prevForm.mealPlan,
            [day]: {
              ...prevForm.mealPlan?.[day],
              [mealType]: [...tags, value],
            },
          }, // Add the new tag to the tags array
        }));
      }

      // Clear the input field after adding a tag
      e.target.value = "";
      return "";
  };

  // Handler for removing a tag
  const removeTag = (indexToRemove: any, day: any, mealType: any) => {
    setForm((prevForm: any) => ({
      ...prevForm,
      mealPlan: {
        ...prevForm.mealPlan,
        [day]: {
          ...prevForm.mealPlan?.[day],
          [mealType]: prevForm.mealPlan?.[day]?.[mealType].filter(
            (_: any, index: any) => index !== indexToRemove
          ),
        },
      },
    }));
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Meal Plan`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/meal-plans`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    {/* Title Field */}
                    <Col md={4}>
                      <Label for="title">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        {...register("title", {
                          required: "Please enter a valid title",
                        })}
                        placeholder="Enter title"
                        value={form.title}
                        onChange={(e) => saveDataForm("title", e.target.value)}
                      />
                      {errors.title && (
                        <FormFeedback>{errors.title.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Assign To */}
                    <Col md={3}>
                      <Label for="assignTo">
                        Assign To <span className="text-danger">*</span>
                      </Label>
                      <div>
                        <input
                          type="radio"
                          {...register("assignTo", {
                            required: "Please select an option",
                          })}
                          value="children"
                          className={`form-check-input ${
                            errors.assignTo ? "is-invalid" : ""
                          }`}
                          id="children"
                          checked={form.assignTo === "children"}
                          onChange={(e) =>
                            saveDataForm("assignTo", e.target.value)
                          }
                        />{" "}
                        <Label for="children">Children</Label>
                        <input
                          type="radio"
                          {...register("assignTo", {
                            required: "Please select an option",
                          })}
                          className={`form-check-input ${
                            errors.assignTo ? "is-invalid" : ""
                          }`}
                          value="rooms"
                          checked={form.assignTo === "rooms"}
                          onChange={(e) =>
                            saveDataForm("assignTo", e.target.value)
                          }
                          style={{ marginLeft: "15px" }}
                          id="rooms"
                        />{" "}
                        <Label for="rooms">Rooms</Label>
                      </div>

                      {errors.assignTo && (
                        <FormFeedback style={{ display: "block" }}>
                          {errors.assignTo.message}
                        </FormFeedback>
                      )}
                    </Col>

                    {/* Conditional Dropdown */}
                    {form.assignTo === "rooms" && (
                      <Col md={5}>
                        <Label>
                          Rooms List <span className="text-danger">*</span>
                        </Label>
                        <Controller
                          name="rooms"
                          control={control}
                          rules={{
                            required: "Please select a room",
                          }} // Validation rules
                          render={({ field }) => (
                            <Select<any, true>
                              {...field}
                              isMulti
                              id="rooms"
                              className={`${errors.rooms ? "is-invalid" : ""}`}
                              options={[
                                { value: "selectAll", label: "Select All" },
                                ...roomNames, // Room options
                              ]}
                              value={roomNames.filter((option: any) =>
                                form.rooms?.includes(option.value)
                              )}
                              onChange={(selectedOptions: any) => {
                                // Check if "Select All" is in the selected options
                                const isSelectAllSelected =
                                  selectedOptions.some(
                                    (option: any) =>
                                      option.value === "selectAll"
                                  );

                                if (isSelectAllSelected) {
                                  // If "Select All" is selected, select all rooms
                                  const allValues = roomNames.map(
                                    (option: any) => option.value
                                  );
                                  field.onChange(allValues); // Update the field value
                                  saveDataForm("rooms", allValues); // Save the data
                                } else {
                                  // Otherwise, just handle normal selection
                                  const selectedValues = selectedOptions
                                    ? selectedOptions.map(
                                        (option: any) => option.value
                                      )
                                    : [];
                                  field.onChange(selectedValues); // Update the field value
                                  saveDataForm("rooms", selectedValues); // Save the data
                                }
                              }} // Handle value changes
                            />
                          )}
                        />
                        {errors.rooms && (
                          <FormFeedback
                            style={{ display: "block", color: "orangeRed" }}
                          >
                            {`${errors?.rooms?.message}`}
                          </FormFeedback>
                        )}
                      </Col>
                    )}

                    {form.assignTo === "children" && (
                      <Col md={5}>
                        <Label>
                          Children List <span className="text-danger">*</span>
                        </Label>
                        <Controller
                          name="children"
                          control={control}
                          rules={{
                            required: "Please select a child",
                          }} // Validation rules
                          render={({ field }) => (
                            <Select
                              {...field}
                              id="children"
                              className={`${
                                errors.children ? "is-invalid" : ""
                              }`}
                              isMulti
                              options={[
                                { value: "selectAll", label: "Select All" },
                                ...childrenData, // Children options
                              ]}
                              value={childrenData.filter((option: any) =>
                                form.children?.includes(option.value)
                              )}
                              onChange={(selectedOptions: any) => {
                                // Check if "Select All" is in the selected options
                                const isSelectAllSelected =
                                  selectedOptions.some(
                                    (option: any) =>
                                      option.value === "selectAll"
                                  );

                                if (isSelectAllSelected) {
                                  // If "Select All" is selected, select all children
                                  const allValues = childrenData.map(
                                    (option: any) => option.value
                                  );
                                  field.onChange(allValues); // Update the field value
                                  saveDataForm("children", allValues); // Save the data
                                } else {
                                  // Otherwise, just handle normal selection
                                  const selectedValues = selectedOptions
                                    ? selectedOptions.map(
                                        (option: any) => option.value
                                      )
                                    : [];
                                  field.onChange(selectedValues); // Update the field value
                                  saveDataForm("children", selectedValues); // Save the data
                                }
                              }} // Handle value changes
                            />
                          )}
                        />
                        {errors.children && (
                          <FormFeedback
                            style={{ display: "block", color: "orangeRed" }}
                          >
                            {`${errors?.children?.message}`}
                          </FormFeedback>
                        )}
                      </Col>
                    )}

                    {/* Date Pickers */}
                    <Col md={6}>
                      <Label>
                        Start Date <span className="text-danger">*</span>
                      </Label>
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="startDate"
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input ${
                                  errors.startDate ? "is-invalid" : ""
                                }`}
                                selected={field.value}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("startDate", date);
                                }}
                                placeholderText="Select a date"
                                dateFormat="MMMM dd,yyyy"
                              />
                            );
                          }}
                        />
                        <InputGroupText className="list-light-primary custom-calender-icon">
                          <i className="fa-solid fa-calendar text-primary" />
                        </InputGroupText>
                      </InputGroup>
                      {errors.startDate && (
                        <FormFeedback
                          style={{ display: "block", color: "orangeRed" }}
                        >
                          {"Please select start date."}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col md={6}>
                      <Label>
                        End Date <span className="text-danger">*</span>
                      </Label>
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="endDate"
                          rules={{
                            required: "Please select end date.",
                            validate: (value) =>
                              !form.startDate ||
                              value >= form.startDate ||
                              "End date cannot be before start date.",
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input ${
                                  errors.endDate ? "is-invalid" : ""
                                }`}
                                selected={field.value}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("endDate", date);
                                }}
                                placeholderText="Select a date"
                                dateFormat="MMMM dd,yyyy"
                              />
                            );
                          }}
                        />
                        <InputGroupText className="list-light-primary custom-calender-icon">
                          <i className="fa-solid fa-calendar text-primary" />
                        </InputGroupText>
                      </InputGroup>
                      {errors.endDate && (
                        <FormFeedback
                          style={{ display: "block", color: "orangeRed" }}
                        >
                          {`${errors.endDate.message}`}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <Label>Meal Plan:</Label>
                      <Nav tabs className="mb-3 mt-1 simple-wrapper">
                        {daysOfWeek.map((day, index) => (
                          <NavItem key={index}>
                            <NavLink
                              href={Href}
                              className={`text-primary ${
                                basicTab === day ? "active bg-primary" : ""
                              }`}
                              onClick={() => setBasicTab(day)}
                            >
                              {day}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>

                      <TabContent activeTab={basicTab}>
                        {daysOfWeek.map((day, index) => (
                          <TabPane tabId={day} key={index}>
                            <Row>
                              {mealTypes.map((meal) => (
                                <Col md={6}>
                                  <FormGroup>
                                    <Label key={meal} for={meal}>
                                      {meal}:{" "}
                                    </Label>
                                    <div className="tags-input">
                                      {(
                                        form?.mealPlan?.[day]?.[meal] || []
                                      )?.map((tag: any, index: any) => (
                                        <span
                                          key={index}
                                          className="tag list-light-primary text-primary"
                                        >
                                          {tag}
                                          <span
                                            className="remove-tag"
                                            onClick={() =>
                                              removeTag(index, day, meal)
                                            }
                                          >
                                            &times;
                                          </span>
                                        </span>
                                      ))}
                                      <input
                                        type="text"
                                        id="tags"
                                        className="form-control"
                                        placeholder="Type and press Enter or Comma"
                                        onBlur={(e) => handleBlur(e, day, meal)}
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, day, meal)
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              ))}
                            </Row>
                          </TabPane>
                        ))}
                      </TabContent>
                    </Col>

                    {/* <Col md={12}>
                      <Label>Meal Plan:</Label>
                      <Accordion
                        open={open}
                        toggle={toggle}
                        className="dark-accordion"
                      >
                        {daysOfWeek.map((day) => (
                          <AccordionItem className="accordion-wrapper accordion-left-border">
                            <AccordionHeader
                              color="light-primary"
                              className="text-info"
                              targetId={day}
                            >
                              <span className="font-primary">{day}</span>
                              {open === day ? (
                                <i className="iconly-Arrow-Up-2 icli ms-auto icon font-primary" />
                              ) : (
                                <i className="iconly-Arrow-Down-2 icli ms-auto icon font-primary" />
                              )}
                            </AccordionHeader>
                            <AccordionBody accordionId={day}>
                              {mealTypes.map((meal) => (
                                <FormGroup>
                                  <Label key={meal} for={meal}>
                                    {meal}:{" "}
                                  </Label>
                                  <div className="tags-input">
                                    {(form?.mealPlan?.[day]?.[meal] || [])?.map(
                                      (tag: any, index: any) => (
                                        <span
                                          key={index}
                                          className="tag list-light-primary text-primary"
                                        >
                                          {tag}
                                          <span
                                            className="remove-tag"
                                            onClick={() =>
                                              removeTag(index, day, meal)
                                            }
                                          >
                                            &times;
                                          </span>
                                        </span>
                                      )
                                    )}
                                    <input
                                      type="text"
                                      id="tags"
                                      className="form-control"
                                      placeholder="Type and press Enter or Comma"
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, day, meal)
                                      }
                                      {...register(`mealPlan.${day}.${meal}`)}
                                    />
                                  </div>
                                </FormGroup>
                              ))}
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </Col> */}
                    {/* <Col md={12}>
                      <Label>Meal Plan:</Label>
                      <br />
                      <Table bordered>
                        <thead>
                          <tr className="text-nowrap">
                            <th></th>
                            {daysOfWeek.map((day) => (
                              <th key={day}>{day}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {mealTypes.map((meal) => (
                            <tr key={meal} className="text-nowrap">
                              <th>{meal}</th>
                              {daysOfWeek.map((day) => (
                                <td key={day}>
                                  <Controller
                                    name={`mealPlan.${day}.${meal}`}
                                    control={control}
                                    render={({ field }) => (
                                      <Input
                                        {...field}
                                        type="text"
                                        placeholder={`Enter ${meal}`}
                                        style={{ width: "100%" }}
                                        value={form.mealPlan?.[day]?.[meal]}
                                        onChange={(e) =>
                                          saveDataForm(
                                            "mealPlan",
                                            e.target.value,
                                            day,
                                            meal
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col> */}
                  </Row>

                  {/* Action Buttons */}
                  <Row className="mt-4 text-end">
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Save & List"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/meal-plans`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(AddMealPlan);
