import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Btn, LI, UL } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import ClosureDayApi from "../../../../services/ClosureDayApi";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

interface ClosureDay {
  id: string;
  startDate: any | string;
  endDate: any | string;
  reason: string;
  typeOfClosureDay: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ClosureDay = (props: any) => {
  const navigate = useNavigate();

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<ClosureDay>();

  // const toggleModal = () => {
  //   setIsModalOpen((prevState) => !prevState);
  //   if (!isModalOpen) {
  //     reset(selectedClosureDay); // Reset form with current closure day data when opening modal
  //   }
  // };

  // Toggle modal to open/close
  const [closureDays, setClosureDays] = useState<ClosureDay[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleEditClosureDay = (closureDay: ClosureDay) => {
    setValue("startDate", new Date(closureDay.startDate));
    setValue("endDate", new Date(closureDay.endDate));
    reset({
      ...closureDay,
      startDate: new Date(closureDay.startDate),
      endDate: new Date(closureDay.endDate),
    });
    setSelectedClosureDay(closureDay);
    toggleModal();
  };

  const [selectedClosureDay, setSelectedClosureDay] = useState<
    Partial<ClosureDay>
  >({}); // For editing closure day

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedClosureDay({}); // Clear selected room data
    } else {
      // If opening modal, reset with selected room data for edit, otherwise with empty data for add
      reset(selectedClosureDay);
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async (data: ClosureDay) => {
    setIsLoading(true);
    try {
      if (selectedClosureDay.id) {
        // Update closure day
        await ClosureDayApi.updateClosureDay(selectedClosureDay.id, {
          ...data,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Closure Day updated successfully",
          icon: "success",
        });
      } else {
        // Create new closure day
        await ClosureDayApi.createClosureDay({
          ...data,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Closure Day created successfully",
          icon: "success",
        });
      }
      // Reload the data after successful operation
      fetchClosureDays();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch closure days from the API
  const fetchClosureDays = async () => {
    try {
      const response = await ClosureDayApi.getClosureDay({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setClosureDays(response.data);
      } else {
        throw new Error("Failed to fetch closure days");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch closure days",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchClosureDays();
  }, [props.nurseryId]);

  const filteredItems = closureDays.filter((closureDay) =>
    closureDay.reason.toLowerCase().includes(filterText.toLowerCase())
  );

  const customStyles = {
    table: {
      style: {
        tableLayout: "fixed", // Fixes the table layout to enforce column widths
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        width: "150px", // Adjust width for all cells
        whiteSpace: "nowrap", // Prevent text wrapping
        overflow: "hidden",
        textOverflow: "ellipsis", // Show ellipsis for overflow
      },
    },
  };

  const columns: TableColumn<ClosureDay>[] = [
    {
      name: <CustomHeader title="Start Date" />,
      selector: (row) => {
        return moment(row.startDate).format("DD-MM-YYYY");
      },
      style: {
        minWidth: "150px", // Enforces a minimum width
      },
      sortable: true,
    },
    {
      name: <CustomHeader title="End Date" />,
      selector: (row) => {
        return moment(row.endDate).format("DD-MM-YYYY");
      },
      style: {
        minWidth: "150px",
      },
      sortable: true,
    },
    {
      name: <CustomHeader title="Type" />,
      selector: (row) => row.typeOfClosureDay,
      sortable: true,
      style: {
        minWidth: "200px",
      },
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap" }}>{row.typeOfClosureDay}</div>
      ),
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "150px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "150px", // Set consistent width
      maxWidth: "150px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="javascript:void(0);"
              onClick={() => handleEditClosureDay(row)}
              className="icon"
            >
              <Btn color="primary" size="sm">
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
                {"Edit"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <>
      <Card className="card-absolute">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ borderBottom: "5px solid #eee" }}
        >
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Closure Days"}
          />

          <Button
            color="primary"
            className="btn-md m-4 mt-3 mb-3"
            onClick={toggleModal}
          >
            <i className="fa fa-plus me-2" /> Add Closure Day
          </Button>
        </div>
        <CardBody>
          <Row className="justify-content-end mb-3">
            <Col md={6}>
              <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                filterText={filterText}
              />
            </Col>
          </Row>
          <div className="table-responsive">
            <DataTable
              data={filteredItems}
              columns={columns}
              pagination
              className="display"
              customStyles={customStyles}
              responsive
              highlightOnHover
              conditionalRowStyles={[
                {
                  when: (row) => true,
                  style: {
                    "&:hover": {
                      backgroundColor: "rgba(48, 142, 135, 0.2)",
                      cursor: "pointer",
                    },
                  },
                },
              ]}
            />
          </div>
        </CardBody>
      </Card>

      {/* Modal for Adding Closure Day */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          {selectedClosureDay.id ? "Edit Closure Day" : "Add Closure Day"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="startDate">
                {"Start Date"} <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="startDate"
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <DatePicker
                      className={`form-control flatpickr-input ${
                        errors.startDate ? "is-invalid" : ""
                      }`}
                      selected={field.value}
                      id={"startDate"}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholderText="Select a date"
                      dateFormat="MMMM dd,yyyy"
                    />
                  );
                }}
              />
              {errors.startDate && (
                <FormFeedback style={{ display: "block" }}>
                  {"Start date is required"}
                </FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="endDate">
                {"End Date"} <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="endDate"
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <DatePicker
                      className={`form-control flatpickr-input ${
                        errors.endDate ? "is-invalid" : ""
                      }`}
                      selected={field.value}
                      id={"endDate"}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholderText="Select a date"
                      dateFormat="MMMM dd,yyyy"
                    />
                  );
                }}
              />
              {errors.endDate && (
                <FormFeedback style={{ display: "block" }}>
                  {"End date is required"}
                </FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="reason">
                Reason <span className="text-danger">*</span>
              </Label>
              <input
                type="text"
                className={`form-control ${errors.reason ? "is-invalid" : ""}`}
                id="reason"
                {...register("reason", { required: "Reason is required" })}
                defaultValue={selectedClosureDay.reason}
                placeholder="Enter closure day reason"
              />
              {errors.reason && (
                <FormFeedback>{errors.reason.message}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="typeOfClosureDay">
                Type of Closure Day <span className="text-danger">*</span>
              </Label>
              <select
                className={`form-control ${
                  errors.typeOfClosureDay ? "is-invalid" : ""
                }`}
                id="typeOfClosureDay"
                {...register("typeOfClosureDay", {
                  required: "Please select a closure day type",
                })}
                defaultValue={selectedClosureDay.typeOfClosureDay || ""}
              >
                <option value="" disabled>
                  Select type
                </option>
                <option value="Bank Holiday">Bank Holiday</option>
                <option value="Training Day/Inset Day">
                  Training Day/Inset Day
                </option>
                <option value="Closure Day">Closure Day</option>
              </select>
              {errors.typeOfClosureDay && (
                <FormFeedback>{errors.typeOfClosureDay.message}</FormFeedback>
              )}
            </FormGroup>

            <ModalFooter>
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  <>
                    {selectedClosureDay.id
                      ? "Update Closure Day"
                      : "Add Closure Day"}
                  </>
                )}
              </Btn>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ClosureDay;
