import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Briefcase,
  CheckCircle,
  DollarSign,
  FilePlus,
  File,
  Calendar,
  Award,
  Clock,
} from "react-feather";
import { Btn, H4, H6 } from "src/AbstractElements";
import CommonCardHeader from "src/Utils/CommonComponents/CommonCardHeader";
import { useParams } from "react-router-dom";
import UserApi from "src/services/UserApi";
import {
  FaCalendar,
  FaCalendarAlt,
  FaCalendarCheck,
  FaCalendarDay,
  FaCalendarTimes,
  FaRegCalendar,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { AnyARecord } from "dns";
import moment from "moment";
import {
  convertToTitleCase,
  getLeaveFormattedDataInNumbersNew,
} from "src/Utils";

const LeaveReport = (props: any) => {
  const [activeTab, setActiveTab] = useState(0); // Initialize with first tab as active
  const { id } = useParams(); // id is the employeeId
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [newLoading, setNewLoading] = useState<boolean>(true);
  const [nurseryId, setNurseryId] = useState<string | null>(null);
  const [leaveData, setLeaveData] = useState<any>(null); // State to store leave data
  const [employeeLeaveData, setEmployeeLeaveData] = useState<any>(null); // State to store leave data
  const STATUS: any = {
    PENDING: { title: "Pending", styleClass: "bg-warning" },
    APPROVED: { title: "Approved", styleClass: "bg-success" },
    DECLINED: { title: "Declined", styleClass: "bg-danger" },
  };

  useEffect(() => {
    setNewLoading(true); // Start loading
    const timer = setTimeout(() => {
      setNewLoading(false); // Stop loading after 3 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timeout on unmount or activeTab change
  }, [activeTab]);

  // Fetch employee details to get nurseryId and leaveEmployees data
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      if (id) {
        try {
          const response = await UserApi.getEmployeeById(id);
          if (response.success && response.data) {
            setNurseryId(response.data.nurseryId); // Set nurseryId from employee data
            const leaveData =
              getLeaveFormattedDataInNumbersNew(
                response.data.leaveEmployees[0]
              ) || []; // Assuming leave data is under "leaveEmployees"
            console.log(55, leaveData);
            console.log(66, response.data.leaveEmployees[0].employeeLeave);
            setEmployeeLeaveData(
              response.data.leaveEmployees[0].employeeLeave || []
            );
            setLeaveData(leaveData); // Store the leave data in state
          } else {
            throw new Error("Failed to fetch employee details");
          }
        } catch (error: any) {
          setError(error.message || "Error fetching employee details");
        } finally {
          setLoading(false); // Set loading to false once the data is fetched
        }
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  // // Fetch leave data using UserApi.getLeaves
  // useEffect(() => {
  //   const fetchLeaves = async () => {
  //     if (nurseryId) {
  //       try {
  //         const leavesData = await UserApi.getLeaves({ nurseryId });
  //         setLeaveData(leavesData); // Set fetched leaves data to state
  //       } catch (error: any) {
  //         setError(error.message || "Error fetching leaves data");
  //       } finally {
  //         setLoading(false); // Set loading to false when the request completes
  //       }
  //     }
  //   };

  //   fetchLeaves();
  // }, [nurseryId]); // Only fetch leaves when nurseryId is set

  // Loading state
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" /> {/* Show loading spinner */}
      </div>
    );
  }
  // Handle tab click
  const toggleTab = (tabId: any) => {
    if (activeTab !== tabId) setActiveTab(tabId);
  };

  // Error handling
  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }
  // If no leave data is available
  if (!leaveData) {
    return <div>No leave data available</div>;
  }

  // // Extract leave data and balance fields dynamically
  // const leaveFields = Object.keys(leaveData).filter(
  //   (key) => key.includes("Leave") && key !== "leave"
  // );

  const sections = [
    {
      id: 1,
      title: "General",
      icon: <FilePlus />,
    },
  ];
  return (
    <>
      {/* <Card >
        <CardBody> */}
      <Row>
        <Col xl={4} className="box-col-4e">
          <Card style={{ height: "500px" }}>
            <CommonCardHeader titleClass="card-title" title={"Leaves"} />
            <CardBody className="pt-0">
              <Row
                className="mb-2"
                style={{ height: "414px", overflow: "scroll" }}
              >
                <div
                  className="md-sidebar"
                  style={{
                    borderBottom: "1px solid #eee",
                    paddingBottom: "1rem",
                    paddingLeft: "0px",
                  }}
                >
                  <Btn
                    color="primary"
                    className="md-sidebar-toggle"
                    onClick={() => {}}
                  >
                    {""}
                  </Btn>
                  <div
                    className={`md-sidebar-aside job-left-aside custom-scrollbar open`}
                  >
                    <div className="email-left-aside">
                      <div
                        className="email-app-sidebar left-bookmark task-sidebar"
                        style={{
                          height: "100%",
                        }}
                      >
                        <Nav className="main-menu">
                          {Object.keys(leaveData)
                            .filter((row) => row != "id")
                            .map((nav, index) => {
                              return (
                                <NavItem key={index}>
                                  <a
                                    style={{ padding: "10px" }}
                                    href="javascript:void(0);"
                                    className={
                                      activeTab === index
                                        ? "active badge-light-primary"
                                        : ""
                                    }
                                    onClick={() => toggleTab(index)}
                                  >
                                    {/* Render the dynamic Font Awesome icon */}

                                    <span
                                      className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                      style={{
                                        borderRadius: "100px",
                                        lineHeight: "0px",
                                      }}
                                    >
                                      <FaCalendarAlt />
                                    </span>

                                    <span className="title ms-2">
                                      {convertToTitleCase(nav)}
                                    </span>
                                  </a>
                                </NavItem>
                              );
                            })}
                        </Nav>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xxl={8} xl={8} md={12} className="box-col-12">
          <Card style={{ height: "500px" }}>
            <div className="d-flex align-item-center">
              <CommonCardHeader
                titleClass="card-title"
                title={"Leave Overview"}
              />
              <Btn
                color="dark"
                type="button"
                style={{ marginRight: "20px" }}
                onClick={() => props.setAction({ id: "", action: "List" })}
              >
                {"Back"}
              </Btn>
            </div>
            <CardBody className="pt-0">
              <div className="general-widget">
                <div className="email-right-aside bookmark-tabcontent">
                  <div className="ps-0">
                    <TabContent activeTab={activeTab}>
                      {Object.keys(leaveData)
                        .filter((row) => row != "id")
                        .map((nav, index) => {
                          return (
                            <TabPane key={index} tabId={index}>
                              {newLoading ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "relative",
                                    height: "200px",
                                  }}
                                >
                                  <Spinner color="primary" size="lg" />
                                </div>
                              ) : (
                                <>
                                  {/* Top Row with Leave Cards */}
                                  <Row className="mb-0">
                                    <Col
                                      md={12}
                                      className="d-flex align-item-center"
                                    >
                                      {/* Total Leave Card */}
                                      <Col md={4}>
                                        <Card className="text-center">
                                          <CardBody className="p-3">
                                            <div className="d-flex justify-content-center align-items-center mb-2">
                                              {/* Icon for Total Leave */}
                                              <div
                                                className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              >
                                                <i className="fa fa-calendar-check fa-lg"></i>
                                              </div>
                                            </div>
                                            <H6 className="mb-1 fw-bold">
                                              Total Leave
                                            </H6>
                                            <H4 className="text-primary">
                                              {leaveData[nav].total}
                                            </H4>
                                          </CardBody>
                                        </Card>
                                      </Col>

                                      {/* Received Leave Card */}
                                      <Col md={4}>
                                        <Card className="text-center">
                                          <CardBody className="p-3">
                                            <div className="d-flex justify-content-center align-items-center mb-2">
                                              {/* Icon for Received Leave */}
                                              <div
                                                className="bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              >
                                                <i className="fa fa-calendar-alt fa-lg"></i>
                                              </div>
                                            </div>
                                            <H6 className="mb-1 fw-bold">
                                              Received Leave
                                            </H6>
                                            <H4 className="text-success">
                                              {leaveData[nav].used}
                                            </H4>
                                          </CardBody>
                                        </Card>
                                      </Col>

                                      {/* Balance Leave Card */}
                                      <Col md={4}>
                                        <Card className="text-center">
                                          <CardBody className="p-3">
                                            <div className="d-flex justify-content-center align-items-center mb-2">
                                              {/* Icon for Balance Leave */}
                                              <div
                                                className="bg-danger text-white rounded-circle d-flex align-items-center justify-content-center"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              >
                                                <i className="fa fa-calendar-minus fa-lg"></i>
                                              </div>
                                            </div>
                                            <H6 className="mb-1 fw-bold">
                                              Balance
                                            </H6>
                                            <H4 className="text-danger">
                                              {leaveData[nav].pending}
                                            </H4>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Col>
                                  </Row>
                                  {/* Leave History Table */}
                                  <h5 className="mb-3">Leave History</h5>
                                  <div
                                    className="table-responsive"
                                    style={{
                                      maxHeight: "222px",
                                      overflowY: "auto",
                                      overflowX: "auto",
                                    }}
                                  >
                                    <table className="table table-bordered table-striped">
                                      <thead
                                        className="table-light"
                                        style={{
                                          position: "sticky",
                                          top: 0,
                                          zIndex: 1,
                                        }}
                                      >
                                        <tr>
                                          <th>Date</th>
                                          <th style={{ whiteSpace: "nowrap" }}>
                                            Created/Approved/Rejected By
                                          </th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {employeeLeaveData
                                          .filter(
                                            (row: any) => row.leaveType == nav
                                          )
                                          .map((data: any, id: any) => {
                                            return (
                                              <tr>
                                                <td>
                                                  {moment(
                                                    data.leaveFrom
                                                  ).format("YYYY-MM-DD")}{" "}
                                                  {"-"}
                                                  <br />
                                                  {moment(
                                                    data.leaveTill
                                                  ).format("YYYY-MM-DD")}
                                                </td>

                                                <td>
                                                  {data.status === "PENDING"
                                                    ? `${data.createdByData.firstName} ${data.createdByData.lastName}`
                                                    : `${data.updatedByData.firstName} ${data.updatedByData.lastName}`}
                                                </td>

                                                <td>
                                                  <span
                                                    className={`badge ${
                                                      STATUS[data.status]
                                                        .styleClass
                                                    }`}
                                                  >
                                                    {STATUS[data.status].title}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                            </TabPane>
                          );
                        })}
                    </TabContent>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* </CardBody>
      </Card> */}
    </>
  );
};

export default LeaveReport;
