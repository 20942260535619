import api from "./api"; // Use the Axios instance with interceptor

export default class RoomCustomStatusApi {
  static async getRoomCustomStatuses(params: any) {
    try {
      const response = await api.get("/roomCustomStatus/all", {
        params,
      });
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createRoomCustomStatus(data: {
    title: string; // Changed from 'name' to 'title'
    color: string;
    icon: File | null;
    nurseryId?: string;
    // createdBy: string;
  }) {
    try {
      const response = await api.post("/roomCustomStatus/", data);
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateRoomCustomStatus(
    id: string,
    data: {
      title?: string; // Changed from 'name' to 'title'
      color?: string;
      icon?: File | null;
      nurseryId?: string;
      // updatedBy: string;
    }
  ) {
    try {
      const response = await api.patch(`/roomCustomStatus/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Soft deletes a specific room custom status
  static async deleteRoomCustomStatus(id: string, deletedBy: string) {
    try {
      const response = await api.patch(`/roomCustomStatus/${id}`, {
        isDeleted: 1,
        deletedBy: deletedBy,
        deletedAt: new Date().toISOString(),
      });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
