import React, { useEffect, useState } from "react";
import { LI, UL } from "../../../../AbstractElements";
import LanguageSelect from "./LanguageSelect";
import MoonLight from "./MoonLight";
import CartHeader from "./CartHeader";
import Notification from "./Notification";
import FullScreen from "./FullScreen";
import CloudDesign from "./CloudDesign";
import { Href } from "../../../../Utils/Constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../ReduxToolkit/Store";
import { setIsSearchBarOpen } from "../../../../ReduxToolkit/Reducers/Layout/LayoutReducer";
import UserWrap from "./UserWrap";
import SvgIcon from "../../../../Utils/CommonComponents/CommonSvgIcons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  convertTimeToDateTime,
  formatDateToYYYYMMDD,
  getUserActivityFormattedData,
} from "../../../../Utils";
import { toast } from "react-toastify";
import AttendanceAPi from "../../../../services/AttendanceApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import DashboardSvgIcon from "src/Utils/CommonComponents/DashboardSvgIcon";
import { widgetContentData } from "src/Data/Widgets/General";
import moment from "moment-timezone";

export default function HeaderRight(props: any) {
  const dispatch = useDispatch();
  const { isSearchBarOpen } = useSelector((state: RootState) => state.layout);
  const handleSearch = () => {
    dispatch(setIsSearchBarOpen(!isSearchBarOpen));
  };
  const [modal, setModal] = useState(false);
  const [showClockOut, setShowClockOut] = useState(props.userClockOut);
  const [ukTime, setUKTime] = useState(
    moment.tz("Europe/London").format("HH:mm")
  );
  const [ukDateTime, setUKDateTime] = useState(
    moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00")
  );

  const toggle = () => setModal(!modal);

  const handleEmployeeAttendanceClockOut = async () => {
    try {
      const response: any = await AttendanceAPi.addClockOut({
        nurseryId: props.selectedNursery,
        employeesId: [
          { empId: props?.user?.id, attendanceId: props.attendanceId },
        ],
        clockOutTime: ukDateTime,
        attendanceDate: formatDateToYYYYMMDD(new Date()),
      });

      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );
          toggle();
          props.setEmpClockOut({ empClockOut: ukDateTime, userClockOut: true });
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUKTime(moment.tz("Europe/London").format("HH:mm"));
      setUKDateTime(moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00"));
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, []);

  useEffect(() => {
    setShowClockOut(props.userClockOut);
  }, [props.userClockOut]);

  return (
    <div className="nav-right">
      <UL className="header-right simple-list flex-row">
        {/* <LanguageSelect /> */}
        {/* <LI className="search d-lg-none d-flex">
          <a href={Href} onClick={handleSearch}>
            <SvgIcon iconId="Search" />
          </a>
        </LI> */}
        {/* <MoonLight /> */}
        {/* <CartHeader /> */}
        <div className="d-flex align-item-center">
          <div className="general-widget">
            <Row>
              <Card className="widget-1 mb-0">
                <CardBody className="p-0">
                  <div className="widget-content">
                    <div className={`widget-round primary`} onClick={toggle}>
                      <div className="bg-round">
                        <DashboardSvgIcon
                          className="svg-fill danger"
                          iconId="clock"
                        />
                        <DashboardSvgIcon
                          className="half-circle svg-fill"
                          iconId="halfcircle"
                        />
                      </div>
                    </div>
                    <div>
                      {!showClockOut && (
                        <div>
                          <span
                            style={{ fontSize: "12px", fontWeight: "bold" }}
                            className="text-primary"
                          >
                            Clock In{" "}
                            {moment
                              .tz(`${props.empClockIn}`, "Europe/London")
                              .format("DD-MM-YYYY HH:mm:00")}
                          </span>
                          {/* <Button color="primary btn-sm" onClick={toggle}>
                            Clock Out
                          </Button> */}
                          <Modal
                            isOpen={modal}
                            toggle={toggle}
                            backdrop="static"
                            centered
                          >
                            <ModalBody>
                              <div className="d-flex flex-column gap-1">
                                <h2 className="h2">
                                  Are you sure you want to clock out ?{" "}
                                </h2>
                                <h6 className="h6">
                                  Date & Time:{" "}
                                  {moment
                                    .tz("Europe/London")
                                    .format("DD-MM-YYYY")}
                                  {", "}
                                  {ukTime}
                                </h6>
                              </div>
                              <div className="mt-3 d-flex justify-content-center gap-2">
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={handleEmployeeAttendanceClockOut}
                                >
                                  Yes, I want
                                </Button>
                                <Button color="secondary" onClick={toggle}>
                                  No
                                </Button>
                              </div>
                            </ModalBody>
                          </Modal>
                        </div>
                      )}

                      {showClockOut && (
                        <div
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                          className="text-primary"
                        >
                          Clock Out{" "}
                          {moment
                            .tz(`${props.empClockOut}`, "Europe/London")
                            .format("DD-MM-YYYY HH:mm:00")}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </div>
        </div>

        <Notification />
        {/* <FullScreen /> */}
        {/* <CloudDesign /> */}
        <UserWrap
          userName={`${props.user.firstName} ${props.user.lastName}`}
          logout={props.logout}
          user={props.user}
          setNursery={props.setNursery}
          selectedNursery={props.selectedNursery}
        />
      </UL>
    </div>
  );
}
