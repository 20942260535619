import api from "./api"; // Use the Axios instance with interceptor

export default class JobTitleApi {
  static async getJobTitle() {
    try {
      const response = await api.get("/jobTitle");
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createJobTitle(data: { jobTitle: string }) {
    try {
      const response = await api.post("/jobTitle/add", data);
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateJobTitle(
    id: string,
    data: {
      jobTitle?: string; // Changed from 'name' to 'title'
    }
  ) {
    try {
      // Make the API call to the '/update' endpoint
      const response = await api.patch(`/jobTitle/update`, {
        id, // Pass the id to specify the job title to update
        jobTitle: data.jobTitle, // Pass the updated job title
      });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Delete a specific job title
  static async deleteJobTitle(jobTitleId: string) {
    try {
      const response = await api.delete(`/jobTitle/${jobTitleId}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
