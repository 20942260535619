import { TableColumn } from "react-data-table-component";
import { Badges, LI, UL } from "../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import moment from "moment";
import Status from "src/Components/Common/Status";
import { announcementStatus, statusColors } from "src/Utils/Constants";
import { camelCase, lowerCase } from "lodash";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleStatusChange,
  toggleDropdownOne,
  dropdownOpenOne
}: {
  handleStatusChange: (row: any, status: string) => void;
  toggleDropdownOne: (row: any) => void;
  dropdownOpenOne: any;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Date" />,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.status,
      cell: (row: any) => {
        return (
          <Status
            position={
              Object.keys(announcementStatus).includes(lowerCase(row?.status))
                ? announcementStatus[lowerCase(row?.status)]
                : ""
            }
            color={statusColors[lowerCase(row?.status)]}
            row={row}
            id={row.id}
            newUI={true}
            statusList={["DRAFT", "PUBLISHED"]}
            handleStatusChange={handleStatusChange}
            toggleDropdownOne={toggleDropdownOne}
            dropdownOpenOne={dropdownOpenOne}
          />
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Share With" />,
      selector: (row) => row.visibility,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },

    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={`/dashboard/announcements/edit/${row.id}`}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
          {/* <LI className="delete">
          <a
            href={""}
            className="btn btn-danger d-flex justify-content-center align-items-center"
            style={{ borderRadius: "50%", width: "40px", height: "40px" }}
          >
            <i className="icon-trash text-white" />
          </a>
        </LI> */}
        </UL>
      ),
    },
  ];

  return columns;
};
