import api from "./api"; // Use the Axios instance with interceptor

export default class SessionAPi {
  static async createSession(data: any) {
    try {
      const response = await api.post("/session", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async updateSession(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/session/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getAllSessions(params: any) {
    try {
      const response = await api.get("/session", { params });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getSessionById(id: any) {
    try {
      const response = await api.get(`/session/${id}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createChildrenSession(data: any) {
    try {
      const response = await api.post("/children-session", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async updateChildrenSession(data: any) {
    try {
      const response = await api.put(`/children-session/${data.id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async getChildrenSessions(params: any) {
    try {
      const response = await api.get(`/children-session`, { params });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }
}