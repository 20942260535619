import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { LI, UL } from "../../../AbstractElements";
import DatePicker from "react-datepicker";
import AttendanceAPi from "../../../services/AttendanceApi";
import { formatDateToYYYYMMDD } from "../../../Utils";

const CustomHeader = ({ title, style }: { title: string; style: any }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px", ...style }}>
    {title}
  </span>
);

function EmployeeAttendance(props: any) {
  const [filterText, setFilterText] = useState(""); // Filter text for search

  const [selectedDate, setSelectedDate] = useState<any>({
    start: null,
    end: null,
  }); // Date picker state

  const [saveData, setSaveData] = useState([]);

  const handleChange = (dates: any) => {
    const [start, end] = dates;
    setSelectedDate({ start, end });
  };

  // Filter data based on search text
  const filteredItems: any = saveData.filter((item: any) => {
    const filterObj = { attendanceDate: item.attendanceDate };
    return Object.values(filterObj).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Attendance Date" style={{}} />,
      selector: (row) => moment(row.attendanceDate).format("DD MMM YYYY"),
      sortable: true,
      style: {
        minWidth: "30%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "30%", // Set consistent width
      maxWidth: "30%",
    },
    {
      name: <CustomHeader title="Clock In Time" style={{ justifyContent: "flex-end", width: "100%", display: "flex" }} />, style: { textAlign: "left", justifyContent: " end", paddingRight: "30px" },
      selector: (row) =>
        row.clockInTime
          ? moment(row.clockInTime).format("HH:mm")
          : "Haven't Clock In Yet",
      sortable: true, 
      minWidth: "30%", // Set consistent width
      maxWidth: "30%",
    },
    {
      name: <CustomHeader title="End Date" style={{ justifyContent: "flex-end", width: "100%", display: "flex" }} />, style: { textAlign: "left", justifyContent: " end", paddingRight: "30px" },
      selector: (row) =>
        row.clockOutTime
          ? moment(row.clockOutTime).format("HH:mm")
          : "Haven't Clock Out Yet",
      sortable: true,
      minWidth: "40%", // Set consistent width
      maxWidth: "40%",
    },
  ];

  const fetchEmployeeAttendance = async () => {
    try {
      let response: any;
      if (selectedDate.start && selectedDate.end) {
        response = await AttendanceAPi.getEmpMonthlyAttendance({
          employeeId: props.auth?.user?.id,
          from: formatDateToYYYYMMDD(selectedDate.start),
          to: formatDateToYYYYMMDD(selectedDate.end),
        });
      } else {
        response = await AttendanceAPi.getEmpMonthlyAttendance({
          employeeId: props.auth?.user?.id,
          from: "",
          to: "",
        });
      }

      if (response.success && Array.isArray(response.data)) {
        setSaveData(response.data);
      } else {
        throw new Error("Expected an array of Employees Attendance");
      }
    } catch (error: any) { }
  };

  useEffect(() => {
    fetchEmployeeAttendance();
  }, [props.auth?.user?.id, selectedDate]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View Attendance Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <Row className="mb-4 d-flex align-items-center">                
                  <Col md={4}>
                    <div className="d-flex gap-3">
                      <Label>Date: </Label>
                      <DatePicker
                        className={`form-control flatpickr-input`}
                        selected={selectedDate.start}
                        onChange={handleChange}
                        startDate={selectedDate.start}
                        endDate={selectedDate.end}
                        selectsRange
                        dateFormat="MMMM dd, yyyy"
                        placeholderText="Select Date"
                      />
                    </div>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display text-nowrap"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EmployeeAttendance;
