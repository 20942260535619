import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H4 } from "../../../AbstractElements";
import UserActivityAPi from "../../../services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "../../../Utils";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { connectComponent } from "./connector";
import EnquiryApi from "src/services/EnquiryApi";

// Interface for form data
interface IForm {
  id: string;
  childName: string;
  childGender: string;
  childExpectedStartDate: any;
  childDateOfBirth: any;
  isChildLogin: boolean;
  parentName: string;
  parentPhoneNo: string;
  parentEmail: string;
  isParentLogin: boolean;
  source: string;
  bookingMethod: string;
  enquiryReason: string;
  nurseryId: string;
  showaround: boolean;
}

const EditRegistration = (props: any) => {
  const { id } = useParams();

  const initialState = {
    id: "",
    childName: "",
    childGender: "",
    childExpectedStartDate: "",
    childDateOfBirth: "",
    isChildLogin: false,
    parentName: "",
    parentPhoneNo: "",
    parentEmail: "",
    isParentLogin: false,
    source: "",
    bookingMethod: "",
    enquiryReason: "",
    nurseryId: props.nurseryId || "",
    showaround: false
  };

  const navigate = useNavigate();
  const [form, setForm] = useState<IForm>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: initialState,
  });
  console.log('errors: ', errors);

  const saveDataForm = (name: keyof IForm, value: any) => {
    setForm({ ...form, [name]: value });
    setValue(name, value);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setTimeout(async () => {
        await EnquiryApi.updateEnquiry(id ?? "", {
          ...form,
          id: undefined
        });
        Swal.fire({
          title: "Success!",
          text: "Enquiry updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Registration & Enquiry",
              `Enquiry updated successfully`
            )
          );

          navigate(`${process.env.PUBLIC_URL}/dashboard/registration`);
        });
      }, 2000);
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  const sourceOptions = [
    { value: "Website", label: "Website" },
    { value: "Social Media", label: "Social Media" },
    { value: "Word of Mouth", label: "Word of Mouth" },
    { value: "Google", label: "Google" },
    { value: "Recommendation", label: "Recommendation" },
    { value: "Current Child Attending", label: "Current Child Attending" },
    { value: "Day Nurseries", label: "Day Nurseries" },
    { value: "nurseriesandschools.org", label: "Nurseriesandschools.org" },
    { value: "childcare.co.uk", label: "Childcare.co.uk" },
  ];

  const bookingMethodOptions = [
    { value: "Phone", label: "Phone" },
    { value: "Email", label: "Email" },
    { value: "Nursery Direct (In Person)", label: "Nursery Direct (In Person)" },
    { value: "Facebook", label: "Facebook" },
    { value: "daynurseries.co.uk", label: "Daynurseries.co.uk" },
    { value: "childcare.co.uk", label: "Childcare.co.uk" },
  ];

  const enquiryReasonOptions = [
    { value: "Moved to the area", label: "Moved to the area" },
    { value: "Going back to work", label: "Going back to work" },
    { value: "New employment", label: "New employment" },
    { value: "Moved from another childcare setting", label: "Moved from another childcare setting" },
    { value: "Siblings at the setting", label: "Siblings at the setting" },
    { value: "Moved from another internal setting", label: "Moved from another internal setting" },
    { value: "Other", label: "Other" },
    { value: "Eligible for free entitlement", label: "Eligible for free entitlement" },
    { value: "Looked after child", label: "Looked after child" },
    { value: "Parent accessing Learning", label: "Parent accessing Learning" },
    { value: "Social interaction", label: "Social interaction" },
  ];

  useEffect(() => {
    const fetchEnquiry = async () => {
      try {
        const response = await EnquiryApi.fetchEnquiry({ id: id });
        if (response.success && response.data.length > 0) {
          const modifiedObj = commonKeysObject(response.data[0], form);

          setForm({
            ...modifiedObj,
            childExpectedStartDate: new Date(modifiedObj.childExpectedStartDate),
            childDateOfBirth: modifiedObj.childDateOfBirth ? new Date(modifiedObj.childDateOfBirth) : null,
          } as IForm);

          reset({
            ...modifiedObj,
            childExpectedStartDate: new Date(modifiedObj.childExpectedStartDate),
            childDateOfBirth: modifiedObj.childDateOfBirth ? new Date(modifiedObj.childDateOfBirth) : null,
          });
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
        setForm({ ...initialState });
      }
    };
    fetchEnquiry();
  }, [id]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Enquiry Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/registration`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="g-3">
                <Col md={12}>
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Child Detail</H4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <Label>
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className={errors.childName ? "is-invalid" : ""}
                            placeholder={`Enter child name`}
                            {...register("childName", {
                              required: "Child name is required",
                            })}
                            value={form.childName}
                            onChange={(e) =>
                              saveDataForm("childName", e.target.value)
                            }
                          />
                          {errors.childName && (
                            <FormFeedback>
                              {errors.childName.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            Gender
                          </Label>
                          <Input
                            type="select"
                            className={'form-control'}
                            {...register("childGender")}
                            value={form.childGender}
                            onChange={(e) =>
                              saveDataForm("childGender", e.target.value)
                            }
                          >
                            <option value="" disabled>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </Input>

                        </Col>
                        <Col md={4}>
                          <Label>
                            Expected Start Date <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="childExpectedStartDate"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control ${errors.childExpectedStartDate ? "is-invalid invalid-border-color" : ""
                                      }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("childExpectedStartDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />
                          </InputGroup>
                          {errors.childExpectedStartDate && (
                            <FormFeedback
                              style={{ display: "block", color: "#dc3545" }}
                            >
                              {"Please select expected start date."}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative mt-2">
                          <Label>
                            Date of Birth
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="childDateOfBirth"
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("childDateOfBirth", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={12}>
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Parent & Contact Details</H4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <Label>
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className={errors.parentName ? "is-invalid" : ""}
                            placeholder={`Enter parent name`}
                            {...register("parentName", {
                              required: "Parent name is required",
                            })}
                            value={form.parentName}
                            onChange={(e) =>
                              saveDataForm("parentName", e.target.value)
                            }
                          />
                          {errors.parentName && (
                            <FormFeedback>
                              {errors.parentName.message}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4}>
                          <Label>
                            Phone number
                          </Label>
                          <InputGroup>
                            <Input
                              type="text"
                              placeholder={`Enter parent phone number`}
                              {...register("parentPhoneNo", {
                                pattern: {
                                  value: /^[0-9]{11}$/,
                                  message: "Please enter a valid 11-digit phone number"
                                },
                              })}
                              className={errors.parentPhoneNo ? "is-invalid" : ""}
                              value={form.parentPhoneNo}
                              onChange={(e) =>
                                saveDataForm("parentPhoneNo", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.parentPhoneNo && (
                            <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                              {errors.parentPhoneNo.message}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4}>
                          <Label>
                            Email
                          </Label>
                          <InputGroup>
                            <Input
                              type="text"
                              className={errors.parentEmail ? "is-invalid" : ""}
                              placeholder={`Enter parent email`}
                              {...register("parentEmail", {
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Please enter a valid email address"
                                }
                              })}
                              value={form.parentEmail}
                              onChange={(e) =>
                                saveDataForm("parentEmail", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                          </InputGroup>
                          {errors.parentEmail && (
                            <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                              {errors.parentEmail.message}
                            </FormFeedback>
                          )}
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={12}>
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Enquiry Details</H4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <Label>
                            Source
                          </Label>
                          <Controller
                            name="source"
                            control={control}
                            rules={{
                              required: false,
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="source"
                                options={sourceOptions}
                                value={sourceOptions.find(
                                  (option: any) =>
                                    option.value === form.source
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "source",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                        </Col>

                        <Col md={4}>
                          <Label>
                            Booking Method
                          </Label>
                          <Controller
                            name="bookingMethod"
                            control={control}
                            rules={{
                              required: false,
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="bookingMethod"
                                options={bookingMethodOptions}
                                value={bookingMethodOptions.find(
                                  (option: any) =>
                                    option.value === form.bookingMethod
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "bookingMethod",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                        </Col>

                        <Col md={4}>
                          <Label>
                            Enquiry Reason
                          </Label>
                          <Controller
                            name="enquiryReason"
                            control={control}
                            rules={{
                              required: false,
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="enquiryReason"
                                options={enquiryReasonOptions}
                                value={enquiryReasonOptions.find(
                                  (option: any) =>
                                    option.value === form.enquiryReason
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "enquiryReason",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                        </Col>

                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            Showaround
                          </Label>
                          <div className="form-check d-flex align-items-center gap-2 ms-2">
                            <input
                              type="checkbox"
                              id="showaround"
                              className={`form-check-input`}
                              style={{ fontSize: "1.2rem" }}
                              {...register("showaround")}
                              checked={form.showaround}
                              onChange={(e) =>
                                saveDataForm(
                                  "showaround",
                                  e.target.checked
                                )
                              }
                            />
                            <Label
                              className="form-check-label mb-0"
                              htmlFor="showaround"
                            >
                              Book showaround
                            </Label>
                          </div>
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col
                  xs={12}
                  style={{ borderTop: "5px solid #eee", padding: 0 }}
                >
                  <Card
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  >
                    <CardBody>
                      <Btn
                        color="dark"
                        className="btn-md"
                        type="submit"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Update & Save"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/registration`
                          );
                        }}
                        className="btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(EditRegistration);
