import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  Row,
  CardHeader,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import DropItem from "../../../../Components/Forms/Common/DropItem";
import { Btn, H4, H5, H6 } from "../../../../AbstractElements";
import { LooksGood } from "../../../../Utils/Constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormDataTypes } from "../../../../Types/Forms.type";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import PricingBrandsAPi from "src/services/PricingBrandsApi";
import { connectComponent } from "../PricingBrands/connector";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { getUserActivityFormattedData } from "src/Utils";
import SessionAPi from "src/services/SessionApi";

type activePlans = {
  sessionTitle: string;
  pricingType: string;
  extraSession: boolean;
  additionalCharges: boolean;
};

function AddActivePlans(props: any) {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<activePlans>();

  console.log("errors: ", errors);
  const initialState = {
    sessionTitle: "",
    pricingType: "",
    extraSession: false,
    additionalCharges: false,
  };

  const [form, setForm] = useState<activePlans>(initialState);

  const [isLoading, setIsLoading] = useState(false);

  const [pricingBands, setPricingBands] = useState([]);

  useEffect(() => {
    const fetchPricingBrands = async () => {
      try {
        const response = await PricingBrandsAPi.getPriceBand({
          nurseryId: props.nurseryId,
        });
        if (response.success && Array.isArray(response.data)) {
          const formattedData = response.data.map((row: any) => {
            return {
              id: row.id,
              name: row.title,
              hourlyPrice: "",
              adhocPrice: "",
              enabled: false,
            };
          });
          setPricingBands(formattedData);
        } else setPricingBands([]);
      } catch (error: any) {
        setPricingBands([]);
      }
    };
    fetchPricingBrands();
  }, [props.nurseryId]);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
    setValue(name, value);
  };

  const handleBandToggle = (id: any) => {
    setPricingBands((prev: any) =>
      prev.map((band: any) =>
        band.id === id ? { ...band, enabled: !band.enabled } : band
      )
    );
  };

  const initialStateAvailability = [
    {
      day: "Monday",
      startTime: null,
      endTime: null,
      enabled: true,
    },
    { day: "Tuesday", startTime: null, endTime: null, enabled: false },
    { day: "Wednesday", startTime: null, endTime: null, enabled: false },
    { day: "Thursday", startTime: null, endTime: null, enabled: false },
    { day: "Friday", startTime: null, endTime: null, enabled: false },
    { day: "Saturday", startTime: null, endTime: null, enabled: false },
    { day: "Sunday", startTime: null, endTime: null, enabled: false },
  ];

  const [availability, setAvailability] = useState(initialStateAvailability);

  const handleAvailabilityToggle = (day: any) => {
    setAvailability((prev: any) =>
      prev.map((slot: any) =>
        slot.day === day
          ? {
            ...slot,
            enabled: !slot.enabled,
            // Reset times when enabling
            ...(slot.enabled
              ? { startTime: null, endTime: null }
              : {
                startTime: moment.tz("Europe/London").toDate(),
                endTime: moment.tz("Europe/London").add(1, "hour").toDate(),
              }),
          }
          : slot
      )
    );
  };

  const handleTimeChange = (day: any, field: any, value: any) => {
    setAvailability((prev) =>
      prev.map((slot) =>
        slot.day === day ? { ...slot, [field]: value } : slot
      )
    );
  };

  const onSubmit = (data: any) => {
    // Check if any enabled pricing band does not have a value
    const invalidPricingBands = pricingBands.some(
      (band: any) =>
        band.enabled &&
        (!band.hourlyPrice || band.hourlyPrice.trim() === "") &&
        (!band.adhocPrice || band.adhocPrice.trim() === "")
    );

    // Check if no availability is enabled
    const invalidAvailability =
      !availability.some((day) => day.enabled) ||
      availability.some(
        (day) =>
          day.enabled &&
          (!day.startTime || !day.endTime || day.startTime >= day.endTime)
      );

    if (invalidPricingBands) {
      toast.error("Please provide a value for all enabled pricing bands.");
      return;
    }

    if (invalidAvailability) {
      toast.error(
        "Please enable Availability for at least one day and provide valid start and end times. End time must be after start time."
      );
      return;
    }

    console.log("Form Submitted:", data);

    const priceBandAmountSessionArr = pricingBands
      .filter((d: any) => d.enabled)
      .map((row: any) => {
        return {
          priceBandId: row.id,
          amount: row.hourlyPrice,
          adhocAmount: row.adhocPrice,
        };
      });

    const priceBandArr = pricingBands
      .filter((d: any) => d.enabled)
      .map((row: any) => {
        return {
          id: row.id,
        };
      });

    const sessionAvailabilityArr = availability
      .filter((a: any) => a.enabled)
      .map((row1: any) => {
        return {
          day: row1.day,
          startTime: moment(row1.startTime)
            .tz("Europe/London")
            .toDate()
            .toString(),
          endTime: moment(row1.endTime).tz("Europe/London").toDate().toString(),
        };
      });

    const finalFormData = {
      title: form.sessionTitle,
      pricing: form.pricingType,
      isExtraSession: form.extraSession,
      isAdditionalCharges: form.additionalCharges,
      priceBandAmountSessionArr: priceBandAmountSessionArr,
      priceBandArr: priceBandArr,
      sessionAvailabilityArr: sessionAvailabilityArr,
    };

    try {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          await SessionAPi.updateSession({ ...finalFormData, id: id });
          Swal.fire({
            title: "Success!",
            text: "Session updated successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Active plans",
                `Session updated successfully`
              )
            );

            navigate(`${process.env.PUBLIC_URL}/dashboard/plans/active-plans`);
          });
        } catch (error: any) {
          const errorEncode = JSON.parse(error.message);
          const message = `${errorEncode.priceBandArr ? errorEncode.priceBandArr + ",\n" : ""
            }
          ${errorEncode.priceBandAmountSessionArr
              ? errorEncode.priceBandAmountSessionArr + ",\n"
              : ""
            }
          ${errorEncode.sessionAvailabilityArr
              ? errorEncode.sessionAvailabilityArr + ",\n"
              : ""
            }
          ${errorEncode.title ? errorEncode.title + ",\n" : ""}
          ${errorEncode.pricing ? errorEncode.pricing + ",\n" : ""}`;
          Swal.fire({
            title: "Error!",
            text: message || "Something went wrong",
            icon: "error",
          });
        }
      }, 2000);
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  useEffect(() => {
    const fetchSessionById = async () => {
      try {
        let formattedDataPricing = [];
        const responsePricing = await PricingBrandsAPi.getPriceBand({
          nurseryId: props.nurseryId,
        });
        if (responsePricing.success && Array.isArray(responsePricing.data)) {
          formattedDataPricing = responsePricing.data.map((row: any) => {
            return {
              id: row.id,
              name: row.title,
              hourlyPrice: "",
              adhocPrice: "",
              enabled: false,
            };
          });
        }

        const response = await SessionAPi.getSessionById(id ?? "");
        console.log("response: ", response);

        if (response.success && Object.keys(response.data).length > 0) {
          const resp = response.data;

          // Map pricingBands with priceBandAmountForSession
          const updatedPricingBands: any = formattedDataPricing.map(
            (band: any) => {
              // Find matching price band amount for session
              const matchedBand = resp.priceBandAmountForSession.find(
                (sessionBand: any) => sessionBand.priceBandId === band.id
              );

              if (matchedBand) {
                // Update the pricing band values if matched
                return {
                  ...band,
                  hourlyPrice: matchedBand.amount.toString(),
                  adhocPrice: matchedBand.adhocAmount.toString(),
                  enabled: true,
                };
              }

              // Return the original band if no match
              return band;
            }
          );

          // Update state with new pricing bands
          setPricingBands(updatedPricingBands);

          // Update availability state if needed
          const updatedAvailability = availability.map((availability: any) => {
            const matched = resp.sessionAvailability.find(
              (sessionAvail: any) => sessionAvail.day === availability.day
            );

            if (matched) {
              // Parse the matched start and end times as IST and then convert to "Europe/London"
              const startTime = moment(
                matched.startTime,
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
              )
                .tz("Asia/Kolkata") // Parse as IST
                .tz("Europe/London") // Convert to "Europe/London"
                .toDate();

              const endTime = moment(
                matched.endTime,
                "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
              )
                .tz("Asia/Kolkata") // Parse as IST
                .tz("Europe/London") // Convert to "Europe/London"
                .toDate();

              return {
                day: availability.day,
                startTime, // Corrected time with timezone adjustment
                endTime, // Corrected time with timezone adjustment
                enabled: true,
              };
            }

            return availability;
          });
          setAvailability(updatedAvailability);

          // Update the form if required
          setForm({
            sessionTitle: resp.title,
            pricingType: resp.pricing,
            extraSession: resp.isExtraSession,
            additionalCharges: resp.isAdditionalCharges,
          });

          reset({
            sessionTitle: resp.title,
            pricingType: resp.pricing,
            extraSession: resp.isExtraSession,
            additionalCharges: resp.isAdditionalCharges,
          });
        } else {
          // Reset state if no valid data is found
          setForm({ ...initialState });
          setAvailability(initialStateAvailability);
        }
      } catch (error: any) {
        console.error("Error fetching session:", error);
        setForm({ ...initialState });
        setAvailability(initialStateAvailability);
      }
    };

    fetchSessionById();
  }, [id]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Active Plans Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/plans/active-plans`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Row className="g-3">
                <Col md={12}>
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Session Detail</H4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <Label>
                            Session Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className={errors.sessionTitle ? "is-invalid" : ""}
                            placeholder={`Eg: "AM-Session", "PM-Session" `}
                            {...register("sessionTitle", {
                              required: "Session title is required",
                            })}
                            value={form.sessionTitle}
                            onChange={(e) =>
                              saveDataForm("sessionTitle", e.target.value)
                            }
                          />
                          {errors.sessionTitle && (
                            <FormFeedback>
                              {errors.sessionTitle.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4}>
                          <Label>
                            Pricing Type <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="select"
                            className={errors.pricingType ? "is-invalid" : ""}
                            {...register("pricingType", {
                              required: "Pricing type is required",
                            })}
                            value={form.pricingType}
                            onChange={(e) =>
                              saveDataForm("pricingType", e.target.value)
                            }
                          >
                            <option value="">Select Pricing Type</option>
                            <option value="HOURLY">Hourly</option>
                            <option value="MONTHLY">Monthly</option>
                            <option value="PER_SESSION">Per Session</option>
                          </Input>
                          {errors.pricingType && (
                            <FormFeedback>
                              {errors.pricingType.message}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={2}>
                          <Label>Is Extra Session</Label>
                          <br />
                          <div className="d-flex">
                            <Input
                              type="checkbox"
                              {...register("extraSession")}
                              style={{ fontSize: "20px" }}
                              checked={form.extraSession}
                              className="mt-0"
                              onChange={(e) =>
                                saveDataForm("extraSession", e.target.checked)
                              }
                            />
                            <Label className="ms-2 mb-0">
                              {form.extraSession ? "Yes" : "No"}
                            </Label>
                          </div>
                        </Col>

                        <Col md={2}>
                          <Label>Additional Charges</Label>
                          <br />
                          <div className="d-flex">
                            <Input
                              type="checkbox"
                              {...register("additionalCharges")}
                              style={{ fontSize: "20px" }}
                              checked={form.additionalCharges}
                              className="mt-0"
                              onChange={(e) =>
                                saveDataForm("additionalCharges", e.target.checked)
                              }
                            />
                            <Label className="ms-2 mb-0">
                              {form.additionalCharges ? "Yes" : "No"}
                            </Label>
                          </div>
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={6} className="mt-2">
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Session Pricing</H4>
                    </CardHeader>
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            borderBottomLeftRadius: 6,
                            borderTopLeftRadius: 6,
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>Bands</b>
                          </H6>
                        </Col>
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>Hourly Price</b>
                          </H6>
                        </Col>
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            borderBottomRightRadius: 6,
                            borderTopRightRadius: 6,
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>Adhoc Pricing</b>
                          </H6>
                        </Col>
                      </Row>
                      {pricingBands.length > 0 &&
                        pricingBands.map((band: any) => (
                          <Row
                            key={band.id}
                            className="align-items-center mb-3"
                          >
                            <Col md={4}>
                              <div className="d-flex align-items-center">
                                <div className={`icon-state switch-md`}>
                                  <Label className="switch mb-0">
                                    <Input
                                      type="checkbox"
                                      checked={band.enabled}
                                      onChange={() => handleBandToggle(band.id)}
                                    />
                                    <span
                                      className={`switch-state bg-primary`}
                                    ></span>
                                  </Label>
                                </div>
                                <Label className="ms-2 mt-2">{band.name}</Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <Input
                                type="number"
                                placeholder="Hourly Price"
                                value={band.hourlyPrice}
                                min={0}
                                onChange={(e) =>
                                  setPricingBands((prev: any) =>
                                    prev.map((b: any) =>
                                      b.id === band.id
                                        ? { ...b, hourlyPrice: e.target.value }
                                        : b
                                    )
                                  )
                                }
                                disabled={!band.enabled}
                              />
                            </Col>
                            <Col md={4}>
                              <Input
                                type="number"
                                placeholder="Adhoc Price"
                                value={band.adhocPrice}
                                min={0}
                                onChange={(e) =>
                                  setPricingBands((prev: any) =>
                                    prev.map((b: any) =>
                                      b.id === band.id
                                        ? { ...b, adhocPrice: e.target.value }
                                        : b
                                    )
                                  )
                                }
                                disabled={!band.enabled}
                              />
                            </Col>
                          </Row>
                        ))}
                    </CardBody>
                  </Card>
                </Col>

                <Col md={6} className="mt-2">
                  <Card style={{ borderRadius: "0.4rem" }}>
                    <CardHeader>
                      <H4>Availability</H4>
                    </CardHeader>
                    <CardBody>
                      <Row className="align-items-center mb-3">
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            borderBottomLeftRadius: 6,
                            borderTopLeftRadius: 6,
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>Working Day</b>
                          </H6>
                        </Col>
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>Start Time</b>
                          </H6>
                        </Col>
                        <Col
                          md={4}
                          className="pt-3 pb-3"
                          style={{
                            borderBottomRightRadius: 6,
                            borderTopRightRadius: 6,
                            backgroundColor: "#eee",
                          }}
                        >
                          <H6>
                            <b>End Time</b>
                          </H6>
                        </Col>
                      </Row>
                      {availability.map((slot) => (
                        <Row key={slot.day} className="align-items-center mb-3">
                          <Col md={4}>
                            <div className="d-flex align-items-center">
                              <div className={`icon-state switch-md`}>
                                <Label className="switch mb-0">
                                  <Input
                                    type="checkbox"
                                    checked={slot.enabled}
                                    onChange={() =>
                                      handleAvailabilityToggle(slot.day)
                                    }
                                  />
                                  <span
                                    className={`switch-state bg-primary`}
                                  ></span>
                                </Label>
                              </div>
                              <Label className="ms-2 mt-2">{slot.day}</Label>
                            </div>
                          </Col>
                          <Col md={4}>
                            <DatePicker
                              selected={slot.startTime}
                              onChange={(date) =>
                                handleTimeChange(
                                  slot.day,
                                  "startTime",
                                  moment(date).tz("Europe/London").toDate()
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Start Time"
                              dateFormat="h:mm aa"
                              disabled={!slot.enabled}
                              placeholderText="Select Start Time (UK)"
                              className="form-control"
                            />
                          </Col>
                          <Col md={4}>
                            <DatePicker
                              selected={slot.endTime}
                              onChange={(date) =>
                                handleTimeChange(
                                  slot.day,
                                  "endTime",
                                  moment(date).tz("Europe/London").toDate()
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="End Time"
                              dateFormat="h:mm aa"
                              disabled={!slot.enabled}
                              placeholderText="Select End Time (UK)"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                </Col>

                <Col
                  xs={12}
                  style={{ borderTop: "5px solid #eee", padding: 0 }}
                >
                  <Card
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  >
                    <CardBody>
                      <Btn
                        color="dark"
                        className="btn-md"
                        type="submit"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Update & Save"
                        )}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/plans/active-plans`
                          );
                        }}
                        className="btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(AddActivePlans);
