import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import FilterComponent from "src/Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "src/Utils/CommonComponents/CommonCardHeader";
import Status from "src/Components/Common/Status";
import { empStatusOption, statusColors } from "src/Utils/Constants";
import { Btn } from "src/AbstractElements";
import DropDownAPi from "src/services/DropdownApi";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FaUpload } from "react-icons/fa";
import { fetchImage, getUserActivityFormattedData } from "src/Utils";
import UserApi from "src/services/UserApi";
import { baseUrl } from "src/Utils/Constants/config";
import { useForm } from "react-hook-form";
import AttendanceAPi from "src/services/AttendanceApi";
import UserActivityAPi from "src/services/UserActivityApi";
import { cu } from "node_modules/@fullcalendar/core/internal-common";
import { camelCase, set } from "lodash";
import UploadAPi from "src/services/UploadApi";
import { use } from "i18next";
import moment from "moment-timezone";
import { connectComponent } from "../PricingBrands/connector";
import PricingBrandsAPi from "src/services/PricingBrandsApi";

interface Children {
  id: string;
  studentName: string;
  nurseryName: string;
  roomId: string;
  date: string;
  status: 0 | 1; // 0 for ACTIVE, 1 for INACTIVE
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ textAlign: "center", fontSize: "12px" }}>{title}</span>
);

interface InitialState {
  childrenIds: string[];
  signIn?: string;
  signInVisibleToParent?: string;
  signOut?: string;
  signOutVisibleToParent?: string;
  sleepToTime?: string;
  sleepFromTime?: string;
  sleepNotes?: string;
  sleepTimeVisibleToParent?: string;
  nappyChange?: string;
  toiletVisit?: string;
  nappyOrToiletNotes?: string;
  nappyOrToiletVisibleToParent?: string;
  nappyOrTolietTime?: string;
  meal?: string;
  mealTime?: string;
  mealNotes?: string;
  mealVisibleToParent?: string;
  notes?: string;
  notesAttachmentUrl?: string;
  notesVisibleToParent?: string;
  currDate?: string;
}

function SessionRequest(props: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeForm, setActiveForm] = useState("");
  const [customTime, setCustomTime] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const toggleFormModal = (formType: any) => {
    if (selectedEmployees.length === 0) {
      toast.error("Please select children first.");
      return;
    }

    if (filterObj.roomId === undefined || filterObj.roomId === "all-rooms") {
      toast.error("Please select room first.");
      return;
    }
    const { isCheck, data } = checkData(formType);
    if (isCheck) {
      Swal.fire(
        "Error",
        "Activity already exists for selected children \n (" + data + ")",
        "error"
      );
      return;
    }
    setActiveForm(formType);
  };

  const [childrenAttendance, setChildrenAttendance] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Date picker state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState("");
  const [fileName, setFileName] = useState("");
  const [fName, setFName] = useState("");
  const [isIndeterminate, setIsIndeterminate] = useState(false); // "Select All" indeterminate state
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]); // Track selected employees by id
  const [isAllSelected, setIsAllSelected] = useState(false); // "Select All" selected state
  const [clear, setClear] = useState(false);
  const [modalData, setModalData] = useState<any>(null); // State to store the row data

  const initialState = {
    childrenIds: [],
    signIn: "",
    signInVisibleToParent: "",
    signOut: "",
    signOutVisibleToParent: "",
    sleepToTime: "",
    sleepFromTime: "",
    sleepNotes: "",
    sleepTimeVisibleToParent: "",
    nappyChange: "",
    toiletVisit: "",
    nappyOrToiletNotes: "",
    nappyOrToiletVisibleToParent: "",
    nappyOrTolietTime: "",
    meal: "",
    mealTime: "",
    mealNotes: "",
    mealVisibleToParent: "",
    notes: "",
    notesAttachmentUrl: "",
    notesVisibleToParent: "",
    currDate: "",
  };

  const [form, setForm] = useState<InitialState>(initialState);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<InitialState>();

  const times = ["10", "20", "30", "45", "60", "90", "NOW"];

  const convertTime = (time: any) => {
    return moment.utc(time, "HH:mm").tz("Europe/London").format("HH:mm");
  };

  const onSubmit = async (data: any) => {
    console.log("Data: ", data, form);
    try {
      let updatedForm: any = {
        childrenIds: selectedEmployees.map((item) => item.empId),
        currDate: selectedDate,
      };

      if (activeForm === "SignIn") {
        updatedForm = {
          ...updatedForm,
          signIn: convertTime(form.signIn),
          signInVisibleToParent: form.signInVisibleToParent ? "true" : "false",
        };
      }

      if (activeForm === "SignOut") {
        updatedForm = {
          ...updatedForm,
          signOut: convertTime(form.signOut),
          signOutVisibleToParent: form.signOutVisibleToParent
            ? "true"
            : "false",
        };
      }

      if (activeForm === "Sleep") {
        updatedForm = {
          ...updatedForm,
          sleepNotes: form.sleepNotes,
          sleepTimeVisibleToParent: form.sleepTimeVisibleToParent
            ? "true"
            : "false",
          sleepFromTime: times.includes(form?.sleepFromTime || "")
            ? getISODateOffset(
                form.sleepFromTime === "NOW"
                  ? 0
                  : convertTime(form.sleepFromTime)
              )
            : form.sleepFromTime,
          sleepToTime: times.includes(form?.sleepToTime || "")
            ? ""
            : convertTime(form.sleepToTime),
        };
      }

      if (activeForm === "Meals") {
        updatedForm = {
          ...updatedForm,
          meal: form.meal,
          mealTime: convertTime(form.mealTime),
          mealNotes: form.mealNotes,
          mealVisibleToParent: form.mealVisibleToParent ? "true" : "false",
        };
      }

      if (activeForm === "NappyToilet") {
        updatedForm = {
          ...updatedForm,
          nappyChange: form.nappyChange,
          toiletVisit: form.toiletVisit,
          nappyOrToiletNotes: form.nappyOrToiletNotes,
          nappyOrTolietTime: convertTime(form.nappyOrTolietTime),
          nappyOrToiletVisibleToParent: form.nappyOrToiletVisibleToParent
            ? "true"
            : "false",
        };
      }

      if (activeForm === "Notes") {
        let mediaUrl = "";

        if (fileName !== "") {
          const uploadedMedia = await uploadImage(fileName);
          mediaUrl = uploadedMedia.mediaUrl; // Ensure mediaUrl is set after upload
        }

        updatedForm = {
          ...updatedForm,
          notes: form.notes,
          notesVisibleToParent: form.notesVisibleToParent ? "true" : "false",
          notesAttachmentUrl: mediaUrl,
        };
      }

      console.log("Updated Form:", updatedForm);

      const response = await AttendanceAPi.childrenGeneralActivity(updatedForm);

      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );

          setSelectedEmployees([]);
          setIsIndeterminate(false);
          setIsAllSelected(false);
          closeFormModal();
          setClear(true);
          setFileName("");
          setFName("");
          fetchChildren();
        });
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message);
      Swal.fire(
        "Error",
        parsedError.message || "Failed to update status",
        "error"
      );
    }
  };

  const checkData = (formType: any) => {
    const filteredData = childrenAttendance.filter((item: any) => {
      if (selectedEmployees.find((emp: any) => emp.empId === item.id)) {
        if (formType === "SignIn") {
          return item.childrenGeneralActivity[0]?.signIn;
        } else if (formType === "SignOut") {
          return item.childrenGeneralActivity[0]?.signOut;
        } else if (formType === "NappyToilet") {
          return item.childrenGeneralActivity[0]?.nappyOrTolietTime;
        } else if (formType === "Sleep") {
          return item.childrenGeneralActivity[0]?.sleepFromTime;
        } else if (formType === "Meals") {
          return item.childrenGeneralActivity[0]?.mealTime;
        } else if (formType === "Notes") {
          return item.childrenGeneralActivity[0]?.notes;
        }
      }
    });
    if (filteredData.length > 0) {
      return {
        isCheck: true,
        data: filteredData
          .map((item: any) => `${item.firstName} ${item.lastName}`)
          .join(", "),
      };
    }
    return {
      isCheck: false,
      data: [],
    };
  };

  useEffect(() => {
    setTimeout(() => {
      if (clear) setClear(false);
    }, 5000);
  }, [clear]);

  const closeFormModal = () => {
    setActiveForm("");
    setModalData(null); // Clear modal data
    setCustomTime(false); // Reset custom time state
    setForm(initialState);
    reset(initialState);
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "childrenActivity");
    try {
      const response = await UploadAPi.uploadFile(
        formData,
        "upload-childrenActivity"
      );

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      return { mediaUrl: "", mediaType: "" };
    }
  };

  const [pricingBrandDropDown, setPricingBrandDropDown] = useState({
    id: "pricingBrand",
    options: [],
    title: "Select Pricing Brands",
  });

  const [statusDropDown, setStatusDropDown] = useState({
    id: "status",
    options: [{label: "Approved", value: "Approved"}, {label: "Declined", value: "Declined"}, {label: "Pending", value: "Pending"}],
    title: "Select Status",
  });

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files[0]);
      setFName(e.target.files[0].name);
    }
  };

  const fetchChildren = async () => {
    try {
      const response = await UserApi.getAllChildren(filterObj);
      if (response.success && Array.isArray(response.children)) {
        const dataWithImage: any = await Promise.all(
          response.children.map(async (row: any) => {
            let avatarUrlData = "";
            if (!!row.avatarUrl) {
              // Fetch the image if avatarUrl exists
              avatarUrlData = await fetchImage(baseUrl + row.avatarUrl);
            }
            return { ...row, avatarUrl: avatarUrlData };
          })
        );
        console.log("dataWithImage: ", dataWithImage);
        setChildrenAttendance(dataWithImage);
      } else {
        throw new Error("Expected an array of Children");
      }
    } catch (error: any) {
      setChildrenAttendance([]);
      console.error(error);
    }
  };

  const fetchPricingBrandOptions = async () => {
    try {
      const response = await PricingBrandsAPi.getPriceBand({ nurseryId: props.nurseryId,});

      if (response.success && response?.data?.length > 0) {
        let priceBrandOptions: any = [];
        response?.data?.forEach((item: any) => {
          priceBrandOptions.push({
            label: item.title,
            value: item.id,
          });
        });

        setPricingBrandDropDown({
          ...pricingBrandDropDown,
          options: priceBrandOptions,
        });
      }
    } catch (error: any) {
      setPricingBrandDropDown({ ...pricingBrandDropDown, options: [] });
    }
  };

  useEffect(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    setCurrentTime(`${hours}:${minutes}`);
  }, []);

  const [filterObj, setFilterObj] = useState<any>({
    nurseryId: props.nurseryId,
    roomId: undefined,
    currDate: new Date(),
  });

  useEffect(() => {
    setLoading(false);
    fetchPricingBrandOptions();
    setFilterObj({ ...filterObj, nurseryId: props.nurseryId });
  }, [props.nurseryId]);

  useEffect(() => {
    fetchChildren();
  }, [filterObj]);

  useEffect(() => {
    setFilterObj({ ...filterObj, currDate: selectedDate });
  }, [selectedDate]);

  const filteredItems: any = childrenAttendance.filter((item: any) => {
    const newItem = {
      name: `${item.firstName} ${item.lastName}`,
      nurseryName: item.nursery.name,
      roomName: item.childrenRoomMoves?.[0]?.room?.roomName,
      date: item.date,
      status: item.status,
    };
    return Object.values(newItem).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const handleDetailPage = (row: any) => {
    let data = {};
    if (
      row?.childrenGeneralActivity?.[0]?.notesAttachmentUrl ||
      row?.childrenGeneralActivity?.[0]?.notes
    ) {
      data = {
        notes: row.childrenGeneralActivity[0].notes,
        notesAttachmentUrl: row.childrenGeneralActivity[0].notesAttachmentUrl,
        notesVisibleToParent:
          row.childrenGeneralActivity[0].notesVisibleToParent,
      };
    }
    setModalData(data);
    setActiveForm("Details");
  };

  const handleStatusChange = async (row: any) => {
    const currentStatus = row.status === 0 ? 1 : 0; // Determine the current status as a string
    const id = row.id;
    try {
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const formatKey = (key: any) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before uppercase letters
      .replace(
        /([A-Z]{2,})/g,
        (match: any) => match.charAt(0) + match.slice(1).toLowerCase()
      ) // Handle all-uppercase words
      .replace(/^./, (str: any) => str.toUpperCase()); // Capitalize the first letter
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Date",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: "Parent Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    }, 
    {
      name: "Name of Child",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    }, 
    {
      name: "Pricing Band",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    }, 
    {
      name: "Extra Session Request",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    }, 
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.employeeStatus,
      cell: (row: any) => {
        return (
          <Status
            position={
              Object.keys(empStatusOption).includes(
                camelCase(row?.employeeStatus)
              )
                ? empStatusOption[camelCase(row?.employeeStatus)]
                : ""
            }
            color={statusColors[camelCase(row?.employeeStatus)]}
            row={row}
            id={row.id}
            handleStatusChange={handleStatusChange}
          />
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
      button: true,
      cell: (row) => (
        <a
          className="icon"
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            handleDetailPage(row); // Call the function
          }}
          style={{ marginLeft: "2px" }}
        >
          <Btn color="primary" size="sm">
            <i className="fa-solid fa-eye me-1"></i>
            {"View"}
          </Btn>
        </a>
      ),
    },
  ];
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleRowSelect = (state: { selectedRows: any[] }) => {
    const selectedIds = state.selectedRows.map((row: any) => {
      return { empId: row.id, attendanceId: row.attendance?.[0]?.id || "" };
    });
    setSelectedEmployees(selectedIds);
    setIsIndeterminate(
      selectedIds.length > 0 && selectedIds.length < filteredItems.length
    );
    setIsAllSelected(selectedIds.length === filteredItems.length);
    setClear(false);
  };

  const getISODateOffset = (minutes: any) => {
    return new Date(new Date().getTime() - minutes * 60000).toISOString();
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Session Requests`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <Row className="justify-content-center">
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(date: any) => {
                        setSelectedDate(date);
                      }}
                      filterText={selectedDate}
                      type="date"
                      showDateLabel={false}
                    />
                  </Col>
                  <Col md={3}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterObj({ ...filterObj, roomId: e })
                      }
                      filterText={filterObj.roomId}
                      type="select"
                      dropdown={{ ...pricingBrandDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Pricing Brands" // Custom placeholder text
                    />
                  </Col>
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterObj({ ...filterObj, roomId: e })
                      }
                      filterText={filterObj.roomId}
                      type="select"
                      dropdown={{ ...statusDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Status" // Custom placeholder text
                    />
                  </Col>
                  <Col md={3}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                  <Col md={2}>
                    {/* Add Activity Dropdown */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle color="primary" className="btn-md">
                        <i className="fa fa-plus mr-2"></i> Action
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown-menu">
                        <div
                          className="custom-dropdown-item bg-success"
                          onClick={() => toggleFormModal("Approved")}
                        >
                          <span>Approved</span>
                          <i className="fa fa-check"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-danger"
                          onClick={() => toggleFormModal("Declined")}
                        >
                          <span>Declined</span>
                          <i className="fa fa-times"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-warning"
                          onClick={() => toggleFormModal("Pending")}
                        >
                          <span>Pending</span>
                          -
                        </div>
                        
                      </DropdownMenu>
                    </Dropdown>

                    {/* Modals for Individual Activities */}
                    {activeForm === "Details" && (
                      <Modal isOpen toggle={() => {}} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {activeForm}
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            {Object.keys(modalData).length == 0 && (
                              <>No Data Available</>
                            )}
                            {Object.keys(modalData).length > 0 &&
                              Object.entries(modalData || {}).map(
                                ([key, value]) => (
                                  <div key={key} className="mb-3">
                                    {value?.toString() ? (
                                      <>
                                        <strong>{formatKey(key)}:</strong>{" "}
                                        <span>
                                          {key !== "notesAttachmentUrl" ? (
                                            value?.toString() || "N/A"
                                          ) : (
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                window.open(
                                                  baseUrl + value,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              View Attachment
                                            </a>
                                          )}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                )
                              )}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={closeFormModal}>
                            {"Cancel"}
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )}
                    {/* Modals for Individual Activities */}
                    {activeForm === "SignIn" && (
                      <Modal isOpen toggle={() => {}} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {"Sign In"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="time">
                                {"Time"} <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="time"
                                {...register("signIn", {
                                  required: "Please enter time",
                                })}
                                className={`form-control ${
                                  errors.signIn ? "" : ""
                                }`}
                                placeholder="Time"
                                value={form.signIn}
                                onChange={(e) =>
                                  saveDataForm("signIn", e.target.value)
                                }
                              />
                              {errors.signIn && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signIn?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("signInVisibleToParent", {
                                      required: "Please select an option",
                                    })}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.signInVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={!!form.signInVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "signInVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.signInVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signInVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "SignOut" && (
                      <Modal isOpen toggle={() => {}} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {"Sign Out"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="time">
                                {"Time"} <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="time"
                                {...register("signOut", {
                                  required: "Please enter time",
                                })}
                                className={`form-control ${
                                  errors.signOut ? "" : ""
                                }`}
                                placeholder="Time"
                                value={form.signOut}
                                onChange={(e) =>
                                  saveDataForm("signOut", e.target.value)
                                }
                              />
                              {errors.signOut && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signOut?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("signOutVisibleToParent", {
                                      required: "Please select an option",
                                    })}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.signOutVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={!!form.signOutVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "signOutVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.signOutVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signOutVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "NappyToilet" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => {}}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Nappy/Toilet"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="nappyChange">{"Nappy Change"}</Label>
                              <Row>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyDry"
                                  >
                                    <span>{"Dry"}</span>
                                    <input
                                      type="radio"
                                      name="nappyChange"
                                      value="DRY"
                                      id="nappyDry"
                                      className="form-check-input"
                                      checked={form.nappyChange === "DRY"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyWet"
                                  >
                                    <span>{"Wet"}</span>
                                    <input
                                      type="radio"
                                      name="nappyChange"
                                      value="WET"
                                      id="nappyWet"
                                      className="form-check-input"
                                      checked={form.nappyChange === "WET"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyBM"
                                  >
                                    <span>{"BM"}</span>
                                    <input
                                      type="radio"
                                      name="nappyChange"
                                      value="BM"
                                      id="nappyBM"
                                      className="form-check-input"
                                      checked={form.nappyChange === "BM"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Label for="toiletVisit">{"Toilet Visit"}</Label>
                              <Row>
                                <Col md={4}>
                                  {/* Dry */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitDry"
                                  >
                                    <span>{"Dry"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      value="DRY"
                                      id="toiletVisitDry"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "DRY"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  {/* Wet */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitWet"
                                  >
                                    <span>{"Wet"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      value="WET"
                                      id="toiletVisitWet"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "WET"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  {/* BM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitBM"
                                  >
                                    <span>{"BM"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      id="toiletVisitBM"
                                      value="BM"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "BM"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                            {/* Time Field (NOW or Custom) */}
                            <FormGroup>
                              <Label for="timeSelection">{"Time"}</Label>
                              <Row>
                                <Col md={6}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="NOW"
                                      id="toiletVisitNow"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        saveDataForm(
                                          "nappyOrTolietTime",
                                          moment(new Date().toISOString())
                                            .utc()
                                            .format("HH:mm")
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className="form-check-input"
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Label for="time">
                                    {"Time of the day"}{" "}
                                    <span className="text-danger mt-3">*</span>
                                  </Label>
                                  <input
                                    type="time"
                                    className="form-control mt-3"
                                    placeholder="Enter Time"
                                    value={form.nappyOrTolietTime}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "nappyOrTolietTime",
                                        e.target.value
                                      )
                                    } // To allow the user to modify the time if needed
                                  />
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.nappyOrToiletNotes}
                                onChange={(e) =>
                                  saveDataForm(
                                    "nappyOrToiletNotes",
                                    e.target.value
                                  )
                                }
                              ></textarea>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register(
                                      "nappyOrToiletVisibleToParent",
                                      {
                                        required: "Please select an option",
                                      }
                                    )}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.nappyOrToiletVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={
                                      !!form.nappyOrToiletVisibleToParent
                                    }
                                    onChange={(e) =>
                                      saveDataForm(
                                        "nappyOrToiletVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.nappyOrToiletVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.nappyOrToiletVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Sleep" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => {}}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Add Sleep"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="nappyChange">
                                {"When did Emily Rich fall asleep?"}
                              </Label>
                              <Row>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="NOW"
                                      id="toiletVisitNow"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "DRY"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitTenMins"
                                  >
                                    <span>{"-10 mins"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="10"
                                      id="toiletVisitTenMins"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "10"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitTwentyMins"
                                  >
                                    <span>{"-20 mins"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="20"
                                      id="toiletVisitTwentyMins"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "20"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitThirtyMins"
                                  >
                                    <span>{"-30 mins"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="30"
                                      id="toiletVisitThirtyMins"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "30"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitFortyFiveMins"
                                  >
                                    <span>{"-45 mins"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="45"
                                      id="toiletVisitFortyFiveMins"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "45"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitOneHour"
                                  >
                                    <span>{"-1 hour"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="60"
                                      id="toiletVisitOneHour"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "60"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitOneAndHalfHour"
                                  >
                                    <span>{"-1.5 hour"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="90"
                                      id="toiletVisitOneAndHalfHour"
                                      className="form-check-input"
                                      checked={form.sleepFromTime === "90"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={3}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className="form-check-input"
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Row>
                                    <Col md={6}>
                                      <Label for="from">
                                        {"From"}{" "}
                                        <span className="text-danger mt-3">
                                          *
                                        </span>
                                      </Label>
                                      <input
                                        type="time"
                                        className="form-control mt-3"
                                        placeholder="Enter Time"
                                        onChange={(e) =>
                                          saveDataForm(
                                            "sleepFromTime",
                                            e.target.value
                                          )
                                        } // To allow the user to modify the time if needed
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label for="to">
                                        {"To"}{" "}
                                        <span className="text-danger mt-3">
                                          *
                                        </span>
                                      </Label>
                                      <input
                                        type="time"
                                        className="form-control mt-3"
                                        placeholder="Enter Time"
                                        onChange={(e) =>
                                          saveDataForm(
                                            "sleepToTime",
                                            e.target.value
                                          )
                                        } // To allow the user to modify the time if needed
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.sleepNotes}
                                onChange={(e) => {
                                  saveDataForm("sleepNotes", e.target.value);
                                }}
                              ></textarea>
                            </FormGroup>
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("sleepTimeVisibleToParent", {
                                      required: "Please select an option",
                                    })}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.sleepTimeVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={!!form.sleepTimeVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "sleepTimeVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.sleepTimeVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.sleepTimeVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Meals" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => {}}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Meals"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="registerMeal">
                                {"Register meal"}
                              </Label>
                              <Row>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealBreakfast"
                                  >
                                    <span>{"Breakfast"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Breakfast"
                                      id="registerMealBreakfast"
                                      className="form-check-input"
                                      checked={form.meal === "Breakfast"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealSnackAM"
                                  >
                                    <span>{"Snack (AM)"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Snack(AM)"
                                      id="registerMealSnackAM"
                                      className="form-check-input"
                                      checked={form.meal === "Snack(AM)"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealLunch"
                                  >
                                    <span>{"Lunch"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Lunch"
                                      id="registerMealLunch"
                                      className="form-check-input"
                                      checked={form.meal === "Lunch"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealSnackPM"
                                  >
                                    <span>{"Snack (PM)"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Snack(PM)"
                                      id="registerMealSnackPM"
                                      className="form-check-input"
                                      checked={form.meal === "Snack(PM)"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMeaTea"
                                  >
                                    <span>{"Tea"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Tea"
                                      id="registerMeaTea"
                                      className="form-check-input"
                                      checked={form.meal === "Tea"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealBottle"
                                  >
                                    <span>{"Bottle"}</span>
                                    <input
                                      type="radio"
                                      name="registerMeal"
                                      value="Bottle"
                                      id="registerMealBottle"
                                      className="form-check-input"
                                      checked={form.meal === "Bottle"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                            {/* Time Field (NOW or Custom) */}
                            <FormGroup>
                              <Label for="timeSelection">{"Time"}</Label>
                              <Row>
                                <Col md={6}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="NOW"
                                      id="toiletVisitNow"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        setCustomTime(false);
                                        saveDataForm(
                                          "mealTime",
                                          moment(new Date().toISOString())
                                            .utc()
                                            .format("HH:mm")
                                        );
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className="form-check-input"
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Label for="time">
                                    {"Time of the day"}{" "}
                                    <span className="text-danger mt-3">*</span>
                                  </Label>
                                  <input
                                    type="time"
                                    className="form-control mt-3"
                                    placeholder="Enter Time"
                                    onChange={(e) =>
                                      saveDataForm("mealTime", e.target.value)
                                    } // To allow the user to modify the time if needed
                                  />
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.mealNotes}
                                onChange={(e) =>
                                  saveDataForm("mealNotes", e.target.value)
                                }
                              ></textarea>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("mealVisibleToParent", {
                                      required: "Please select an option",
                                    })}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.mealVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={!!form.mealVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "mealVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.mealVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.mealVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Notes" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => {}}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Notes"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="notes">
                                {"Add Notes"}{" "}
                                <span className="text-danger mt-3">*</span>
                              </Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.notes}
                                onChange={(e) =>
                                  saveDataForm("notes", e.target.value)
                                }
                              ></textarea>
                            </FormGroup>
                            <FormGroup>
                              <Label for="fileUpload">Attachments</Label>
                              {/* Label wraps the whole div */}
                              <label
                                htmlFor="fileUpload"
                                className="border rounded px-3 py-2 d-flex align-items-center justify-content-between mb-0"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{fName || "Upload attachment"}</span>
                                <i className="fa fa-upload"></i>
                              </label>
                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="fileUpload"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                accept=".pdf, .docx, .png, .jpg, .jpeg"
                              />
                              <small className="text-danger d-block mt-2">
                                Max file size: 5MB. File format: pdf, docx, png,
                                jpg, and jpeg
                              </small>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("notesVisibleToParent", {
                                      required: "Please select an option",
                                    })}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated ${
                                      errors.notesVisibleToParent
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={!!form.notesVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "notesVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                              {errors.notesVisibleToParent && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.notesVisibleToParent?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display data-table-wrapper"
                    onSelectedRowsChange={handleRowSelect}
                    selectableRows
                    clearSelectedRows={clear}
                    selectableRowsHighlight
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(SessionRequest);
