import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { Btn } from "../../../../AbstractElements";
import { connectComponent } from "../connector";
import Swal from "sweetalert2";
import {
  convertToCSV,
  flattenObject,
  getDataFromArray,
} from "../../../../Utils";
import moment from "moment";

interface initialFilter {
  searchText: string;
  status: string | boolean;
  startDate: string;
  endDate: string;
  pageNo: 1;
  pageSize: 100;
  packageId: string;
}

function UsersReport(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [year, setYear] = useState(new Date());
  const [saveData, setSaveData] = useState([]);
  const [plansData, setPlansData] = useState([]);
  const initialFilter: initialFilter = {
    searchText: "",
    status: "",
    startDate: `${new Date().getFullYear()}-01-01`,
    endDate: `${new Date().getFullYear()}-12-31`,
    pageNo: 1,
    pageSize: 100,
    packageId: "",
  };

  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const [filterObj, setFilterObj] = useState(initialFilter);

  useEffect(() => {
    props.loadAdminData(filterObj);
  }, []);

  useEffect(() => {
    props.fetchPlans({});
  }, []);

  useEffect(() => {
    const data = props.plans.map((plan: any) => {
      return {
        label: `${plan.title}`,
        value: plan.id,
      };
    });
    setPlansData(data);
  }, [props.plans]);

  useEffect(() => {
    let data: any = [...props.admins].sort(
      (a: any, b: any) => b.joinDate - a.joinDate
    );
    data = data.map((row: any) => {
      return flattenObject(row);
    });

    setSaveData(data);
  }, [props.admins]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/users/edit/${id}`);
  };

  const handleStatusChange = (row: any) => {
    const selectedValue = row?.status === 0 ? 1 : 0;
    const id = row.id;
    props.updateForm({
      id: id,
      body: {
        status: selectedValue,
      },
      filterObj,
    });
  };

  useEffect(() => {
    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  useEffect(() => {
    const updatedYear = year.toString().substring(11, 15);
    setFilterObj({
      ...filterObj,
      startDate: `${updatedYear}-01-01`,
      endDate: `${updatedYear}-12-31`,
    });
  }, [year]);

  const handleApplyFilter = () => {
    props.loadAdminData(filterObj);
  };

  const handleClearFilter = () => {
    props.loadAdminData(initialFilter);
  };

  const handleDownloadCSV = () => {
    const formattedData = saveData.map((row: any) => {
      return {
        date: moment(row?.createdAt).format("DD MMM YYYY"),
        name: `${row.firstName} ${row.lastName}`,
        nursery: row["nursery[0].name"],
        role: row.role,
        phone: row.phoneNo,
        subscription: row["package[0].package.title"],
        status: row?.isActive === true ? "ACTIVE" : "INACTIVE",
      };
    });

    const jsonToCsvData = convertToCSV(formattedData);
    const blob = new Blob([jsonToCsvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `UsersReport_${new Date().getTime()}.csv`);
    a.click();
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Users Report`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => handleDownloadCSV()}
                >
                  Download Report
                </Button>
              </div>
              <CardBody>
                <Card color="light" inverse={true} className="custom-card">
                  <CardBody>
                    <span className="h4 text-bold">Filter : </span>
                    <div
                      className="d-flex align-items-center mt-3"
                      style={{ gap: "1.5rem" }}
                    >
                      <FilterComponent
                        onFilter={(value) => setYear(value)}
                        filterText={year}
                        type="datepicker"
                      />
                      <FilterComponent
                        onFilter={(value) =>
                          setFilterObj({
                            ...filterObj,
                            status: value === "active" ? true : false,
                          })
                        }
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "status",
                          options: [
                            {
                              value: "active",
                              label: "Active",
                            },
                            {
                              value: "inactive",
                              label: "Inactive",
                            },
                          ],
                          title: "Status",
                        }}
                      />
                      <FilterComponent
                        onFilter={(value) =>
                          setFilterObj({
                            ...filterObj,
                            packageId: value,
                          })
                        }
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "plan",
                          options: plansData,
                          title: "Plans",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center mt-4"
                      style={{ gap: "1rem" }}
                    >
                      <Btn
                        color="dark"
                        type="button"
                        onClick={() => handleApplyFilter()}
                      >
                        Apply Filter
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => handleClearFilter()}
                      >
                        Clear
                      </Btn>
                    </div>
                  </CardBody>
                </Card>

                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />

                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true, // This applies to all rows
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [props.isLoading, props.success, saveData])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(UsersReport);
