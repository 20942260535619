import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  InputGroup,
  InputGroupText,
  FormGroup,
  Input,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import PackageApi from "../../../services/PackageApi"; // Adjust as needed
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import Select from "react-select";
import { Btn, LI, P, UL } from "../../../AbstractElements";
import { statusOptions, LooksGood } from "../../../Utils/Constants";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";
import DemoRequestAPi from "../../../services/DemoRequest";
import DatePicker from "react-datepicker";

// Interface for form data
type BookingDetails = {
  firstName: string;
  lastName: string;
  message: string;
  designation: string;
  email: string;
  phoneNo: string;
  demoDate: any;
  demoTime: any;
  reasonForDecline: string;
  finalMessage: string;
  status: string;
  addToCalendar: boolean;
};

const EditDemoRequest: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>(); // Make id optional
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<BookingDetails>();

  const initialState = {
    firstName: "",
    lastName: "",
    message: "",
    designation: "",
    email: "",
    phoneNo: "",
    demoDate: "",
    demoTime: "",
    reasonForDecline: "",
    finalMessage: "",
    status: "",
    addToCalendar: true,
  };
  const [form, setForm] = useState<BookingDetails>({ ...initialState });

  const saveDataForm = (name: string, value: any) => {
    if (form.firstName != "") {
      setForm({ ...form, [name]: value });
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const response = await DemoRequestAPi.getDemoRequest({
          page: 1,
          pageSize: 1000,
        }); // Adjust limit/offset as needed
        if (Array.isArray(response.data.demos)) {
          if (id) {
            const foundPlan = response.data.demos.find(
              (plan: { id: string }) => plan.id === id
            );
            if (foundPlan) {
              const formData: any = {
                firstName: foundPlan.firstName,
                lastName: foundPlan.lastName,
                message: foundPlan.message,
                designation: foundPlan.designation,
                email: foundPlan.email,
                phoneNo: foundPlan.phoneNo,
                demoDate: foundPlan.demoDate,
                demoTime: foundPlan.demoTime,
                reasonForDecline: foundPlan.reasonForDecline,
                finalMessage: foundPlan.finalMessage,
                status: foundPlan.status,
                addToCalendar: true,
              };

              // reset(formData); // Populate form with plan data
              setForm(formData);
            } else {
              throw new Error("Plan not found");
            }
          } else {
            throw new Error("Plan ID is not defined");
          }
        } else {
          throw new Error("Expected an array of plans");
        }
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: error.message || "Failed to fetch plans.",
          icon: "error",
        });
      }
    };

    fetchPlan();
  }, [id]);

  // Handle form submission
  const onSubmit = async () => {
    try {
      const formattedData = {
        ...form,
      };

      if (id) {
        await DemoRequestAPi.updateDemoRequest(formattedData, id);
        Swal.fire({
          title: "Success!",
          text: "Booking updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Booking",
              `Booking updated successfully`
            )
          );
          navigate(`${process.env.PUBLIC_URL}/dashboard/bookings`);
        });
      } else {
        throw new Error("bookings ID is not defined");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Booking Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/dashboard/bookings`)
                  }
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row className="g-3">
                    {/* Title Field */}
                    <Col md={4}>
                      <Label for="firstName">
                        FirstName <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.firstName ? "is-invalid" : ""
                        }`}
                        {...register("firstName", {
                          required: false,
                        })}
                        placeholder="Enter FirstName"
                        value={form.firstName}
                        onChange={(e) =>
                          saveDataForm("firstName", e.target.value)
                        }
                      />
                      {errors.firstName && (
                        <FormFeedback>
                          {"Please enter a valid firstName"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={4}>
                      <Label for="lastName">
                        LastName <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.lastName ? "is-invalid" : ""
                        }`}
                        {...register("lastName", {
                          required: false,
                        })}
                        placeholder="Enter LastName"
                        value={form.lastName}
                        onChange={(e) =>
                          saveDataForm("lastName", e.target.value)
                        }
                      />
                      {errors.lastName && (
                        <FormFeedback>
                          {"Please enter a valid lastName"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={4}>
                      <Label for="email">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        {...register("email", {
                          required: false,
                        })}
                        placeholder="Enter Email"
                        value={form.email}
                        onChange={(e) => saveDataForm("email", e.target.value)}
                      />
                      {errors.email && (
                        <FormFeedback>
                          {"Please enter a valid email"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={4}>
                      <Label for="phoneNo">
                        PhoneNo <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.phoneNo ? "is-invalid" : ""
                        }`}
                        {...register("phoneNo", {
                          required: false,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "This field must contain only digits",
                          },
                        })}
                        placeholder="Enter PhoneNo"
                        value={form.phoneNo}
                        onChange={(e) =>
                          saveDataForm("phoneNo", e.target.value)
                        }
                      />
                      {errors.phoneNo && (
                        <FormFeedback>
                          {"Please enter a valid phoneNo"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={4}>
                      <Label for="designation">
                        Designation <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.designation ? "is-invalid" : ""
                        }`}
                        {...register("designation", {
                          required: false,
                        })}
                        placeholder="Enter Designation"
                        value={form.designation}
                        onChange={(e) =>
                          saveDataForm("designation", e.target.value)
                        }
                      />
                      {errors.designation && (
                        <FormFeedback>
                          {"Please enter a valid designation"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col md={4} className="position-relative">
                      <Label>
                        {"Date"} <span className="text-danger">*</span>
                      </Label>
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="demoDate"
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input ${
                                  errors.demoDate ? "is-invalid" : ""
                                }`}
                                selected={form.demoDate}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("demoDate", date);
                                }}
                                placeholderText="Select a date"
                                dateFormat="MMMM dd,yyyy"
                              />
                            );
                          }}
                        />

                        {errors.demoDate && (
                          <FormFeedback tooltip style={{ display: "block" }}>
                            <>{"Please select event date."}</>
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                    <Col md={4} className="position-relative">
                      <Label>
                        {"Time"} <span className="text-danger">*</span>
                      </Label>
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="demoTime"
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input ${
                                  errors.demoTime ? "is-invalid" : ""
                                } `}
                                selected={form.demoTime}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("demoTime", date);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                              />
                            );
                          }}
                        />

                        {errors.demoTime && (
                          <FormFeedback tooltip style={{ display: "block" }}>
                            <>{"Please select booking time."}</>
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mt-4">
                        <UL className="tg-list common-flex simple-list flex-row mt-3">
                          <Fragment key={0}>
                            <LI className="tg-list-item">
                              <Input
                                className={`tgl tgl-skewed`}
                                id={`cb1`}
                                type="checkbox"
                                {...register("addToCalendar", {
                                  required: false,
                                })}
                                checked={form.addToCalendar}
                                onChange={(e) =>
                                  saveDataForm(
                                    "addToCalendar",
                                    e.target.checked
                                  )
                                }
                              />
                              <Label
                                className="tgl-btn"
                                data-tg-off={"No"}
                                data-tg-on={"Yes"}
                                htmlFor={`cb1`}
                              />
                            </LI>
                            <LI>
                              <P>{"Add to my calendar"}</P>
                            </LI>
                          </Fragment>
                        </UL>
                      </FormGroup>
                    </Col>

                    {/* Message Field */}
                    <Col md={12} className="mt-3">
                      <Label for="message">
                        Message <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="message"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={form.message}
                            onChange={(value) => {
                              // field.onChange(
                              //   value === "<p><br></p>" ? "" : value
                              // );
                              saveDataForm(
                                "message",
                                value === "<p><br></p>" ? "" : value
                              );
                            }} // Update form value on change
                            placeholder="Enter Message"
                          />
                        )}
                      />
                      {errors.message && (
                        <FormFeedback className="d-block">
                          {"Message is required"}
                        </FormFeedback>
                      )}
                    </Col>

                    {/* Status Field */}
                    <Col md={4}>
                      <Label for="status">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="status"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="status"
                            className={`${errors.status ? "is-invalid" : ""}`}
                            options={statusOptions}
                            value={statusOptions.find(
                              (option) => option.value === form.status
                            )}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption?.value);
                              saveDataForm("status", selectedOption?.value);
                            }}
                          />
                        )}
                      />
                      {errors.status && (
                        <FormFeedback>
                          {"Please select a status option"}
                        </FormFeedback>
                      )}
                    </Col>

                    {form.status === "DECLINE" && (
                      <Col md={4}>
                        <Label for="reasonForDecline">
                          Reason For Decline{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <input
                          type="text"
                          className={`form-control`}
                          {...register("reasonForDecline", {
                            required: false,
                          })}
                          placeholder="Enter Reason For Decline"
                          value={form.reasonForDecline}
                          onChange={(e) =>
                            saveDataForm("reasonForDecline", e.target.value)
                          }
                        />
                      </Col>
                    )}
                  </Row>

                  {/* Action Buttons */}
                  <Row className="mt-4">
                    <Col>
                      <Btn
                        color="dark"
                        type="submit"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        Update & Save
                      </Btn>
                      <Btn
                        color="primary"
                        outline
                        type="button"
                        onClick={() =>
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/bookings`
                          )
                        }
                        className="mt-3 btn-md"
                      >
                        Cancel
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditDemoRequest;
