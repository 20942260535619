import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "src/AbstractElements";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";

function Password(props: any) {
  const initialState = {
    id: "",
    firstName: "",
    lastName: "",
    password: "",
    backupPassword: "",
  };

  const [form, setForm] = useState<any>(props.initialState);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const toggle = () => setPasswordVisible(!isPasswordVisible);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.createAndUpdateChild({
        ...form,
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Children", response.message)
          );
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let modifiedObj = commonKeysObject(props.initialState, initialState);
    modifiedObj.password = modifiedObj.backupPassword;
    setForm(modifiedObj);
    reset(modifiedObj);
  }, [props.initialState]);

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Set Collection Password"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={6}>
                <FormGroup>
                  <Label className="mt-3">{`Set Collection Password for ${form.firstName} ${form.lastName}`}</Label>{" "}
                  <div className="form-input position-relative">
                    <input
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Password"
                      {...register("password", { required: true })}
                      value={form.password}
                      onChange={(e) => saveDataForm("password", e.target.value)}
                    />
                    <div
                      className="show-hide"
                      onClick={toggle}
                      style={{ top: "19.5px", right: "32px" }}
                    >
                      {/* Eye icon for show/hide */}
                      <i
                        className={`fa ${
                          isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                        }`}
                        style={{ fontSize: "14px", color: "#6c757d" }}
                      ></i>{" "}
                    </div>
                    {errors.password && (
                      <FormFeedback
                        style={{ color: "orangeRed", display: "block" }}
                      >
                        {"Password is required"}{" "}
                      </FormFeedback>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Set Password"
                )}
              </Btn>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    </Form>
  );
}

export default Password;
