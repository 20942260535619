import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa'
import { Button, Card, CardBody, Col, Form, FormFeedback, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Nav, NavItem, NavLink, TabContent, TabPane, Input, Spinner } from 'reactstrap'
import { ProgressBar, Ribbon } from 'src/AbstractElements'
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader'
import DatePicker from "react-datepicker";
import Select from "react-select";
import PricingBrandsAPi from 'src/services/PricingBrandsApi';
import classnames from 'classnames';
import SessionAPi from 'src/services/SessionApi';
import { toast } from 'react-toastify';
import { getUserActivityFormattedData } from 'src/Utils';
import UserActivityAPi from 'src/services/UserActivityApi';
import Swal from 'sweetalert2';

interface Booking {
    id: string;
    startDate: Date | null;
    endDate: Date | null;
    pricingBandId: string;
    invoicingProfile: string;
}

interface PricingBandOption {
    label: string;
    value: string;
}

interface Session {
    id: string;
    title: string;
    startTime: string;
    endTime: string;
    price: number;
    type: 'fixed' | 'flexible';
    color: string;
    frequency: string;
    pricingBandId?: string; // Optional field for pricing band ID
}

interface ExtraCharge {
    id: string;
    title: string;
    price: number;
    color: string;
    quantity?: number;
}

interface DaySchedule {
    sessions: Session[];
    extraCharges: Record<string, number>;
}

interface Props {
    nurseryId: string;
    childrenName?: string;
    childrenId?: string;
}

function Bookings({ nurseryId, childrenName = "Children Name", childrenId }: Props) {
    const [modal, setModal] = useState<boolean>(false);
    const [pricingBandOptions, setPricingBandOptions] = useState<PricingBandOption[]>([]);
    const [secondModal, setSecondModal] = useState<Record<number, boolean>>({ 0: false, 1: false, 2: false, 3: false, 4: false });
    const [activeTab, setActiveTab] = useState<Record<number, string>>({
        0: '1',
        1: '1',
        2: '1',
        3: '1',
        4: '1'
    });
    const [discount, setDiscount] = useState<number>(0);
    const [discountType, setDiscountType] = useState<{ value: 'percentage' | 'amount', label: string }>({ value: 'percentage', label: '%' });
    const [notes, setNotes] = useState<string>('');
    const [daySchedules, setDaySchedules] = useState<Record<number, DaySchedule>>({
        0: { sessions: [], extraCharges: {} },
        1: { sessions: [], extraCharges: {} },
        2: { sessions: [], extraCharges: {} },
        3: { sessions: [], extraCharges: {} },
        4: { sessions: [], extraCharges: {} }
    });
    const [session, setSession] = useState<any[]>([]);
    const [tempSessions, setTempSessions] = useState<Session[]>([]);
    const [tempExtraCharges, setTempExtraCharges] = useState<Record<string, number>>({});
    const [filteredSessions, setFilteredSessions] = useState<Record<number, Session[]>>({
        0: [], // Monday
        1: [], // Tuesday
        2: [], // Wednesday
        3: [], // Thursday
        4: []  // Friday
    });
    const [filteredExtraCharges, setFilteredExtraCharges] = useState<Record<number, ExtraCharge[]>>({
        0: [], // Monday
        1: [], // Tuesday
        2: [], // Wednesday
        3: [], // Thursday
        4: []  // Friday
    });
    const [showClearConfirm, setShowClearConfirm] = useState(false);
    const [showPricingBandWarning, setShowPricingBandWarning] = useState(false);
    const [newPricingBandId, setNewPricingBandId] = useState<string>('');
    const [extraCharges, setExtraCharges] = useState<ExtraCharge[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState<string>('');
    const [activePlan, setActivePlan] = useState<any>(null);

    const fetchActivePlan = async () => {
        try {
            if (childrenId) {
                const response = await SessionAPi.getChildrenSessions({ childrenId });
                if (response.success && response.data && response.data.length > 0) {
                    setActivePlan(response.data[0]);
                }
            }
        } catch (error) {
            console.error('Error fetching active plan:', error);
        }
    };

    useEffect(() => {
        fetchActivePlan();
    }, [childrenId]);

    const calculateWeeklyHours = (schedules: any[]) => {
        let totalMinutes = 0;
        schedules.forEach(schedule => {
            const startTime = new Date(`2000-01-01T${schedule.startTime}`);
            const endTime = new Date(`2000-01-01T${schedule.endTime}`);
            const diffInMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
            totalMinutes += diffInMinutes;
        });
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    };

    const getProgressForDay = (schedules: any[], day: number, extraCharges: any[] = []) => {
        const progressBars: { value: number; color: string }[] = [];

        // Add regular sessions progress
        const daySchedules = schedules.filter(s => s.day === day);
        daySchedules.forEach(schedule => {
            const startTime = new Date(`2000-01-01T${schedule.startTime}`);
            const endTime = new Date(`2000-01-01T${schedule.endTime}`);
            const startHour = startTime.getHours();
            const endHour = endTime.getHours();
            const duration = endHour - startHour;
            progressBars.push({
                value: (duration / 15) * 100,
                color: schedule.color || 'primary'
            });
        });

        // Add extra charges progress
        const dayExtraCharges = extraCharges.filter(c => c.day === day);
        dayExtraCharges.forEach((charge, index) => {
            progressBars.push({
                value: 20, // Fixed width for extra charges
                color: 'info'
            });
        });

        return progressBars;
    };

    const loadExistingChildrenSession = async () => {
        try {
            if (childrenId) {
                const response = await SessionAPi.getChildrenSessions({ childrenId });
                console.log('response: ', response);
                if (response.success && response.data && response.data.length > 0) {
                    const sessionData = response.data[0]; // Get first session data

                    // Set form data
                    setValue('startDate', new Date(sessionData.startDate));
                    setValue('endDate', sessionData.endDate ? new Date(sessionData.endDate) : null);
                    setValue('pricingBandId', sessionData.pricingBandId);
                    setValue('invoicingProfile', sessionData.invoicingProfile);
                    setId(sessionData.id);
                    // Initialize daySchedules object
                    const newDaySchedules: Record<number, DaySchedule> = {
                        0: { sessions: [], extraCharges: {} },
                        1: { sessions: [], extraCharges: {} },
                        2: { sessions: [], extraCharges: {} },
                        3: { sessions: [], extraCharges: {} },
                        4: { sessions: [], extraCharges: {} }
                    };

                    // Process regular sessions
                    sessionData.schedules.forEach((schedule: any) => {
                        if (schedule.day >= 0 && schedule.day <= 4) {
                            newDaySchedules[schedule.day].sessions.push({
                                id: schedule.sessionId,
                                title: schedule.title || 'Session',
                                startTime: schedule.startTime,
                                endTime: schedule.endTime,
                                price: schedule.price,
                                type: schedule.type || 'fixed',
                                color: schedule.color || 'primary',
                                frequency: schedule.frequency || 'Weekly'
                            });
                        }
                    });

                    // Process extra charges
                    sessionData.extraCharges.forEach((charge: any) => {
                        if (charge.day >= 0 && charge.day <= 4) {
                            // Get price for this charge from priceBandAmountForSession
                            const priceInfo = charge.session.priceBandAmountForSession.find(
                                (price: any) => price.priceBandId === sessionData.pricingBandId
                            );
                            console.log('priceInfo: ', priceInfo);

                            // Add to extraCharges array for the UI
                            const extraCharge: ExtraCharge = {
                                id: charge.extraChargeId,
                                title: charge.session.title,
                                price: priceInfo ? priceInfo.amount : 0,
                                color: 'primary',
                                quantity: charge.amount
                            };
                            setExtraCharges(prev => [...prev, extraCharge]);

                            // Add to daySchedules for the selected day
                            newDaySchedules[charge.day].extraCharges[charge.extraChargeId] = charge.amount;
                        }
                    });

                    // Set state values
                    setDaySchedules(newDaySchedules);
                    setDiscount(sessionData.discount || 0);
                    setDiscountType({
                        value: sessionData.discountType || 'percentage',
                        label: sessionData.discountType === 'amount' ? '£' : '%'
                    });
                    setNotes(sessionData.notes || '');
                }
            }
        } catch (error) {
            setId('');
            console.error('Error loading existing session:', error);
        }
    };

    const handleModal = () => {
        if (!modal) {
            // When opening modal
            loadExistingChildrenSession();
        } else {
            // Reset all form data when closing modal
            setDaySchedules({
                0: { sessions: [], extraCharges: {} },
                1: { sessions: [], extraCharges: {} },
                2: { sessions: [], extraCharges: {} },
                3: { sessions: [], extraCharges: {} },
                4: { sessions: [], extraCharges: {} }
            });
            setDiscount(0);
            setDiscountType({ value: 'percentage', label: '%' });
            setNotes('');
            setId('');
            setValue('startDate', null);
            setValue('endDate', null);
            setValue('pricingBandId', '');
            setValue('invoicingProfile', '');
        }
        setModal(!modal);
    };

    const handlSecondModal = (id: number) => {
        setSecondModal({ ...secondModal, [id]: !secondModal[id] });
    };

    const handleSecondModalOpen = (id: number) => {
        setTempSessions(daySchedules[id].sessions);
        setTempExtraCharges(daySchedules[id].extraCharges);

        // Get the current pricing band ID
        const priceBandId = watch('pricingBandId');

        // Update extraCharges array with all available extra charges
        if (session.length > 0) {
            const newExtraCharges: ExtraCharge[] = [];
            session.forEach(s => {
                if (s.isAdditionalCharges) {
                    const priceInfo = s.priceBandAmountForSession.find(
                        (price: any) => price.priceBandId === priceBandId
                    );
                    if (priceInfo) {
                        newExtraCharges.push({
                            id: s.id,
                            title: s.title,
                            price: priceInfo.amount,
                            color: 'primary'
                        });
                    }
                }
            });
            setExtraCharges(newExtraCharges);
        }

        setSecondModal({ ...secondModal, [id]: true });
    };

    const handleSecondModalClose = (id: number) => {
        setTempSessions([]);
        setTempExtraCharges({});
        setSecondModal({ ...secondModal, [id]: false });
    };

    const handleMakeChanges = (id: number) => {
        // Update day schedules with both sessions and extra charges
        const updatedDaySchedules = {
            ...daySchedules,
            [id]: {
                sessions: tempSessions,
                extraCharges: tempExtraCharges
            }
        };
        setDaySchedules(updatedDaySchedules);

        // Keep the existing extra charges in state
        const existingExtraCharges = extraCharges;
        setExtraCharges(existingExtraCharges);

        setSecondModal({ ...secondModal, [id]: false });
    };

    const handleClearAll = () => {
        setDaySchedules({
            0: { sessions: [], extraCharges: {} },
            1: { sessions: [], extraCharges: {} },
            2: { sessions: [], extraCharges: {} },
            3: { sessions: [], extraCharges: {} },
            4: { sessions: [], extraCharges: {} }
        });
        setDiscount(0);
        setShowClearConfirm(false);
    };

    const handlePricingBandChange = (selectedOption: any) => {
        const hasSchedules = Object.values(daySchedules).some(
            schedule => schedule.sessions.length > 0 || Object.keys(schedule.extraCharges).length > 0
        );

        if (hasSchedules) {
            setNewPricingBandId(selectedOption.value);
            setShowPricingBandWarning(true);
        } else {
            setValue('pricingBandId', selectedOption.value);
        }
    };

    const confirmPricingBandChange = () => {
        setValue('pricingBandId', newPricingBandId);
        setShowPricingBandWarning(false);
        handleClearAll();
    };

    useEffect(() => {
        const fetchPricingBrands = async () => {
            try {
                const response = await PricingBrandsAPi.getPriceBand({
                    nurseryId,
                });
                if (response.success && Array.isArray(response.data)) {
                    const formattedData = response.data.map((row: any) => ({
                        label: row.title,
                        value: row.id,
                    }));
                    setPricingBandOptions(formattedData);
                } else {
                    setPricingBandOptions([]);
                }
            } catch (error) {
                setPricingBandOptions([]);
                console.error('Error fetching pricing bands:', error);
            }
        };
        fetchPricingBrands();
    }, [nurseryId]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch
    } = useForm<Booking>({});

    const fetchSessions = async () => {
        try {
            const response = await SessionAPi.getAllSessions({
                nurseryId: nurseryId,
            });
            if (response.success && Array.isArray(response.data.sessions)) {
                setSession(response.data.sessions);
            } else setSession([]);
        } catch (error: any) {
            setSession([]);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, [nurseryId]);

    const filterSessionsByPriceBandAndDay = (priceBandId: string, allSessions: any[]) => {
        const dayMapping: Record<string, number> = {
            'Monday': 0,
            'Tuesday': 1,
            'Wednesday': 2,
            'Thursday': 3,
            'Friday': 4
        };

        // Reset filtered sessions
        const newFilteredSessions: Record<number, Session[]> = {
            0: [], 1: [], 2: [], 3: [], 4: []
        };

        // Reset extra charges
        const newFilteredExtraCharges: Record<number, ExtraCharge[]> = {
            0: [], 1: [], 2: [], 3: [], 4: []
        };

        allSessions.forEach(session => {
            // Check if session has the selected pricing band
            const hasPriceBand = session.priceBands.some((pb: any) => pb.id === priceBandId);

            if (hasPriceBand) {
                // Get price for this session from priceBandAmountForSession
                const priceInfo = session.priceBandAmountForSession.find(
                    (price: any) => price.priceBandId === priceBandId
                );

                if (session.isAdditionalCharges) {
                    // Process each availability for extra charges
                    session.sessionAvailability.forEach((availability: any) => {
                        const dayIndex = dayMapping[availability.day];
                        if (dayIndex !== undefined) {
                            // Add to extra charges
                            newFilteredExtraCharges[dayIndex].push({
                                id: session.id,
                                title: session.title,
                                price: priceInfo ? priceInfo.amount : 0,
                                color: 'primary'
                            });
                        }
                    });
                } else {
                    // Process each availability for regular sessions
                    session.sessionAvailability.forEach((availability: any) => {
                        const dayIndex = dayMapping[availability.day];
                        if (dayIndex !== undefined) {
                            // Convert time strings to HH:mm format
                            const startTime = new Date(availability.startTime);
                            const endTime = new Date(availability.endTime);

                            const sessionObj: Session = {
                                id: session.id,
                                title: session.title,
                                startTime: `${startTime.getHours().toString().padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}`,
                                endTime: `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`,
                                price: priceInfo ? priceInfo.amount : 0,
                                type: 'fixed',
                                color: 'primary',
                                frequency: 'Weekly'
                            };

                            newFilteredSessions[dayIndex].push(sessionObj);
                        }
                    });
                }
            }
        });

        setFilteredSessions(newFilteredSessions);
        setFilteredExtraCharges(newFilteredExtraCharges);
    };

    useEffect(() => {
        const priceBandId = watch('pricingBandId');
        if (priceBandId && session.length > 0) {
            filterSessionsByPriceBandAndDay(priceBandId, session);
        }
    }, [watch('pricingBandId'), session]);

    const handleSessionSelect = (session: Session) => {
        if (tempSessions.find(s => s.id === session.id)) {
            setTempSessions(tempSessions.filter(s => s.id !== session.id));
        } else {
            setTempSessions([...tempSessions, session]);
        }
    };

    const handleExtraChargeSelect = (chargeId: string) => {
        const newCharges = { ...tempExtraCharges };
        if (chargeId in tempExtraCharges) {
            delete newCharges[chargeId];
        } else {
            newCharges[chargeId] = 1;
        }
        setTempExtraCharges(newCharges);
    };

    const handleExtraChargeQuantity = (chargeId: string, quantity: number) => {
        if (quantity === 0) {
            const newCharges = { ...tempExtraCharges };
            delete newCharges[chargeId];
            setTempExtraCharges(newCharges);
        } else {
            setTempExtraCharges({
                ...tempExtraCharges,
                [chargeId]: quantity
            });
        }
    };

    const getProgressWidth = (startTime: string, endTime: string) => {
        const start = parseInt(startTime.split(':')[0]);
        const end = parseInt(endTime.split(':')[0]);
        const duration = end - start;
        // Calculate width based on 15-hour day (5 AM to 8 PM)
        return (duration / 15) * 100;
    };

    const getProgressPosition = (startTime: string) => {
        const start = parseInt(startTime.split(':')[0]);
        // Adjust position based on 5 AM start
        return ((start - 5) / 15) * 100;
    };

    const isOverlapping = (session1: Session, session2: Session) => {
        const start1 = parseInt(session1.startTime.split(':')[0]);
        const end1 = parseInt(session1.endTime.split(':')[0]);
        const start2 = parseInt(session2.startTime.split(':')[0]);
        const end2 = parseInt(session2.endTime.split(':')[0]);

        return (start1 < end2 && end1 > start2);
    };

    const getSessionGroups = (sessions: Session[]) => {
        const groups: Session[][] = [];

        sessions.forEach(session => {
            let added = false;

            // Try to add to existing group
            for (let group of groups) {
                const hasOverlap = group.some(groupSession =>
                    isOverlapping(session, groupSession)
                );

                if (!hasOverlap) {
                    group.push(session);
                    added = true;
                    break;
                }
            }

            // If couldn't add to any existing group, create new group
            if (!added) {
                groups.push([session]);
            }
        });

        return groups;
    };

    const calculateSubtotal = () => {
        let total = 0;

        // Calculate sessions total from all days
        Object.values(daySchedules).forEach(schedule => {
            total += schedule.sessions.reduce((sum, session) => sum + session.price, 0);

            // Calculate extra charges total for each day
            Object.entries(schedule.extraCharges).forEach(([chargeId, quantity]) => {
                const charge = extraCharges.find(c => c.id === chargeId);
                if (charge) {
                    total += charge.price * quantity;
                }
            });
        });

        return total;
    };

    const calculateDiscount = () => {
        const subtotal = calculateSubtotal();
        if (discountType.value === 'percentage') {
            return (subtotal * discount) / 100;
        }
        return discount;
    };

    const calculateTotal = () => {
        return calculateSubtotal() - calculateDiscount();
    };

    const getDayDuration = (sessions: Session[]) => {
        if (sessions.length === 0) return '';
        const times = sessions.map(s => ({
            start: s.startTime ? parseInt(s.startTime.split(':')[0]) : 0,
            end: s.endTime ? parseInt(s.endTime.split(':')[0]) : 0
        }));
        const minStart = Math.min(...times.map(t => t.start));
        const maxEnd = Math.max(...times.map(t => t.end));
        return `${minStart.toString().padStart(2, '0')}:00 - ${maxEnd.toString().padStart(2, '0')}:00`;
    };

    const onSubmit = async (data: Booking) => {
        try {
            setIsLoading(true);
            const formData = {
                ...data,
                daySchedules,
                discount,
                discountType: discountType.value,
                notes,
                nurseryId,
                childrenId,
                totalAmount: calculateTotal()
            };

            setTimeout(async () => {
                console.log('Form Data:', formData);
                let response;
                if (id) {
                    response = await SessionAPi.updateChildrenSession({ ...formData, id });
                } else {
                    response = await SessionAPi.createChildrenSession(formData);
                }
                console.log('Response:', response);
                if (response.success) {
                    Swal.fire({
                        title: "Success!",
                        text: id ? "Session Updated successfully" : "Session Created successfully",
                        icon: "success",
                    }).then(async () => {
                        await UserActivityAPi.addUserActivity(
                            getUserActivityFormattedData(
                                "Children",
                                id ? "Session Updated successfully" : "Session Created successfully"
                            )
                        );
                        // Refresh active plan data after successful submission
                        await fetchActivePlan();
                        handleModal();
                    });
                } else {
                    toast.error(response.message);
                }
            }, 2000);
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2300);
        }
    };

    return (
        <Col md={12} xl={12}>
            <Card className="card-absolute">
                <CommonCardHeader
                    headClass="bg-primary"
                    titleClass="text-light"
                    title={"Bookings"}
                />
                <CardBody>
                    <Row className='mt-4'>
                        <Col md={12} xl={12}>
                            <div className="ribbon-wrapper border border-1" style={{ padding: "20px 15px 25px" }}>
                                <Ribbon className="ribbon ribbon-info ribbon-clip">Plans</Ribbon>
                                <div>
                                    <div style={{ textAlign: "end", paddingBottom: "15px" }}>
                                        <Button type='button' color='primary' outline onClick={handleModal}>Create New Plan</Button>
                                    </div>
                                    {activePlan ? (
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <p className='f-w-700'>{new Date(activePlan.startDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                                    <p className='text-muted'>Active</p>
                                                </div>
                                                <div>
                                                    <p className='f-w-700'>£{activePlan.totalAmount}/month</p>
                                                    <p className='text-muted'>{calculateWeeklyHours(activePlan.schedules)}/week</p>
                                                </div>
                                            </div>
                                            {[0, 1, 2, 3, 4].map(day => (
                                                <div key={day} className='d-flex justify-content-start gap-2 pt-3'>
                                                    <span className='f-w-700 text-muted' style={{ width: "20px" }}>
                                                        {['M', 'T', 'W', 'T', 'F'][day]}
                                                    </span>
                                                    <ProgressBar multi className='w-100'>
                                                        {getProgressForDay(activePlan.schedules, day, activePlan.extraCharges).map((progress, index) => (
                                                            <ProgressBar
                                                                key={index}
                                                                value={progress.value}
                                                                color={progress.color}
                                                                bar
                                                            />
                                                        ))}
                                                    </ProgressBar>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="text-center p-4">
                                            <p className="text-muted mb-0">No active plans</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col md={4} xl={4}>
                            <div className="ribbon-wrapper border border-1 shadow-sm" style={{ padding: "60px 30px 30px", textAlign: "center" }}>
                                <Ribbon className="ribbon ribbon-danger ribbon-clip">Bill Payers</Ribbon>
                                <FaUser className='badge-light-primary mb-2' style={{ fontSize: "48px", padding: "10px" }} />
                                <p>dummy text</p>
                                <Button color='primary' outline size='md' className='mt-2'>Add Bill Payer</Button>
                            </div>
                        </Col>

                        <Col md={4} xl={4}>
                            <div className="ribbon-wrapper border border-1 shadow-sm" style={{ padding: "60px 30px 30px", textAlign: "center" }}>
                                <Ribbon className="ribbon ribbon-success ribbon-clip">Purchases</Ribbon>
                                <FaUser className='badge-light-primary mb-2' style={{ fontSize: "48px", padding: "10px" }} />
                                <p>dummy text</p>
                                <Button color='primary' outline size='md' className='mt-2'>Add Purchase</Button>
                            </div>
                        </Col>
                        <Col md={4} xl={4}>
                            <div className="ribbon-wrapper border border-1 shadow-sm" style={{ padding: "60px 30px 30px", textAlign: "center" }}>
                                <Ribbon className="ribbon ribbon-primary ribbon-clip">Deposits</Ribbon>
                                <FaUser className='badge-light-primary mb-2' style={{ fontSize: "48px", padding: "10px" }} />
                                <p>dummy text</p>
                                <Button color='primary' outline size='md' className='mt-2'>Add Deposit</Button>
                            </div>
                        </Col>
                    </Row>

                    <Modal isOpen={modal} toggle={handleModal} backdrop="static" size="xl">
                        <ModalHeader toggle={handleModal} className="bg-primary text-white">
                            <i className="fa fa-user me-2"></i>
                            {childrenName}
                        </ModalHeader>
                        <ModalBody className="p-4">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={6} className="position-relative">
                                        <Label>
                                            {"Plan Period"} <span className="text-danger">*</span>
                                        </Label>
                                        <div className='d-flex gap-4'>
                                            <div className='w-100'>
                                                <InputGroup className="flatpicker-calender">
                                                    <Controller
                                                        control={control}
                                                        name="startDate"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field }) => {
                                                            return (
                                                                <DatePicker
                                                                    className={`form-control flatpickr-input ${errors.startDate ? "is-invalid" : ""
                                                                        }`}
                                                                    selected={field.value}
                                                                    onChange={(date) => {
                                                                        field.onChange(date);
                                                                        // saveDataForm("startDate", date);
                                                                    }}
                                                                    placeholderText="Select start date"
                                                                    dateFormat="MMMM dd,yyyy"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.startDate && (
                                                    <FormFeedback
                                                        style={{ display: "block", color: "orangeRed" }}
                                                    >
                                                        {"Please select start date."}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                            <div className='w-100'>
                                                <InputGroup className="flatpicker-calender">
                                                    <Controller
                                                        control={control}
                                                        name="endDate"
                                                        rules={{
                                                            required: false,
                                                        }}
                                                        render={({ field }) => {
                                                            return (
                                                                <DatePicker
                                                                    className={`form-control flatpickr-input ${errors.endDate ? "is-invalid" : ""
                                                                        }`}
                                                                    selected={field.value}
                                                                    onChange={(date) => {
                                                                        field.onChange(date);
                                                                        // saveDataForm("endDate", date);
                                                                    }}
                                                                    placeholderText="Select end date"
                                                                    dateFormat="MMMM dd,yyyy"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.endDate && (
                                                    <FormFeedback
                                                        style={{ display: "block", color: "orangeRed" }}
                                                    >
                                                        {"Please select end date."}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={3} className="position-relative">
                                        <Label>
                                            {"Pricing Band"} <span className="text-danger">*</span>
                                        </Label>
                                        <Controller
                                            name="pricingBandId"
                                            control={control}
                                            rules={{
                                                required: "Please select a pricing band",
                                            }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    id="pricingBandId"
                                                    className={`${errors.pricingBandId ? "is-invalid" : ""}`}
                                                    options={pricingBandOptions}
                                                    value={pricingBandOptions.find(
                                                        (option: any) => option.value === field.value
                                                    ) || null}
                                                    onChange={handlePricingBandChange}
                                                />
                                            )}
                                        />
                                        {errors.pricingBandId && (
                                            <FormFeedback
                                                style={{ display: "block", color: "orangeRed" }}
                                            >
                                                <>{errors?.pricingBandId?.message}</>
                                            </FormFeedback>
                                        )}
                                    </Col>

                                    <Col md={3} className="position-relative">
                                        <Label>
                                            {"Invoicing Profile"} <span className="text-danger">*</span>
                                        </Label>
                                        <Controller
                                            name="invoicingProfile"
                                            control={control}
                                            rules={{
                                                required: "Please select invoicing profile",
                                            }} // Validation rules
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    id="invoicingProfile"
                                                    className={`${errors.invoicingProfile ? "is-invalid" : ""
                                                        }`}
                                                    options={[
                                                        { value: "term-time-only-care", label: "Term-Time Only Care" },
                                                        { value: "childcare-grants-scheme", label: "Childcare Grants Scheme" },
                                                        { value: "full-year-care", label: "Full Year Care" },
                                                        { value: "invoice-type", label: "Invoice Type" },
                                                    ]} // invoicingProfile options
                                                    value={
                                                        [
                                                            { value: "term-time-only-care", label: "Term-Time Only Care" },
                                                            { value: "childcare-grants-scheme", label: "Childcare Grants Scheme" },
                                                            { value: "full-year-care", label: "Full Year Care" },
                                                            { value: "invoice-type", label: "Invoice Type" },
                                                        ].find(
                                                            (option) => option.value === field.value
                                                        ) || null
                                                    } // Set current value, handle null
                                                    onChange={(selectedOption) => {
                                                        // Check if selectedOption is not null
                                                        if (selectedOption) {
                                                            field.onChange(selectedOption.value); // Update the field value
                                                            // saveDataForm(
                                                            //     "invoicingProfile",
                                                            //     selectedOption.value
                                                            // ); // Save the data
                                                        }
                                                    }} // Handle value change
                                                />
                                            )}
                                        />
                                        {errors.invoicingProfile && (
                                            <FormFeedback
                                                style={{ display: "block", color: "orangeRed" }}
                                            >
                                                <>{errors?.invoicingProfile?.message}</>
                                            </FormFeedback>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col md={8}>
                                        <div className="border rounded p-3">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h6 className="mb-0">Weekly Schedule</h6>
                                                <Button color="danger" size="sm" outline onClick={() => setShowClearConfirm(true)}>
                                                    <i className="fa fa-trash me-1"></i>Clear All
                                                </Button>
                                            </div>
                                            <div className="d-flex flex-column gap-3">
                                                {Object.values(daySchedules).map((schedule, dayIndex) => (
                                                    <div key={dayIndex} className='w-100'>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <span className="text-muted" style={{ width: '80px' }}>{['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'][dayIndex]}</span>
                                                            <div className="flex-grow-1">
                                                                {Object.keys(schedule.extraCharges).length > 0 && (
                                                                    <div style={{ height: '30px', background: '#f8f9fa', borderRadius: '4px', position: 'relative', marginBottom: '4px' }}>
                                                                        {Object.entries(schedule.extraCharges).map(([chargeId, quantity], index) => {
                                                                            const charge = extraCharges.find(c => c.id === chargeId);
                                                                            console.log('charge: ', charge, extraCharges, chargeId, quantity, index);
                                                                            if (charge && quantity > 0) {
                                                                                return (
                                                                                    <div
                                                                                        key={chargeId}
                                                                                        className={`position-absolute bg-${charge.color}`}
                                                                                        style={{
                                                                                            left: `${(index * 30)}%`,
                                                                                            width: '28%',
                                                                                            height: '100%',
                                                                                            opacity: 0.8,
                                                                                            borderRadius: '4px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            paddingLeft: '8px',
                                                                                            whiteSpace: 'nowrap',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis'
                                                                                        }}
                                                                                    >
                                                                                        <small className="text-white">{quantity}x {charge.title}</small>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </div>
                                                                )}
                                                                <div className="position-relative" style={{
                                                                    height: 'auto',
                                                                    background: '#f8f9fa',
                                                                    borderRadius: '4px',
                                                                    minHeight: '30px'
                                                                }}>
                                                                    {getSessionGroups(schedule.sessions)
                                                                        .map((group, groupIndex) => (
                                                                            <div key={groupIndex} className="position-relative" style={{ height: '30px', marginBottom: '2px' }}>
                                                                                {group.map((session) => (
                                                                                    <div
                                                                                        key={session.id}
                                                                                        className={`position-absolute bg-${session.color}`}
                                                                                        style={{
                                                                                            left: `${getProgressPosition(session.startTime)}%`,
                                                                                            width: `${getProgressWidth(session.startTime, session.endTime)}%`,
                                                                                            height: '30px',
                                                                                            opacity: 0.8,
                                                                                            borderRadius: '4px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            paddingLeft: '8px',
                                                                                            whiteSpace: 'nowrap',
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis'
                                                                                        }}
                                                                                    >
                                                                                        <small className="text-white">{session.title}</small>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            {schedule.sessions.length > 0 || Object.keys(schedule.extraCharges).length > 0 ? (
                                                                <Button color="primary" size="sm" outline onClick={() => handleSecondModalOpen(dayIndex)}>
                                                                    <i className="fa fa-edit me-1"></i>Edit
                                                                </Button>
                                                            ) : (
                                                                <Button color="light" size="sm" className="text-dark" onClick={() => handleSecondModalOpen(dayIndex)}>
                                                                    <i className="fa fa-plus me-1"></i>Add
                                                                </Button>
                                                            )}
                                                        </div>
                                                        {(schedule.sessions.length > 0 || Object.keys(schedule.extraCharges).length > 0) && (
                                                            <small className="text-muted mt-1 d-block">
                                                                {getDayDuration(schedule.sessions)}
                                                                {Object.keys(schedule.extraCharges).length > 0 && ' + Extra Charges'}
                                                            </small>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="border rounded p-3">
                                            <h6 className="mb-3">Selected Sessions & Charges</h6>

                                            <div style={{ display: 'flex', flexDirection: 'column', height: '465px' }}>
                                                <div style={{ flex: '1', overflowY: 'auto', marginBottom: '10px' }}>
                                                    <table className="table">
                                                        <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 0 }}>
                                                            <tr>
                                                                <th>Session/Extra Charge</th>
                                                                <th className="text-end">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(daySchedules).map(([dayIndex, schedule]) => (
                                                                <>
                                                                    {schedule.sessions.map(session => (
                                                                        <tr key={`${dayIndex}-${session.id}`}>
                                                                            <td>{'1'}x {session.title}</td>
                                                                            <td className="text-end">£{session.price}</td>
                                                                        </tr>
                                                                    ))}
                                                                    {Object.entries(schedule.extraCharges).map(([chargeId, quantity]) => {
                                                                        const charge = extraCharges.find(c => c.id === chargeId);
                                                                        if (charge && quantity > 0) {
                                                                            return (
                                                                                <tr key={`${dayIndex}-${chargeId}`}>
                                                                                    <td>{quantity}x {charge.title}</td>
                                                                                    <td className="text-end">£{charge.price * quantity}</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div style={{ borderTop: '1px solid #dee2e6' }}>
                                                    <table className="table mb-0">
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan={3} className="text-end fw-bold">Subtotal</td>
                                                                <td className="text-end" style={{ width: '100px' }}>£{calculateSubtotal()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={3} className="text-end">
                                                                    <div className="d-flex align-items-center justify-content-end gap-2">
                                                                        <span style={{ fontSize: '12px', fontWeight: '700' }}>Discount</span>
                                                                        <div style={{ width: '65px' }}>
                                                                            <Input
                                                                                type="number"
                                                                                value={discount}
                                                                                onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
                                                                                className="form-control-sm"
                                                                            />
                                                                        </div>
                                                                        <Select
                                                                            value={discountType}
                                                                            onChange={(value) => {
                                                                                if (value) {
                                                                                    setDiscountType(value);
                                                                                }
                                                                            }}
                                                                            options={[
                                                                                { value: 'percentage', label: '%' },
                                                                                { value: 'amount', label: '£' }
                                                                            ]}
                                                                            className="discount-select"
                                                                            styles={{
                                                                                control: (base) => ({
                                                                                    ...base,
                                                                                    minHeight: '31px',
                                                                                    width: '75px'
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="text-end">-£{calculateDiscount()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={3} className="text-end fw-bold">Total</td>
                                                                <td className="text-end fw-bold">£{calculateTotal()}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col md={12}>
                                        <div className="form-group">
                                            <Label>Notes</Label>
                                            <Input
                                                type="textarea"
                                                rows={4}
                                                placeholder="Add any additional notes here..."
                                                value={notes}
                                                onChange={(e) => setNotes(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-end">
                                        <Button type="button" color="light" className="me-2 text-dark" onClick={handleModal}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" color="primary" disabled={isLoading}>
                                            {isLoading ? (
                                                <span className="d-flex gap-2">
                                                    <Spinner color="white" size="sm" /> Loading...
                                                </span>
                                            ) : (
                                                <>{id ? "Update & Save" : "Save & List"}</>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                    {/* Clear All Confirmation Modal */}
                    <Modal isOpen={showClearConfirm} toggle={() => setShowClearConfirm(false)} size="sm">
                        <ModalHeader toggle={() => setShowClearConfirm(false)} className="bg-danger text-white">
                            Clear All Schedules
                        </ModalHeader>
                        <ModalBody>
                            <p>Are you sure you want to clear all weekly schedules? This action cannot be undone.</p>
                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" onClick={() => setShowClearConfirm(false)} className="text-dark">Cancel</Button>
                                <Button color="danger" onClick={handleClearAll}>Clear All</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    {/* Pricing Band Change Warning Modal */}
                    <Modal isOpen={showPricingBandWarning} toggle={() => setShowPricingBandWarning(false)} size="sm">
                        <ModalHeader toggle={() => setShowPricingBandWarning(false)} className="bg-warning text-white">
                            Warning
                        </ModalHeader>
                        <ModalBody>
                            <p>Changing the pricing band will clear all current schedules. Do you want to continue?</p>
                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" onClick={() => setShowPricingBandWarning(false)} className="text-dark">Cancel</Button>
                                <Button color="warning" onClick={confirmPricingBandChange}>Continue</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={secondModal[0]} toggle={() => handlSecondModal(0)} backdrop="static" size="xl">
                        <ModalHeader toggle={() => handlSecondModal(0)} className="bg-primary text-white">
                            Monday Schedule
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[0] === '1' })}
                                            onClick={() => setActiveTab({ ...activeTab, 0: '1' })}
                                        >
                                            Sessions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[0] === '2' })}
                                            onClick={() => setActiveTab({ ...activeTab, 0: '2' })}
                                        >
                                            Extra charges
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab[0]}>
                                <TabPane tabId="1">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredSessions[0].length > 0 ? (
                                                filteredSessions[0].map((session) => (
                                                    <Card key={session.id} className={`p-3 ${tempSessions.find(s => s.id === session.id) ? 'border-primary' : ''} shadow`} style={{ width: '200px', cursor: 'pointer' }} onClick={() => handleSessionSelect(session)}>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{session.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={tempSessions.find(s => s.id === session.id) !== undefined}
                                                                onChange={() => handleSessionSelect(session)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <small className="text-muted d-block">{session.startTime} - {session.endTime}</small>
                                                        <small className="text-muted d-block">{session.frequency}</small>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${session.color}`}>£{session.price}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No sessions available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredExtraCharges[0].length > 0 ? (
                                                filteredExtraCharges[0].map((charge) => (
                                                    <Card
                                                        key={charge.id}
                                                        className={`p-3 ${charge.id in tempExtraCharges ? 'border-primary' : ''} shadow`}
                                                        style={{ width: '200px', cursor: 'pointer' }}
                                                        onClick={() => handleExtraChargeSelect(charge.id)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{charge.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={charge.id in tempExtraCharges}
                                                                onChange={() => handleExtraChargeSelect(charge.id)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${charge.color}`}>£{charge.price}</span>
                                                        </div>
                                                        {charge.id in tempExtraCharges && (
                                                            <div className="mt-3 d-flex align-items-center">
                                                                <div className="d-flex align-items-center border rounded">
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, Math.max(0, (tempExtraCharges[charge.id] || 0) - 1));
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Input
                                                                        type="number"
                                                                        value={tempExtraCharges[charge.id] || 0}
                                                                        onChange={(e) => handleExtraChargeQuantity(charge.id, Math.max(0, parseInt(e.target.value) || 0))}
                                                                        min="0"
                                                                        style={{
                                                                            width: '50px',
                                                                            textAlign: 'center',
                                                                            border: 'none',
                                                                            background: 'transparent'
                                                                        }}
                                                                        className="form-control-sm"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    />
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, (tempExtraCharges[charge.id] || 0) + 1);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No extra charges available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" className='text-dark' onClick={() => handleSecondModalClose(0)}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={() => handleMakeChanges(0)}>
                                    Make changes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={secondModal[1]} toggle={() => handlSecondModal(1)} backdrop="static" size="xl">
                        <ModalHeader toggle={() => handlSecondModal(1)} className="bg-primary text-white">
                            Tuesday Schedule
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[1] === '1' })}
                                            onClick={() => setActiveTab({ ...activeTab, 1: '1' })}
                                        >
                                            Sessions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[1] === '2' })}
                                            onClick={() => setActiveTab({ ...activeTab, 1: '2' })}
                                        >
                                            Extra charges
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab[1]}>
                                <TabPane tabId="1">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredSessions[1].length > 0 ? (
                                                filteredSessions[1].map((session) => (
                                                    <Card key={session.id} className={`p-3 ${tempSessions.find(s => s.id === session.id) ? 'border-primary' : ''} shadow`} style={{ width: '200px', cursor: 'pointer' }} onClick={() => handleSessionSelect(session)}>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{session.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={tempSessions.find(s => s.id === session.id) !== undefined}
                                                                onChange={() => handleSessionSelect(session)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <small className="text-muted d-block">{session.startTime} - {session.endTime}</small>
                                                        <small className="text-muted d-block">{session.frequency}</small>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${session.color}`}>£{session.price}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No sessions available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredExtraCharges[1].length > 0 ? (
                                                filteredExtraCharges[1].map((charge) => (
                                                    <Card
                                                        key={charge.id}
                                                        className={`p-3 ${charge.id in tempExtraCharges ? 'border-primary' : ''} shadow`}
                                                        style={{ width: '200px', cursor: 'pointer' }}
                                                        onClick={() => handleExtraChargeSelect(charge.id)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{charge.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={charge.id in tempExtraCharges}
                                                                onChange={() => handleExtraChargeSelect(charge.id)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${charge.color}`}>£{charge.price}</span>
                                                        </div>
                                                        {charge.id in tempExtraCharges && (
                                                            <div className="mt-3 d-flex align-items-center">
                                                                <div className="d-flex align-items-center border rounded">
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, Math.max(0, (tempExtraCharges[charge.id] || 0) - 1));
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Input
                                                                        type="number"
                                                                        value={tempExtraCharges[charge.id] || 0}
                                                                        onChange={(e) => handleExtraChargeQuantity(charge.id, Math.max(0, parseInt(e.target.value) || 0))}
                                                                        min="0"
                                                                        style={{
                                                                            width: '50px',
                                                                            textAlign: 'center',
                                                                            border: 'none',
                                                                            background: 'transparent'
                                                                        }}
                                                                        className="form-control-sm"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    />
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, (tempExtraCharges[charge.id] || 0) + 1);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No extra charges available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" className='text-dark' onClick={() => handleSecondModalClose(1)}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={() => handleMakeChanges(1)}>
                                    Make changes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={secondModal[2]} toggle={() => handlSecondModal(2)} backdrop="static" size="xl">
                        <ModalHeader toggle={() => handlSecondModal(2)} className="bg-primary text-white">
                            Wednesday Schedule
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[2] === '1' })}
                                            onClick={() => setActiveTab({ ...activeTab, 2: '1' })}
                                        >
                                            Sessions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[2] === '2' })}
                                            onClick={() => setActiveTab({ ...activeTab, 2: '2' })}
                                        >
                                            Extra charges
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab[2]}>
                                <TabPane tabId="1">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredSessions[2].length > 0 ? (
                                                filteredSessions[2].map((session) => (
                                                    <Card key={session.id} className={`p-3 ${tempSessions.find(s => s.id === session.id) ? 'border-primary' : ''} shadow`} style={{ width: '200px', cursor: 'pointer' }} onClick={() => handleSessionSelect(session)}>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{session.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={tempSessions.find(s => s.id === session.id) !== undefined}
                                                                onChange={() => handleSessionSelect(session)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <small className="text-muted d-block">{session.startTime} - {session.endTime}</small>
                                                        <small className="text-muted d-block">{session.frequency}</small>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${session.color}`}>£{session.price}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No sessions available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredExtraCharges[2].length > 0 ? (
                                                filteredExtraCharges[2].map((charge) => (
                                                    <Card
                                                        key={charge.id}
                                                        className={`p-3 ${charge.id in tempExtraCharges ? 'border-primary' : ''} shadow`}
                                                        style={{ width: '200px', cursor: 'pointer' }}
                                                        onClick={() => handleExtraChargeSelect(charge.id)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{charge.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={charge.id in tempExtraCharges}
                                                                onChange={() => handleExtraChargeSelect(charge.id)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${charge.color}`}>£{charge.price}</span>
                                                        </div>
                                                        {charge.id in tempExtraCharges && (
                                                            <div className="mt-3 d-flex align-items-center">
                                                                <div className="d-flex align-items-center border rounded">
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, Math.max(0, (tempExtraCharges[charge.id] || 0) - 1));
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Input
                                                                        type="number"
                                                                        value={tempExtraCharges[charge.id] || 0}
                                                                        onChange={(e) => handleExtraChargeQuantity(charge.id, Math.max(0, parseInt(e.target.value) || 0))}
                                                                        min="0"
                                                                        style={{
                                                                            width: '50px',
                                                                            textAlign: 'center',
                                                                            border: 'none',
                                                                            background: 'transparent'
                                                                        }}
                                                                        className="form-control-sm"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    />
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, (tempExtraCharges[charge.id] || 0) + 1);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No extra charges available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" className='text-dark' onClick={() => handleSecondModalClose(2)}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={() => handleMakeChanges(2)}>
                                    Make changes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={secondModal[3]} toggle={() => handlSecondModal(3)} backdrop="static" size="xl">
                        <ModalHeader toggle={() => handlSecondModal(3)} className="bg-primary text-white">
                            Thursday Schedule
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[3] === '1' })}
                                            onClick={() => setActiveTab({ ...activeTab, 3: '1' })}
                                        >
                                            Sessions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[3] === '2' })}
                                            onClick={() => setActiveTab({ ...activeTab, 3: '2' })}
                                        >
                                            Extra charges
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab[3]}>
                                <TabPane tabId="1">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredSessions[3].length > 0 ? (
                                                filteredSessions[3].map((session) => (
                                                    <Card key={session.id} className={`p-3 ${tempSessions.find(s => s.id === session.id) ? 'border-primary' : ''} shadow`} style={{ width: '200px', cursor: 'pointer' }} onClick={() => handleSessionSelect(session)}>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{session.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={tempSessions.find(s => s.id === session.id) !== undefined}
                                                                onChange={() => handleSessionSelect(session)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <small className="text-muted d-block">{session.startTime} - {session.endTime}</small>
                                                        <small className="text-muted d-block">{session.frequency}</small>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${session.color}`}>£{session.price}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No sessions available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredExtraCharges[3].length > 0 ? (
                                                filteredExtraCharges[3].map((charge) => (
                                                    <Card
                                                        key={charge.id}
                                                        className={`p-3 ${charge.id in tempExtraCharges ? 'border-primary' : ''} shadow`}
                                                        style={{ width: '200px', cursor: 'pointer' }}
                                                        onClick={() => handleExtraChargeSelect(charge.id)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{charge.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={charge.id in tempExtraCharges}
                                                                onChange={() => handleExtraChargeSelect(charge.id)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${charge.color}`}>£{charge.price}</span>
                                                        </div>
                                                        {charge.id in tempExtraCharges && (
                                                            <div className="mt-3 d-flex align-items-center">
                                                                <div className="d-flex align-items-center border rounded">
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, Math.max(0, (tempExtraCharges[charge.id] || 0) - 1));
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Input
                                                                        type="number"
                                                                        value={tempExtraCharges[charge.id] || 0}
                                                                        onChange={(e) => handleExtraChargeQuantity(charge.id, Math.max(0, parseInt(e.target.value) || 0))}
                                                                        min="0"
                                                                        style={{
                                                                            width: '50px',
                                                                            textAlign: 'center',
                                                                            border: 'none',
                                                                            background: 'transparent'
                                                                        }}
                                                                        className="form-control-sm"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    />
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, (tempExtraCharges[charge.id] || 0) + 1);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No extra charges available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" className='text-dark' onClick={() => handleSecondModalClose(3)}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={() => handleMakeChanges(3)}>
                                    Make changes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={secondModal[4]} toggle={() => handlSecondModal(4)} backdrop="static" size="xl">
                        <ModalHeader toggle={() => handlSecondModal(4)} className="bg-primary text-white">
                            Friday Schedule
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-3">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[4] === '1' })}
                                            onClick={() => setActiveTab({ ...activeTab, 4: '1' })}
                                        >
                                            Sessions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab[4] === '2' })}
                                            onClick={() => setActiveTab({ ...activeTab, 4: '2' })}
                                        >
                                            Extra charges
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab[4]}>
                                <TabPane tabId="1">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredSessions[4].length > 0 ? (
                                                filteredSessions[4].map((session) => (
                                                    <Card key={session.id} className={`p-3 ${tempSessions.find(s => s.id === session.id) ? 'border-primary' : ''} shadow`} style={{ width: '200px', cursor: 'pointer' }} onClick={() => handleSessionSelect(session)}>
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{session.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={tempSessions.find(s => s.id === session.id) !== undefined}
                                                                onChange={() => handleSessionSelect(session)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <small className="text-muted d-block">{session.startTime} - {session.endTime}</small>
                                                        <small className="text-muted d-block">{session.frequency}</small>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${session.color}`}>£{session.price}</span>
                                                        </div>
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No sessions available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                        </div>
                                        <div className="d-flex flex-wrap gap-3">
                                            {filteredExtraCharges[4].length > 0 ? (
                                                filteredExtraCharges[4].map((charge) => (
                                                    <Card
                                                        key={charge.id}
                                                        className={`p-3 ${charge.id in tempExtraCharges ? 'border-primary' : ''} shadow`}
                                                        style={{ width: '200px', cursor: 'pointer' }}
                                                        onClick={() => handleExtraChargeSelect(charge.id)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <h6>{charge.title}</h6>
                                                            <Input
                                                                type="checkbox"
                                                                checked={charge.id in tempExtraCharges}
                                                                onChange={() => handleExtraChargeSelect(charge.id)}
                                                                onClick={(e) => e.stopPropagation()}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className={`badge bg-${charge.color}`}>£{charge.price}</span>
                                                        </div>
                                                        {charge.id in tempExtraCharges && (
                                                            <div className="mt-3 d-flex align-items-center">
                                                                <div className="d-flex align-items-center border rounded">
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, Math.max(0, (tempExtraCharges[charge.id] || 0) - 1));
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Input
                                                                        type="number"
                                                                        value={tempExtraCharges[charge.id] || 0}
                                                                        onChange={(e) => handleExtraChargeQuantity(charge.id, Math.max(0, parseInt(e.target.value) || 0))}
                                                                        min="0"
                                                                        style={{
                                                                            width: '50px',
                                                                            textAlign: 'center',
                                                                            border: 'none',
                                                                            background: 'transparent'
                                                                        }}
                                                                        className="form-control-sm"
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    />
                                                                    <Button
                                                                        color="light"
                                                                        size="sm"
                                                                        className="border-0 text-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleExtraChargeQuantity(charge.id, (tempExtraCharges[charge.id] || 0) + 1);
                                                                        }}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>
                                                ))
                                            ) : (
                                                <div className="text-center w-100 p-4">
                                                    <p className="text-muted mb-0">No extra charges available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                <Button color="light" className='text-dark' onClick={() => handleSecondModalClose(4)}>
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={() => handleMakeChanges(4)}>
                                    Make changes
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                </CardBody>
            </Card>
        </Col>
    )
}

export default Bookings