import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditIForm, IForm } from "../../../Pages/SuperAdmin/Plans/Helper/types";
import { createPlans, updatePlan } from "./meditator";
import { handleErrors } from "../../../Utils";

interface IpurchasedPlanUser {
  id: string | number;
  billing: string;
  createdAt: string;
  expiredAt: number;
  fee: number;
  firstName: string;
  isActive: boolean;
  lastName: string;
  packageId: string;
  parentId: string;
  paymentStatus: string;
  startedAt: number;
  title: string;
  updatedAt: string;
}

export interface IPlanObj {
  id: string | number;
  title: string;
  subTitle?: string;
  fee: number;
  billing: string;
  description: string;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
}

interface IInitialState {
  plans: IPlanObj[] | [];
  count: number;
  purchasedPlans: IpurchasedPlanUser[] | [];
  purchasedCount: number;
  isLoading: boolean;
  error: any;
  success: any;
}

const initialState: IInitialState = {
  plans: [],
  count: 0,
  purchasedPlans: [],
  purchasedCount: 0,
  isLoading: false,
  error: {},
  success: {},
};

const PackagePlansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    SET_PLANS: (state: IInitialState, action) => {
      if (action?.payload?.pageNo && action?.payload?.pageNo > 1) {
        state.plans = [...state.plans, ...action.payload.plans];
      } else {
        state.plans = action.payload.plans;
      }
      state.count = action.payload.count;
    },
    SET_PURCHASED_PLANS: (state: IInitialState, action) => {
      state.purchasedPlans = action.payload.purchasedPlans;
      state.purchasedCount = action.payload.purchasedCount;
    },
    FETCH_PLANS: (state, action) => {
      // * To get the user data
    },
    SET_MESSAGE: (state, action) => {
      state.error = action.payload.error;
      state.success = action.payload.success;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addForm.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateForm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateForm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateForm.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const addForm = createAsyncThunk(
  "addForm",
  async (formData: IForm, thunkApi) => {
    formData.subTitle = formData.title;
    formData.fee = Number(formData.fee);
    formData.maxNumOfChild = Number(formData.maxNumOfChild);
    const response = await createPlans(formData, thunkApi);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: message }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while adding plan.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const updateForm = createAsyncThunk(
  "updateForm",
  async (formData: EditIForm, thunkApi) => {
    const response = await updatePlan(formData, thunkApi);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: message }, error: {} })
      );

      thunkApi.dispatch(FETCH_PLANS(formData.filterObj));
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while updating plan.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const { SET_PLANS, FETCH_PLANS, SET_PURCHASED_PLANS, SET_MESSAGE } =
  PackagePlansSlice.actions;
export default PackagePlansSlice.reducer;
