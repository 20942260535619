import { camelCase } from "lodash";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { statusClass, statusColors } from "src/Utils/Constants";

export interface StatusCellInterFace {
  position: any;
  id: number | string;
  row: any;
  newUI?: boolean;
  statusList?: any;
  color: { text: string; background: string };
  handleStatusChange: (row: any, status?: any) => void;
  toggleDropdownOne?: (id: any) => void;
  dropdownOpenOne?: any;
  disable?: boolean;
}

const Status = ({
  position,
  id,
  color,
  row,
  disable,
  statusList,
  newUI = false,
  handleStatusChange,
  toggleDropdownOne,
  dropdownOpenOne,
}: StatusCellInterFace) => {


  return (
    <>
      {newUI ? disable ? (
        <div
          style={{
            width: "100px",
            borderRadius: "8px",
            textAlign: "center",
            fontSize: "10px",
            fontWeight: "700",
            fontFamily: "Nunito Sans, sans-serif",
            padding: "4px 0px",
          }}
          className={`statusBox ${statusClass[camelCase(position.toLowerCase())?.toLowerCase()]}`}
        >
          {position.toUpperCase()}
        </div>
      ) : (
        <Dropdown
          isOpen={dropdownOpenOne?.[row.id]}
          toggle={() => toggleDropdownOne?.(row.id)}
        >
          <DropdownToggle className={`table-btn statusBox ${statusClass[camelCase(position.toLowerCase())?.toLowerCase()]}`} style={{ padding: "3px" }}>
            {position.toUpperCase()}
          </DropdownToggle>
          <DropdownMenu className="table-dropdown-menu" style={{ zIndex: 1050 }} container="body">
            {statusList && statusList?.length > 0 && statusList?.map((status: any) => (
              <>
                <div
                  className="table-dropdown-item" style={{ backgroundColor: statusColors[status.toLowerCase()]?.background }}
                  onClick={() => handleStatusChange(row, status)}
                >
                  <span style={{ color: statusColors[status.toLowerCase()]?.text, fontWeight: "700", fontSize: "10px", fontFamily: "Nunito Sans, sans-serif" }}>{status.toUpperCase()}</span>
                </div>
              </>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <div
          style={{
            backgroundColor: color?.background || '#000',
            width: "100px",
            borderRadius: "8px",
            textAlign: "center",
          }}
          onClick={() => handleStatusChange(row)}
        >
          <span
            style={{
              padding: "4px 0px",
              color: color?.text || 'Unkown',
              textTransform: "uppercase",
              fontWeight: "700",
              fontSize: "10px",
            }}
          >
            {position}
          </span>
        </div>
      )}
    </>
  );
};

export default Status;
