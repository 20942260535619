import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  ModalFooter,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Swal from "sweetalert2";
import FilterComponent from "src/Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "src/Utils/CommonComponents/CommonCardHeader";
import Status from "src/Components/Common/Status";
import { statusColors } from "src/Utils/Constants";
import { Btn } from "src/AbstractElements";
import DropDownAPi from "src/services/DropdownApi";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { fetchImage, getUserActivityFormattedData } from "src/Utils";
import UserApi from "src/services/UserApi";
import { baseUrl } from "src/Utils/Constants/config";
import { connectComponent } from "./connector";
import { useForm } from "react-hook-form";
import AttendanceAPi from "src/services/AttendanceApi";
import UserActivityAPi from "src/services/UserActivityApi";
import UploadAPi from "src/services/UploadApi";
import moment from "moment-timezone";

interface Children {
  id: string;
  studentName: string;
  nurseryName: string;
  roomId: string;
  date: string;
  status: 0 | 1; // 0 for ACTIVE, 1 for INACTIVE
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ textAlign: "center", fontSize: "12px" }}>{title}</span>
);

interface InitialState {
  childrenIds: string[];
  signIn?: string;
  signInVisibleToParent?: string;
  signOut?: string;
  signOutVisibleToParent?: string;
  sleepToTime?: string;
  sleepFromTime?: string;
  sleepNotes?: string;
  sleepTimeVisibleToParent?: string;
  nappyChange?: string;
  toiletVisit?: string;
  nappyOrToiletNotes?: string;
  nappyOrToiletVisibleToParent?: string;
  nappyOrTolietTime?: string;
  meal?: string;
  mealTime?: string;
  mealNotes?: string;
  mealVisibleToParent?: string;
  notes?: string;
  notesAttachmentUrl?: string;
  notesVisibleToParent?: string;
  currDate?: string;
}

function ChildrenAttendance(props: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeForm, setActiveForm] = useState("");
  const [customTime, setCustomTime] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const toggleFormModal = (formType: any) => {
    if (selectedEmployees.length === 0) {
      toast.error("Please select children first.");
      return;
    }

    if (filterObj.roomId === undefined || filterObj.roomId === "all-rooms") {
      toast.error("Please select room first.");
      return;
    }
    const { isCheck, data } = checkData(formType);
    if (isCheck) {
      Swal.fire(
        "Error",
        "Activity already exists for selected children \n (" + data + ")",
        "error"
      );
      return;
    }

    if (formType === "SickLeave" || formType === "Holiday") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this! \n This will be marked as " + (formType == "SickLeave" ? "Sick Leave" : "Holiday") + " date & time " + moment.tz("Europe/London").format("DD MMM YYYY, HH:mm"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await AttendanceAPi.childrenGeneralActivity({
            childrenIds: selectedEmployees.map((item) => item.empId),
            currDate: selectedDate,
            ...(formType == "SickLeave" ? { sickLeave: moment.tz("Europe/London").format("HH:mm") } : { holiday: moment.tz("Europe/London").format("HH:mm") })
          });
          if (response.success) {
            Swal.fire({
              title: "Success!",
              text: `${response.message}`,
              icon: "success",
            }).then(async () => {
              await UserActivityAPi.addUserActivity(
                getUserActivityFormattedData("Attendance", `${response.message}`)
              );
              setSelectedEmployees([]);
              setIsIndeterminate(false);
              setIsAllSelected(false);
              closeFormModal();
              setClear(true);
              setFileName("");
              setFName("");
              fetchChildren();
            });
          } else {
            Swal.fire("Error", response.message, "error");
          }
        }
      });
    } else {
      setActiveForm(formType);
    }
  };

  const [childrenAttendance, setChildrenAttendance] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Date picker state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState("");
  const [fileName, setFileName] = useState("");
  const [fName, setFName] = useState("");
  const [isIndeterminate, setIsIndeterminate] = useState(false); // "Select All" indeterminate state
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]); // Track selected employees by id
  const [isAllSelected, setIsAllSelected] = useState(false); // "Select All" selected state
  const [clear, setClear] = useState(false);
  const [modalData, setModalData] = useState<any>(null); // State to store the row data
  const [activeTab, setActiveTab] = useState('1');

  const initialState = {
    childrenIds: [],
    signIn: "",
    signInVisibleToParent: "",
    signOut: "",
    signOutVisibleToParent: "",
    sleepToTime: "",
    sleepFromTime: "",
    sleepNotes: "",
    sleepTimeVisibleToParent: "",
    nappyChange: "",
    toiletVisit: "",
    nappyOrToiletNotes: "",
    nappyOrToiletVisibleToParent: "",
    nappyOrTolietTime: "",
    meal: "",
    mealTime: "",
    mealNotes: "",
    mealVisibleToParent: "",
    notes: "",
    notesAttachmentUrl: "",
    notesVisibleToParent: "",
    currDate: "",
  };

  const [form, setForm] = useState<InitialState>(initialState);

  const {
    register,
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<InitialState>();

  const times = ["10", "20", "30", "45", "60", "90", "Now"];

  const convertTime = (time: any) => {
    console.log('time: ', time, moment.utc(time, "HH:mm").tz("Europe/London").format("HH:mm"));
    return moment.utc(time, "HH:mm").tz("Europe/London").format("HH:mm");
  };

  const onSubmit = async (data: any) => {
    console.log("Data: ", data, form);
    try {
      let updatedForm: any = {
        childrenIds: selectedEmployees.map((item) => item.empId),
        currDate: selectedDate,
      };

      if (activeForm === "SignIn") {
        updatedForm = {
          ...updatedForm,
          signIn: convertTime(form.signIn),
          signInVisibleToParent: form.signInVisibleToParent ? "true" : "false",
        };
      }

      if (activeForm === "SignOut") {
        updatedForm = {
          ...updatedForm,
          signOut: convertTime(form.signOut),
          signOutVisibleToParent: form.signOutVisibleToParent
            ? "true"
            : "false",
        };
      }

      if (activeForm === "Sleep") {
        updatedForm = {
          ...updatedForm,
          sleepNotes: form.sleepNotes,
          sleepTimeVisibleToParent: !!form.sleepTimeVisibleToParent
            ? "true"
            : "false",
          sleepFromTime: times?.includes(form?.sleepFromTime || "")
            ? getISODateOffset(
              form.sleepFromTime === "Now"
                ? "00:00"
                : convertTime(form.sleepFromTime)
            )
            : form.sleepFromTime,
          sleepToTime: times?.includes(form?.sleepToTime || "")
            ? ""
            : convertTime(form.sleepToTime),
        };

        console.log("Updated Form:", updatedForm);
      }

      if (activeForm === "Meals") {
        updatedForm = {
          ...updatedForm,
          meal: form.meal,
          mealTime: convertTime(form.mealTime),
          mealNotes: form.mealNotes,
          mealVisibleToParent: form.mealVisibleToParent ? "true" : "false",
        };
      }

      if (activeForm === "NappyToilet") {
        updatedForm = {
          ...updatedForm,
          nappyChange: form.nappyChange,
          toiletVisit: form.toiletVisit,
          nappyOrToiletNotes: form.nappyOrToiletNotes,
          nappyOrTolietTime: convertTime(form.nappyOrTolietTime),
          nappyOrToiletVisibleToParent: form.nappyOrToiletVisibleToParent
            ? "true"
            : "false",
        };
      }

      if (activeForm === "Notes") {
        let mediaUrl = "";

        if (fileName !== "") {
          const uploadedMedia = await uploadImage(fileName);
          mediaUrl = uploadedMedia.mediaUrl; // Ensure mediaUrl is set after upload
        }

        updatedForm = {
          ...updatedForm,
          notes: form.notes,
          notesVisibleToParent: form.notesVisibleToParent ? "true" : "false",
          notesAttachmentUrl: mediaUrl,
        };
      }



      const response = await AttendanceAPi.childrenGeneralActivity(updatedForm);

      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );

          setSelectedEmployees([]);
          setIsIndeterminate(false);
          setIsAllSelected(false);
          closeFormModal();
          setClear(true);
          setFileName("");
          setFName("");
          fetchChildren();
        });
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message);
      Swal.fire(
        "Error",
        parsedError.message || "Failed to update status",
        "error"
      );
    }
  };

  const checkData = (formType: any) => {
    const filteredData = childrenAttendance.filter((item: any) => {
      if (selectedEmployees.find((emp: any) => emp.empId === item.id)) {
        if (formType === "SignIn") {
          return item.childrenGeneralActivity[0]?.signIn;
        } else if (formType === "SignOut") {
          return item.childrenGeneralActivity[0]?.signOut;
        } else if (formType === "SickLeave") {
          return item.childrenGeneralActivity[0]?.sickLeave;
        } else if (formType === "Holiday") {
          return item.childrenGeneralActivity[0]?.holiday;
        }
      }
    });
    if (filteredData.length > 0) {
      return {
        isCheck: true,
        data: filteredData
          .map((item: any) => `${item.firstName} ${item.lastName}`)
          .join(", "),
      };
    }
    return {
      isCheck: false,
      data: [],
    };
  };

  useEffect(() => {
    setTimeout(() => {
      if (clear) setClear(false);
    }, 5000);
  }, [clear]);

  const closeFormModal = () => {
    setActiveForm("");
    setModalData(null); // Clear modal data
    setCustomTime(false); // Reset custom time state
    setForm(initialState);
    reset(initialState);
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
    if (name === 'mealTime' || name === 'nappyOrTolietTime' || name === 'sleepFromTime' || name === 'sleepToTime') {
      clearErrors(name);
    }
  };

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "childrenActivity");
    try {
      const response = await UploadAPi.uploadFile(
        formData,
        "upload-childrenActivity"
      );

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      return { mediaUrl: "", mediaType: "" };
    }
  };

  const [classroomDropDown, setClassroomDropDown] = useState({
    id: "Classroom",
    options: [],
    title: "Select Job",
  });

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files[0]);
      setFName(e.target.files[0].name);
    }
  };

  const fetchChildren = async () => {
    try {
      const response = await UserApi.getAllChildren(filterObj);
      if (response.success && Array.isArray(response.children)) {
        const dataWithImage: any = await Promise.all(
          response.children.map(async (row: any) => {
            let avatarUrlData = "";
            if (!!row.avatarUrl) {
              // Fetch the image if avatarUrl exists
              avatarUrlData = await fetchImage(baseUrl + row.avatarUrl);
            }
            return { ...row, avatarUrl: avatarUrlData };
          })
        );
        console.log("dataWithImage: ", dataWithImage);
        setChildrenAttendance(dataWithImage);
      } else {
        throw new Error("Expected an array of Children");
      }
    } catch (error: any) {
      setChildrenAttendance([]);
      console.error(error);
    }
  };

  const fetchClassRoomsOptions = async () => {
    try {
      const response = await DropDownAPi.getClassRoomsOptions(
        props.nurseryId || ""
      );

      if (response.success && response?.data?.length > 0) {
        let roomNameOptions: any = [];
        roomNameOptions.push({ label: "All Rooms", value: "all-rooms" });
        response?.data?.forEach((item: any) => {
          roomNameOptions.push({
            label: item.roomName,
            value: item.id,
          });
        });

        setClassroomDropDown({
          ...classroomDropDown,
          options: roomNameOptions,
        });
      }
    } catch (error: any) {
      setClassroomDropDown({ ...classroomDropDown, options: [] });
    }
  };

  useEffect(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    setCurrentTime(`${hours}:${minutes}`);
  }, []);

  const [filterObj, setFilterObj] = useState<any>({
    nurseryId: props.nurseryId,
    roomId: undefined,
    currDate: new Date(),
  });

  useEffect(() => {
    setLoading(false);
    fetchClassRoomsOptions();
    setFilterObj({ ...filterObj, nurseryId: props.nurseryId });
  }, [props.nurseryId]);

  useEffect(() => {
    fetchChildren();
  }, [filterObj]);

  useEffect(() => {
    setFilterObj({ ...filterObj, currDate: selectedDate });
  }, [selectedDate]);

  const filteredItems: any = childrenAttendance.filter((item: any) => {
    const newItem = {
      name: `${item.firstName} ${item.lastName}`,
      nurseryName: item.nursery.name,
      roomName: item.childrenRoomMoves?.[0]?.room?.roomName,
      date: item.date,
      status: item.status,
    };
    return Object.values(newItem).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const handleDetailPage = (row: any) => {
    let data = {};
    if (
      row?.childrenGeneralActivity?.[0]?.childrenNoteRecords?.[0]?.notesAttachmentUrl ||
      row?.childrenGeneralActivity?.[0]?.childrenNoteRecords?.[0]?.notes
    ) {
      data = {
        notes: row.childrenGeneralActivity[0].childrenNoteRecords[0].notes,
        notesAttachmentUrl: row.childrenGeneralActivity[0].childrenNoteRecords[0].notesAttachmentUrl,
        notesVisibleToParent:
          row.childrenGeneralActivity[0].childrenNoteRecords[0].notesVisibleToParent,
      };
    }
    setModalData(data);
    setActiveForm("Details");
  };

  const handleShowDetails = (row: any) => {
    setModalData(row);
    setActiveForm("History");
  };

  const formatKey = (key: any) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before uppercase letters
      .replace(
        /([A-Z]{2,})/g,
        (match: any) => match.charAt(0) + match.slice(1).toLowerCase()
      ) // Handle all-uppercase words
      .replace(/^./, (str: any) => str.toUpperCase()); // Capitalize the first letter
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Student Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Room" />,
      selector: (row) => row.childrenRoomMoves[0].room.roomName,
      style: {
        minWidth: "40px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "80px", // Set consistent width
      maxWidth: "80px",
      cell: (row) => {
        return row.childrenRoomMoves?.map((item: any, index: number) => {
          // Function to calculate the luminance of a color
          const getLuminance = (hex: string) => {
            let r: number = 0;
            let g: number = 0;
            let b: number = 0;

            // 3-digit hex code (e.g., #03f)
            if (hex.length === 4) {
              r = parseInt(hex[1] + hex[1], 16);
              g = parseInt(hex[2] + hex[2], 16);
              b = parseInt(hex[3] + hex[3], 16);
            }
            // 6-digit hex code (e.g., #ff0033)
            else if (hex.length === 7) {
              r = parseInt(hex[1] + hex[2], 16);
              g = parseInt(hex[3] + hex[4], 16);
              b = parseInt(hex[5] + hex[6], 16);
            }
            // Apply the luminance formula
            r /= 255;
            g /= 255;
            b /= 255;
            const a = [r, g, b].map(function (v) {
              return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
            });
            const luminance = 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
            return luminance;
          };

          // Default to red if no color
          const bgColor = item?.room?.color || "#FF0000"; // Fallback color (red)

          // Get luminance and choose appropriate text color
          const luminance = getLuminance(bgColor);
          const textColor = luminance > 0.5 ? "black" : "white"; // Dark background => white text, light background => black text

          return (
            <div
              key={index}
              style={{
                backgroundColor: bgColor,
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                margin: "2px",
                display: "inline-block",
                color: textColor, // Invert the text color based on luminance
                textAlign: "center", // Center the text
                lineHeight: "20px", // Center the text vertically inside the circle
                fontSize: "10px", // Adjust font size to fit inside circle
              }}
              title={item?.room?.roomName} // Display room name on hover
            >
              {item?.room?.roomName?.charAt(0)}{" "}
              {/* Display the first letter of room name */}
            </div>
          );
        });
      },
      sortable: true,
      center: true,
    },
    {
      name: "Sign In",
      selector: (row) => row.childrenGeneralActivity?.[0]?.signIn,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.signIn ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{row.childrenGeneralActivity?.[0]?.signIn}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Nappy/Toilet",
      selector: (row) => row.childrenGeneralActivity?.[0]?.childrenNappyRecords?.[0]?.nappyChange,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.childrenNappyRecords?.[0]?.nappyOrTolietTime ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>
                  {row.childrenGeneralActivity?.[0]?.childrenNappyRecords?.[0]?.nappyOrTolietTime}
                </span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Sleep",
      selector: (row) => row.childrenGeneralActivity?.[0]?.childrenSleepRecords?.[0]?.sleepFromTime,
      style: { maxWidth: "50%" },
      cell: (row) => {
        const time = row?.childrenGeneralActivity?.[0]?.childrenSleepRecords?.[0]?.sleepFromTime;
        const formattedTime = time ? moment(time).format('HH:mm') : null;

        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.childrenSleepRecords?.[0]?.sleepFromTime ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{formattedTime}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Meals",
      selector: (row) => row.childrenGeneralActivity?.[0]?.childrenMealRecords?.[0]?.mealTime,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.childrenMealRecords?.[0]?.mealTime ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{row.childrenGeneralActivity?.[0]?.childrenMealRecords?.[0]?.mealTime}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Notes",
      selector: (row) => row.childrenGeneralActivity?.[0]?.childrenNoteRecords?.[0]?.notes,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <a
            className="icon"
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor behavior
              handleDetailPage(row); // Call the function
            }}
            style={{ marginLeft: "2px" }}
          >
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-eye me-1"></i>
            </Btn>
          </a>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Sick Leave",
      selector: (row) => row.childrenGeneralActivity?.[0]?.sickLeave,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.sickLeave ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{row.childrenGeneralActivity?.[0]?.sickLeave}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Holiday",
      selector: (row) => row.childrenGeneralActivity?.[0]?.holiday,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.holiday ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{row.childrenGeneralActivity?.[0]?.holiday}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Sign Out",
      selector: (row) => row.childrenGeneralActivity?.[0]?.signOut,
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <>
            {row?.childrenGeneralActivity?.[0]?.signOut ? (
              <div className="d-flex flex-column justify-content-center">
                <i className="fa-solid fa-check me-1 text-success"></i>
                <span>{row.childrenGeneralActivity?.[0]?.signOut}</span>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center"
                style={{ width: "30px" }}
              >
                <i className="fa-solid fa-close me-1 text-danger"></i>
              </div>
            )}
          </>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
      button: true,
      cell: (row) => (
        <a
          className="icon"
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            handleShowDetails(row); // Call the function
          }}
          style={{ marginLeft: "2px" }}
        >
          <Btn color="primary" size="sm">
            <i className="fa-solid fa-eye me-1"></i>
            {"View"}
          </Btn>
        </a>
      ),
    },
  ];
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleRowSelect = (state: { selectedRows: any[] }) => {
    const selectedIds = state.selectedRows.map((row: any) => {
      return { empId: row.id, attendanceId: row.attendance?.[0]?.id || "" };
    });
    setSelectedEmployees(selectedIds);
    setIsIndeterminate(
      selectedIds.length > 0 && selectedIds.length < filteredItems.length
    );
    setIsAllSelected(selectedIds.length === filteredItems.length);
    setClear(false);
  };

  const getISODateOffset = (timeString: any) => {
    // टाइम स्ट्रिंग को मिनट्स में कन्वर्ट करें
    const [hours, minutes] = timeString.split(':').map(Number);
    const totalMinutes = (hours * 60) + minutes;

    return new Date(new Date().getTime() - totalMinutes * 60000).toISOString();
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Children Attendance`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                {/* <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() =>
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/children-attendance-download`
                    )
                  }
                >
                  <i className="fa fa-download mr-2"></i> Download
                </Button> */}
              </div>
              <CardBody>
                <Row className="justify-content-center">
                  <Col md={3}>
                    <FilterComponent
                      onFilter={(date: any) => {
                        setSelectedDate(date);
                      }}
                      filterText={selectedDate}
                      type="date"
                      showDateLabel={false}
                    />
                  </Col>
                  <Col md={3}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterObj({ ...filterObj, roomId: e })
                      }
                      filterText={filterObj.roomId}
                      type="select"
                      dropdown={{ ...classroomDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Rooms" // Custom placeholder text
                    />
                  </Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                  <Col md={2}>
                    {/* Add Activity Dropdown */}
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle color="primary" className="btn-md">
                        <i className="fa fa-plus mr-2"></i> Add Activity
                      </DropdownToggle>
                      <DropdownMenu className="custom-dropdown-menu">
                        <div
                          className="custom-dropdown-item bg-success"
                          onClick={() => toggleFormModal("SignIn")}
                        >
                          <span>Sign in</span>
                          <i className="fa fa-check"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-danger"
                          onClick={() => toggleFormModal("SignOut")}
                        >
                          <span>Sign out</span>
                          <i className="fa fa-times"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-primary"
                          onClick={() => toggleFormModal("NappyToilet")}
                        >
                          <span>Nappy/Toilet</span>
                          <i className="fa fa-bath"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-warning"
                          onClick={() => toggleFormModal("Sleep")}
                        >
                          <span>Sleep</span>
                          <i className="fa fa-bed"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-info"
                          onClick={() => toggleFormModal("Meals")}
                        >
                          <span>Meals</span>
                          <i className="fa fa-cutlery"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-secondary"
                          onClick={() => toggleFormModal("Notes")}
                        >
                          <span>Notes</span>
                          <i className="fa fa-sticky-note"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-danger"
                          onClick={() => toggleFormModal("SickLeave")}
                        >
                          <span>Sick Leave</span>
                          <i className="fa fa-bed"></i>
                        </div>
                        <div
                          className="custom-dropdown-item bg-warning"
                          onClick={() => toggleFormModal("Holiday")}
                        >
                          <span>Holiday</span>
                          <i className="fa fa-calendar"></i>
                        </div>
                      </DropdownMenu>
                    </Dropdown>

                    {activeForm === "History" && (
                      <Modal isOpen toggle={() => { }} backdrop="static" size="lg">
                        <ModalHeader toggle={closeFormModal} className="bg-primary text-white">
                          <i className="fa fa-history me-2"></i>
                          {`History`}
                        </ModalHeader>
                        <ModalBody className="p-4">
                          <div className="history-container">
                            {modalData ? (
                              <div className="activity-timeline">
                                <Row>
                                  <Col md={12}>
                                    <div>
                                      <h5 className="section-title d-flex gap-3 flex-column  align-items-start mb-3">
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-user me-2 text-primary"></i>
                                          <span><b>Child Name:</b> {`${modalData?.firstName || ''} ${modalData?.lastName || ''}`}</span>
                                        </div>
                                      </h5>
                                    </div>
                                    <div>
                                      <h5 className="section-title d-flex gap-3 flex-column  align-items-start mb-3">
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-user me-2 text-primary"></i>
                                          <span><b>Room Name:</b> {modalData?.childrenRoomMoves?.[0]?.room?.roomName || ''}</span>
                                        </div>
                                      </h5>
                                    </div>
                                    {/* Sign In/Out Section */}
                                    <div className="activity-section mb-4 mt-4">
                                      <h5 className="section-title d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: "1px solid #bbb", paddingBottom: "10px" }}>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-sign-in me-2 text-primary"></i>
                                          <span>Sign In/Out</span>
                                        </div>
                                      </h5>
                                      {modalData?.childrenGeneralActivity?.map((activity: any, actIndex: number) => (
                                        <div key={actIndex} className="record-card mb-3">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <strong>Sign In:</strong> {activity?.signIn || 'Not signed in'}
                                            </div>
                                            <div>
                                              <strong>Sign Out:</strong> {activity?.signOut || 'Not signed out'}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>

                                    {/* Nappy/Toilet Section */}
                                    <div className="activity-section mb-4">
                                      <h5 className="section-title d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: "1px solid #bbb", paddingBottom: "10px" }}>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-bath me-2 text-primary"></i>
                                          <span>Nappy/Toilet</span>
                                        </div>
                                        <span className="record-count">
                                          <i className="fa fa-list-ul me-1"></i>
                                          {modalData?.childrenGeneralActivity?.reduce((count: number, activity: any) =>
                                            count + (activity?.childrenNappyRecords?.length || 0), 0)} Records
                                        </span>
                                      </h5>
                                      {modalData?.childrenGeneralActivity?.map((activity: any, actIndex: number) => (
                                        activity?.childrenNappyRecords?.map((record: any, index: number) => (
                                          <div key={`${actIndex}-${index}`} className="record-card mb-3 border-bottom pb-2">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <strong>Time:</strong> {record.nappyOrTolietTime}
                                              </div>
                                              <div>
                                                <strong>Nappy Change:</strong> {record.nappyChange}
                                              </div>
                                              <div>
                                                <strong>Toilet Visit:</strong> {record.toiletVisit}
                                              </div>
                                            </div>
                                            {record.nappyOrToiletNotes && (
                                              <div className="mt-2 notes-section">
                                                <strong>Notes:</strong> {record.nappyOrToiletNotes}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )) || <div className="text-muted">No nappy/toilet records</div>}
                                    </div>

                                    {/* Sleep Section */}
                                    <div className="activity-section mb-4">
                                      <h5 className="section-title d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: "1px solid #bbb", paddingBottom: "10px" }}>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-bed me-2 text-primary"></i>
                                          <span>Sleep</span>
                                        </div>
                                        <span className="record-count">
                                          <i className="fa fa-list-ul me-1"></i>
                                          {modalData?.childrenGeneralActivity?.reduce((count: number, activity: any) =>
                                            count + (activity?.childrenSleepRecords?.length || 0), 0)} Records
                                        </span>
                                      </h5>
                                      {modalData?.childrenGeneralActivity?.map((activity: any, actIndex: number) => (
                                        activity?.childrenSleepRecords?.map((record: any, index: number) => (
                                          <div key={`${actIndex}-${index}`} className="record-card mb-3">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <strong>From:</strong> {moment(record.sleepFromTime).format('HH:mm')}
                                              </div>
                                              <div>
                                                <strong>To:</strong> {record.sleepToTime ? moment(record.sleepToTime).format('HH:mm') : 'Ongoing'}
                                              </div>
                                            </div>
                                            {record.sleepNotes && (
                                              <div className="mt-2 notes-section">
                                                <strong>Notes:</strong> {record.sleepNotes}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )) || <div className="text-muted">No sleep records</div>}
                                    </div>

                                    {/* Meals Section */}
                                    <div className="activity-section mb-4">
                                      <h5 className="section-title d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: "1px solid #bbb", paddingBottom: "10px" }}>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-cutlery me-2 text-primary"></i>
                                          <span>Meals</span>
                                        </div>
                                        <span className="record-count">
                                          <i className="fa fa-list-ul me-1"></i>
                                          {modalData?.childrenGeneralActivity?.reduce((count: number, activity: any) =>
                                            count + (activity?.childrenMealRecords?.length || 0), 0)} Records
                                        </span>
                                      </h5>
                                      {modalData?.childrenGeneralActivity?.map((activity: any, actIndex: number) => (
                                        activity?.childrenMealRecords?.map((record: any, index: number) => (
                                          <div key={`${actIndex}-${index}`} className="record-card mb-3">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <strong>Meal:</strong> {record.meal}
                                              </div>
                                              <div>
                                                <strong>Time:</strong> {record.mealTime}
                                              </div>
                                            </div>
                                            {record.mealNotes && (
                                              <div className="mt-2 notes-section">
                                                <strong>Notes:</strong> {record.mealNotes}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )) || <div className="text-muted">No meal records</div>}
                                    </div>

                                    {/* Notes Section */}
                                    <div className="activity-section">
                                      <h5 className="section-title d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: "1px solid #bbb", paddingBottom: "10px" }}>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-sticky-note me-2 text-primary"></i>
                                          <span>Notes</span>
                                        </div>
                                        <span className="record-count">
                                          <i className="fa fa-list-ul me-1"></i>
                                          {modalData?.childrenGeneralActivity?.reduce((count: number, activity: any) =>
                                            count + (activity?.childrenNoteRecords?.length || 0), 0)} Records
                                        </span>
                                      </h5>
                                      {modalData?.childrenGeneralActivity?.map((activity: any, actIndex: number) => (
                                        activity?.childrenNoteRecords?.map((record: any, index: number) => (
                                          <div key={`${actIndex}-${index}`} className="record-card mb-3">
                                            <div className="notes-section">
                                              <strong>Notes:</strong> {record.notes}
                                            </div>
                                            {record.notesAttachmentUrl && (
                                              <div className="mt-2">
                                                <strong>Attachment:</strong>{" "}
                                                <a
                                                  href={baseUrl + record.notesAttachmentUrl}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="attachment-link"
                                                >
                                                  View Attachment <i className="fa fa-external-link"></i>
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )) || <div className="text-muted">No notes</div>}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <div className="text-center">No Data Available</div>
                            )}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={closeFormModal}>
                            <i className="fa fa-times me-2"></i>Close
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )}

                    {/* Modals for Individual Activities */}
                    {activeForm === "Details" && (
                      <Modal isOpen toggle={() => { }} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {activeForm}
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            {Object.keys(modalData).length == 0 && (
                              <>No Data Available</>
                            )}
                            {Object.keys(modalData).length > 0 &&
                              Object.entries(modalData || {}).map(
                                ([key, value]) => (
                                  <div key={key} className="mb-3">
                                    {value?.toString() ? (
                                      <>
                                        <strong>{formatKey(key)}:</strong>{" "}
                                        <span>
                                          {key !== "notesAttachmentUrl" ? (
                                            value?.toString() || "N/A"
                                          ) : (
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() =>
                                                window.open(
                                                  baseUrl + value,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              View Attachment
                                            </a>
                                          )}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                )
                              )}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={closeFormModal}>
                            {"Cancel"}
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )}
                    {/* Modals for Individual Activities */}
                    {activeForm === "SignIn" && (
                      <Modal isOpen toggle={() => { }} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {"Sign In"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="time">
                                {"Time"} <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="time"
                                {...register("signIn", {
                                  required: "Please enter time",
                                })}
                                className={`form-control ${errors.signIn ? "" : ""
                                  }`}
                                placeholder="Time"
                                value={form.signIn}
                                onChange={(e) =>
                                  saveDataForm("signIn", e.target.value)
                                }
                              />
                              {errors.signIn && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signIn?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("signInVisibleToParent")}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={!!form.signInVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "signInVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "SignOut" && (
                      <Modal isOpen toggle={() => { }} backdrop="static">
                        <ModalHeader toggle={closeFormModal}>
                          {"Sign Out"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="time">
                                {"Time"} <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="time"
                                {...register("signOut", {
                                  required: "Please enter time",
                                })}
                                className={`form-control ${errors.signOut ? "" : ""
                                  }`}
                                placeholder="Time"
                                value={form.signOut}
                                onChange={(e) =>
                                  saveDataForm("signOut", e.target.value)
                                }
                              />
                              {errors.signOut && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.signOut?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("signOutVisibleToParent")}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={!!form.signOutVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "signOutVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "NappyToilet" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => { }}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Nappy/Toilet"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="nappyChange">{"Nappy Change"} <span className="text-danger">*</span></Label>
                              <Row>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyDry"
                                  >
                                    <span>{"Dry"}</span>
                                    <input
                                      type="radio"
                                      {...register("nappyChange", { required: "Please select nappy Dry" })}
                                      value="DRY"
                                      id="nappyDry"
                                      className={`form-check-input ${errors.nappyChange ? "is-invalid" : ""}`}
                                      checked={form.nappyChange === "DRY"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                  {errors.nappyChange && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.nappyChange?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyWet"
                                  >
                                    <span>{"Wet"}</span>
                                    <input
                                      type="radio"
                                      {...register("nappyChange", { required: "Please select nappy Wet" })}
                                      value="WET"
                                      id="nappyWet"
                                      className={`form-check-input ${errors.nappyChange ? "is-invalid" : ""}`}
                                      checked={form.nappyChange === "WET"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                  {errors.nappyChange && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.nappyChange?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="nappyBM"
                                  >
                                    <span>{"BM"}</span>
                                    <input
                                      type="radio"
                                      {...register("nappyChange", { required: "Please select nappy BM" })}
                                      value="BM"
                                      id="nappyBM"
                                      className={`form-check-input ${errors.nappyChange ? "is-invalid" : ""}`}
                                      checked={form.nappyChange === "BM"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "nappyChange",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                  {errors.nappyChange && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.nappyChange?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Label for="toiletVisit">{"Toilet Visit"}</Label>
                              <Row>
                                <Col md={4}>
                                  {/* Dry */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitDry"
                                  >
                                    <span>{"Dry"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      value="DRY"
                                      id="toiletVisitDry"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "DRY"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  {/* Wet */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitWet"
                                  >
                                    <span>{"Wet"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      value="WET"
                                      id="toiletVisitWet"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "WET"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                                <Col md={4}>
                                  {/* BM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitBM"
                                  >
                                    <span>{"BM"}</span>
                                    <input
                                      type="radio"
                                      name="toiletVisit"
                                      id="toiletVisitBM"
                                      value="BM"
                                      className="form-check-input"
                                      checked={form.toiletVisit === "BM"}
                                      onChange={(e) =>
                                        saveDataForm(
                                          "toiletVisit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </Col>
                              </Row>
                            </FormGroup>
                            {/* Time Field (NOW or Custom) */}
                            <FormGroup>
                              <Label for="timeSelection">{"Time"}</Label>
                              <Row>
                                <Col md={6}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="NOW"
                                      id="toiletVisitNow"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        saveDataForm(
                                          "nappyOrTolietTime",
                                          moment(new Date().toISOString())
                                            .utc()
                                            .format("HH:mm")
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className="form-check-input"
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Label for="time">
                                    {"Time of the day"}{" "}
                                  </Label>
                                  <input
                                    type="time"
                                    className="form-control mt-3"
                                    placeholder="Enter Time"
                                    value={form.nappyOrTolietTime}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "nappyOrTolietTime",
                                        e.target.value
                                      )
                                    } // To allow the user to modify the time if needed
                                  />
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.nappyOrToiletNotes}
                                onChange={(e) =>
                                  saveDataForm(
                                    "nappyOrToiletNotes",
                                    e.target.value
                                  )
                                }
                              ></textarea>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register(
                                      "nappyOrToiletVisibleToParent",
                                    )}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={
                                      !!form.nappyOrToiletVisibleToParent
                                    }
                                    onChange={(e) =>
                                      saveDataForm(
                                        "nappyOrToiletVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Sleep" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => { }}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Add Sleep"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="nappyChange">
                                {"When did child fall asleep?"} <span className="text-danger">*</span>
                              </Label>
                              <Row>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="Now"
                                      id="toiletVisitNow"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "Now"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitTenMins"
                                  >
                                    <span>{"-10 mins"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="10"
                                      id="toiletVisitTenMins"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "10"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitTwentyMins"
                                  >
                                    <span>{"-20 mins"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="20"
                                      id="toiletVisitTwentyMins"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "20"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitThirtyMins"
                                  >
                                    <span>{"-30 mins"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="30"
                                      id="toiletVisitThirtyMins"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "30"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitFortyFiveMins"
                                  >
                                    <span>{"-45 mins"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="45"
                                      id="toiletVisitFortyFiveMins"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "45"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitOneHour"
                                  >
                                    <span>{"-1 hour"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="60"
                                      id="toiletVisitOneHour"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "60"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitOneAndHalfHour"
                                  >
                                    <span>{"-1.5 hour"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="90"
                                      id="toiletVisitOneAndHalfHour"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      checked={form.sleepFromTime === "90"}
                                      onChange={(e) => {
                                        saveDataForm(
                                          "sleepFromTime",
                                          e.target.value
                                        );
                                        setCustomTime(false);
                                      }}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={3}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      {...register("sleepFromTime", { required: "Please select an option" })}
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className={`form-check-input ${errors.sleepFromTime ? "is-invalid" : ""}`}
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                  {errors.sleepFromTime && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.sleepFromTime?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Row>
                                    <Col md={6}>
                                      <Label for="from">
                                        {"From"}{" "}
                                        <span className="text-danger mt-3">
                                          *
                                        </span>
                                      </Label>
                                      <input
                                        type="time"
                                        className="form-control mt-3"
                                        placeholder="Enter Time"
                                        onChange={(e) =>
                                          saveDataForm(
                                            "sleepFromTime",
                                            e.target.value
                                          )
                                        } // To allow the user to modify the time if needed
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label for="to">
                                        {"To"}{" "}
                                        <span className="text-danger mt-3">
                                          *
                                        </span>
                                      </Label>
                                      <input
                                        type="time"
                                        className="form-control mt-3"
                                        placeholder="Enter Time"
                                        onChange={(e) =>
                                          saveDataForm(
                                            "sleepToTime",
                                            e.target.value
                                          )
                                        } // To allow the user to modify the time if needed
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.sleepNotes}
                                onChange={(e) => {
                                  saveDataForm("sleepNotes", e.target.value);
                                }}
                              ></textarea>
                            </FormGroup>
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("sleepTimeVisibleToParent")}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={!!form.sleepTimeVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "sleepTimeVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Meals" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => { }}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Meals"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="registerMeal">
                                {"Register meal"} <span className="text-danger">*</span>
                              </Label>
                              <Row>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealBreakfast"
                                  >
                                    <span>{"Breakfast"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Breakfast"
                                      id="registerMealBreakfast"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Breakfast"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealSnackAM"
                                  >
                                    <span>{"Snack (AM)"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Snack(AM)"
                                      id="registerMealSnackAM"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Snack(AM)"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealLunch"
                                  >
                                    <span>{"Lunch"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Lunch"
                                      id="registerMealLunch"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Lunch"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealSnackPM"
                                  >
                                    <span>{"Snack (PM)"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Snack(PM)"
                                      id="registerMealSnackPM"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Snack(PM)"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMeaTea"
                                  >
                                    <span>{"Tea"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Tea"
                                      id="registerMeaTea"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Tea"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                                <Col md={6}>
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="registerMealBottle"
                                  >
                                    <span>{"Bottle"}</span>
                                    <input
                                      type="radio"
                                      {...register("meal", { required: "Please select an option" })}
                                      value="Bottle"
                                      id="registerMealBottle"
                                      className={`form-check-input ${errors.meal ? "is-invalid" : ""}`}
                                      checked={form.meal === "Bottle"}
                                      onChange={(e) =>
                                        saveDataForm("meal", e.target.value)
                                      }
                                    />
                                  </label>
                                  {errors.meal && (
                                    <FormFeedback
                                      style={{
                                        color: "orangeRed",
                                        display: "block",
                                      }}
                                    >
                                      {`${errors?.meal?.message}`}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            {/* Time Field (NOW or Custom) */}
                            <FormGroup>
                              <Label for="timeSelection">{"Time"}</Label>
                              <Row>
                                <Col md={6}>
                                  {/* Now */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitNow"
                                  >
                                    <span>{"Now"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="NOW"
                                      id="toiletVisitNow"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        setCustomTime(false);
                                        saveDataForm(
                                          "mealTime",
                                          moment(new Date().toISOString())
                                            .utc()
                                            .format("HH:mm")
                                        );
                                      }}
                                    />
                                  </label>
                                </Col>
                                <Col md={6}>
                                  {/* CUSTOM */}
                                  <label
                                    className="d-flex align-items-center justify-content-between border rounded px-3 py-2 w-100"
                                    htmlFor="toiletVisitCustom"
                                  >
                                    <span>{"Custom"}</span>
                                    <input
                                      type="radio"
                                      name="timeSelection"
                                      value="CUSTOM"
                                      id="toiletVisitCustom"
                                      className="form-check-input"
                                      onChange={(e) => setCustomTime(true)}
                                    />
                                  </label>
                                </Col>
                              </Row>
                              {customTime && (
                                <>
                                  <Label for="time">
                                    {"Time of the day"}{" "}
                                  </Label>
                                  <input
                                    type="time"
                                    className="form-control mt-3"
                                    placeholder="Enter Time"
                                    onChange={(e) =>
                                      saveDataForm("mealTime", e.target.value)
                                    } // To allow the user to modify the time if needed
                                  />
                                </>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="notes">{"Notes"}</Label>
                              <textarea
                                className="form-control"
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.mealNotes}
                                onChange={(e) =>
                                  saveDataForm("mealNotes", e.target.value)
                                }
                              ></textarea>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("mealVisibleToParent")}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={!!form.mealVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "mealVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}

                    {activeForm === "Notes" && (
                      <Modal
                        className="modal-lg"
                        isOpen
                        toggle={() => { }}
                        backdrop="static"
                      >
                        <ModalHeader toggle={closeFormModal}>
                          {"Notes"}
                        </ModalHeader>
                        <ModalBody>
                          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <FormGroup>
                              <Label for="notes">
                                {"Add Notes"}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <textarea
                                {...register("notes", { required: "Please enter your notes" })}
                                className={`form-control ${errors.notes ? "is-invalid" : ""}`}
                                id="notes"
                                placeholder="Enter your notes here..."
                                rows={4} // Adjust the number of rows as needed
                                value={form.notes}
                                onChange={(e) =>
                                  saveDataForm("notes", e.target.value)
                                }
                              ></textarea>
                              {errors.notes && (
                                <FormFeedback
                                  style={{
                                    color: "orangeRed",
                                    display: "block",
                                  }}
                                >
                                  {`${errors?.notes?.message}`}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="fileUpload">Attachments</Label>
                              {/* Label wraps the whole div */}
                              <label
                                htmlFor="fileUpload"
                                className="border rounded px-3 py-2 d-flex align-items-center justify-content-between mb-0"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{fName || "Upload attachment"}</span>
                                <i className="fa fa-upload"></i>
                              </label>
                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="fileUpload"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                accept=".pdf, .docx, .png, .jpg, .jpeg"
                              />
                              <small className="text-danger d-block mt-2">
                                Max file size: 5MB. File format: pdf, docx, png,
                                jpg, and jpeg
                              </small>
                            </FormGroup>
                            {/* Visible To Field */}
                            <FormGroup>
                              <Label for="visibleTo">
                                {"Visible To"}{" "}
                              </Label>
                              <div>
                                <Label className="form-check-label">
                                  <input
                                    id="inline-11"
                                    type="checkbox"
                                    {...register("notesVisibleToParent")}
                                    style={{ marginRight: "15px" }}
                                    className={`checkbox_animated`}
                                    checked={!!form.notesVisibleToParent}
                                    onChange={(e) =>
                                      saveDataForm(
                                        "notesVisibleToParent",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {"Parent"}
                                </Label>
                              </div>
                            </FormGroup>
                            <ModalFooter>
                              <Btn color="primary" type="submit">
                                {"Submit"}
                              </Btn>
                              <Button
                                color="secondary"
                                onClick={closeFormModal}
                              >
                                {"Cancel"}
                              </Button>
                            </ModalFooter>
                          </Form>
                        </ModalBody>
                      </Modal>
                    )}
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display data-table-wrapper"
                    onSelectedRowsChange={handleRowSelect}
                    selectableRows
                    clearSelectedRows={clear}
                    selectableRowsHighlight
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(ChildrenAttendance);
