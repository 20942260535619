import { TableColumn } from "react-data-table-component";
import { Badges, Btn, LI, UL } from "../../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import Status from "../../../../../Components/Common/Status";
import {
  empStatusOption,
  Href,
  statusColors,
} from "../../../../../Utils/Constants";
import { camelCase } from "lodash";

const statuses = [
  { label: "ACTIVE", color: "#C5F1E6" },
  { label: "ON BOARDING", color: "#F3E6FF" },
  { label: "PROBATION", color: "#FFEDED" },
  { label: "ON LEAVE", color: "#FFE5CC" },
  { label: "SUSPENDED", color: "#FFD6D6" },
  { label: "INACTIVE", color: "#F0F0F0" },
];

// export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("ACTIVE");
  const statuses = [
    { label: "ACTIVE", color: "#C5F1E6" },
    { label: "ON BOARDING", color: "#F3E6FF" },
    { label: "PROBATION", color: "#FFEDED" },
    { label: "ON LEAVE", color: "#FFE5CC" },
    { label: "SUSPENDED", color: "#FFD6D6" },
    { label: "INACTIVE", color: "#F0F0F0" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectStatus = (status: string) => {
    setSelectedStatus(status);
    setIsOpen(false);
  };

  return (
    <>
      <div className="dropdown-custom">
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div
            className="status-box"
            style={{
              backgroundColor: statuses.find((s) => s.label === selectedStatus)
                ?.color,
            }}
          >
            {selectedStatus}
          </div>
          <span className="dropdown-icon">{isOpen ? "▲" : "▼"}</span>
        </div>

        {isOpen && (
          <ul className="dropdown-menus">
            {statuses.map((status) => (
              <li
                key={status.label}
                className="dropdown-item"
                onClick={() => selectStatus(status.label)}
                style={{ backgroundColor: status.color }}
              >
                {status.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

const StatusDropdown: React.FC<{ row: any }> = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(row.employeeStatus);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectStatus = (status: string) => {
    setSelectedStatus(status);
    setIsOpen(false);

    // Optional: Update data in parent state
    // setData((prevData) =>
    //   prevData.map((item) =>
    //     item.id === row.id ? { ...item, status } : item
    //   )
    // );
  };

  return (
    <div className="dropdown-container">
      <div
        className="dropdown-header"
        onClick={toggleDropdown}
        style={{
          backgroundColor: statuses.find((s) => s.label === selectedStatus)
            ?.color,
        }}
      >
        {selectedStatus}
        <span className="dropdown-icon">{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <ul className="dropdown-menues">
          {statuses.map((status) => (
            <li
              key={status.label}
              className="dropdown-item"
              onClick={() => selectStatus(status.label)}
              style={{ backgroundColor: status.color }}
            >
              {status.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleStatusChange,
  toggleDropdownOne,
  dropdownOpenOne
}: {
  handleStatusChange: (row: any, status: string) => void;
  toggleDropdownOne: (id: any) => void;
  dropdownOpenOne: any;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Employee Name" />,
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Job Title" />,
      selector: (row) => row.jobTitle,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Nursery" />,
      selector: (row) => row.nurseryName,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.employeeStatus,
      cell: (row: any) => {
        console.log(camelCase(row?.employeeStatus));
        return (
          <Status
            position={
              Object.keys(empStatusOption).includes(
                camelCase(row?.employeeStatus)
              )
                ? empStatusOption[camelCase(row?.employeeStatus)]
                : ""
            }
            statusList={Object.keys(empStatusOption)}
            color={statusColors[camelCase(row?.employeeStatus)]}
            row={row}
            id={row.id}
            newUI={true}
            handleStatusChange={handleStatusChange}
            toggleDropdownOne={toggleDropdownOne}
            dropdownOpenOne={dropdownOpenOne}
          />
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Classroom" />,
      selector: (row) => row?.rooms,

      style: {
        minWidth: "60px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => {
        return row.rooms?.map((item: any, index: number) => {
          // Function to calculate the luminance of a color
          const getLuminance = (hex: string) => {
            let r: number = 0;
            let g: number = 0;
            let b: number = 0;

            // 3-digit hex code (e.g., #03f)
            if (hex.length === 4) {
              r = parseInt(hex[1] + hex[1], 16);
              g = parseInt(hex[2] + hex[2], 16);
              b = parseInt(hex[3] + hex[3], 16);
            }
            // 6-digit hex code (e.g., #ff0033)
            else if (hex.length === 7) {
              r = parseInt(hex[1] + hex[2], 16);
              g = parseInt(hex[3] + hex[4], 16);
              b = parseInt(hex[5] + hex[6], 16);
            }
            // Apply the luminance formula
            r /= 255;
            g /= 255;
            b /= 255;
            const a = [r, g, b].map(function (v) {
              return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
            });
            const luminance = 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
            return luminance;
          };

          // Default to red if no color
          const bgColor = item.color || "#FF0000"; // Fallback color (red)

          // Get luminance and choose appropriate text color
          const luminance = getLuminance(bgColor);
          const textColor = luminance > 0.5 ? "black" : "white"; // Dark background => white text, light background => black text

          return (
            <div
              key={index}
              style={{
                backgroundColor: bgColor,
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                margin: "2px",
                display: "inline-block",
                color: textColor, // Invert the text color based on luminance
                textAlign: "center", // Center the text
                lineHeight: "20px", // Center the text vertically inside the circle
                fontSize: "10px", // Adjust font size to fit inside circle
              }}
              title={item.roomName} // Display room name on hover
            >
              {item.roomName?.charAt(0)}{" "}
              {/* Display the first letter of room name */}
            </div>
          );
        });
      },
      sortable: false,
    },
    // {
    //   name: <CustomHeader title="Action" />,
    //   sortable: true,
    //   center: true,
    //   style: { maxWidth: "50%" },
    //   cell: (row) => (
    //     <UL className="action simple-list flex-row">
    //       <LI className="view">
    //         <a
    //           href={`/dashboard/users/employees/view/${row.id}`}
    //           className="btn btn-primary d-flex justify-content-center align-items-center"
    //           style={{
    //             borderRadius: "50%",
    //             width: "40px",
    //             height: "40px",
    //             marginRight: 6,
    //           }}
    //         >
    //           <i className="icon-eye text-white" />
    //         </a>
    //       </LI>
    //       <LI className="edit">
    //         <a
    //           href={`/dashboard/users/employees/edit/${row.id}`}
    //           className="btn btn-light d-flex justify-content-center align-items-center"
    //           style={{ borderRadius: "50%", width: "40px", height: "40px" }}
    //         >
    //           <i className="icon-pencil-alt text-dark" />
    //         </a>
    //       </LI>
    //       {/* <LI className="delete">
    //       <a
    //         href={""}
    //         className="btn btn-danger d-flex justify-content-center align-items-center"
    //         style={{ borderRadius: "50%", width: "40px", height: "40px" }}
    //       >
    //         <i className="icon-trash text-white" />
    //       </a>
    //     </LI> */}
    //     </UL>
    //   ),
    // },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <td className="text-end">
          <a
            className="icon"
            href={`/dashboard/users/employees/edit/${row.id}`}
          >
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-pencil me-1"></i>
              {"Edit"}
            </Btn>
          </a>
          <a
            className="icon"
            href={`/dashboard/users/employees/view/${row.id}`}
          >
            <Btn color="transparent" size="sm">
              <i className="fa-solid fa-eye me-1"></i>
              {"View"}
            </Btn>
          </a>
          {/* <a className="icon" href="#javascript">
            <Btn color="danger" size="sm">
              <i className="fa-solid fa-trash me-1"></i>
              {"Delete"}
            </Btn>
          </a> */}
        </td>
      ),
    },
  ];

  return columns;
};
