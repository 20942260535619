import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";

export default function NurseryDropDown(props: any) {
  const [nurseryData, setNurseryData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const disableUrl = [
    "employees/edit",
    "children/add",
    "children/edit",
    "children/",
  ];

  useEffect(() => {
    if (
      props.user &&
      props.user.role != "super_admin" &&
      props?.user?.nursery?.length > 0
    ) {
      const data = props.user.nursery.map((nursery: any) => {
        return {
          label: `${nursery.name}`,
          value: nursery.id,
        };
      });
      setNurseryData(data);
    }
  }, [props.user]);

  const handleChange: any = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as any;
      props.setNursery(newSelectedOption?.value || null);
    }
  };

  useEffect(() => {
    setDisabled(disableUrl.some((url) => location.pathname.includes(url)));
  }, [location.pathname]);

  return (
    <div className="form-group-header d-lg-block">
      <div className="Typeahead Typeahead--twitterUsers">
        <div className="u-posRelative d-flex align-items-center">
          <Select
            id={"nursery-dropdown"}
            options={nurseryData}
            value={nurseryData.find(
              (option: any) => option.value === props.selectedNursery
            )}
            onChange={handleChange}
            isDisabled={disabled}
            className={`nursery-dropdown-form w-100`}
          />
        </div>
      </div>
    </div>
  );
}
